import * as types from './actionType';

export const fetchScoreCardOrderList = (data) => ({
  type: types.SCORECARDORDERING_LIST_REQUESTED,
  data: data,
});

export const fetchUpdateScoreCardOrder = (data) => ({
  type: types.SCORECARDORDERING_UPDATE_REQUESTED,
  data: data,
});

export const fetchScoreCardOrderOpu = () => ({
  type: types.SCORECARDORDERING_OPU_REQUESTED,
});

import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import s from "./sustainability.module.css";
import ScrollContainer from "react-indiana-drag-scroll";
import { mapPointViewDetail, updateActiveTab } from "../../action/actionMap";
import { useEffect, useRef, useState } from "react";
import { URL_SIDE_BAR } from "../../constants/map.constants";
import { useDispatch, useSelector } from "react-redux";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { get } from "lodash";
import { transformToPixels } from "../../utils/LatLong.utils";
import { getSustainabilityMap } from "../../utils/user.utils";
import queryString from "query-string";
import { fetchDisableCallOutSustainability, fetchGhgData, ghgMapLevelChange } from "../../action/actionSustainability";
import GHGPointComponent from "../../components/Sustainability/GHGPointComponent";
import SustainabilityCalloutComponent from "../../components/Callout/SustainabilityCallout";
import { buildGhgUrl } from "../../utils/Url.utils";
import { useHistory } from "react-router-dom";
import GhgActionsComponent from "../../components/Sustainability/GhgActionsComponent";
import { ClockCircleOutlined } from "@ant-design/icons";
import GHGMultiPointComponent from "../../components/Sustainability/GHGMultiPoint/GHGMultiPointComponent";
import GHGListEquipmentSATUComponent from "../../components/Sustainability/GHGListEquipment/GHGListEquipmentSATUComponent";
import GHGListEquipmentDUAComponent from "../../components/Sustainability/GHGListEquipment/GHGListEquipmentDUAComponent";
import GHGListEquipmentRGTPComponent from "../../components/Sustainability/GHGListEquipment/GHGListEquipmentRGTPComponent";
import GHGListEquipmentRGTSUComponent from "../../components/Sustainability/GHGListEquipment/GHGListEquipmentRGTSUComponent";
import GHGListEquipmentGTComponent from "../../components/Sustainability/GHGListEquipment/GHGListEquipmentGTComponent";

const SustainabilityComponent = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const params = queryString.parse(window.location.search);

  //Variables for size calculations
  let winWidth = window.innerWidth;
  let winHeight = window.innerHeight;
  const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100) / 100;

  //States defined
  const [originalImageSize, setOriginalImageSize] = useState({
    width: 1440,
    height: 810,
  });
  const [currentImageSize, setCurrentImageSize] = useState(getImageSize());
  const [isBaseWidth, setIsBaseWidth] = useState(true);
  const [ghgPoints, setGHGPoints] = useState([]);
  const [locationName, setLocationName] = useState('')
  const [ghgImage, setGHGImage] = useState(`/img/malaysia_map.jpg`);
  const handle = useFullScreenHandle();

  //Retrieve data from store
  const ghgData = useSelector((state) => get(state, "sustainability.mapData", {}));

  const mapScroll = useRef<null | HTMLDivElement>(null);
  // useEffect(() => {
  //   if (!mapScroll) return;
  //   mapScroll.current?.scrollIntoView({ behavior: "smooth" })
  // }, [mapScroll]);

  useEffect(() => {
    if (params?.level === '2') {  
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      mapScroll.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [params]);

  useEffect(() => {
    if(params?.location === "4"){
      window.scrollTo({
        top: window.innerHeight * 0.1,
        behavior: 'smooth'
      });
    }
  }, [params]);

  useEffect(() => {
    if(params?.location === "5"){
      window.scrollTo({
        top: (document.documentElement.scrollHeight - window.innerHeight) / 2,
        behavior: 'smooth'
      });
    }
  }, [params]);

  // Tracking mapData change
  useEffect(() => {
    if (ghgData?.result) {
      originalImageSizeChange();
      dispatch(ghgMapLevelChange(ghgData?.result?.level));
      const location = Number(ghgData?.result?.locationId);
      const level = Number(ghgData?.result?.level);
      history.push(buildGhgUrl(location, level));
      document.title =
        ghgData?.result?.locationName?.toUpperCase() +
        " - PETRONAS - GO Dashboard";
    }
  }, [ghgData]);

  useEffect(() => {
    dispatch(updateActiveTab(URL_SIDE_BAR.SUSTAINABILITY));
    const defaultMap = getSustainabilityMap();
    if (params?.location) {
      // const allowLocation = isAllowLocation(params?.location)
      //   ? params?.location
      //   : defaultMap.locationId;
      dispatch(fetchGhgData(Number(params?.location)));
    }
    else {
      dispatch(fetchGhgData(defaultMap.locationId));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize)
  });

  function originalImageSizeChange() {
    const imageSrc = ghgData?.result?.imageUrl?.satellite//Background Image 
    let img = new Image();
    img.src = imageSrc;
    setGHGImage(imageSrc);
    img.onload = () => {
      let imgElm = document.getElementById("rootMapBg") as HTMLElement;
      const imgWidth = imgElm?.clientWidth;
      const imgHeight = imgElm?.clientHeight;
      setOriginalImageSize({ width: imgWidth, height: imgHeight });
      setIsBaseWidth(winWidth / imgWidth > winHeight / imgHeight);
      const dataTransform = transformData(ghgData?.result?.locationPoints);
      setGHGPoints(dataTransform);
    };
    img.onerror = () => {
      setOriginalImageSize({ width: winWidth, height: winHeight });
      setIsBaseWidth(true);
      const dataTransform = transformData(ghgData?.result?.locationPoints);
      setGHGPoints(dataTransform);
    };

    if (params.id) {
      const rs = ghgData?.result?.locationPoints?.find(
        (x: any) => x.locationId === Number(params.id)
      );
      dispatch(mapPointViewDetail(rs));
    }
  }

  // Handle window resize
  function handleResize() {
    winWidth = window.innerWidth;
    winHeight = window.innerHeight;
    setIsBaseWidth(
      winWidth / originalImageSize.width > winHeight / originalImageSize.height
    );
    setCurrentImageSize(getImageSize());
    dispatch(fetchGhgData(ghgData?.result?.locationId));
    dispatch(fetchDisableCallOutSustainability())
  }

  // detect IOS device
  function isIOSDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  function getImageSize() {
    const zoomRatio = zoomLevel > 1 ? zoomLevel : 1;
    const oWidth = 1440;
    const oHeight = 810;
    if (sessionStorage.getItem("preUrl") || isIOSDevice()) {
      return winWidth / oWidth > winHeight / oHeight
        ? { width: winWidth * zoomRatio, height: oHeight * (winWidth / oWidth) * zoomRatio }
        : { width: oWidth * (winHeight / oHeight) * zoomRatio, height: winHeight * zoomRatio };
    }
    return { width: winWidth * zoomRatio, height: oHeight * (winWidth / oWidth) * zoomRatio };
  }

  // Transform data
  function transformData(data: any) {
    const imageCoordinates = ghgData?.result?.imageCoordinates;
    const imageNorthLat = imageCoordinates?.topLeft?.latitude;
    const imageSouthLat = imageCoordinates?.bottomLeft?.latitude;
    const imageWestLong = imageCoordinates?.topLeft.longitude;
    const imageEastLong = imageCoordinates?.bottomRight.longitude;

    data?.forEach((element: any) => {
      const lat = element?.coordinates?.latitude;
      const long = element?.coordinates?.longitude;
      const oWidth = originalImageSize.width;
      const oHeight = originalImageSize.height;
      const elementTransform = transformToPixels(
        lat,
        long,
        imageNorthLat,
        imageSouthLat,
        imageWestLong,
        imageEastLong,
        oWidth,
        oHeight,
        currentImageSize.width
      );
      element.X = elementTransform.X;
      element.Y = elementTransform.Y;
    });
    return data;
  }

  const currentPoint = ghgData?.result?.locationId;
  
  function GHGPoint() {
    if (!ghgPoints.length) {
      switch (currentPoint) {
        case 2:
              return <GHGListEquipmentRGTSUComponent location={ghgData?.result?.locationId}/>;
        case 3:
              return <GHGListEquipmentRGTPComponent location={ghgData?.result?.locationId}/>;
        case 6:
              return <GHGListEquipmentSATUComponent location={ghgData?.result?.locationId}/>;  
        case 7:
              return <GHGListEquipmentDUAComponent location={ghgData?.result?.locationId}/>;
        default:
              return '';
      }
  }
    return ghgPoints?.filter(function (location: any) { return !location.isFlowrate }).map((location: any, index: any) => {
      
      return (
        <div key={index}>
          {location.parameters.length === 1 ? <GHGPointComponent data={location} setLocationName={setLocationName} /> : <GHGMultiPointComponent data={location} />}
        </div>
      );
    });
  }

  function PiTagLegend() {
    return (
      typeof params?.location === 'string' && params?.location === '1' && (
        <div className={s.timeStamp}>
          <div>
            <img src='/img/icon/pi.svg' alt='Notifications' />
            <span style={{marginLeft: '2px'}}>Hourly from PI Tag</span>
          </div>
        </div>
      )
    );
  }

  function setBaseClass() {
    return `${isBaseWidth ? s.baseWidth : s.baseHeight}`;
  }
  return (
    <>
      <Desktop>
        <div className={`fullscreen-pt-0 ${s.sustainabilityRoot}`}>
          <FullScreen handle={handle}>
            <ScrollContainer
              horizontal={false}
              className={`page-container bg-map ${s.sustainabilityContainer} ${setBaseClass()}`}
            >
              {/* Map Actions */}
              <GhgActionsComponent />

              {/* Map Background */}
              {<img
                id="rootMapBg"
                alt="Map Background"
                src={ghgImage}
                width={currentImageSize.width}
                height={currentImageSize.height}
                className={s.mapBg}
              />}

              {GHGPoint()}
              {PiTagLegend()}
              {/* scroll to bot */}
              <div ref={mapScroll} />
            </ScrollContainer>
            {/* Point details Right Panel */}
            <SustainabilityCalloutComponent specificLocation={locationName} />
          </FullScreen>

        </div>
      </Desktop>
      <Mobile>
        <ScrollContainer
          className={`page-container bg-map ${s.sustainabilityContainer} ${setBaseClass()}`}
        >
          {/* Map Actions */}
          <GhgActionsComponent />

          {/* Map Background */}
          {<img
            id="rootMapBg"
            alt="Map Background"
            src={ghgImage}
            width={currentImageSize.width}
            height={currentImageSize.height}
            className={s.mapBg}
          />}

          {GHGPoint()}
        </ScrollContainer>

        {/* Point details Right Panel */}
        <SustainabilityCalloutComponent specificLocation={locationName} />
      </Mobile>
      <Tablet>
        <ScrollContainer
          className={`page-container bg-map ${s.sustainabilityContainer} ${setBaseClass()}`}
        >
          {/* Map Actions */}
          <GhgActionsComponent />

          {/* Map Background */}
          {<img
            id="rootMapBg"
            alt="Map Background"
            src={ghgImage}
            width={currentImageSize.width}
            height={currentImageSize.height}
            className={s.mapBg}
          />}

          {GHGPoint()}
        </ScrollContainer>

        {/* Point details Right Panel */}
        <SustainabilityCalloutComponent specificLocation={locationName} />
      </Tablet>
    </>
  );
}

export default SustainabilityComponent;


import { Input, Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import { get } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { mapModalChange } from "../../action/actionMap";
import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import s from "../Map/map.module.css";
import sMobile from "../Map/mapResponsive.module.css";

const isTemplateFive = { undefined: false, 5: true };
const MapModalComponent = (props: any) => {
  const [filterTable, setFilterTable] = useState(null as any);
  const isModalVisible = useSelector((state) =>
    get(state, "callout.isModalVisible", null)
  );
  const deliverHistoryData = useSelector((state) =>
    get(state, "callout.deliverHistoryData", [])
  );
  const callOutData = useSelector((state) =>
    get(state, "callout.calloutData", null)
  );
  const [expandedRow, setExpandedRow] = useState(NaN);
  const dispatch = useDispatch();

  const handleOk = () => {
    dispatch(mapModalChange(false));
  };

  const handleCancel = () => {
    dispatch(mapModalChange(false));
  };

  const onSearch = (value: any) => {
    const filteredTable = deliverHistoryData.filter((o: any) =>
      Object.keys(o).some((k) => {
        return `${String(o[k].label)}${String(o[k].subLabel)}`
          .toLowerCase()
          .includes(value.toLowerCase());
      })
    );
    const modalData = value === "" ? null : filteredTable;
    setFilterTable(modalData);
  };

  const templateTypeId: number = callOutData?.typeId;
  let destinationColTitle = "DESTINATION";

  let columns: any = [];
  let mobileColumns: any = [];

  const destinationTitle = isTemplateFive[templateTypeId]
    ? "ORIGIN/DESTINATION"
    : destinationColTitle;
  columns = renderDesktopColumn(
    destinationTitle,
    isTemplateFive[templateTypeId]
  );
  mobileColumns = renderMobileColumn(destinationColTitle);

  function setImageUrl(imageUrl) {
    return imageUrl !== "" ? imageUrl : "/img/icon/Blank Flag.svg";
  }

  function _renderTableText(text) {
    return (
      <div className={s.tableRow}>
        <span>{text}</span>
      </div>
    );
  }
  function _renderVesselText(text) {
    return (
      <div className={s.tableRow}>
        <span>{text.label}</span>
        <br />
        <span>{text.sublabel}</span>
      </div>
    );
  }

  function vesselSorting(a: any, b: any) {
    return `${a?.vessel?.label}${a?.vessel?.sublabel}`.localeCompare(
      `${b?.vessel?.label}${b?.vessel?.sublabel}`);
  }

  function locationSorting(a: any, b: any) {
    return `${a?.location?.label}${a?.location?.sublabel}`.localeCompare(
      `${b?.location?.label}${b?.location?.sublabel}`);
  }
  function renderDesktopColumn(destTitle, showActivity) {
    let activityCol = {};
    if (showActivity) {
      activityCol = {
        title: "ACTIVITY",
        dataIndex: "activity",
        render: (text: any) => (
          <div className={s.tableRowActivity}>
            <div>
              <img
                className={text.label === "Unloaded" ? "" : s.activityArrowUp}
                src={"/img/icon/Icon Up.svg"}
                alt="Arrow"
              />
            </div>
            <div className={s.tableRow}>
              <span>
                {text.volume} {text.unit}
              </span>
              <br />
              <span>{text.label}</span>
            </div>
          </div>
        ),
        sorter: (a: any, b: any) =>
          `${a?.volume}`.localeCompare(`${b?.volume}`),
      };
    }
    return [
      {
        title: "VESSEL",
        dataIndex: "vessel",
        render: (text: any) => _renderVesselText(text),
        sorter: (a: any, b: any) => vesselSorting(a, b),
      },
      {
        title: `${destTitle}`,
        dataIndex: "location",
        render: (text: any) => (
          <div className={s.tableRowLocation}>
            <div>
              <img src={setImageUrl(text.imageUrl)} alt="Flag" />
            </div>
            <div className={s.locationTextModal}>
              <span>{text.label}</span>
              <span>{text.sublabel}</span>
            </div>
          </div>
        ),
        sorter: (a: any, b: any) => locationSorting(a, b)
      },
      activityCol,
      {
        title: "BERTHING",
        dataIndex: "berthing",
        render: (text: any) => _renderTableText(text),
        sorter: (a: any, b: any) =>
          moment(a.berthing).unix() - moment(b.berthing).unix(),
      },
      {
        title: "SAILWAY",
        dataIndex: "sailway",
        render: (text: any) => _renderTableText(text),
        sorter: (a: any, b: any) =>
          moment(a.sailway).unix() - moment(b.sailway).unix(),
      },
    ];
  }

  function renderMobileColumn(locationColTitle) {
    return [
      {
        title: "VESSEL",
        dataIndex: "vessel",
        render: (text) => _renderVesselText(text),
        sorter: (a, b) => vesselSorting(a, b),
      },
      {
        title: `${locationColTitle}`,
        dataIndex: "location",
        render: (text) => (
          <div className={s.tableRowLocation}>
            <div>
              <img src={setImageUrl(text.imageUrl)} alt="Flag" />
            </div>
            <div className={sMobile.locationTextModal}>
              <span>{text.label}</span>
              <span>{text.sublabel}</span>
            </div>
          </div>
        ),
        sorter: (a, b) => locationSorting(a, b)
      },
      {
        title: ``,
        dataIndex: "",
        render: (text, record, index) => (
          <div>
            <img
              src={
                isRowExpand(record.key)
                  ? "/img/icon/Button Minus.svg"
                  : "/img/icon/Button Plus.svg"
              }
              alt={"Button"}
              onClick={() =>
                setExpandedRow(expandedRow === record.key ? NaN : record.key)
              }
            />
          </div>
        ),
      },
    ];
  }

  function isRowExpand(key: number) {
    return expandedRow === key;
  }

  function getSectionHeading() {
    let sectionHeading = 'Delivery History';

    if (callOutData?.sectionHeading) {
      sectionHeading = callOutData.sectionHeading;
    }
    if (callOutData?.locationName) {
      sectionHeading = callOutData.locationName;
    }

    return sectionHeading;
  }

  const expandedRowRender = (record) => {
    return (
      <div className={sMobile.mobileModalExpandRowContainer}>
        <div>
          <div>BERTHING</div>
          <div>SAILWAY</div>
        </div>
        <div></div>
        <div>
          <div>{record.berthing}</div>
          <div>{record.sailway}</div>
        </div>
      </div>
    );
  };

  const expandedRowtTemplateFiveRender = (record) => {
    return (
      <div className={sMobile.mobileModalExpandRow5Container}>
        <div>
          <div>ACTIVITY</div>
          <div>BERTHING</div>
          <div>SAILWAY</div>
        </div>
        <div>
          <div>
            <img alt="Close" src="/img/icon/Icon Up.svg" />
          </div>
        </div>
        <div>
          <div>
            {record.activity.volume} {record.activity.unit}{" "}
            {record.activity.volume} {record.activity.label}
          </div>
          <div>{record.berthing}</div>
          <div>{record.sailway}</div>
        </div>
      </div>
    );
  };
  const _renderMobileTablet = () => (
    <Modal
      className="scrollInfoMobileModal"
      closable={false}
      visible={isModalVisible}
      onOk={handleOk}
      footer={null}
      onCancel={handleCancel}
      zIndex={1000}
    >
      <div className={sMobile.mapRightPanelBody}>
        <div className={sMobile.mapRightPaneMobileTitle}>
          <div className={sMobile.mobileModalTitle}>
            <span>
              {getSectionHeading()} (
              {filterTable === null
                ? deliverHistoryData.length + 1
                : filterTable.length + 1}
              )
            </span>
          </div>
          <img
            alt="Close button"
            src="/img/icon/ic_close.svg"
            onClick={handleCancel}
          />
        </div>
        <Input.Search
          className={sMobile.inputSearch}
          placeholder="Search"
          onSearch={onSearch}
        />
        <div className={sMobile.divBorder} />

        <div className={`mobileModal ${sMobile.mapRightPanelMobileContent}`}>
          <Table
            columns={mobileColumns}
            dataSource={filterTable === null ? deliverHistoryData : filterTable}
            showSorterTooltip={false}
            expandIconColumnIndex={-1}
            expandedRowRender={
              isTemplateFive[templateTypeId]
                ? expandedRowtTemplateFiveRender
                : expandedRowRender
            }
            expandedRowKeys={[expandedRow]}
            pagination={{ pageSize: 9, position: ["bottomLeft"] }}
            rowClassName={(record, index) => {
              return record.key === expandedRow
                ? "mobileHiglightRow"
                : "mobileRow";
            }}
            scroll={{ y: 600, x: "max-content" }}
          />
        </div>
      </div>
    </Modal>
  );

  return (
    <>
      <Desktop>
        <Modal
          className="scrollInfoModal"
          closable={false}
          visible={isModalVisible}
          onOk={handleOk}
          footer={null}
          onCancel={handleCancel}
          width={820}
        >
          <div className={s.scrollInfoModalHeader}>
            <span>{getSectionHeading()}</span>
            <div>
              <Input.Search
                placeholder="Search"
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </div>
            <img
              alt="Close button"
              src="/img/icon/ic_close.svg"
              onClick={handleCancel}
            />
          </div>
          <div className={s.scrollInfoModalTable}>
            <Table
              rowKey={(record) => record.uid}
              columns={columns}
              dataSource={
                filterTable === null ? deliverHistoryData : filterTable
              }
              pagination={{ pageSize: 6, position: ["bottomLeft"] }}
              showSorterTooltip={false}
              scroll={{ y: 552, x: "max-content" }}
            />
          </div>
        </Modal>
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>
    </>
  );
};
export default MapModalComponent;

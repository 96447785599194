import { ENDPOINTS } from "../constants/endpoints";
import axiosClient from "./axiosClient.service";

export function getMapData(data: any) {
    return axiosClient.get(ENDPOINTS.GET_MAP_DATA + data)
}

export function getCalloutData(data: any) {
    return axiosClient.get(ENDPOINTS.GET_CALLOUT_DATA + data)
}

export function getSustainabilityCalloutData(data: any) {
    return axiosClient.get(ENDPOINTS.GET_SUSTAINABILITY_CALLOUT_DATA + data)
}

export function getCalloutDataSec(data: any) {
    return axiosClient.get(ENDPOINTS.GET_CALLOUT_DATA_SEC + data)
}

export function getHightlightData(locationId: any) {
    return axiosClient.get(ENDPOINTS.GET_HIGHLIGHT_DATA + locationId)
}

export function getPrimaryData(locationId: any, tabId: any) {
    return axiosClient.get(ENDPOINTS.GET_PRIMARY_INFO_DATA + locationId + tabId)
}

export function getDeliverData(locationId: any) {
    return axiosClient.get(ENDPOINTS.GET_DELIVER_HISTORY_DATA + locationId)
}

export function getCargoDetails(
  locationId: any,
  beforeDateTime: any,
  limit: any,
  latestByBerth: any
) {
  return axiosClient.get(
    ENDPOINTS.GET_CARGO_DETAILS +
      locationId +
      beforeDateTime +
      limit +
      latestByBerth
  );
}

export function getChartData(locationId: string, tabId: string, frequency: string) {
    return axiosClient.get(ENDPOINTS.GET_CHART_DATA + locationId + tabId + frequency)
}

export function getHisTrendChartData(locationId: string, frequency: string) {
    return axiosClient.get(ENDPOINTS.GET_HIS_TREND_CHART_DATA + locationId + frequency)
}
export const GAS_BUSINESS_VALUE_CHAIN_STATIC_PROCESS = [
  {
    icon: '/img/icon/Icon Feed Gas.svg',
    text: 'Feedgas'
  },
  {
    icon: '/img/icon/Icon Production.svg',
    text: 'Production'
  },
  {
    icon: '/img/icon/Icon Inventory.svg',
    text: 'Inventory'
  },
  {
    icon: '/img/icon/Icon Delivered.svg',
    text: 'Delivered'
  }
]
export const LMT_VALUE_CHAIN_STATIC_PROCESS = [
  {
    icon: '/img/icon/Icon Feed Gas.svg',
    text: 'Gas fields',
    col: 10
  },
  {
    icon: '/img/icon/Icon Production.svg',
    text: 'production',
    col: 6
  },
  {
    icon: '/img/icon/Icon Inventory.svg',
    text: 'sales',
    col: 4
  },
  {
    icon: '/img/icon/Icon Delivered.svg',
    text: 'countries',
    col: 4
  }
]

export const LMT_SALES_VOLUME_TAB = {
  GENERAL: '0',
  COUNTRY_DETAIL: '1',
  CUSTOM_DETAIL: '2'
}

export const COUNTRY_LIST = ['Japan', 'Korea', 'China', 'Malaysia', 'Thailand', 'UK', 'Others']
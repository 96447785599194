import { Action } from "redux";

import * as types from "../action/actionType";

export const initialData = {
    datapipelineData: {},
    isLoading: false
};

interface DataPipelineAction extends Action {
    payload: string | any;
    data: string | any;
}

export function dataPipelineReducer(
    state = initialData,
    action: DataPipelineAction
) {
    switch (action.type) {
        case types.DATA_PIPELINE_SUCCEEDED: {
            return {
                ...state,
                datapipelineData: action.payload,
                isLoading: false
            };
        }
        case types.DATA_PIPELINE_REQUESTED: {
            return {
                ...state,
                isLoading: true
            };
        }
        case types.DATA_PIPELINE_FAILED: {
            return {
                ...state,
                isLoading: false,
            };
        }
    }
    return state;
}

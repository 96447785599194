import { Col, Row } from 'antd';
import { get } from 'lodash';
import { useSelector } from 'react-redux';

import s from '../../SecondaryCard.module.css';
import cardStyle from './LmtHssePerformanceSecondary.module.css';

function LmtHsseMhsev() {
    const { secondaryCardData } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            secondaryCardData: scorecard.secondaryCardDetail
        };
    });

    function getQuarterColorClass(quarter) {
        var colorClass = quarter.replace(/\s+/g, '').toLowerCase();
        return colorClass;
    }

    const mhsev = secondaryCardData.mhses;

    return (
        <div className={s.detailsContent}>
            {mhsev?.length > 0 &&
                mhsev.map((card) => {
                    return (
                        <div className={s.detailsItem}>
                            <div className={cardStyle.mhsevInfo}>
                                <div className={`${cardStyle.mhsevQuarter} ${cardStyle[getQuarterColorClass(card.date)]}`} >
                                    <div className={cardStyle.mhsevQuarterValue}>
                                        {card.date}
                                    </div>
                                </div>
                                <div className={cardStyle.mhsevMission}>
                                    {card.entity}
                                </div>
                            </div>
                        </div>);
                        })}
                        </div>
                    );
                }

export default LmtHsseMhsev;
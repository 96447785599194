import { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../action/actionType';
import { getGhgData, getSustainabilityDrilldownPFLNG, getSustainabilityDrilldownGT, getSustainabilityDrilldownRGTP, getSustainabilityDrilldownRGTSU, updateCalloutSummary } from '../services/sustainability.service';
interface SustainabilityAction extends Action {
  data: string | any;
  payload: {} | any;
}

function* fetchGhgData(action: SustainabilityAction) {
    try {
      const data = yield call(getGhgData, action.data);
      yield put({ type: types.GHG_DATA_FETCH_SUCCEEDED, payload: data });
    } catch (error: any) {
      yield put({ type: types.GHG_DATA_FETCH_FAILED, message: error.message });
    }
  }

function* fetchSustainabilityDrilldownFPLNG(action?: SustainabilityAction) {
  try {
    const response = yield call(getSustainabilityDrilldownPFLNG, action?.payload)
    yield put({type: types.GET_DRILLDOWN_PFLNG_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_DRILLDOWN_PFLNG_FAIL,
      message: error.message,
    });
  }
}

function* fetchSustainabilityDrilldownRGTP(action?: SustainabilityAction) {
  try {
    const response = yield call(getSustainabilityDrilldownRGTP, action?.payload)
    yield put({type: types.GET_DRILLDOWN_RGTP_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_DRILLDOWN_RGTP_FAIL,
      message: error.message,
    });
  }
}

function* fetchSustainabilityDrilldownRGTSU(action?: SustainabilityAction) {
  try {
    const response = yield call(getSustainabilityDrilldownRGTSU, action?.payload)
    yield put({type: types.GET_DRILLDOWN_RGTSU_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_DRILLDOWN_RGTSU_FAIL,
      message: error.message,
    });
  }
}

function* fetchSustainabilityDrilldownGT(action?: SustainabilityAction) {
  try {
    const response = yield call(getSustainabilityDrilldownGT, action?.payload)
    yield put({type: types.GET_DRILLDOWN_GT_SUCCESS,
      payload: response,
    })
  } catch (error: any) {
    yield put({
      type: types.GET_DRILLDOWN_GT_FAIL,
      message: error.message,
    });
  }
}

  function* updateSustainabilitySummary(action: SustainabilityAction) {
    try {
      yield put({
        type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
        payload: true,
      });
      const data = yield call(updateCalloutSummary, action.data);
      yield put({ 
        type: types.SUSTAINABILITY_SUMMARY_UPDATE_SUCCEEDED, 
        payload: data 
      });
    } catch (error: any) {
      yield put({
        type: types.RC_ANNOUNCEMENT_ACTION_FAILED,
        payload: error?.response?.data?.message || error.message,
      });
      yield put({
        type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
        payload: false,
      });
    }
  }

  export function* workerFetchGhgData() {
    yield takeLatest(types.GHG_DATA_FETCH_REQUESTED, fetchGhgData);
    yield takeLatest(types.SUSTAINABILITY_SUMMARY_UPDATE_REQUESTED, updateSustainabilitySummary);
    yield takeLatest(types.GET_DRILLDOWN_PFLNG_REQUESTED, fetchSustainabilityDrilldownFPLNG);
    yield takeLatest(types.GET_DRILLDOWN_RGTP_REQUESTED, fetchSustainabilityDrilldownRGTP);
    yield takeLatest(types.GET_DRILLDOWN_RGTSU_REQUESTED, fetchSustainabilityDrilldownRGTSU);
    yield takeLatest(types.GET_DRILLDOWN_GT_REQUESTED, fetchSustainabilityDrilldownGT);
  }
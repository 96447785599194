import { Popover } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";

import s from "./DotColorsComponent.module.css";

interface Props {
  index?: number;
  color: "green" | "red" | "amber" | string;
  selectColorFunc?: any;
}

const colorClass = {
  green: s.green,
  red: s.red,
  amber: s.amber,
};

const colorText = {
  green: "Green",
  red: "Red",
  amber: "Amber",
};

const colorsArray = ["green", "red", "amber"];

const DotColorsPopComponent = ({ color, selectColorFunc, index }: Props) => {
  const [editedColor, seteditedColor] = useState("");
  const currentColor = isEmpty(editedColor) ? color : editedColor;
  function setColor(value) {
    seteditedColor(value);
    const setIndex = index ? index : 0;
    selectColorFunc(value, setIndex);
  }
  function _renderContent() {
    return (
      <div className={s.popoverContent}>
        {colorsArray.map((value, idxColor) => (
          <span onClick={() => setColor(value)} key={idxColor}>
            {colorText[value]}
          </span>
        ))}
      </div>
    );
  }
  return (
    <Popover
      overlayClassName="tbodyDropdown"
      placement="bottom"
      trigger="click"
      content={_renderContent()}
    >
      <div className={s.dotBox}>
        <div className={s.colorsColum}>
          <div className={colorClass[currentColor]}>
            <div />
          </div>
          <div className={s.colorText}>{colorText[currentColor]}</div>
        </div>
        <img src="/img/icon/DownArrow.svg" alt="arrow" />
      </div>
    </Popover>
  );
};

export default DotColorsPopComponent;

import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import { fetchAuth, fetchLoginCountAction } from "../action/actionPrivateRoute";
import HeaderComponent from "../components/Header/HeaderComponent";
import LoadingComponent from "../components/Loading/LoadingComponent";
import ModalAuthComponent from "../components/ModalAuth/ModalAuthComponent";
import ModalContentReleaseAnnouncement from "../components/ModalContentReleaseAnnouncement/ModalContentReleaseAnnouncement";
import SideBarComponent from "../components/SideBar/SideBarComponent";
import { ROUTE_ADMIN } from "../constants/route.constants";
import { SESSION_STORAGE_KEY } from "../constants/user.constants";
import { getRouteBaseOnAllowViews, isUserAdmin, userRoutes } from "../utils/user.utils";
import { logoutAllTabsEventListener } from "../AuthService";

function MainPageComponent() {
  const dispatch = useDispatch();
  const pathName = window.location.pathname;

  const [isLoginCountCall, setIsLoginCountCall] = useState(false);
  const isAuth = useSelector((state) =>
    get(state, "privateroute.isAuth", null)
  );

  useEffect(() => {
    dispatch(fetchAuth());
    sessionStorage.setItem("preUrl", pathName);
    logoutAllTabsEventListener()
  }, []);

  useEffect(() => {
    if (isAuth === "error") {
      dispatch(fetchAuth());
    }
    if (sessionStorage.getItem(SESSION_STORAGE_KEY.PERSONA_TOKEN)) {
      if (!isLoginCountCall) {
        setIsLoginCountCall(true)
        dispatch(fetchLoginCountAction())
      }
    }
  }, [isAuth]);


  if (
    isAuth === null ||
    isAuth === "error" ||
    isEmpty(sessionStorage.getItem(SESSION_STORAGE_KEY.PERSONA_TOKEN))
  ) {
    return (
      <>
        <ModalAuthComponent />
      </>
    );
  }

  return (
    <>
      <HeaderComponent />
      <SideBarComponent />
      <LoadingComponent />
      <Switch>
        <Redirect exact from="/" to={userRoutes()[4]?.path} />
        {getRouteBaseOnAllowViews().map((route) => {
          return <Route {...route} />;
        })}
        {isUserAdmin() &&
          ROUTE_ADMIN.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
        <Route path="*" exact={true} component={userRoutes()[4]?.component} />
      </Switch>
      <ModalContentReleaseAnnouncement />
    </>
  );
}

export default MainPageComponent;

import { Action } from 'redux';

import * as types from '../action/actionType';

export const initialData = {
  isListLoading: false,
  listData: [],
  opuList: [],
};

interface ScorecardOrderAction extends Action {
  payload: string | any;
  data: string | any;
}

export function scoreCardOrderReducer(
  state = initialData,
  action: ScorecardOrderAction
) {
  switch (action.type) {
    case types.SCORECARDORDERING_LIST_REQUESTED: {
      return {
        ...state,
        isListLoading: true,
        listData: [],
      };
    }
    case types.SCORECARDORDERING_LIST_SUCCEEDED: {
      return {
        ...state,
        listData: action.payload,
        isListLoading: false,
      };
    }
    case types.SCORECARDORDERING_LIST_FAILED: {
      return {
        ...state,
        isListLoading: false,
        listData: [],
      };
    }
    case types.SCORECARDORDERING_UPDATE_REQUESTED: {
      return {
        ...state,
        isListLoading: true,
      };
    }
    case types.SCORECARDORDERING_OPU_SUCCEEDED: {
      return {
        ...state,
        opuList: action.payload,
      };
    }
  }
  return state;
}

import * as types from "./actionType";

export const fetchLimtiConfigTable = () => ({
  type: types.LIMITCONFIG_GET_WM_TABLE_REQUESTED,
});

export const fetchLimtiVCTable = () => ({
  type: types.LIMITCONFIG_GET_VC_TABLE_REQUESTED,
});

export const fetchUpdateRules = (updateRules) => ({
  type: types.LIMITCONFIG_UPDATE_RULES_REQUESTED,
  data: updateRules,
});

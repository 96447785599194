import { useDispatch, useSelector } from "react-redux";

import s from "./BaseLmtSalesSummaryChart.module.css";
import { useState } from "react";
import { setLmtSourceSelection } from "../../../../action/actionScoreCard";
import { get } from "lodash";

export interface LmtSalesBySourceSelectionComponentProps {
    sourceData: any[]
}

export default function LmtSalesBySourceSelectionComponent({sourceData}: LmtSalesBySourceSelectionComponentProps) {
  const dispatch = useDispatch();
  const { selectedLmtSource, selectedLmtCustomer, countriesInfo } = useSelector((state) => {
    const scorecard = get(state, 'scoreCard', null);
    return {
        selectedLmtCustomer: scorecard.selectedLmtCustomer,
        selectedLmtSource: scorecard.selectedLmtSource,
        countriesInfo: scorecard.lmtCountryData

    };
  });
  
  let tempSourceSelection:any[] = [];
  let filteredSources: any[] = []

  if (selectedLmtCustomer && selectedLmtCustomer.length > 0) {
    selectedLmtCustomer.forEach(customer => {
      const foundCountry = countriesInfo.find(country => {
        return country.listCustomerName.some(item => item.customerName === customer);
      });
      if (foundCountry) {
        foundCountry.listCustomerName.forEach(customerItem => {
          customerItem.listSource.forEach(source => {
            const existingSource = filteredSources.find(item => item.source === source);
            if (!existingSource) {
            filteredSources.push({ source: source, selected: true });
          }
          });
        });
      } else {
        filteredSources = sourceData
      }
    });
  } else {
    filteredSources = sourceData
  }


  // if (selectedLmtCustomer && selectedLmtCustomer.length > 0) {
    
    // for (let idx = 0; idx < selectedLmtCustomer.length; idx++) {
    // // for (let idx = 0; idx < sourceData.length; idx++) {
    //   console.log("first",selectedLmtCustomer)
    //   let customerName = countriesInfo.find(c => c.listCustomerName.customerName === selectedLmtCustomer[idx])
    //   // if (selectedLmtCustomer.indexOf(sourceData[idx].source) >= 0) {
    //   //   sourceData[idx].selected = true;
    //   // }
    //   if(customerName) {
    //     customerName.listCustomerName.listSource.map(s => {
    //       filteredSources.push({source: s, selected: true})
    //     })
    //   }
    //   else {
    //     // sourceData[idx].selected = false;
    //     filteredSources = sourceData;

    //   }
    //   // tempSourceSelection.push(sourceData[idx])
    // }
  // }
  if(selectedLmtSource && selectedLmtSource.length > 0) {
    tempSourceSelection.push({ source: "All", selected: false })
    for(let idx = 0; idx < filteredSources.length; idx++) {
      if(selectedLmtSource.indexOf(filteredSources[idx].source) >= 0) {
        filteredSources[idx].selected = true;
      }
      else {
        filteredSources[idx].selected = false;
      }
      tempSourceSelection.push(filteredSources[idx])
    }
  }
  else {
    // Add All first
    tempSourceSelection.push({ source: "All", selected: true })
    tempSourceSelection.push(...filteredSources);
  }
  tempSourceSelection = tempSourceSelection.filter((value, index) => {
    const _value = JSON.stringify(value);
    return index === tempSourceSelection.findIndex(obj => {
      return JSON.stringify(obj) === _value;
    });
  });
  // else {
  //   // Add All first
  //   // tempSourceSelection.push({source: "All", selected: true})
  //   tempSourceSelection.push(...sourceData);
  // }
  
  const [sourceSelection, setSourceSelection] = useState(tempSourceSelection);

  function handleSourceSelect(sourceName: string, selectionStatus: boolean) {
    let selectedSources: string[] = [];
    if (sourceName === "All" && selectionStatus) {
      // Change all sources to selected
      for (let idx = 0; idx < sourceSelection.length; idx++) {
        sourceSelection[idx].selected = true;
      }
    } 
    else if (sourceName === "All" && !selectionStatus) {
      for (let idx = 0; idx < sourceSelection.length; idx++) {
        sourceSelection[idx].selected = false;
      }
    }
    else {
      const selectedSource = sourceSelection.find(c => c.source === sourceName);
      const sourceIdx = sourceSelection.indexOf(selectedSource);
      if (sourceIdx >= 0) {
        sourceSelection[sourceIdx].selected = selectionStatus;
      }   
      selectedSources = sourceSelection.filter(c => c.selected).map(c => c.source);
    }
    // const selectedSource = sourceSelection.find(c => c.source === sourceName);
    // const sourceIdx = sourceSelection.indexOf(selectedSource);
    // if (sourceIdx >= 0) {
    //   sourceSelection[sourceIdx].selected = selectionStatus;
    // }
    // selectedSources = sourceSelection.filter(c => c.selected).map(c => c.source);

    setSourceSelection([...sourceSelection]);
    dispatch(setLmtSourceSelection(selectedSources));
  }

  return (
    <ul>
      {sourceSelection && sourceSelection.map((source) => {
        return (
        <div className={s.countrySelection}>
          <li key={source.source}>
            <button
              type="button"
              onClick={() => handleSourceSelect(source.source, !source.selected)}>
              <img alt="checkbox" src={source.selected
                ? "/img/icon/Checkbox-checked.svg"
                : "/img/icon/Checkbox-uncheck.svg"} />
            </button>
            <p>{source.source}</p>
          </li>
        </div>);
      })}
    </ul>
  );
}

import { ENDPOINTS, MFT_API_BASE_URL } from "../constants/endpoints";
import { OPU } from "../constants/opu.constants";
import { buildApiEndPoint, getOpuApi } from "../utils/Url.utils";
import axiosClient from "./axiosClient.service";

export function getMftExecSummary(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_EXECSUMMARY,
    ENDPOINTS.GET_MFT_EXECSUMMARY_OPU,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
  );
  return axiosClient.get(url + params);
}

export function getMftCommon(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_COMMON,
    ENDPOINTS.GET_MFT_COMMON,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA],
    [OPU.GNE]
  );
  return axiosClient.get(url + params);
}

export function getMftLineGne(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_LINE_GNE,
    ENDPOINTS.GET_MFT_LINE_OPU,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA],
  );
  return axiosClient.get(url + params);
}

export function getMftCardGne(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_CARD_GNE,
    ENDPOINTS.GET_MFT_CARD_OPU,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA],
  );
  return axiosClient.get(url + params);
}

export function getMftLineGrowthGne(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_LINE_GROWTH_GNE,
    ENDPOINTS.GET_MFT_LINE_GROWTH_OPU,
    [OPU.MLNG, OPU.LNGA]
  );
  return axiosClient.get(url + params);
}

export function getMftCardGrowthGne(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_CARD_GROWTH_GNE,
    ENDPOINTS.GET_MFT_CARD_GROWTH_OPU,
    [OPU.MLNG, OPU.LNGA]
  );
  return axiosClient.get(url + params);
}

export function getMftPeriodList() {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_PERIOD_LIST,
    ENDPOINTS.GET_MFT_PERIOD_LIST_OPU,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
  );
  return axiosClient.get(url);
}

export function getMftEmissionCardsGne(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_EMISSION_CARDS_GNE,
    ENDPOINTS.GET_MFT_EMISSION_CARDS_OPU,
    [OPU.LNGA],
  )
  return axiosClient.get(url + params);
}

export function getMftReductionCardsGne(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_REDUCTION_CARDS_GNE,
    ENDPOINTS.GET_MFT_REDUCTION_CARDS_OPU,
    [OPU.LNGA],
  )
  return axiosClient.get(url + params);

}

export function getMftEmissionChartsGne(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_EMISSION_CHARTS_GNE,
    ENDPOINTS.GET_MFT_EMISSION_CHARTS_OPU,
    [OPU.LNGA],
  )
  return axiosClient.get(url + params);
}

export function getMftIntensityChartsGne(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_INTENSITY_CHARTS_GNE,
    ENDPOINTS.GET_MFT_INTENSITY_CHARTS_OPU,
    [OPU.LNGA],
  )
  return axiosClient.get(url + params);

}

export function getMftIntensityCardsGne(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_INTENSITY_CARDS_GNE,
    ENDPOINTS.GET_MFT_INTENSITY_CARDS_OPU,
    [OPU.LNGA],
  )
  return axiosClient.get(url + params);
}

export function getMftSustainCards(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_SUSTAIN_CARDS,
    ENDPOINTS.GET_MFT_SUSTAIN_CARDS,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
  );
  return axiosClient.get(url + params);
}

export function getMftSustainLineChart(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_SUSTAIN_LINE_CHART,
    ENDPOINTS.GET_MFT_SUSTAIN_LINE_CHART,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
  );
  return axiosClient.get(url + params);
}

export function getMftSustainWaveChart(params) {
  const opu = getOpuApi("MFT Scorecard");
  const url = buildApiEndPoint(
    opu as string,
    MFT_API_BASE_URL,
    ENDPOINTS.GET_MFT_SUSTAIN_WAVE_CHART,
    ENDPOINTS.GET_MFT_SUSTAIN_WAVE_CHART,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
  );
  return axiosClient.get(url + params);
}

import s from "./PLCListEquipmentComponent.module.css";
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { toFixedSustainabilityNumber } from "../../utils/Number.utils";
import { Xwrapper } from "react-xarrows";
import Xarrow from "react-xarrows";
import queryString from "query-string";
import PiTagLegend from "../Sustainability/GHGTimestamp/GHGTimestampComponent";

const PLCListEquipment = (props) => {

  const { data,location } = props;
  const params = queryString.parse(window.location.search);

  const itemCoordinates = {
    "LPG TERMINAL": { X: 5, Y: 60 },
    "MLNG": { X: 5, Y: 20 },
    "MLNG DUA": { X: 52, Y: 55 },
    "MLNG TIGA" : {X: 81, Y: 55},
    "TRAIN 9": { X: 81, Y: 25 },
  }

  const drilldown = useSelector((state) => get(state, "sustainability.mapData.result.locationPoints", {}));  

  const lastUpdated = useSelector((state) => get(state, "sustainability.mapData", {}));

  let listDrillDowns = {};
  drilldown.map((item)  => {
    if ('listDrillDown' in item && item.listDrillDown !== null) {
      listDrillDowns[item.locationName] = item.listDrillDown;
    }
  });
  
  const [expandedModules, setExpandedModules] = useState({
    "MLNG": 4,
    "MLNG DUA": 4,
    "MLNG TIGA": 3,
    "TRAIN 9": 2,
    "LPG TERMINAL": 2
  });
  
  const handleModuleClick = (data, index) => {
    setExpandedModules(prevState => ({
      ...prevState,
      [data]: prevState[data] === index ? null : index
    }));
  };

  const renderListItems = () => {
    return (
    <Xwrapper>

      <div className={s.equipmentItemContainer} id={location.locationName} style={{ position: "absolute", top: `calc(0vh + ${itemCoordinates[data].Y}%)`, left: `calc(0vw + ${itemCoordinates[data].X}%)`}}>
        
        <div className={s.header}>{data}
        <span className={s.unit} title="tCO2e Per Hour">(tCO<sub>2</sub>e/h)</span>
        </div>

        {listDrillDowns[data] && listDrillDowns[data].listModule
          .sort((a, b) => a.moduleName === 'Common Facility' ? 1 : b.moduleName === 'Common Facility' ? -1 : 0)
          .map((location, index) => {

            const total = { value : 0 };

            function calculateTotal(num: number | string){
              const number = typeof num === 'string' ? parseFloat(num) : num;
              total.value += number;
            }

            if (((location.moduleName === 'LPG TERMINAL' && location.moduleName === 'Common Facility') 
            || location.listEquipment.length === 0 || location.moduleName === 'Callout') ) return null;

            return (
              <div
                key={index}
                className={s.equipmentModule}
                onClick={() => handleModuleClick(data, index)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleModuleClick(data, index);
                  }
                }}
                tabIndex={0}
              >
                {location.moduleName}
                <img
                src={expandedModules[data] === index ? 'img/icon/arrow-up-1.svg' : 'img/icon/arrow-down-1.svg'} 
                alt={expandedModules[data] === index ? 'Collapse' : 'Expand'} 
                style={{ float: 'right' }} 
                />


                { expandedModules[data] === index && location.listEquipment.sort((a, b) => b.value - a.value).map((key, index, array) => {
                    calculateTotal(key.value);
                    return (
                        <>
                            <div className={s.equipmentList}>
                                <div className={s.equipmentName}>{key.equipmentName}</div>
                                <div className={s.data}>{Array.isArray(key.value) ? key.value.map(val => Number(val).toFixed(3)).join(', ') : Number(key.value).toFixed(3)}</div>
                            </div>
                            {/* Display total if exhausted equipment for the module */}
                            {index === array.length - 1 && (
                                <div className={s.equipmentList}>
                                    <div className={`${s.equipmentName} ${s.font}`}>Total</div>
                                    <div className={`${s.data} ${s.font}`}>{toFixedSustainabilityNumber(total.value, 3)}</div>
                                </div>
                            )}
                        </>
                    );
                })}
              </div>
            );
          })
        }
        
      </div>
      <Xarrow color={'white'} path={'grid'}
                start={"17"} 
                end={"LPG TERMINAL"}
                dashness={true}
                strokeWidth={2}
                endAnchor={{
                  position: 'right',
                  offset: {y: -50},
                }}
                headShape={"circle"}
                gridBreak={"0%"}
            />
      <Xarrow color={'white'} path={'grid'}
                start={"14"} 
                end={"MLNG"}    
                endAnchor={{
                  position: 'right',
                  offset: {y: -50},
                }}
                startAnchor={{
                  position: 'bottom', 
                  offset:{
                  }
                }}
                dashness={true}
                strokeWidth={2}
                headShape={"circle"}
                gridBreak={"70%"}
            />
      <Xarrow color={'white'} path={'grid'}
                start={"16"} 
                end={"MLNG DUA"}
                endAnchor={{
                  position: 'top',
                  offset: {x: 92},
                }}
                dashness={true}
                strokeWidth={2}
                headShape={"circle"}
                gridBreak={"0%"}
            /> 
      <Xarrow color={'white'} path={'grid'}
                start={"13"} 
                end={"TRAIN 9"} 
                endAnchor={{
                  position: 'left',
                  offset: {y: -100},
                }}
                dashness={true}                
                headShape={"circle"}
                strokeWidth={2}
            />  
      <Xarrow color={'white'} path={'grid'}
                start={"15"} 
                end={"MLNG TIGA"} 
                endAnchor={{
                  position: 'left',
                  offset: {y: -100},
                }}
                startAnchor={{
                  position: 'right', 
                  offset:{
                    
                  }
                }}
                dashness={true}
                headShape={"circle"}
                strokeWidth={2}
            /> 
      </Xwrapper>
    );
  };
  
  return (
    <>
      <PiTagLegend param={params} ghgData={lastUpdated} />
      {renderListItems()}
    </>
  );
};

export default PLCListEquipment;

import ScrollContainer from "react-indiana-drag-scroll";
import { Tabs } from "antd";
import s from "./datahealth.module.css";
import DataPipelinesComponent from "../../components/DataHealth/DataPipelines/DataPipelinesComponent";

const DataHealthComponent = () => {
    const { TabPane } = Tabs;

    function _tabsComponent() {
        return (
            <Tabs className="usermanagement-tabs" defaultActiveKey="1">
                <TabPane tab="Data Pipelines" key="1">
                    <DataPipelinesComponent />
                </TabPane>
                <TabPane tab="Data Tables" key="2" disabled>
                </TabPane>
            </Tabs>
        );
    }
    return (
        <div className="usermanagement">
            <div className={`fullscreen-pt-0 ${s.datahealthRoot}`}>
                <ScrollContainer
                    className={`page-container bg-map ${s.datahealthContainer}`}
                >
                    <div className={s.datahealthPanel}>
                        <div className={s.datahealthPanelContainer}>
                            <h2>Data Health</h2>
                            <div className={s.datahealthDiv} />
                            {_tabsComponent()}
                        </div>
                    </div>
                </ScrollContainer>
            </div>
        </div>
    );
};
export default DataHealthComponent;

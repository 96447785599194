import { isArray } from 'lodash';
import React, { ReactElement } from 'react'
import s from './ExecutiveSummary.module.css'

export default function ExecutiveSummaryComponent(props: any): ReactElement {

  const _renderSumarryLines = () => {
    if (isArray(props?.data)) {
      return props?.data?.map((menu, idx) => {
        return (
          <li key={idx} className={s.executiveSummaryItem}>
            {menu?.category && <div>{menu?.category}</div>}
            {menu?.summaryLines &&
              menu?.summaryLines.map((summary, index) => {
                return (
                  <li key={index} className={s.executiveSummaryItem}>
                    {summary}
                    <br />
                  </li>
                );
              })}
          </li>
        );
      })
    } else {
      return props?.data?.summaryLines?.map((summary, index) => {
        return (
          <li key={index} className={index !== props?.data?.summaryLines?.length - 1 ? s.executiveSummaryItemLevel2 : ''}>
            {summary}
            <br />
          </li>
        );
      })
    }
  }

  return (
    <>
      <ul className={s.executiveSummaryList}>
      { props?.data && _renderSumarryLines()}
      </ul>
    </>
  )
}
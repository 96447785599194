import { DatePicker, Space, Tabs } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { OPU_UPCS } from '../../../constants/opu.constants';
import {
  fetchMftCardSection,
  fetchMftExecSummary,
  setMftCurrentDate,
  setMftCurrentSection,
  setMftCurrentSubSection,
  fetchMftCommon,
  resetDynamicTabs,
  updateDynamicLineChart,
} from '../../../action/actionMft';
import { MFT_SECTION } from '../../../constants/mft.constants';
import { MONTH_SHORT_TEXT } from '../../../constants/score-card.constants';
import MftTabsComponent from '../MftTabs/MftTabsComponent';
import s from './MftScroecardsComponent.module.css';
import DateDropdown from '../../DateDropdown/DateDropdown';

const MftScorecardsComponent = () => {
  const { TabPane } = Tabs;
  const { MonthPicker } = DatePicker;
  const dispatch = useDispatch();
  const [showPeriodFilter, setShowPeriodFilter] = useState(false);
  const [activeKey, setactiveKey] = useState('');
  const periodsList = useSelector((state) => get(state, 'mft.periodsList', []));
  const currentSection = useSelector((state) =>
    get(state, 'mft.currentSection', 1)
  );

  const currentSubSection = useSelector((state) =>
    get(state, 'mft.currentSubSection', 0)
  );

  const currentTabKey = useSelector((state) =>
    get(state, 'mft.currentTabKey', undefined)
  );

  const currentDate = useSelector((state) =>
    get(state, 'mft.currentDate', {
      month: NaN,
      year: NaN,
    })
  );
  const [dateFilterValue, setDateFilterValue] = useState({
    year: NaN,
    month: NaN,
  });

  const subTabs = useSelector((state) =>
    get(state, 'mft.dynamicTab.categories', [])
  );

  const selectedPersona = useSelector((state) =>
    get(state, 'user.selectedPersona')
  );

  const dynamicTab = useSelector((state) =>
    get(state, "mft.dynamicTab", undefined)
  );
  const cardSectionData = useSelector((state) =>
    get(state, "mft.cardSectionData", {})
  );
  const unitIndex = useSelector((state) => get(state, "mft.unitIndex", 0));
  const selectedPrimaryCard = useSelector((state) => get(state, "mft.selectedPrimaryCard"));
  const selectedPrimaryCardKey = selectedPrimaryCard?.key
  const selectedUnitKey = selectedPrimaryCard?.units?.[unitIndex]

  const isDisabledGrowthTab = function () {
    if (selectedPersona === OPU_UPCS.PFLNG) {
      return true;
    }
    return false;
  };

  const isDynamicTab = function () {
    if ([OPU_UPCS.PFLNG, OPU_UPCS.MLNG].includes(selectedPersona)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!isDynamicTab()) {
      dispatch(resetDynamicTabs());
    }
  }, [isDynamicTab()]);

  // get periods list
  useEffect(() => {
    if (periodsList && periodsList.length > 0) {
      const dateFilter = periodsList?.filter(
        (item: any) => item.defaultSelection === true
      )[0];

      const dateFilterData = {
        year: dateFilter?.year,
        month: dateFilter?.month,
      };
      setDateFilterValue(dateFilterData);

      const dateCurrentData = {
        year: dateFilter?.year,
        month: dateFilter?.month - 1,
      };
      if (isNaN(currentDate.month)) {
        dispatch(setMftCurrentDate(dateCurrentData));
      }
    }
  }, [periodsList]);

  useEffect(() => {
    if (!isNaN(currentDate.month) && isDynamicTab()) {
      const data = {
        year: currentDate.year,
        month: MONTH_SHORT_TEXT[currentDate.month - 1],
        sectionId: currentSection,
      };
      dispatch(fetchMftCommon(data));
    }
  }, [currentDate.month, currentDate.year, selectedPersona]);

  useEffect(() => {
    if (!isNaN(currentDate.month)) {
      console.log('currentDate:', currentDate)
      let data = {
        year: currentDate.year,
        month: MONTH_SHORT_TEXT[currentDate.month - 1],
        sectionId: currentSection,
        subTabId: currentSubSection,
        categoryId: currentTabKey,
        isDynamicTab: isDynamicTab(),
      };
      if (isDynamicTab()) {
        if (subTabs.length > 0) {
          dispatch(fetchMftCardSection(data));
        }
      } else {
        dispatch(fetchMftCardSection(data));
      }
    }
  }, [currentDate.month, currentDate.year, currentTabKey, selectedPersona]);

  useEffect(() => {
    if (!isNaN(currentDate.month)) {
      const data = {
        year: currentDate.year,
        month: MONTH_SHORT_TEXT[currentDate.month - 1],
        sectionId: currentSection,
      }
      dispatch(fetchMftExecSummary(data));
    }
  }, [currentDate.month, currentDate.year, currentSection, selectedPersona])

  //update Line chart
  useEffect(() => {
    if ([MFT_SECTION.GROWTH, MFT_SECTION.VALUE].includes(dynamicTab?.sectionId)) {
      let key
      if (MFT_SECTION.GROWTH === dynamicTab.sectionId) {
        key = selectedPrimaryCardKey
      }
      if (MFT_SECTION.VALUE === dynamicTab.sectionId) {
        key = currentTabKey
      }
      if (key && selectedUnitKey) {
        const data = {
          year: currentDate.year,
          month: MONTH_SHORT_TEXT[currentDate.month - 1],
          categoryId: key,
          unit: selectedUnitKey,
          sectionId: dynamicTab?.sectionId
        }
        dispatch(updateDynamicLineChart(data))
      }
    }
  }, [selectedUnitKey, selectedPrimaryCardKey])

  useEffect(() => {
    if (!dynamicTab) {
      const data = {
        year: currentDate.year,
        month: MONTH_SHORT_TEXT[currentDate.month - 1],
        unit: selectedUnitKey,
        sectionId: cardSectionData?.cards?.sectionId
      }
      dispatch(updateDynamicLineChart(data))
    }
  }, [selectedUnitKey])

  function makeText(m) {
    if (m && m.year && m.month)
      return MONTH_SHORT_TEXT[m.month - 1] + ' ' + m.year;
    return '?';
  }

  function disabledDate(current: moment.Moment) {
    if (current) {
      return (
        current <
        moment(`${periodsList[0].month}-${periodsList[0].year}`, 'MM-YYYY') ||
        current >
        moment(
          `${periodsList[periodsList.length - 1].month}-${periodsList[periodsList.length - 1].year
          }`,
          'MM-YYYY'
        )
      );
    }
    return false;
  }

  function onDateChange($event) {
    let { month, year } = $event
    setDateFilterValue({ month, year });
    dispatch(setMftCurrentDate({ month, year }));
    setShowPeriodFilter(false);
  }

  function _headerComponents() {
    return (
      <div className={s.headerComponents}>
        <p>Moving Forward Together 50.30.0</p>
        <DateDropdown disabledDate={disabledDate} periodsList={periodsList} currentDate={currentDate} onDateChange={onDateChange} />
      </div>
    );
  }

  function setTabChange(sectionId) {
    const subSection =
      parseInt(sectionId) === MFT_SECTION.SUSTAINIBILITY ? 1 : 0;
    const data = {
      year: currentDate.year,
      month: MONTH_SHORT_TEXT[currentDate.month - 1],
      sectionId: parseInt(sectionId),
      subTabId: subSection,
      categoryId: currentTabKey,
      isDynamicTab: isDynamicTab(),
    };
    dispatch(setMftCurrentSubSection(subSection));
    dispatch(setMftCurrentSection(parseInt(sectionId)));

    if (isDynamicTab()) {
      dispatch(fetchMftCommon(data));
      if (subTabs.length > 0) {
        dispatch(fetchMftCardSection(data));
      }
    } else {
      dispatch(fetchMftCardSection(data));
    }

    setactiveKey('');
  }

  function _MftTabsComponent(sectionId) {
    return (
      <MftTabsComponent
        sectionId={sectionId}
        activeKey={activeKey}
        setactiveKey={setactiveKey}
      />
    );
  }
  function _tabComponents() {
    return (
      <Tabs defaultActiveKey='1' onChange={(e) => setTabChange(e)}>
        <TabPane tab='Value' key={MFT_SECTION.VALUE}>
          {_MftTabsComponent(MFT_SECTION.VALUE)}
        </TabPane>
        {!isDisabledGrowthTab() && (
          <TabPane tab='Growth' key={MFT_SECTION.GROWTH}>
            {_MftTabsComponent(MFT_SECTION.GROWTH)}
          </TabPane>
        )}
        <TabPane tab='Sustainability' key={MFT_SECTION.SUSTAINIBILITY}>
          {_MftTabsComponent(MFT_SECTION.SUSTAINIBILITY)}
        </TabPane>
      </Tabs>
    );
  }
  return (
    <>
      <div className={`mft ${s.mftRight}`}>
        {_headerComponents()}
        {_tabComponents()}
      </div>
    </>
  );
};
export default MftScorecardsComponent;

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { MOBILE_RESPONSIVE } from "../../../constants/responsive.constants";
import { Desktop, Mobile, Tablet } from "../../../responsive/responesive";
import { customizeScollbar, setTooltip } from "../../../utils/chart.utils";
import s from "./ChartComponent.module.css";
import sMobile from "./ChartResponsiveMobile.module.css";

const chartColorCode = {
    blue: "#8FC8F8",
    yellow: "#DFEB98",
    green: "#50A4B9",
    purple: "#9E94DB",
    cerulean:"#009DDC"
}

const TrendChartLevel3 = (props: any) => {
    const chart = useRef<am4charts.XYChart>();
    const revenueChartLvl3 = useSelector((state) =>
        get(state, 'scoreCard.revenueChartLvl3', {})
    );

    const currentDate = useSelector((state) =>
        get(state, "scoreCard.currentDate", 1)
    );

    const isMobile = useMediaQuery(MOBILE_RESPONSIVE);

    useEffect(() => {
        if (!isEmpty(revenueChartLvl3)) {
            let chartdiv = am4core.create("trendchartlevel3", am4charts.XYChart);
            chart.current = chartdiv;
            let chartCurrent = chart.current;
            if (chartCurrent) {
                if (revenueChartLvl3?.charts?.length > 0) {
                    let data = revenueChartLvl3.data;
                    chartCurrent.data = data;
                    chartCurrent.numberFormatter.numberFormat = '#.##a';
                    chartCurrent.numberFormatter.smallNumberThreshold = 0;
                    chartCurrent.background.fillOpacity = 0;
                    chartCurrent.maskBullets = false;
                    chartCurrent.height = 239;
                    chartCurrent.cursor = new am4charts.XYCursor();
                    chartCurrent.cursor.lineY.disabled = true;
                    chartCurrent.cursor.behavior = 'none';
                    customizeDateAxis(chartCurrent);
                    let tooltipHtml = customizeTooltip();
                    customizeValueAxis(chartCurrent, tooltipHtml);
                }
            }
        }

        // Add data
        return () => {
            chart.current?.dispose();
        };
    }, [revenueChartLvl3]);

    function customizeDateAxis(chartCurrent: am4charts.XYChart) {
        let dateAxis = chartCurrent.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.fullWidthTooltip = true;
        dateAxis.renderer.ticks.template.disabled = false;
        dateAxis.baseInterval = { "timeUnit": "month", "count": 1 };
        dateAxis.renderer.labels.template.fill = am4core.color("#A09F9B");
        dateAxis.fontSize = 11;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.labels.template.disabled = true;
        dateAxis.cursorTooltipEnabled = false;
        dateAxis.events.on("datavalidated", (ev) => {
            let axis = ev.target;
            let start = axis.positionToDate(0);
            let end = axis.positionToDate(1);
            let current = new Date(start);

            while (current < end) {
                let range = axis.axisRanges.create();
                range.label.dataItem.text = `${moment(current).format('MMM')}`.toUpperCase();
                range.label.dx = 30;
                range.grid.strokeOpacity = 0;

                let nextMonth = new Date(current);
                nextMonth.setMonth(current.getMonth() + 1);
                range.date = current;
                range.endDate = nextMonth;

                if (current.getMonth() === (currentDate?.month - 1)) {
                    range.axisFill.fill = am4core.color("#FFFFFF");
                    range.axisFill.fillOpacity = 0.2;
                    range.grid.dx = 20
                    range.label.fill = am4core.color("#FFFFFF");
                }
                //Iterate
                current.setMonth(current.getMonth() + 1);
            }
        });
        isMobile && chartCurrent.events.on("ready", function () {
            let year = new Date().getFullYear();
            dateAxis.zoomToDates(
                new Date(year, 0, 1), new Date(year, 5, 1), false, true
            )
            chartCurrent.zoomOutButton.disabled = true;
            customizeScollbar(chartCurrent, true);
        });
    }

    function customizeTooltip() {
        let tooltipHTML = "<table style='line-height:10px;'>";
        for (let idx = 0; idx < revenueChartLvl3.charts.length; idx++) {
            let data = revenueChartLvl3.charts[idx]
            let seriesNo = idx + 1;
            tooltipHTML += `<tr style='font-size:10px;'>
                                <td>
                                    <span style="height: 14px;
                                                width: 24px;
                                                color: #C8CCCD;
                                                font-family: "Museo Sans";
                                                font-size: 12px;
                                                letter-spacing: 0;
                                                line-height: 14px;">
                                        {label${seriesNo}}
                                    </span>
                                </td>
                                <td>
                                    <span style="height: 14px;
                                                width: 18px;
                                                color: #9B9B9B;
                                                font-family: "Museo Sans";
                                                font-size: 12px;
                                                letter-spacing: 0;
                                                line-height: 14px;">
                                                {unit${seriesNo}}
                                    </span>
                                </td>
                                <td>
                                    <span style="height: 14px;
                                    width: 14px;
                                    color: ${chartColorCode[data.chartColor]};
                                    font-family: "Museo Sans";
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    line-height: 14px;
                                    text-align: center;">
                                        {series${seriesNo}}
                                    </span>
                                </td>
                                <td>
                                    <span style="height: 14px;
                                    width: 16px;
                                    color: #9B9B9B;
                                    font-family: "Museo Sans";
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    line-height: 14px;">
                                        {subUnit${seriesNo}}
                                    </span>
                                    
                                </td>
                            </tr>`
        }
        tooltipHTML += "</table>"
        return tooltipHTML;
    }

    function customizeValueAxis(chartCurrent: am4charts.XYChart, tooltipHtml: string) {
        // Create series
        let isTooltip = false;
        let valueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.cursorTooltipEnabled = false;
        for (let idx = 0; idx < revenueChartLvl3.charts.length; idx++) {
            const data = revenueChartLvl3.charts[idx];
            const seriesNo = idx + 1;

            switch (data.chartType) {
                case 'dot':
                    createDotChart(chartCurrent, `series${seriesNo}`, data.chartColor, tooltipHtml, isTooltip, valueAxis);
                    isTooltip = true;
                    break;
                case 'bar':
                    createBarChart(chartCurrent, `series${seriesNo}`, data.chartColor, tooltipHtml, valueAxis);
                    break;
                case 'line':
                    createLineChart(chartCurrent, `series${seriesNo}`, data.chartColor, tooltipHtml, isTooltip, valueAxis);
                    isTooltip = true;
                    break;
                case 'dash':
                    createDashChart(chartCurrent, `series${seriesNo}`, data.chartColor, tooltipHtml, isTooltip, valueAxis);
                    break;
                default:
                    break;
            }
        }
    }

    function createLineChart(chartCurrent: am4charts.XYChart, seriesNo: string, chartColor: string, tooltipHtml: string, isTooltip: boolean, valueAxis: am4charts.ValueAxis) {
        let lineSeries = chartCurrent.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.valueY = seriesNo;
        lineSeries.dataFields.dateX = "date";
        lineSeries.yAxis = valueAxis;
        lineSeries.stroke = am4core.color(chartColorCode[chartColor]);
        lineSeries.strokeWidth = 2;
        lineSeries.showOnInit = true;
        setTooltip(isTooltip, tooltipHtml, lineSeries)
    }
    function createBarChart(chartCurrent: am4charts.XYChart, seriesNo: string, chartColor: string, tooltipHtml: string, valueAxis: am4charts.ValueAxis) {
        var barSeries = chartCurrent.series.push(new am4charts.ColumnSeries());
        barSeries.dataFields.valueY = seriesNo;
        barSeries.dataFields.dateX = "date";
        barSeries.yAxis = valueAxis;
        barSeries.columns.template.fill = am4core.color(chartColorCode[chartColor]);
        barSeries.columns.template.stroke = am4core.color(chartColorCode[chartColor]);
        barSeries.columns.template.width = 14;
    }

    function createDotChart(chartCurrent: am4charts.XYChart, seriesNo: string, chartColor: string, tooltipHtml: string, isTooltip: boolean, valueAxis: am4charts.ValueAxis) {
        var dotSeries = chartCurrent.series.push(new am4charts.LineSeries());
        dotSeries.dataFields.valueY = seriesNo;
        dotSeries.dataFields.dateX = "date";
        dotSeries.yAxis = valueAxis;
        dotSeries.stroke = am4core.color(chartColorCode[chartColor]);
        dotSeries.strokeWidth = 2;
        dotSeries.strokeDasharray = "3,3";
        dotSeries.propertyFields.strokeDasharray = "3,3";
        dotSeries.showOnInit = true;
        setTooltip(isTooltip, tooltipHtml, dotSeries)
        var latitudeBullet = dotSeries.bullets.push(new am4charts.CircleBullet());
        latitudeBullet.circle.radius = 5;
        latitudeBullet.circle.stroke = am4core.color('#000000');
        latitudeBullet.circle.strokeWidth = 2;
    }

    function createDashChart(chartCurrent: am4charts.XYChart, seriesNo: string, chartColor: string, tooltipHtml: string, isTooltip: boolean, valueAxis: am4charts.ValueAxis) {
        var dashSeries = chartCurrent.series.push(new am4charts.LineSeries());
        dashSeries.dataFields.valueY = seriesNo;
        dashSeries.dataFields.dateX = "date";
        dashSeries.yAxis = valueAxis;
        dashSeries.stroke = am4core.color(chartColorCode[chartColor]);
        dashSeries.strokeWidth = 2;
        dashSeries.strokeDasharray = "5,5";
        dashSeries.propertyFields.strokeDasharray = "5,5";
        dashSeries.showOnInit = true;
    }

    function ChartNotAvailableComponent(cssModule) {
        return revenueChartLvl3?.charts?.length > 0 ?
            <div id="trendchartlevel3" className={cssModule.trendChart} />
            :
            <span className={cssModule.chartNotAvailable}>Chart not available</span>
    }

    function CurrencyComponent(cssModule) {
        return <div className={cssModule.subTrendChartDiv}>{`${revenueChartLvl3?.currency ? revenueChartLvl3?.currency : ''} ${revenueChartLvl3?.unit ? revenueChartLvl3?.unit : ''}`}</div>
    }

    function _renderMobileTablet() {
        return (
            <div className={sMobile.trendChartContainerlevel3}>
                <div className={sMobile.topTrendChartDiv}>
                    <div className={sMobile.topLeftTrendDiv}>
                        {revenueChartLvl3?.externalLink &&
                            <>
                                <img src='/img/icon/Icon Link.svg' alt="Link to others app" />
                                <a href={revenueChartLvl3?.externalLink} target="_blank" rel="noreferrer">More Details</a>
                            </>
                        }
                    </div>
                </div>
                {CurrencyComponent(sMobile)}
                {ChartNotAvailableComponent(sMobile)}
                <div className={sMobile.divBorder} />
                <div className={sMobile.topRightTrendDiv}>
                    {revenueChartLvl3?.charts && revenueChartLvl3?.charts?.length > 0 && revenueChartLvl3?.charts.map((value, idx) => {
                        const legendChartClass = s[`legend${value.chartType}`];
                        return (
                            <React.Fragment key={idx}>
                                <div key={idx}
                                    className={legendChartClass}
                                    style={{ backgroundColor: chartColorCode[value.chartColor] }}></div>
                                <span>{value.chartTitle}</span>
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <>
            <Desktop>
                <div className={s.trendChartContainerlevel3}>
                    <div className={s.topTrendChartDiv}>
                        <div className={s.topLeftTrendDiv}>
                            {revenueChartLvl3?.externalLink &&
                                <>
                                    <img src='/img/icon/Icon Link.svg' alt="Link to others app" />
                                    <a href={revenueChartLvl3?.externalLink} target="_blank" rel="noreferrer">More Details</a>
                                </>
                            }
                        </div>
                        <div className={s.topRightTrendDiv}>
                            {revenueChartLvl3?.charts?.length > 0 && revenueChartLvl3?.charts.map((value, idx) => {
                                const legendChartClass = s[`legend${value.chartType}`];
                                return (
                                    <>
                                        <div key={idx} className={legendChartClass} style={{ backgroundColor: chartColorCode[value.chartColor] }}></div>
                                        <span>{value.chartTitle}</span>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    {CurrencyComponent(s)}
                    {ChartNotAvailableComponent(s)}
                </div>
            </Desktop>
            <Mobile>{_renderMobileTablet()}</Mobile>
            <Tablet>{_renderMobileTablet()}</Tablet>
        </>
    )
}
export default TrendChartLevel3;
import * as types from "../action/actionType";

export const INITIAL_STATE = {
  isAuth: null,
  userConfig: {},
};

export function privateRouteReducer(state = INITIAL_STATE, action: any) {
  switch (action.type) {
    case types.PRIVATE_ROUTE_AUTH_REQUESTED: {
      return {
        ...state,
        isAuth: null,
      };
    }
    case types.PRIVATE_ROUTE_AUTH_SUCCEEDED: {
      return {
        ...state,
        isAuth: action.data,
        userConfig: action.userConfig,
      };
    }
    case types.PRIVATE_ROUTE_AUTH_FAILED: {
      return {
        ...state,
        isAuth: action.message,
      };
    }
  }
  return state;
}

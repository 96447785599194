import React from 'react';
import s from './primary-info.module.css';
import sMobile from './primary-info.mobile.module.css';
import { Row, Col, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { formatDate, numberWithCommas } from '../../../utils/callout.utils';

function PrimaryInfoComponentMobile() {
  const { callOutData } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.calloutData,
    };
  });

  function _renderDetailInfo() {
    return (
      <div className={s.detailInfo}>
        {callOutData?.primaryTags?.map((p) => {
          return (
            <div className={s.detailInfoItem}>
              <p className={s.detailInfoTitle}>{p.tagName}</p>
              <Row gutter={8}>
                {p.dataSource.map((d) => {
                  return (
                    <Col span={12} key={p.tagName}>
                      <div>
                        <p className={s.detailInfoSubTitle}>
                          {d.dataSourceType}
                        </p>
                        <p className={s.detailInfoText}>
                          <img alt='info' src='/img/icon/ic-time.svg' />
                          &nbsp;
                          <span>{formatDate(d.timeStamp)}</span>
                        </p>
                        <p className={s.detailInfoText}>
                          <img alt='info' src='/img/icon/ic-db.svg' />
                          &nbsp;
                          <span>{d.dataSourceName}</span>
                        </p>
                        <p className={s.detailInfoText}>
                          <img alt='info' src='/img/icon/ic-tag.svg' />
                          &nbsp;
                          <span>{d.dataSourceTarget}</span>
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={sMobile.mapRightPanelPrimaryInfo}>
      <Row gutter={16}>
        <Col span={5}>
          <div className={sMobile.primaryInfoImage}>
            <img
              src={
                callOutData?.imageUrl === ''
                  ? '/callout-images/vessels/Default.png'
                  : callOutData?.imageUrl
              }
              alt='vessel'
            />
          </div>
        </Col>
        <Col span={19}>
          <div className={s.primaryInfoContent}>
            <Row gutter={8}>
              <Col span={22}>
                <div className={s.contentDetail}>
                  <Row gutter={[16, 16]}>
                    {callOutData?.primaryTags?.map((p, i) => {
                      const isCouple = callOutData?.primaryTags?.length === 2;
                      if (isCouple && i === 0) {
                        return (
                          <>
                            <Col span={12}>
                              <div className={s.infoItem}>
                                <Row>
                                  <Col span={2}>
                                    <span
                                      className={`${s.colorDot} ${
                                        s[p?.primaryValue?.color]
                                      }`}
                                    />
                                  </Col>
                                  <Col span={22}>
                                    <p className={s.infoTitle}>{p?.tagName}</p>
                                    <p className={s.infoNumber}>
                                      <span className={s.primaryNumber}>
                                        {numberWithCommas(
                                          p?.primaryValue?.value
                                        )}
                                      </span>
                                      &nbsp;
                                      <span className={s.numberUnit}>
                                        {p?.primaryValue?.unit}
                                      </span>
                                    </p>
                                    <p className={s.infoSub}>
                                      {p?.primaryValue?.thresholdText}{' '}
                                      {p?.primaryValue?.thresholdValue && (
                                        <>
                                          <b>
                                            {p?.primaryValue?.thresholdValue}
                                          </b>{' '}
                                          <span className={s.subUnit}>
                                            {p?.primaryValue?.unit}{' '}
                                          </span>
                                        </>
                                      )}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col span={12}></Col>
                          </>
                        );
                      }
                      return (
                        <Col span={12}>
                          <div className={s.infoItem}>
                            <Row>
                              <Col span={2}>
                                <span
                                  className={`${s.colorDot} ${
                                    s[p?.primaryValue?.color]
                                  }`}
                                />
                              </Col>
                              <Col span={22}>
                                <p className={s.infoTitle}>{p?.tagName}</p>
                                <p className={s.infoNumber}>
                                  <span className={s.primaryNumber}>
                                    {numberWithCommas(p?.primaryValue?.value)}
                                  </span>
                                  &nbsp;
                                  <span className={s.numberUnit}>
                                    {p?.primaryValue?.unit}
                                  </span>
                                </p>
                                <p className={s.infoSub}>
                                  {p?.primaryValue?.thresholdText}{' '}
                                  {p?.primaryValue?.thresholdValue && (
                                    <>
                                      <b>{p?.primaryValue?.thresholdValue}</b>
                                      &nbsp;
                                      <span className={s.subUnit}>
                                        {p?.primaryValue?.unit}
                                      </span>
                                    </>
                                  )}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>
              <Col span={2}>
                <Tooltip
                  overlayClassName={s.callOutTooltipOverlay}
                  placement='bottomLeft'
                  title={() => _renderDetailInfo()}
                  trigger='click'
                >
                  <div className={s.contentMoreDetail}>
                    <img alt='info' src='/img/icon/ic-info.svg' />
                  </div>
                </Tooltip>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PrimaryInfoComponentMobile;

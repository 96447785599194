import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { formatDate } from '../../../utils/callout.utils';

import {
    closeScorecardDetailsChartModal
} from '../../../action/actionScoreCard';


import s from './detailschart.module.css';
import { Modal } from 'antd';

import TrendChartLevel2 from '../Chart/TrendChartLevel2';
import { DISABLED_CHART_BY_OPU } from '../../../constants/score-card.constants';

const DetailsChart = (tab: any) => {
  const dispatch = useDispatch();

  const { isVisibleModal, revenueChart } = useSelector((state) => {
    const scorecard = get(state, 'scoreCard', null);
    return {
      isVisibleModal: scorecard.isOpenScorecardDetailsChartModal,
      revenueChart: scorecard.revenueChart
    };
  });

  const opu = useSelector((state) =>
    get(state, "privateroute.userConfig.claims.persona.opu", "")
  );
  const isDisabledWaterfallChart = DISABLED_CHART_BY_OPU?.[opu]?.[tab]?.waterfall || false;
  
  const waterfallChart = useSelector((state) =>
    get(state, "scoreCard.waterfallChart", {})
  );

  const handleCancel = () => {
    dispatch(closeScorecardDetailsChartModal());
  };

  const CloseButton = () => <img alt='close-icon' src='/img/icon/close-btn.svg' />;

  return (
    <>
      <Modal
        className='historicalTrendModal'
        visible={isVisibleModal}
        footer={null}
        onCancel={handleCancel}
        width='60%'
        zIndex={1000}
        closeIcon={<CloseButton />}
        maskClosable={false}
      >
        <div className={s.modalHeader}>
          <p>{revenueChart.chartLabel}</p>
        </div>
        <div className={s.chartContainer}>
          <TrendChartLevel2
              isWaterFallChart={
                !isDisabledWaterfallChart && waterfallChart?.data?.length > 0
              }
            />
        </div>
        <div className={s.modalFooter}>
          <span className={s.lastUpdated}>
            <img src="/img/icon/ic-time.svg" alt='last-updated'/>
              &nbsp; {formatDate(revenueChart.lastUpdated)}
          </span>
        </div>
      </Modal>
    </>
  );
};

export default DetailsChart;


export const OPU = {
  GNE: "gas business",
  PFLNG: "pflng",
  MLNG: "mlng",
  LNGA: "lnga",
  LMT: "lmt",
  GP: "g&p",
  PEGT: "pegt",
  PGB: "pgb"
};

export const OPU_UPCS = {
  GNE: "Gas Business",
  PFLNG: "PFLNG",
  MLNG: "MLNG",
  LNGA: "LNGA",
  LMT: "LMT",
  GP: "G&P",
  PEGT: "PEGT",
  PGB: "PGB",
  OTHERS: "Others",
  PDSB: "PDSB",
  HSE: "HSE",
  GTR: "GTR"
};

export const BREADCRUMB = {
  //gas business
  [OPU_UPCS.GNE]: ["Gas Business Scorecard"],
  //LNGA
  [OPU_UPCS.LNGA]: ["Gas Business Scorecard", OPU_UPCS.LNGA],
  [OPU_UPCS.PFLNG]: ["Gas Business Scorecard", OPU_UPCS.LNGA, OPU_UPCS.PFLNG],
  [OPU_UPCS.MLNG]: ["Gas Business Scorecard", OPU_UPCS.LNGA, OPU_UPCS.MLNG],
  //LMT
  [OPU_UPCS.LMT]: ["Gas Business Scorecard", OPU_UPCS.LMT],
  //HSE
  [OPU_UPCS.HSE]: ["Gas Business Scorecard", OPU_UPCS.HSE],
  //G&P
  [OPU_UPCS.GP]: ["Gas Business Scorecard", OPU_UPCS.GP],
  [OPU_UPCS.GTR]: ["Gas Business Scorecard", OPU_UPCS.GP],
  [OPU_UPCS.PGB]: ["Gas Business Scorecard", OPU_UPCS.GP, OPU_UPCS.PGB],
  [OPU_UPCS.PEGT]: ["Gas Business Scorecard", OPU_UPCS.GP, OPU_UPCS.PEGT],
};

export const BREADCRUMB_VALUECHAIN = {
  //gas business
  [OPU_UPCS.GNE]: ["Gas Business Value Chain"],
  //PDSB
  [OPU_UPCS.PDSB]: ["Gas Business Value Chain"],
  //Others
  [OPU_UPCS.OTHERS]: ["Gas Business Value Chain"],
  //LNGA
  [OPU_UPCS.LNGA]: ["Gas Business Value Chain", OPU_UPCS.LNGA],
  [OPU_UPCS.PFLNG]: ["Gas Business Value Chain", OPU_UPCS.LNGA, OPU_UPCS.PFLNG],
  [OPU_UPCS.MLNG]: ["Gas Business Value Chain", OPU_UPCS.LNGA, OPU_UPCS.MLNG],
  //LMT
  [OPU_UPCS.LMT]: ["Gas Business Value Chain", OPU_UPCS.LMT],
  //HSE
  [OPU_UPCS.HSE]: ["Gas Business Value Chain", OPU_UPCS.HSE],
  //G&P
  [OPU_UPCS.GP]: ["Gas Business Value Chain", OPU_UPCS.GP],
  [OPU_UPCS.GTR]: ["Gas Business Value Chain", OPU_UPCS.GP],
  [OPU_UPCS.PGB]: ["Gas Business Value Chain", OPU_UPCS.GP, OPU_UPCS.PGB],
  [OPU_UPCS.PEGT]: ["Gas Business Value Chain", OPU_UPCS.GP, OPU_UPCS.PEGT],
};

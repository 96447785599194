export const SLIDE_LIMIT = {
  MAX: 3,
  MIN: 1,
};

export const CONFIRM_MODAL = {
  PUBLISH: {
    MESSAGE: 'Are you sure you want to publish this content?',
    KEY: 'PUBLISH',
  },
  DELETE: {
    MESSAGE: 'Are you sure you want to delete this content?',
    KEY: 'DELETE',
  },
  SAVE: {
    MESSAGE: 'Are you sure you want to save this content?',
    KEY: 'SAVE',
  },
};

import React from 'react'
import s from './UploadItem.module.css'

interface Iprop {
    name: string;
    size: number;
    onRemove?: () => void
}


const UploadItem = (prop: Iprop) => {
    const { name, size, onRemove } = prop

    const handleKeyDown = (e: React.KeyboardEvent<HTMLImageElement>) => {
        if (e.key === 'Enter' && onRemove) {
            onRemove();
        }
    };
    
    return (
        <div className={s.uploadFileItemContainer}>
            <div className={s.uploadFileItem}>
                <img src="/img/icon/XLSIcon.svg" alt="XLSIcon" />
                <div className={s.uploadFileItemName}>
                    <p>{name}</p>
                    <span>{size} kb</span>
                </div>
                <img
                    src="/img/icon/Delete(Green).svg"
                    alt="Delete(Green)"
                    style={{ cursor: 'pointer' }}
                    onClick={onRemove}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                />
            </div>
        </div>
    )
}

export default UploadItem
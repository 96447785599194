import { useDispatch, useSelector } from "react-redux";

import s from "./BaseLmtSalesSummaryChart.module.css";
import { useState } from "react";
import { setLmtCountrySelection } from "../../../../action/actionScoreCard";
import { get } from "lodash";

export interface LmtChartCountrySelectionComponentProps {
    countryData: any[]
}

export default function LmtChartCountrySelectionComponent({countryData}: LmtChartCountrySelectionComponentProps) {
  const dispatch = useDispatch();
  const { selectedLmtCountry } = useSelector((state) => {
    const scorecard = get(state, 'scoreCard', null);
    return {
      selectedLmtCountry: scorecard.selectedLmtCountry
    };
  });

  let tempCountrySelection:any[] = [];
  
  if (selectedLmtCountry && selectedLmtCountry.length > 0) {
    // Add All first
    tempCountrySelection.push({country: "All", selected: false})
    // Starts from the next country
    for (let idx = 0; idx < countryData.length; idx++) {
      if (selectedLmtCountry.indexOf(countryData[idx].country) >= 0) {
        countryData[idx].selected = true;
      }
      else {
        countryData[idx].selected = false;
      }
      tempCountrySelection.push(countryData[idx])
    }
  }
  else {
    // Add All first
    tempCountrySelection.push({country: "All", selected: true})
    tempCountrySelection.push(...countryData);
  }
  
  const [countrySelection, setCountrySelection] = useState(tempCountrySelection);
  
  function handleCountrySelect(countryName: string, selectionStatus: boolean) {
    let selectedCountries: string[] = [];
    if (countryName === "All" && selectionStatus) {
      // Change all countries to selected
      for (let idx = 0; idx < countrySelection.length; idx++) {
        countrySelection[idx].selected = true;
      }
    } 
    else if (countryName === "All" && !selectionStatus) {
      for (let idx = 0; idx < countrySelection.length; idx++) {
        countrySelection[idx].selected = false;
      }
    }
    else {
      const selectedCountry = countrySelection.find(c => c.country === countryName);
      const countryIdx = countrySelection.indexOf(selectedCountry);
      if (countryIdx >= 0) {
        countrySelection[countryIdx].selected = selectionStatus;
      }   
      selectedCountries = countrySelection.filter(c => c.selected).map(c => c.country);
    }

    setCountrySelection([...countrySelection]);
    // Filter All value out of selection
    dispatch(setLmtCountrySelection(selectedCountries.filter(s => s !== "All")));
  }

  return (
    <ul>
      {countrySelection && countrySelection.map((country) => {
        return (
        <div className={s.countrySelection}>
          <li key={country.country}>
            <button
              type="button"
              onClick={() => handleCountrySelect(country.country, !country.selected)}>
              <img alt="checkbox" src={country.selected
                ? "/img/icon/Checkbox-checked.svg"
                : "/img/icon/Checkbox-uncheck.svg"} />
            </button>
            <p>{country.country}</p>
          </li>
        </div>);
      })}
    </ul>
  );
}


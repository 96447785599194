import Modal from 'antd/lib/modal/Modal';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import s from './ModalWaterfallChart.module.css';
import WaterfallChart from '../ScoreCards/Chart/WaterFallChartInModal';
import {
  closeModalWaterfallChart,
} from '../../action/actionScoreCard';
const ModalForwardPriceComponent = (props: any) => {
  const dispatch = useDispatch();
  const isVisibleModal = useSelector((state) =>
    get(state, 'scoreCard.isVisibleModalWaterfallChart', false)
  );
  const barNumber = useSelector((state) =>
    get(state, 'scoreCard.waterfallChart.barNumber', 0)
  );
  const isExtendModal = barNumber >= 10;

  const handleCancel = () => {
    dispatch(closeModalWaterfallChart());
  };

  function _renderChart() {
    return (
      <WaterfallChart waterfallChart={props.data} isExtend={isExtendModal} />
    );
  }

  function calcWidth() {
    if (isExtendModal) {
      return '80%';
    }
    return '60%';
  }

  return (
    <>
      <Modal
        className='waterfallChartExtendModal'
        visible={isVisibleModal}
        footer={null}
        onCancel={handleCancel}
        width={calcWidth()}
        zIndex={1000}
      >
        <div className={s.header}>
          <p>Waterfall chart</p>
        </div>
        <div className={s.divBorder} />
        {_renderChart()}
      </Modal>
    </>
  );
};
export default ModalForwardPriceComponent;

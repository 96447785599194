import "react-responsive-carousel/lib/styles/carousel.min.css";

import Carousel from "react-elastic-carousel";

import { Desktop } from "../../../responsive/responesive";
import s from "../scorecards.module.css";
import MiniCardInforItemComponent from "./MiniCardInforItemComponent";

const IpadPro = window.screen.width;
const miniCardMaxSize = IpadPro < 1025 ? 1 : 2;
const isTabHorizontal = IpadPro < 1025;

const MiniCardInforComponentLevel2 = (props: any) => {
  const card = props.data;
  const infoRowsLevel = card?.parameters;
  const isPriceForex = card.categoryKey === "fne-price/forex"
  const cardToShowPrice = isPriceForex && isTabHorizontal ? 2 : 3;
  return (
    <>
      <Desktop>
        <div
          className={`${s.miniCardItemLevel2} ${card?.parameters?.length / miniCardMaxSize <= 1
            ? "hide-pagination"
            : ""
            }`}
        >
          {/* Card Header */}
          <div className={s.miniCardItemHeader}>{card?.categoryName}</div>
          {/* Card Body */}
          <div>
            {/* Slider */}
            {card?.parameters && card?.parameters?.length > 0 && (
              <Carousel
                isRTL={false}
                itemPadding={[0, 6]}
                itemsToScroll={1}
                itemsToShow={isPriceForex ? cardToShowPrice : miniCardMaxSize}
                renderPagination={({ pages, activePage, onClick }) => {
                  return (
                    <div
                      className={`${pages.length <= 1
                        ? s.hidePagiMiniCardList
                        : s.pagiMiniCardList
                        }`}
                    >
                      {pages.map((page) => {
                        return (
                          <div
                            className={`${page === activePage
                              ? s.pagiMiniCardActive
                              : s.pagiMiniCard
                              }`}
                            key={page}
                            onClick={() => onClick(page.toString())}
                          ></div>
                        );
                      })}
                    </div>
                  );
                }}
                renderArrow={() => {
                  return <div className={s.hidePagiMiniCardList}></div>;
                }}
              >
                {infoRowsLevel.map((miniCard: any) => (
                  <MiniCardInforItemComponent
                    miniCard={miniCard}
                    key={miniCard.key}
                  />
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </Desktop>
    </>
  );
};

export default MiniCardInforComponentLevel2;

import { DatePicker, Modal, Popover, Space, Spin, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { MONTH_SHORT_TEXT } from '../../../constants/score-card.constants';
import { removeUploadFileMessage, removeValidateScorecard, resetUploadFileSuccessStatus, uploadScorecardFile, validateScorecardFile } from '../../../action/actionScoreCard';
import s from './ScorecardManagementUploadModal.module.css'
import moment from 'moment';
import UploadItem from '../uploadItem/UploadItem';
import { FileUploader } from 'react-drag-drop-files';
import Icon from '@ant-design/icons/lib/components/Icon';
import { removeValidateScorecardMessage } from '../../../action/actionScoreCard';

const { MonthPicker } = DatePicker;

const fileTypes = ["xlsx", "xls"];

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

interface IProp {
    folderOptions:  {
        name: string;
    }[],
    isShowModal: boolean,
    setIsShowModal: (value: boolean) => void,
    folder: string,
    setFolder: (value: string) => void,
    currentDate: { month: number, year: number },
    onSuccessUpload: () => void
}

const ScorecardManagementUploadModal = (props: IProp) => {
    const { isShowModal, setIsShowModal, folder, setFolder, currentDate, onSuccessUpload, folderOptions } = props
    const [file, setFile] = useState<null | { name: string, size: number, lastModifiedDate: string }>(null);
    const [showPeriod, setShowPeriod] = useState(false);
    const [isShowFolder, setIsShowFolder] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [stringFile, setStringFile] = useState('');
    const [fileName, setFileName] = useState('');
    
    const [currentModalDate, setCurrentModalDate] = useState(currentDate)

    const prevModalDateRef = useRef(currentModalDate);
    const [monthPickerValue, setMonthPickerValue] = useState(moment(`${currentModalDate.year}-${currentModalDate.month}`, 'YYYY-MM'));
    const scoreCard = useSelector((state: any) => state.scoreCard);
    const dispatch = useDispatch();
    const {
        result,
        resultMessage,
        isUploadFileSuccess,
        uploadMessage,
        periodsList,
        isUploadLoading
    } = scoreCard;

    const onCloseModal = () => {
        setShowPeriod(false)
        setIsShowFolder(false)
        setErrorMessage('')
        setFile(null)
        setTimeout(() => {
            setIsShowModal(false)
        }, 0);
        dispatch(resetUploadFileSuccessStatus())
    }

    useEffect(() => {
        if (prevModalDateRef.current === currentModalDate) {
            setMonthPickerValue(moment(`${currentModalDate.year}-${currentModalDate.month}`, 'YYYY-MM'));
        } else {
            prevModalDateRef.current = currentModalDate;
            setMonthPickerValue(moment(`${currentModalDate.year}-${currentModalDate.month}`, 'YYYY-MM'));
        }
    }, [currentModalDate]);

    const handleFileChange = (file) => {
        setErrorMessage('')
        setFile(file);

        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => {
            setStringFile(reader.result as string)
        }
    };

    function disabledDate(current: moment.Moment) {
        const currentMonthYear = moment().format("MM-YYYY");
        if (periodsList && periodsList.length > 0) {
            return (
                current <
                moment(`${periodsList[0].month}-${periodsList[0].year}`, "MM-YYYY") ||
                current > moment(`${currentMonthYear}`, "MM-YYYY")
            );
        } else {
            return false;
        }
    }

    const convertDate = (m) => {
        if (m && m.year && m.month)
            return MONTH_SHORT_TEXT[m.month - 1] + " " + m.year;
        return "?";
    };

    function handleDateSelect($event) {
        setCurrentModalDate({ month: $event.month() + 1, year: $event.year() })
        setShowPeriod(false);
        dispatch(resetUploadFileSuccessStatus())
    }

    const handleUpload = () => {
        const payload = {
            stringFile: stringFile.split(',')[1],
            folderName: folder,
            fileName: file?.name,
            period: convertDate(currentModalDate)
        }
        setFileName(payload.fileName || '')
        dispatch(validateScorecardFile(payload))
    }

    useEffect(() => {
        const payload = {
            stringFile: stringFile.split(',')[1],
            folderName: folder,
            fileName: file?.name,
            period: convertDate(currentModalDate)
        }
        if (result) {
            dispatch(uploadScorecardFile(payload));
            dispatch(removeValidateScorecard())
            onCloseModal()
        }
    }, [result])

    function _renderPopFolder() {
        return (
            <div className={s.popoverContent}>
                {folderOptions?.map((folder, idx) => {
                    return (
                        <span
                            key={idx}
                            onClick={() => {
                                dispatch(resetUploadFileSuccessStatus())
                                setFolder(folder.name)
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  dispatch(resetUploadFileSuccessStatus());
                                  setFolder(folder.name);
                                }
                            }}
                            tabIndex={0}  
                        >
                            {folder.name}
                        </span>
                    )
                })}
            </div>
        );
    }

    const _upload = () => {
        return <div className={s.uploadContainer}>
            <img src="/img/icon/uploadIcon.svg" alt="uploadIcon" />
            <p>Drag & Drop or <strong>Choose File</strong> to upload</p>
            <span>XLS , XLSX</span>
        </div>
    }

    function renderModalHeader() {
        return (
            <div className={s.modalHeader}>
                <p>Upload File</p>
                <img
                    onClick={() => onCloseModal()}
                    src="/img/icon/CrossPopup.svg"
                    alt="close"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onCloseModal();
                        }
                    }}
                    tabIndex={0}
                />
            </div>
        )
    }

    useEffect(() => {
        setCurrentModalDate(currentDate)
    }, [currentDate, isShowModal]);

    useEffect(() => {
        dispatch(removeValidateScorecardMessage())
        dispatch(removeUploadFileMessage())
    }, [isShowModal])

    useEffect(() => {
        if (isUploadFileSuccess) {
            notification.open({
                message: 'Successfully uploaded',
                description: (<>The file <b>"{fileName}"</b> has been uploaded successfully.</>),
                duration: 3,
                className: 'gotoast',
                icon: <img src="/img/icon/Check - Filled.svg" alt="close" />,
            });
            onCloseModal();
            onSuccessUpload();
        }
    }, [isUploadFileSuccess]);

    return (
        <Modal
            className="scorecardUploadModalComponent"
            closable={false}
            visible={isShowModal}
            footer={null}
            width={600}
            centered={true}
        >
            {renderModalHeader()}
            <div className={s.divider} />
            <div className={s.modalContent}>
                <div className={s.modalDropdownContainer}>
                    <div className={s.opuPopover}>
                        <p>Folder</p>
                        <Popover
                            overlayClassName="folderTbodyDropdown"
                            placement="bottom"
                            content={_renderPopFolder()}
                            trigger="focus"
                            visible={isShowFolder}
                        >
                            <button
                                type='button'
                                onClick={() => setIsShowFolder(!isShowFolder)}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setIsShowFolder(false)
                                    }, 300);
                                }}
                            >
                                {folder}
                                <img
                                    src="/img/icon/DownArrow.svg"
                                    alt="arrow"
                                />
                            </button>
                        </Popover>
                    </div>
                    <div className={s.filterBlocks}>
                        <div className={s.filterBlock}>
                            <p>Period</p>
                            <div
                                onBlur={() => setShowPeriod(false)}
                            >
                                <button
                                    type='button'
                                    onClick={() => setShowPeriod(!showPeriod)}
                                >
                                    {convertDate(currentModalDate)}
                                    <img
                                        src="/img/icon/DownArrow.svg"
                                        alt="arrow"
                                    />
                                </button>
                                <div className='scorecards-management-month-picker'>
                                    <Space direction='vertical'>
                                        <MonthPicker
                                            disabledDate={disabledDate}
                                            value={monthPickerValue}
                                            onChange={handleDateSelect}
                                            open={showPeriod}
                                        />
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {file ? <UploadItem name={file.name} size={file.size} onRemove={() => { setFile(null); setErrorMessage(''); dispatch(removeUploadFileMessage()); dispatch(removeValidateScorecardMessage()) }} /> : <FileUploader
                handleChange={handleFileChange}
                maxSize={200}
                types={fileTypes}
                onTypeError={(e) => {
                    setErrorMessage("File type is invalid. Please upload XLS, XLSX file")
                }}
                onSizeError={(e) => {
                    setErrorMessage("File size limit exceeded. You cannot upload files larger than 200MB. Please select a different file that is within the limit")
                }}
                name="file"
                children={_upload()}
            />}
            {errorMessage && <span className={s.errorMessage}>{errorMessage}</span>}
            {uploadMessage && <span className={s.errorMessage}>{uploadMessage}</span>}
            {resultMessage && <span className={s.errorMessage}>{resultMessage}</span>}
            <div className={s.modalFooter}>
                <button type='button' className={`${s.uploadButton} ${!(file && !errorMessage && !uploadMessage && !resultMessage) && s.disableUploadButton}`} onClick={handleUpload}>Upload {isUploadLoading && <Spin size='small' className='uploadChartSpin' />}</button>
            </div>
        </Modal >
    )
}

export default ScorecardManagementUploadModal
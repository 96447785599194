import { Desktop, Mobile, Tablet } from '../../../responsive/responesive';
import { useDispatch, useSelector } from 'react-redux';

import { get } from 'lodash';
import s from '../scorecards.module.css';
import styles from './MiniCardInforComponent.module.css';
import { toFixedNumber } from '../../../utils/Number.utils';
import { updateLevelAndKeyScoreCard } from '../../../action/actionScoreCard';
import sMobile from './MiniCardItemLevel2Responsive.module.css'

let miniCardMaxSize = 2;
const MiniCardInforItemComponent = (props: any) => {
  const dispatch = useDispatch();

  const keyActiveScoreCard = useSelector((state) => get(state, 'scoreCard.keyScoreCard', ''));
  const levelScoreCard = useSelector((state) => get(state, 'scoreCard.levelScoreCard', ''));
  const sectionIdActive = useSelector((state:any) => state.scoreCard.sectionIdActive)
  const miniCard = props.miniCard;

  function handleClickMiniCard() {
    const payload = {
      level: levelScoreCard,
      key: miniCard.key,
      tab:sectionIdActive
    }

    dispatch(updateLevelAndKeyScoreCard(payload));
  }

  function setActiveScoreCardClass() {
    const isGreenCard = miniCard.color === 'green'? styles.backgroundMiniCardActiveGreen : styles.backgroundMiniCardActiveYellow;
    const isRedCard = miniCard.color === 'red' ? styles.backgroundMiniCardActiveRed : isGreenCard;
    return keyActiveScoreCard === miniCard.key ? isRedCard : ''
  }

  function _renderMobileTablet () {
    return (
      <div
          className={`${sMobile.miniCardItemInforLevel2} ${setActiveScoreCardClass()}`}
          key={miniCard.key}
          style={{ textAlign: 'left' }}
          onClick={() => handleClickMiniCard()}
        >
          <div className={s.miniCardItemTitle}>{miniCard?.name}</div>
          <div className={s.miniCardItemUnit}>{miniCard?.actualValue?.unit}</div>
          <div
            className={
              s.miniCardItemValue + ' ' + s['miniCardItemValueColor' + miniCard?.color]
            }
          >
            {toFixedNumber(miniCard?.actualValue?.value, miniCardMaxSize)}
          </div>
        </div>
    )
  }

  return (
    <>
      <Desktop>
        <div
          className={`${s.miniCardItemInforLevel2} ${setActiveScoreCardClass()}`}
          key={miniCard.key}
          style={{ textAlign: 'left' }}
          onClick={() => handleClickMiniCard()}
        >
          <div className={s.miniCardItemTitle}>{miniCard?.name}</div>
          <div className={s.miniCardItemUnit}>{miniCard?.actualValue?.unit}</div>
          <div
            className={
              s.miniCardItemValue + ' ' + s['miniCardItemValueColor' + miniCard?.color]
            }
          >
            {toFixedNumber(miniCard?.actualValue?.value, miniCardMaxSize)}
          </div>
        </div>
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>
    </>
  );
};

export default MiniCardInforItemComponent;

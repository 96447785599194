import { CALLOUT_CARGO_DETAILS_FAILED } from './../action/actionType';
import { Action } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../action/actionType';
import { getCalloutData, getChartData, getDeliverData, getHightlightData, getPrimaryData, getCalloutDataSec, getHisTrendChartData, getCargoDetails, getSustainabilityCalloutData } from '../services/map.service';
interface CalloutAction extends Action {
    data: string | any;
    isDaily: boolean | any;
}

function* fetchCalloutData(action: CalloutAction) {
    try {
        const data = yield call(getCalloutData, `?locationId=${action.data}`);
        yield put({ type: types.MAP_CALLOUT_FETCH_SUCCEEDED, payload: data });
        const locationId = data.result?.locationId;
        const tabId = data.result?.tabs.length > 0 ? data.result?.tabs[0].key : "0";
        const frequency = data.result?.tabsInfo?.secondaryInfo?.frequency !== null ? data.result?.tabsInfo?.secondaryInfo?.frequency[0].key : '';
        yield put({ type: types.MAP_CHART_FETCH_REQUESTED, locationId: locationId, tabId: tabId, frequency: frequency });
        if (data.result?.tabsInfo?.scrollInfo?.scrollType === "HG") {
            yield put({ type: types.MAP_HIGHLIGHT_FETCH_REQEUSTED, locationId: action.data });
        } else {
            yield put({ type: types.MAP_DELIVER_FETCH_REQUESTED_OLD, locationId: action.data });
        }
    } catch (error: any) {
        yield put({ type: types.MAP_CALLOUT_FETCH_FAILED, message: error.message });
    }
}

function* fetchSustainabilityCalloutData(action: CalloutAction) {
    try {
        const data = yield call(getSustainabilityCalloutData, `?isDaily=${action.isDaily}&locationId=${action.data}`);
        const { lastUpdated, result } = data;
        yield put({ type: types.SUSTAINABILITY_CALLOUT_FETCH_SUCCEEDED, payload: { result, lastUpdated } });
    } catch (error: any) {
        yield put({ type: types.SUSTAINABILITY_CALLOUT_FETCH_FAILED, message: error.message });
    }
}

function* fetchCalloutDataSec(action: CalloutAction) {
    try {
        const data = yield call(getCalloutDataSec, `?locationId=${action.data}`);
        yield put({ type: types.MAP_CALLOUT_FETCH_SUCCEEDED_SEC, payload: data });      
    } catch (error: any) {
        yield put({ type: types.MAP_CALLOUT_FETCH_FAILED_SEC, message: error.message });
    }
}

interface PrimaryAction extends Action {
    locationId: number | any;
    tabId: number | any;
}

function* fetchPrimaryData(action: PrimaryAction) {
    try {
        const data = yield call(getPrimaryData, `?locationId=${action.locationId}`, `&tabId=${action.tabId}`);
        yield put({ type: types.MAP_PRIMARY_FETCH_SUCCEEDED, data: data.result });
    } catch (error: any) {
        yield put({ type: types.MAP_PRIMARY_FETCH_FAILED, message: error.message });
    }
}

interface HightlightAction extends Action {
    locationId: string | any;
}

function* fetchHighlightData(action: HightlightAction) {
    try {
        const data = yield call(getHightlightData, `?locationId=${action.locationId}`);
        yield put({ type: types.MAP_HIGHLIGHT_FETCH_SUCCEEDED, data: data });
    } catch (error: any) {
        yield put({ type: types.MAP_HIGHLIGHT_FETCH_FAILED, message: error.message });
    }
}


interface DeliverAction extends Action {
    locationId: string | any;
}

function* fetchDeliverData(action: CargoDetailAction) {
  try {
    const data = yield call(
      getCargoDetails,
      `?locationId=${action.locationId}`,
      `&beforeDateTime=${action.beforeDateTime}`,
      `&limit=${action.limit}`,
      `&latestByBerth=${action.latestByBerth}`
    );
    let transFormData: any = [];
    if (data.result.length > 0) {
      for (let index = 0; index < data.result.length; index++) {
        const item = data.result[index];
        transFormData.push({ key: index, ...item });
      }
    }
    yield put({ type: types.MAP_DELIVER_FETCH_SUCCEEDED, data: transFormData });
  } catch (error: any) {
    yield put({ type: types.MAP_DELIVER_FETCH_FAILED, message: error.message });
  }
}

function* fetchOldDeliverData(action: DeliverAction) {
    try {
        const data = yield call(getDeliverData, `?locationId=${action.locationId}`);
        let transFormData: any = [];
        if (data.result.length > 0) {
            for (let index = 0; index < data.result.length && index < 20; index++) {
                const item = data.result[index];
                transFormData.push({ key: index, ...item })
            }
        }
        yield put({ type: types.MAP_DELIVER_FETCH_SUCCEEDED, data: transFormData });
    } catch (error: any) {
        yield put({ type: types.MAP_DELIVER_FETCH_FAILED, message: error.message });
    }
}

interface CargoDetailAction extends Action {
  locationId: string | any;
  beforeDateTime: string | any;
  limit: string | any;
  latestByBerth: string | any;
}

function* fetchCargoDetails(action: CargoDetailAction) {
  try {
    const data = yield call(
      getCargoDetails,
      `?locationId=${action.locationId}`,
      `&beforeDateTime=${action.beforeDateTime}`,
      `&limit=${action.limit}`,
      `&latestByBerth=${action.latestByBerth}`
    );
    let transFormData: any = [];
    if (data.result.length > 0) {
      for (let index = 0; index < data.result.length; index++) {
        const item = data.result[index];
        transFormData.push({ key: index, ...item });
      }
    }
    yield put({ type: types.CALLOUT_CARGO_DETAILS_SUCCEEDED, data: transFormData });
  } catch (error: any) {
    yield put({ type: types.CALLOUT_CARGO_DETAILS_FAILED, message: error.message });
  }
}

interface ChartAction extends Action {
    locationId: string; tabId: string; frequency: string;
}

interface HisTrendChartAction extends Action {
    locationId: string; frequency: string;
}

function* fetchChartData(action: ChartAction) {
    try {
        const data = yield call(getChartData, `?locationId=${action.locationId}`, `&tabId=${action.tabId}`, `&frequency=${action.frequency}`);
        yield put({ type: types.MAP_CHART_FETCH_SUCCEEDED, data: data.result });
    } catch (error: any) {
        yield put({ type: types.MAP_CHART_FETCH_FAILED, message: error.message });
    }
}

function* fetchHisTrendChartData(action: HisTrendChartAction) {
    try {
        const data = yield call(getHisTrendChartData, `?locationId=${action.locationId}`, `&frequency=${action.frequency}`);
        yield put({ type: types.HIS_TREND_CHART_DATA_SUCCEEDED, data: {data: data.result, lastUpdated: data.lastUpdated}});
    } catch (error: any) {
        yield put({ type: types.HIS_TREND_CHART_DATA_FAILED, message: error.message });
    }
}

export function* workerFetchCalloutData() {
    yield all([
        takeLatest(types.MAP_CALLOUT_FETCH_REQEUSTED, fetchCalloutData),
        takeLatest(types.SUSTAINABILITY_CALLOUT_FETCH_REQUESTED, fetchSustainabilityCalloutData),
        takeLatest(types.MAP_CALLOUT_FETCH_REQUESTED_SEC, fetchCalloutDataSec),
        takeLatest(types.MAP_PRIMARY_FETCH_REQUESTED, fetchPrimaryData),
        takeLatest(types.MAP_HIGHLIGHT_FETCH_REQEUSTED, fetchHighlightData),
        takeLatest(types.MAP_DELIVER_FETCH_REQEUSTED, fetchDeliverData),
        takeLatest(types.MAP_DELIVER_FETCH_REQUESTED_OLD, fetchOldDeliverData),
        takeLatest(types.CALLOUT_CARGO_DETAILS_REQUESTED, fetchCargoDetails),
        takeLatest(types.MAP_CHART_FETCH_REQUESTED, fetchChartData),
        takeLatest(types.HIS_TREND_CHART_DATA_REQUESTED, fetchHisTrendChartData),
    ])
}
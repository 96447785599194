import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateLevelAndKeyScoreCard } from "../../../action/actionScoreCard";
import { Desktop, Mobile, Tablet } from "../../../responsive/responesive";
import { toFixedNumber, toFixedNumberAbsolute } from "../../../utils/Number.utils";
import s from "./BigCardInforComponentLevel2Item.module.css";
import sMolbie from "./BigCardInforResponsiveMobile.module.css";

const BigCardInforComponentLevel2Item = (props: any) => {
  const scoreCard = useSelector((state: any) => state.scoreCard);
  const card = props.data;
  const getDefaultValue = () => {
    if (scoreCard.unitSelected && card.values.length > 1) {
      const newValues = card.values.filter(
        (v) => v.unit === scoreCard.unitSelected
      );
      return newValues[0];
    }
    return card?.values?.[0];
  };
  const [cardValue, setCardValue] = useState(getDefaultValue() || card);

  const sectionIdActive = useSelector(
    (state: any) => state.scoreCard.sectionIdActive
  );
  const levelScoreCard = useSelector((state) =>
    get(state, "scoreCard.levelScoreCard")
  );

  const keyActiveScoreCard = useSelector((state) =>
    get(state, "scoreCard.keyScoreCard", "")
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setCardValue(getDefaultValue() || props.data);
  }, [props.data]);

  useEffect(() => {
    if (card.units.includes(scoreCard.unitSelected)) {
      const newValues = card.values.filter(
        (v) => v.unit === scoreCard.unitSelected
      );
      setCardValue(newValues[0]);
    }
  }, [scoreCard.unitSelected]);

  function handleClickBigCard(key: any) {
    const units = props.data.units;
    const payload = {
      level: levelScoreCard,
      key,
      tab: sectionIdActive,
      unitSelected: units.length > 1 ? units[0] : "",
      units: units.length > 1 ? units : [],
    };

    dispatch(updateLevelAndKeyScoreCard(payload));
  }


  function _renderDeltaValue(value: number) {
    const formatValue = toFixedNumber(value < 0 ? Math.abs(value) : value, 2);
    return formatValue;
  }

  function setActiveScoreCardClass() {
    const isGreenCard =
      cardValue?.color === "green" ? s.activeGreen : s.activeYellow;
    const isRedCard = cardValue?.color === "red" ? s.activeRed : isGreenCard;
    return keyActiveScoreCard === card.key ? isRedCard : "";
  }

  function _renderMobileTablet() {
    return (
      <div
        className={`cardItem ${sMolbie.scoreCardsItem} ${
          cardValue?.color ? s[cardValue?.color] : ""
        } ${setActiveScoreCardClass()}`}
        onClick={() => handleClickBigCard(card.key)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleClickBigCard(card.key);
          }
        }}
        tabIndex={0}
      >
        {/* Card Body */}
        <div>
          <div className={s.scoreCardsItemTitle}>{card?.name}</div>
          <div className={s.scoreCardsItemValue}>
            {toFixedNumber(cardValue?.actualValue?.value, 1)}
            <b>
              <span style={{ opacity: 0.8 }}>
                {cardValue?.actualValue?.currency}
              </span>
            </b>
            <span className={s.currencyUnit}>
              {cardValue?.actualValue?.unit}
            </span>
          </div>
          {/* </div> */} 
          {/* secondaryValue */}
          {cardValue?.secondaryValue && (
            <div className={s.scoreCardsItemPlanTarget}>
              <div className={s.scoreCardItemValue}>
                {cardValue?.secondaryValue?.type} :{" "}
                {/* {cardValue?.secondaryValue?.currency} */}
                {cardValue?.secondaryValue?.value && (
                  <b>
                    &nbsp;
                    {toFixedNumberAbsolute(cardValue?.secondaryValue?.value, 1)}
                  </b>
                )}
                {/* <span>
                  &nbsp;{cardValue?.secondaryValue?.unit}&nbsp;&nbsp;&nbsp;
                </span> */}
              </div>
              <div className={s.scoreCardItemDeltaValue}>
                {cardValue?.deltaValue?.value && (
                  <b>{_renderDeltaValue(cardValue?.deltaValue?.value)}</b>
                )}
                <span className={s.detalValueUnit}>
                  &nbsp;{cardValue?.deltaValue?.unit}
                </span>
                {cardValue?.deltaValue?.arrowDirection &&
                  (cardValue?.deltaValue?.arrowDirection === "up" ? (
                    <CaretUpOutlined style={{ fontSize: "14px" }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: "14px" }} />
                  ))}
              </div>
            </div>
          )}
          {/* SPYL value */}
          {cardValue?.splyValue && (
            <div className={s.scoreCardsItemPlanTarget}>
              <div className={s.scoreCardItemValue}>
                {cardValue?.splyValue?.type} :{" "}
                {/* {cardValue?.splyValue?.currency} */}
                {cardValue?.splyValue?.value && (
                  <b>
                    &nbsp;
                    {toFixedNumberAbsolute(cardValue?.splyValue?.value, 1)}
                  </b>
                )}
                {/* <span>
                  &nbsp;{cardValue?.splyValue?.unit}&nbsp;&nbsp;&nbsp;
                </span> */}
              </div>
              <div className={s.scoreCardItemDeltaValue}>
                {cardValue?.splyDeltaValue?.value && (
                  <b>{_renderDeltaValue(cardValue?.splyDeltaValue?.value)}</b>
                )}
                <span className={s.detalValueUnit}>
                  &nbsp;{cardValue?.splyDeltaValue?.unit}
                </span>
                {cardValue?.splyDeltaValue?.arrowDirection &&
                  (cardValue?.splyDeltaValue?.arrowDirection === "up" ? (
                    <CaretUpOutlined style={{ fontSize: "14px" }} />
                  ) : (
                    <CaretDownOutlined style={{ fontSize: "14px" }} />
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <Desktop>
        <div
          className={`cardItem ${s.scoreCardsItem} ${
            cardValue?.color ? s[cardValue?.color] : ""
          } ${setActiveScoreCardClass()}`}
          onClick={() => handleClickBigCard(card.key)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClickBigCard(card.key);
            }
          }}
          tabIndex={0}
        >
          {/* Card Body */}
          <div>
            <div className={s.scoreCardsItemTitle}>{card?.name}</div>
            <div className={s.scoreCardsItemValue}>
              {toFixedNumberAbsolute(cardValue?.actualValue?.value, 1)}
              <b>
                <span style={{ opacity: 0.8 }}>
                  {cardValue?.actualValue?.currency}
                </span>
              </b>
              <span className={s.currencyUnit}>
                {cardValue?.actualValue?.unit}
              </span>
            </div>
            {/* </div> */}
            
            {/* Secondary value */}
            {cardValue?.secondaryValue && (
              <div className={s.scoreCardsItemPlanTarget}>
                <div className={s.scoreCardItemValue}> 
                  {cardValue?.secondaryValue?.type}
                  {/* {cardValue?.secondaryValue?.currency &&
                    ` ${cardValue?.secondaryValue?.currency}`} */}
                   &nbsp;&nbsp;
                  {cardValue?.secondaryValue?.value && (
                    <b>
                      {toFixedNumberAbsolute(cardValue?.secondaryValue?.value, 1)}
                    </b>
                  )}
                  {/* <span>
                    &nbsp;{cardValue?.secondaryValue?.unit}&nbsp;&nbsp;&nbsp;
                  </span> */}
                </div>
                <div className={s.scoreCardItemDeltaValue}>
                  {cardValue?.deltaValue?.value && (
                    <b>{_renderDeltaValue(cardValue?.deltaValue?.value)}</b>
                  )}
                  <span className={s.detalValueUnit}>
                    &nbsp;{cardValue?.deltaValue?.unit}
                  </span>
                  {cardValue?.deltaValue?.arrowDirection &&
                    (cardValue?.deltaValue?.arrowDirection === "up" ? (
                      <CaretUpOutlined style={{ fontSize: "14px" }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: "14px" }} />
                    ))}
                </div>
              </div>
            )}

            {/* SPYL value */}
            {cardValue?.splyValue && (
              <div className={s.scoreCardsItemPlanTarget}>
                <div className={s.scoreCardItemValue}>
                  {cardValue?.splyValue?.type}
                  {/* {cardValue?.splyValue?.currency &&
                    ` ${cardValue?.splyValue?.currency}`} */}
                  &nbsp;&nbsp;
                  {cardValue?.splyValue?.value && (
                    <b>
                      {toFixedNumberAbsolute(cardValue?.splyValue?.value, 1)}
                    </b>
                  )}
                  {/* <span>
                    &nbsp;{cardValue?.splyValue?.unit}&nbsp;&nbsp;&nbsp;
                  </span> */}
                </div>

                <div className={s.scoreCardItemDeltaValue}>
                  {cardValue?.splyDeltaValue?.value && (
                    <b>{_renderDeltaValue(cardValue?.splyDeltaValue?.value)}</b>
                  )}
                  <span className={s.detalValueUnit}>
                    &nbsp;{cardValue?.splyDeltaValue?.unit}
                  </span>
                  {cardValue?.splyDeltaValue?.arrowDirection &&
                    (cardValue?.splyDeltaValue?.arrowDirection === "up" ? (
                      <CaretUpOutlined style={{ fontSize: "14px" }} />
                    ) : (
                      <CaretDownOutlined style={{ fontSize: "14px" }} />
                    ))}
                </div>
              </div>
            )}         
          </div>
        </div>
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>
    </>
  );
};

export default React.memo(BigCardInforComponentLevel2Item);

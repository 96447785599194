import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../action/actionType';
import { getValueChain, getValueChainOverview } from '../services/value-chain.service';

function* fetchValueChainData() {
  try {
    const data = yield call(getValueChain);
    yield put({ type: types.VALUE_CHAIN_DATA_FETCH_SUCCEEDED, payload: data });
  } catch (error: any) {
    yield put({ type: types.VALUE_CHAIN_DATA_FETCH_FAILED, message: error.message });
  }
}

function* fetchValueChainOverviewSaga() {
  try {
    const data = yield call(getValueChainOverview);
    yield put({ type: types.VALUE_CHAIN_OVERVIEW_FETCH_SUCCEEDED, payload: data.result });
  } catch (error: any) {
    yield put({ type: types.VALUE_CHAIN_OVERVIEW_FETCH_FAILED, message: error.message });
  }
}

export function* workerFetchValueChainData() {
  yield takeLatest(types.VALUE_CHAIN_DATA_REQUESTED, fetchValueChainData);
  yield takeLatest(types.VALUE_CHAIN_OVERVIEW_REQUESTED, fetchValueChainOverviewSaga);
}


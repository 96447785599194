import * as types from './actionType';

export const updateUserName = (payload: string) => ({
  type: types.UPDATE_USERNAME,
  payload
})

export const fetchUser = () => ({
  type: types.USER_FETCH_REQUESTED,
})

export const fetchUserImage = (payload: any) => ({
  type: types.USER_FETCH_IMAGE,
  payload
})

export const fetchUserName = (payload: any) => ({
  type: types.USER_FETCH_PROPERTIES,
  payload
})

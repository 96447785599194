import { Desktop, Mobile, Tablet } from '../../../responsive/responesive';

import BigCardInforComponentLevel2Item from './BigCardInforComponentLevel2Item';
import Carousel from 'react-elastic-carousel';
import s from './BigCardInforComponentLevel2List.module.css';
import sMolbie from './BigCardInforResponsiveMobile.module.css';

interface Props {
  listBigCard: any
}
const BigCardInforComponentLevel2List = ({ listBigCard }: Props) => {
  const parameters = listBigCard;


  const breakPoints = [
    {width: 400, itemsToShow: 1},
    { width: 550, itemsToShow: 2 },
    { width: 650, itemsToShow: 2.5 },
    { width: 720, itemsToShow: 3 },
    { width: 900, itemsToShow: 3.5 },
    { width: 1024, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4.6 },
    { width: 1300, itemsToShow: 5 },
    { width: 1400, itemsToShow: 6 },
    { width: 1500, itemsToShow: 6.5 },
    { width: 1800, itemsToShow: 7.5 },
    { width: 2000, itemsToShow: 8.5 },
    { width: 2500, itemsToShow: 9.5 },
    { width: 2700, itemsToShow: 10.5 },
    { width: 3100, itemsToShow: 12.5 },
  ];

  const myArrow = ({ type, onClick, isEdge }) => {
    return (
      <button
        onClick={onClick}
        hidden={isEdge}
        // disabled={isEdge}
        className={`${type === 'PREV' ? s.scoreCardButtonPrev : s.scoreCardButtonNext
          }`}
      >
        <img
          style={{ cursor: 'pointer' }}
          src='/img/icon-score-card/Icon Back.svg'
          alt='back'
        />
      </button>
    );
  };

  const _renderMobileTablet = () => (  
  <div className={sMolbie.bigCardsListContainer}>
    <Carousel
      isRTL={false}
      breakPoints={breakPoints}
      showEmptySlots={false}
      pagination={false}
      showArrows={false}
    >
      {parameters && parameters.map((card: any) => {
        return <BigCardInforComponentLevel2Item key={card.key} data={card} />;
      })}
    </Carousel>
  </div>
  )

  return (
    <>
      <Desktop>
        <div>
        {(parameters && parameters.length < 8) ? (
          <div className={s.listBigCardnotCarousel}>
            {
              parameters && parameters.map((card: any) => {
                return (

                  <BigCardInforComponentLevel2Item key={card.key} data={card} />
                );
              })
            }
          </div>
        ) : (
          <Carousel
            isRTL={false}
            breakPoints={breakPoints}
            showEmptySlots={false}
            pagination={false}
            renderArrow={myArrow}
          >
            {parameters && parameters.map((card: any) => {
              return <BigCardInforComponentLevel2Item key={card.key} data={card} />;
            })}
          </Carousel>
        )}
        </div>
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>
    </>
  );
};

export default BigCardInforComponentLevel2List;

import { Spin } from 'antd';
import update from 'immutability-helper';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchScoreCardOrderList, fetchUpdateScoreCardOrder } from '../../../action/actionScorecardorder';
import { IScoreCardOrder } from '../../../model/scorecardOrder.model';
import { IUserPersona } from '../../../model/user.model';
import { getUserPersona, isSuperAdmin } from '../../../utils/user.utils';
import ListDnDComponent from '../../DnD/ListDnD/ListDnDComponent';
import PopoverComponent from '../../Popover/PopoverComponent';
import s from './ListOrderingComponent.module.css';

interface Props {
  sectionId: number;
}
const ListOrderingComponent = (props: Props) => {
  const dispatch = useDispatch();
  const [modifiedListData, setmodifiedListData] = useState<IScoreCardOrder[]>(
    []
  );
  const [opuIndex, setopuIndex] = useState(-1);
  const isListLoading = useSelector((state) =>
    get(state, "scorecardorder.isListLoading", false)
  );

  const listData: IScoreCardOrder[] = useSelector((state) =>
    get(state, "scorecardorder.listData", [])
  );

  const opuList: any[] = useSelector((state) =>
    get(state, "scorecardorder.opuList", [])
  );

  useEffect(() => {
    setmodifiedListData([]);
  }, [listData]);

  function moveCard(id: string, atIndex: number) {
    let newListData = JSON.parse(JSON.stringify(getTableData()));
    const { card, index } = findCard(id);
    setmodifiedListData(
      update(newListData, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      })
    );
  }

  function findCard(id: string) {
    let newListData: IScoreCardOrder[] = JSON.parse(
      JSON.stringify(getTableData())
    );
    const card = newListData.filter(
      (item) => `${item.cardPrimaryKey}` === id
    )[0];

    return {
      card,
      index: newListData.indexOf(card),
    };
  }

  function getTableData() {
    return modifiedListData.length > 0 ? modifiedListData : listData;
  }

  function _renderList() {
    let listDataRow = getTableData();
    return (
      <>
        {listDataRow.map((item, idx) => (
          <ListDnDComponent
            idx={idx}
            key={item.cardPrimaryKey}
            id={item.cardPrimaryKey}
            text={item.cardPrimaryName}
            icon={item.cardIcon}
            moveCard={moveCard}
            findCard={findCard}
          />
        ))}
      </>
    );
  }

  function setOpu(index) {
    setopuIndex(index);
    const data = {
      sectionId: props.sectionId,
      userOpuId: opuList[index].id,
    };
    dispatch(fetchScoreCardOrderList(data));
  }

  function setCancelEdit() {
    setmodifiedListData([]);
  }

  function setSaveCard() {
    const data = {
      sectionId: props.sectionId,
      userOpuId: getOpuId(),
      dataBody: modifiedListData,
    };
    dispatch(fetchUpdateScoreCardOrder(data));
  }

  function getOpuId() {
    const userPersona: IUserPersona = getUserPersona();
    return opuIndex === -1 ? userPersona.opuId : opuList[opuIndex].id;
  }

  function getOpuValue() {
    const userPersona: IUserPersona = getUserPersona();
    return opuIndex === -1 ? userPersona.opu : opuList[opuIndex].text;
  }

  function _scorecardOrderHeader() {
    let opuVal = getOpuValue();
    return (
      <div className={s.scorecardOrderHeader}>
        <div className={s.dropdownPersona}>
          <p>OPU</p>
          {isSuperAdmin() ? (
            <PopoverComponent
              value={opuVal}
              arr={opuList}
              selectFunc={setOpu}
            />
          ) : (
            <div className={s.scorecardOrderOPU}>{opuVal}</div>
          )}
        </div>

        {modifiedListData.length > 0 && (
          <div className={s.headerButton}>
            <button onClick={() => setCancelEdit()} className={s.cancelbutton}>
              CANCEL
            </button>
            <button onClick={() => setSaveCard()} className={s.savebutton}>
              SAVE
            </button>
          </div>
        )}
      </div>
    );
  }
  return (
    <>
      <div className={s.scorecardOrderContainer}>
        {_scorecardOrderHeader()}
        <div className={s.scorecardOrderList}>
          {isListLoading ? (
            <Spin size="large" className="chartSpin" />
          ) : (
            _renderList()
          )}
        </div>
      </div>
    </>
  );
};
export default ListOrderingComponent;

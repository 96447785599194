import { Popover, Spin } from "antd";
import { get, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchLimtiConfigTable } from "../../../action/actionLimitConfig";
import { IWmTable } from "../../../model/limitConfig.model";
import { searchTable } from "../../../utils/table.utils";
import EditRulesModalComponent from "../EditRulesModal/EditRulesModalComponent";
import s from "./WorldMapTableComponent.module.css";

const colorClass = {
  green: s.green,
  red: s.red,
  amber: s.amber,
};

const colorText = {
  green: "Green",
  red: "Red",
  amber: "Amber",
};

const WorldMapTableComponent = (props: any) => {
  const dispatch = useDispatch();

  const [isModalVisible, setisModalVisible] = useState(false);
  const [editRow, seteditRow] = useState({});
  useEffect(() => {
    dispatch(fetchLimtiConfigTable());
  }, []);

  const [modifiedTableData, setTableData] = useState<IWmTable[]>([]);

  const limitWmTableData: IWmTable[] = useSelector((state) =>
    get(state, "limitconfig.limitWmTableData", [])
  );

  const isWMTableLoading = useSelector((state) =>
    get(state, "limitconfig.isWMTableLoading", false)
  );

  function _theadColumn(theadText, column) {
    return (
      <th>
        <div>{theadText}</div>
      </th>
    );
  }

  function _renderTableHead() {
    return (
      <>
        <tr>
          <th>
            <div></div>
          </th>
          {_theadColumn("NO.", "")}
          {_theadColumn("MAP", "parentLocation")}
          {_theadColumn("LOCATION", "locationDescription")}
          {_theadColumn("PARAMETER", "")}
          {_theadColumn("COLORS", "")}
          {_theadColumn("LOWER BOUND", "")}
          {_theadColumn("UPPER BOUND", "")}
          <th>
            <div></div>
          </th>
        </tr>
      </>
    );
  }

  function getTableData() {
    return modifiedTableData.length > 0 ? modifiedTableData : limitWmTableData;
  }

  function _tbodyText(item, attr, setExpandFunc) {
    return (
      <>
        <td onClick={setExpandFunc}>
          <div className={s.tbodyText}>{item[attr]}</div>
        </td>
      </>
    );
  }

  function _tbodyImageArrow(idx) {
    return (
      <>
        <td>
          <img
            onClick={() => setIsExpand(idx)}
            className={s.arrow}
            src="/img/icon/DownArrow.svg"
            alt="arrow"
          />
        </td>
      </>
    );
  }

  function showModal(idx, isDisable) {
    if (!isDisable) {
      let tableRows = getTableData();
      setisModalVisible(true);
      seteditRow(tableRows[idx]);
    }
  }

  function _tbodyImageEdit(item, idx) {
    const isDisable = item.parameters[item.selectedParam].disableEdit;
    let tbodyImageClass = isDisable
      ? `${s.tbodyImageEdit} ${s.disable}`
      : s.tbodyImageEdit;
    return (
      <>
        <td className={tbodyImageClass}>
          <img
            onClick={() => showModal(idx, isDisable)}
            className={s.arrow}
            src="/img/icon/Edit.svg"
            alt="arrow"
          />
        </td>
      </>
    );
  }

  function _tbodyNo(idx, setExpandFunc) {
    return (
      <>
        <td onClick={setExpandFunc}>
          <div className={s.tbodyText}>{idx + 1}</div>
        </td>
      </>
    );
  }

  function setSelectedParam(idx, index) {
    let newTable: IWmTable[] = JSON.parse(JSON.stringify(getTableData()));
    newTable[idx].selectedParam = index;
    setTableData(newTable);
  }
  function _renderContent(arr, idx) {
    return (
      <div className={s.popoverContent}>
        {arr.map((values, index) => (
          <div key={index}>
            <span onClick={() => setSelectedParam(idx, index)} key={index}>
              {values.parameterName}
            </span>
          </div>
        ))}
      </div>
    );
  }
  function _tbodyDropParam(item: IWmTable, idx) {
    return (
      <>
        <td className={s.tbodyDropdown}>
          <Popover
            overlayClassName="tbodyDropdown"
            placement="bottom"
            content={_renderContent(item.parameters, idx)}
            trigger="click"
          >
            <div className={s.tbodyText}>
              <p>{item.parameters[item.selectedParam].parameterName}</p>
              <img
                className={s.arrow}
                src="/img/icon/DownArrow.svg"
                alt="arrow"
              />
            </div>
          </Popover>
        </td>
      </>
    );
  }

  function _tbodyColors(item: IWmTable, setExpandFunc) {
    let color = item.parameters[item.selectedParam].rules[0].color;
    return (
      <>
        <td onClick={setExpandFunc}>
          {item.isExpand ? (
            item.parameters[item.selectedParam].rules.map((value, idx) => (
              <div key={idx} className={s.colorsColum}>
                <div className={colorClass[value.color]}>
                  <div />
                </div>
                <div className={s.colorText}>{colorText[value.color]}</div>
              </div>
            ))
          ) : (
            <div className={s.colorsColum}>
              <div className={colorClass[color]}>
                <div />
              </div>
              <div className={s.plusNumber}>
                +{item.parameters[item.selectedParam].rules.length}
              </div>
            </div>
          )}
        </td>
      </>
    );
  }

  function _tbodyNumber(item: IWmTable, attr, setExpandFunc) {
    return (
      <td onClick={setExpandFunc} className={s.tbodyNumber}>
        {item.isExpand ? (
          item.parameters[item.selectedParam].rules.map((value, idx) => (
            <p>{value[attr] !== null ? value[attr] : '\u00A0'}</p>
          ))
        ) : (
          <p>{item.parameters[item.selectedParam].rules[0][attr]}</p>
        )}
      </td>
    );
  }

  function getRowHeight(item: IWmTable) {
    return 43 * item.parameters[item.selectedParam].rules.length;
  }

  function setIsExpand(idx) {
    let newTable = JSON.parse(JSON.stringify(getTableData()));
    newTable[idx].isExpand = !newTable[idx].isExpand;
    setTableData(newTable);
  }

  function _tbodyColumn(item: IWmTable, idx) {
    let setExpandFunc = item.isExpand ? null : () => setIsExpand(idx);

    return (
      <>
        {_tbodyImageArrow(idx)}
        {_tbodyNo(idx, setExpandFunc)}
        {_tbodyText(item, "parentLocation", setExpandFunc)}
        {_tbodyText(item, "locationDescription", setExpandFunc)}
        {_tbodyDropParam(item, idx)}
        {_tbodyColors(item, setExpandFunc)}
        {_tbodyNumber(item, "lowerBound", setExpandFunc)}
        {_tbodyNumber(item, "upperBound", setExpandFunc)}
        {_tbodyImageEdit(item, idx)}
      </>
    );
  }

  function _renderTableBody() {
    let tableRows = getTableData();
    return (
      <>
        {tableRows.map((item: IWmTable, idx) => (
          <tr
            key={idx}
            className={item.isExpand ? s.expandRow : ""}
            style={item.isExpand ? { height: getRowHeight(item) } : {}}
          >
            {_tbodyColumn(item, idx)}
          </tr>
        ))}
      </>
    );
  }

  function searchTableFunc(e) {
    let tableRows = getTableData();
    let filterTable = searchTable(tableRows, e.target.value);
    if (isEmpty(e.target.value)) {
      setTableData(limitWmTableData);
    } else {
      setTableData(filterTable);
    }
  }
  function setCloseModal() {
    setisModalVisible(false);
  }
  return (
    <>
      <div className={s.limitTable}>
        <input
          onChange={(e) => searchTableFunc(e)}
          className={s.wmTableSearch}
          placeholder="Search"
        />
        <div className="table">
          <table>
            <thead>{_renderTableHead()}</thead>
            <tbody>
              {isWMTableLoading ? (
                <Spin size="large" className="chartSpin" />
              ) : (
                _renderTableBody()
              )}
            </tbody>
          </table>
        </div>
        <EditRulesModalComponent
          isVisible={isModalVisible}
          editRow={editRow}
          closeFunc={setCloseModal}
          type="WM"
        />
      </div>
    </>
  );
};
export default WorldMapTableComponent;

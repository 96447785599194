function transformInitData(result) {
  for (let index = 0; index < result.length; index++) {
    result[index].isEdit = false;
  }

  return result;
}

export function transformAddMapList(results) {
  return transformInitData(results);
}

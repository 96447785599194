import { Desktop, Mobile, Tablet } from "../../responsive/responesive";

import BigCardInforComponent from "./BigCard/BigCardInforComponent";
import BigCardInforComponentLevel2List from "./BigCardLv2/BigCardInforComponentLevel2List";
import ChartComponent from "./Chart/ChartComponent";
import React from "react";
import { get } from "lodash";
import s from "./scorecards.module.css";
import sMobile from "./scorecardsResponsiveMobile.module.css";
import { useSelector } from "react-redux";

const PlantPerformanceComponent = (props: any) => {
  const plantPerformanceData = useSelector((state) =>
    get(state, "scoreCard.plantPerformanceData", [])
  );
  const levelScoreCard = useSelector((state) =>
    get(state, "scoreCard.levelScoreCard", 1)
  );

  function ScoreCardColComponent() {
    return (
      <>
        <div className={s.scoreCardsCol}>
          {plantPerformanceData?.parameters?.map((card, idx) => {
            return idx % 3 === 0 ? (
              <BigCardInforComponent key={idx} data={card} />
            ) : (
              ""
            );
          })}
        </div>
        <div className={s.scoreCardsCol}>
          {plantPerformanceData?.parameters?.map((card, idx) => {
            return idx % 3 === 1 ? (
              <BigCardInforComponent key={idx} data={card} />
            ) : (
              ""
            );
          })}
        </div>
        <div className={s.scoreCardsCol}>
          {plantPerformanceData?.parameters?.map((card, idx) => {
            return idx % 3 === 2 ? (
              <BigCardInforComponent key={idx} data={card} />
            ) : (
              ""
            );
          })}
        </div>
      </>
    );
  }

  const _renderMobileTablet = () => ( levelScoreCard === 1 ? (
    <div className={sMobile.scoreCardsItems}>
      {ScoreCardColComponent()}
    </div>
  ) : (
    <div>
      <BigCardInforComponentLevel2List
        listBigCard={plantPerformanceData?.parameters}
      />
      <div className={sMobile.backGroundChart}>
        <ChartComponent tab="plant"/>
      </div>
    </div>
  ))

  return (
    <>
      <Desktop>
        {levelScoreCard === 1 ? (
          <div className={s.scoreCardsItems}>{ScoreCardColComponent()}</div>
        ) : (
          <div>
            <BigCardInforComponentLevel2List
              listBigCard={plantPerformanceData?.parameters}
            />
            <div className={s.backGroundChart}>
              <ChartComponent tab="plant"/>
            </div>
          </div>
        )}
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>
    </>
  );
};
export default PlantPerformanceComponent;

import { get } from "lodash";
import queryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { fetchMapData, mapLevelChange, mapPointViewDetail, updateActiveTab } from "../../action/actionMap";
import MapActionsComponent from "../../components/Map/MapActionsComponent";
import MapPointDetailComponent from "../../components/Map/MapDetailComponent";
import CalloutComponent from "../../components/Callout/CalloutComponent";
import MapPointComponent from "../../components/Map/MapPointComponent";
import MapVesselComponent from "../../components/Map/MapVesselComponent";
import { URL_SIDE_BAR } from "../../constants/map.constants";
import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import { transformToPixels } from "../../utils/LatLong.utils";
import { isAllowLocation } from "../../utils/map.utils";
import { buildUrl } from "../../utils/Url.utils";
import { getDefaultMap } from "../../utils/user.utils";
import { CALLOUT_TYPE, FLOWRATE_ORDER } from "../../constants/location.constants";
import { getHeaderLabel } from "../../utils/sidebar.utils";
import s from "./map.module.css";
import sMobile from "./mapResponsive.module.css";
import MapFlowrateComponent from "../../components/Map/MapFlowrateComponent";

const MapComponent = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = queryString.parse(window.location.search);

  // Private Variables
  let winWidth = window.innerWidth;
  let winHeight = window.innerHeight;
  const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100) / 100;

  // Define state

  const [originalImageSize, setOriginalImageSize] = useState({
    width: 1440,
    height: 810,
  });
  const [currentImageSize, setCurrentImageSize] = useState(getImageSize());
  const [isBaseWidth, setIsBaseWidth] = useState(true);
  const [mapPoints, setMapPoints] = useState([]);
  const [mapImage, setMapImage] = useState(`/map-images/Malaysia.jpg`);
  const handle = useFullScreenHandle();

  // Get data from store
  const mapData = useSelector((state) => get(state, "map.mapData", {}));
  const mapInfo = useSelector((state) => get(state, "map.selectedPoint", null));
  const isSatelliteMap = useSelector((state) =>
    get(state, "map.isSatelliteMap", true)
  );

  //scroll to bottom
  const mapScroll = useRef<null | HTMLDivElement>(null);
  useEffect(() => {
    if (!mapScroll) return;
    mapScroll.current?.scrollIntoView({ behavior: "smooth" })
  }, [mapScroll]);

  const isNewCallout = [
    CALLOUT_TYPE.UKUG,
    CALLOUT_TYPE.RGT,
    CALLOUT_TYPE.PGU,
    CALLOUT_TYPE.LNG_DELIVERY,
  ].includes(mapInfo?.calloutTypeId);

  // Tracking mapData change
  useEffect(() => {
    if (mapData?.result) {
      originalImageSizeChange();
      dispatch(mapLevelChange(mapData?.result?.level));
      const location = Number(mapData?.result?.locationId);
      const level = Number(mapData?.result?.level);
      history.push(buildUrl(location, isSatelliteMap, level));
      document.title =
        mapData?.result?.locationName?.toUpperCase() +
        " - PETRONAS - GO Dashboard";
    }
  }, [mapData, isSatelliteMap]);

  useEffect(() => {
    const oneMinutes = 60000;
    const intervalId = setInterval(() => {
      const urlParams = queryString.parse(window.location.search);
      if (urlParams?.location) {
        dispatch(fetchMapData(Number(urlParams?.location)));
      }
    }, oneMinutes);
    dispatch(updateActiveTab(URL_SIDE_BAR.MAP));
    const defaultMap = getDefaultMap();
    if (params?.location) {
      // const allowLocation = isAllowLocation(params?.location)
      //   ? params?.location
      //   : defaultMap.locationId;
      dispatch(fetchMapData(Number(params?.location)));
    } 
    else {
       dispatch(fetchMapData(defaultMap.locationId));
    }
    return () => clearInterval(intervalId)
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize)
  });

  function originalImageSizeChange() {
    const imageSrc = isSatelliteMap
      ? mapData?.result?.imageUrl?.satellite
      : mapData?.result?.imageUrl?.roadView;
    let img = new Image();
    img.src = imageSrc;
    setMapImage(imageSrc);
    img.onload = () => {
      var imgElm = document.getElementById("rootMapBg") as HTMLElement;
      const imgWidth = imgElm?.clientWidth;
      const imgHeight = imgElm?.clientHeight;
      setOriginalImageSize({ width: imgWidth, height: imgHeight });
      setIsBaseWidth(winWidth / imgWidth > winHeight / imgHeight);
      const dataTransform = transformData(mapData?.result?.locationPoints);
      setMapPoints(dataTransform);
    };
    img.onerror = () => {
      setOriginalImageSize({ width: winWidth, height: winHeight });
      setIsBaseWidth(true);
      const dataTransform = transformData(mapData?.result?.locationPoints);
      setMapPoints(dataTransform);
    };

    if (params.id) {
      const rs = mapData?.result?.locationPoints?.find(
        (x: any) => x.locationId === Number(params.id)
      );
      dispatch(mapPointViewDetail(rs));
    }
  }

  const allowedCallouts = [21, 23, 24, 26, 30, 31, 32, 33, 34, 35, 47, 48, 49, 57, 58, 59];

  // Handle window resize
  function handleResize() {
    winWidth = window.innerWidth;
    winHeight = window.innerHeight;
    setIsBaseWidth(
      winWidth / originalImageSize.width > winHeight / originalImageSize.height
    );
    setCurrentImageSize(getImageSize());
    dispatch(fetchMapData(mapData?.result?.locationId));
  }

  // detect IOS device
  function isIOSDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  function getImageSize() {
    // const zoomRatio = isIOSDevice() ? zoomLevel > 1 ? zoomLevel : 0.9 : zoomLevel > 1 ? zoomLevel : 1; 
    const zoomRatio = zoomLevel > 1 ? zoomLevel : 1;
    const oWidth = 1440;
    const oHeight = 810;
    if (sessionStorage.getItem("preUrl") || isIOSDevice()) {
      return winWidth / oWidth > winHeight / oHeight
        ? { width: winWidth * zoomRatio, height: oHeight * (winWidth / oWidth) * zoomRatio }
        : { width: oWidth * (winHeight / oHeight) * zoomRatio, height: winHeight * zoomRatio };
    }
    return { width: winWidth * zoomRatio, height: oHeight * (winWidth / oWidth) * zoomRatio };
  }

  // Transform data
  function transformData(data: any) {
    const imageCoordinates = mapData?.result?.imageCoordinates;
    const imageNorthLat = imageCoordinates?.topLeft?.latitude;
    const imageSouthLat = imageCoordinates?.bottomLeft?.latitude;
    const imageWestLong = imageCoordinates?.topLeft.longitude;
    const imageEastLong = imageCoordinates?.bottomRight.longitude;

    data?.forEach((element: any) => {
      const lat = element?.coordinates?.latitude;
      const long = element?.coordinates?.longitude;
      const oWidth = originalImageSize.width;
      const oHeight = originalImageSize.height;
      const elementTransform = transformToPixels(
        lat,
        long,
        imageNorthLat,
        imageSouthLat,
        imageWestLong,
        imageEastLong,
        oWidth,
        oHeight,
        currentImageSize.width
      );
      element.X = elementTransform.X;
      element.Y = elementTransform.Y;
    });
    return data;
  }

  function MapPoint() {
    return mapPoints?.filter(function(location: any) { return !location.isFlowrate}).map((location: any, index: any) => {
      return (
        <div key={index}>
          <MapPointComponent data={location} />
          <MapVesselComponent data={location} />
        </div>
      );
    });
  }

  function MapFlowrate(height: number) {
    let bottomSpace = `calc(104% - ${height}px)`;
    return (
        <div className={s.flowrateContainer} style= {{ bottom: bottomSpace }}>{mapPoints?.filter(function (location: any) { return location.isFlowrate })
          .sort((a: any, b: any) => { return FLOWRATE_ORDER[a.locationName] - FLOWRATE_ORDER[b.locationName] })
          .map((location: any, index: any) => {
            return (
              <MapFlowrateComponent data={location} />
            );
          })}</div>)
  }

  const [showMapOptions, setShowMapOptions] = useState(false);
  const [currentMapSelection, setCurrentMapSelection] = useState('Malaysia');
  const mapOptions = ["Malaysia", "World Map"]

  function handleClickBreadcrumb(locationId) {
    // if (!isAllowLocation(locationId)) return;
    dispatch(fetchMapData(locationId));
  }

  function locationDropdown() {
    return (
      <div className={s.filterBlock}>
        <div className={`${s.filterBlockDropdown} ${showMapOptions ? s.show : ''}`}>
          <button
            type="button"
            onClick={() => setShowMapOptions(!showMapOptions)}
          >
            {currentMapSelection}
            <img src={showMapOptions ? "/img/icon/Chevron Up.svg" : "/img/icon/Chevron Down.svg"} alt="Dropdown Indicator"/>
          </button>
          <div className={s.filterBlockDropdownMenu}>
            <ul>
              {mapOptions.filter(option => option !== currentMapSelection).map((option, index) => (
                <li key={index}>
                  <button 
                    type="button"
                    onClick={() => {setCurrentMapSelection(option); setShowMapOptions(false); handleClickBreadcrumb(option === 'World Map' ? 0 : 1);}}
                  >
                    {option}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  function setBaseClass() {
    return `${isBaseWidth ? s.baseWidth : s.baseHeight}`;
  }

  function setRightPanelClass(cssModule) {
    return `${mapInfo ? cssModule.mapRightPanelShow : ""}`;
  }

  function _renderCallout(cssModule): JSX.Element {
    if(mapInfo?.locationId === 36 || mapInfo?.locationId === 37 || mapInfo?.locationId === 38) return <></>
    if (isNewCallout || allowedCallouts.includes(mapInfo?.locationId)) {
      return <CalloutComponent />;
    } else {
      return (
        <div className={`${cssModule.mapRightPanel}  ${setRightPanelClass(cssModule)}`}>
          <MapPointDetailComponent />
        </div>
      );
    }
  }

  return (
    <>
      <Desktop>
        <div className={s.mapRoot}>
          <FullScreen handle={handle}>
           <ScrollContainer
            className={`page-container ${s.mapContainer} ${setBaseClass()}`}
            >
              {/* Map Actions */}
              <MapActionsComponent handle={handle} />

              {/* Map Background */}
              <img
                id="rootMapBg"
                alt="Map Background"
                src={mapImage}
                width={currentImageSize.width}
                height={currentImageSize.height}
                className={s.mapBg}
              />

              {/* Map points */}
              {MapPoint()}

              {/* Map flowrates */}
              {MapFlowrate(currentImageSize.height)}

              {/* Last updated text and location desc*/}

              {/* <div className={s.lastUpdated}>
                Last updated on&nbsp;
                <Moment format="DD MMM YYYY" date={mapData?.lastUpdated} />
                &nbsp;at&nbsp;
                <Moment format="hh:mm A" date={mapData?.lastUpdated} />{" "}
              </div> */}
              <div ref={mapScroll} />

            </ScrollContainer>
            {/* Point details Right Panel */}
            {_renderCallout(s)}
          </FullScreen>
        </div>
      </Desktop>
      <Mobile>
        <div className={s.mapRoot}>
          {/* <FullScreen handle={handle}> */}
          <ScrollContainer
            className={`page-container ${s.mapContainer} ${setBaseClass()}`}
          >
            {/* Map Actions */}
            {/* <MapActionsComponent handle={handle} /> */}

            {/* Map Background */}
            <img
              id="rootMapBg"
              alt="Map Background"
              src={mapImage}
              width={currentImageSize.width}
              height={currentImageSize.height}
              className={s.mapBg}
            />

            {/* Map points */}
            {MapPoint()}

            {/* Map flowrates */}
            {/* {MapFlowrate(currentImageSize.height)} */}

            {locationDropdown()}

            {/* Last updated text and location desc*/}
          </ScrollContainer>
          {/* Point details Right Panel */}

          {_renderCallout(sMobile)}

          {/* </FullScreen> */}
        </div>
      </Mobile>
      <Tablet>
        <div className={s.mapRoot}>
          {/* <FullScreen handle={handle}> */}
          <ScrollContainer
            className={`page-container ${s.mapContainer} ${setBaseClass()}`}
          >
            {/* Map Actions */}
            {/* <MapActionsComponent handle={handle} /> */}

            {/* Map Background */}
            <img
              id="rootMapBg"
              alt="Map Background"
              src={mapImage}
              width={currentImageSize.width}
              height={currentImageSize.height}
              className={s.mapBg}
            />

            {/* Map points */}
            {MapPoint()}

            {locationDropdown()}

            {/* Last updated text and location desc*/}
          </ScrollContainer>
          {/* Point details Right Panel */}

          {_renderCallout(sMobile)}
          {/* </FullScreen> */}
        </div>
      </Tablet>
    </>
  );
};
export default MapComponent;

import { get } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchScoreCardLmtOperationalExecSummary,
  fetchScoreCardLmtOperationalSecondaryCardDetail,
  openScorecardSecondaryCardTabModal
}
  from "../../../../../action/actionScoreCard";

import {
  CARGO_DETAILS_LABEL, LMT_OPERATIONAL_SALES_UNITS, LMT_OPERATIONAL_SCORECARD_TYPE, LMT_PAGE_TYPE, LMT_SALES_VOLUME_LABEL,
  LMT_SCORECARD_OPERATIONAL_TYPE_CONFIG, LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS, LMT_TAB_SCORE_CARD, MONTH_SHORT_TEXT, TAB_SCORE_CARD
}
  from "../../../../../constants/score-card.constants";

import ScorecardExecutiveSummary from "../ExecutiveSummary/ScorecardExecutiveSummary";
import LmtCommercialExcellence from "./LmtCommercialExcellence";
import LmtSalesVolumeCargoDetails from "./LmtSalesVolumeCargoDetails";
import LmtSalesVolumeSecondary from "./LmtSalesVolumeSecondary";

import s from '../../SecondaryCard.module.css';

export default function BaseLmtOperationalPerformanceSecondary() {
  const [secondaryUnit, setSecondaryUnit] = useState(0)
  const [cargoDetailsUnit, setCargoDetailsUnit] = useState(0)
  const dispatch = useDispatch();

  const { isVisibleModal, currentDate, selectedScorecard, selectedTabIndex, secondaryCardData } = useSelector((state) => {
    const scorecard = get(state, 'scoreCard', null);
    return {
      isVisibleModal: scorecard.isOpenScorecardSecondaryCardModel,
      currentDate: scorecard.currentDate,
      selectedScorecard: scorecard.selectedScorecard,
      selectedTabIndex: scorecard.selectedTabIndex,
      secondaryCardData: scorecard.secondaryCardDetail
    };
  });

  function requestDetail(scorecardTypeId) {
    const params = `?scorecardTypeId=${scorecardTypeId}&year=${currentDate.year}&month=${MONTH_SHORT_TEXT[currentDate.month - 1]}`;
    dispatch(fetchScoreCardLmtOperationalSecondaryCardDetail(params));
  }

  function requestExecSummary() {
    const params = `?section=${LMT_TAB_SCORE_CARD.OPERATIONAL_PERFORMANCE}&page=${LMT_PAGE_TYPE.SCORECARD}` +
      `&year=${currentDate.year}&month=${MONTH_SHORT_TEXT[currentDate.month - 1]}`;
    dispatch(fetchScoreCardLmtOperationalExecSummary(params));
  }

  const scorecardTypeId = selectedScorecard ? selectedScorecard.scorecardTypeId : 0

  useEffect(() => {
    if (isVisibleModal && selectedScorecard) {
      switch (scorecardTypeId) {
        case LMT_OPERATIONAL_SCORECARD_TYPE.LMT_SALES_VOLUME:
          requestDetail(scorecardTypeId);
          dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.LMT_SALES_VOLUME));
          break;
        case LMT_OPERATIONAL_SCORECARD_TYPE.COMMERCIAL_EXCELLENCE:
          requestDetail(scorecardTypeId);
          dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.COMMERCIAL_EXCELLENCE));
          break;
        default:
          requestExecSummary();
          dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.EXECUTIVE_SUMMARY));
          break;
      }
    }
  }, [selectedScorecard]);

  // Tab change
  const salesVolumeTabs = LMT_SCORECARD_OPERATIONAL_TYPE_CONFIG;

  function onTabChange(i: number) {
    switch (i) {
      case LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.LMT_SALES_VOLUME:
        if (secondaryCardData.lMTScorecardSalesVolumes === null || secondaryCardData.lMTScorecardSalesVolumes === undefined) {
          requestDetail(LMT_OPERATIONAL_SCORECARD_TYPE.LMT_SALES_VOLUME);
        }
        break;
      case LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.CARGO_DETAILS:
        if (secondaryCardData.lMTScorecardSalesVolumes === null || secondaryCardData.lMTScorecardSalesVolumes === undefined) {
          requestDetail(LMT_OPERATIONAL_SCORECARD_TYPE.LMT_SALES_VOLUME);
        }
        break;
      case LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.COMMERCIAL_EXCELLENCE:
        requestDetail(LMT_OPERATIONAL_SCORECARD_TYPE.COMMERCIAL_EXCELLENCE);
        break;
      case LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.EXECUTIVE_SUMMARY:
        requestExecSummary();
        break;
      default:
        break;
    }
    dispatch(openScorecardSecondaryCardTabModal(i));
  }

  function tabClassName(i) {
    if (i === selectedTabIndex) {
      return s.active;
    }
    return;
  }

  function renderTab(i) {
    if (i === LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.EXECUTIVE_SUMMARY) {
      return <ScorecardExecutiveSummary />
    }
    if (i === LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.LMT_SALES_VOLUME) {
      return <LmtSalesVolumeSecondary unitIndex={secondaryUnit} />
    }
    else if (i === LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.CARGO_DETAILS) {
      return <LmtSalesVolumeCargoDetails unitIndex={cargoDetailsUnit} />
    }
    else if (i === LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.COMMERCIAL_EXCELLENCE) {
      return <LmtCommercialExcellence />
    }
  }

  // Change unit dataset
  const ChangeUnitIcon = () => <img alt='expand-icon' src='/img/icon/change-unit.svg' />;

  const units = LMT_OPERATIONAL_SALES_UNITS;

  function handleChangeUnit(label: string) {
    const findUnitIndex = (unit: string) => {
      return units.findIndex(v => v !== unit);
    }
    if (label === 'Summary') {
      const selectedUnitIndex = findUnitIndex(units[secondaryUnit])
      setSecondaryUnit(selectedUnitIndex)
    }
    if (label === 'Cargo Details') {
      const selectedUnitIndex = findUnitIndex(units[cargoDetailsUnit])
      setCargoDetailsUnit(selectedUnitIndex)
    }
  };

  return (
    <div className={s.modalBody}>
      <div className={s.detailsTab}>
        <ul>
          {salesVolumeTabs.map((tab) => {
            return (
              <li
                key={tab.label}
                className={tabClassName(tab.value)}
                onClick={() => {
                  onTabChange(tab.value);
                  if (selectedTabIndex === tab.value) {
                    handleChangeUnit(tab.label);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onTabChange(tab.value);
                    if (selectedTabIndex === tab.value) {
                      handleChangeUnit(tab.label);
                    }
                  }
                }}
                tabIndex={0}
              >
                {tab.label}
                {(tab.label === LMT_SALES_VOLUME_LABEL || tab.label === CARGO_DETAILS_LABEL) &&
                  <span className={s.switchUnit}>
                    <a><ChangeUnitIcon /></a>
                  </span>
                }
              </li>
            );
          })}
        </ul>
      </div>

      {renderTab(selectedTabIndex)}
    </div>
  )
}


import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';
import { get, isNull } from 'lodash';

import s from './details.module.css';

import {
  closeScorecardDetailsModal,
  openScorecardDetailsChartModal,
  fetchScoreCardRevenueChart,
} from '../../../action/actionScoreCard';


import { renderSectionBySectionId } from '../../../utils/common.utils';
import {
  SCORECARD_CHARTS,
  TAB_SCORE_CARD
} from '../../../constants/score-card.constants';

const DetailsModal = () => {
  const dispatch = useDispatch();
  const { isVisibleModal, categories, scoreCard, sectionIdActive } = useSelector(
    (state) => {
      const scoreCard = get(state, 'scoreCard', null);
      return {
        scoreCard: scoreCard,
        isVisibleModal: scoreCard.isOpenScorecardDetailsModal,
        details: scoreCard.smallCardInfor,
        categories: scoreCard?.smallCardInfor?.categories || [],
        sectionIdActive: scoreCard.sectionIdActive
      };
    }
  );

  const [tabKey, setTabKey] = useState(0);

  useEffect(() => {
    const validTabIndex = categories.findIndex(c => c.categoryKey !== null);
    if (validTabIndex !== -1) {
      setTabKey(validTabIndex);
    } else {
      setTabKey(0);
    }
  }, [categories]);

  const handleCancel = () => {
    dispatch(closeScorecardDetailsModal());
  };

  const CloseButton = () => (
    <img alt='close-icon' src='/img/icon/close-btn.svg' />
  );

  function onTabChange(i) {
    setTabKey(i);
  }

  function tabClassName(i, categoryKey) {
    if (!categoryKey) {
      return `${s.disabledTab}`;
    }
    return i === tabKey ? s.active : "";
  }

  function openChart(paramKey) {
    const sectionName = renderSectionBySectionId(TAB_SCORE_CARD.FINANCIAL_EXCELLENCE);
    const params = {
      paramKey: paramKey,
      year: scoreCard[sectionName]?.year,
      month: scoreCard[sectionName]?.month,
    };
    dispatch(
      fetchScoreCardRevenueChart({
        params,
        tabActive: TAB_SCORE_CARD.FINANCIAL_EXCELLENCE,
      })
    );
    dispatch(openScorecardDetailsChartModal());
  }

  return (
    <>
      <Modal
        className='scorecardDetailModal'
        visible={isVisibleModal}
        footer={null}
        onCancel={handleCancel}
        zIndex={1000}
        closeIcon={<CloseButton />}
        maskClosable={false}
        transitionName=""
      >
        <div className={s.modalHeader}>
          <p>Details</p>
        </div>

        <div className={s.modalBody}>
          <div className={s.detailsTab}>
            <ul>
              {categories.map((c, index) => {
                const isDisabled = !c.categoryKey;
                return (
                  <li
                    key={c.categoryKey || index}
                    className={tabClassName(index, c.categoryKey)}
                    style={!c.categoryKey ? { color: 'rgba(134, 134, 134, 0.377)', cursor: 'not-allowed', pointerEvents: 'none' } : {}}
                    onClick={() => {
                      if (!isDisabled) onTabChange(index);
                    }}
                    onKeyDown={(e) => {
                      if (!isDisabled && e.key === "Enter") {
                        onTabChange(index);
                      }
                    }}
                    tabIndex={isDisabled ? -1 : 0}
                    aria-disabled={isDisabled}
                  >
                    {c.categoryName}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className={s.detailsContent}>
            {categories?.[tabKey]?.parameters?.map((p) => {
              return (
                <div className={s.detailsItem}>
                  <div className={s.parameterData}>
                    <div className={s.parameterName}>{p.name}</div>
                    <div className={s.actual}>
                      <span className={s.value}>
                        {isNull(p.actualValue.value) ? "-" : p.actualValue.value}  
                      </span>
                      <span className={s.unit}>{p.actualValue?.unit}</span>
                    </div>
                    <div className={s.secondary}>
                      <span className={s.type}>{p.secondaryValue?.type}</span>
                      <span className={s.value}>
                        {isNull(p.secondaryValue?.value) ? "-" : p.secondaryValue?.value}
                      </span>
                      <span className={s.unit}>{p.secondaryValue?.unit}</span>
                    </div>
                  </div>

                  <div className={s.yepData}>
                    <div className={s.label}>YEP</div>
                    <div className={s.yebValue}>
                      <span className={s.value}>
                        {isNull(p.yepYetValue?.yep) ? "-" : p.yepYetValue.yep}
                      </span>
                      <span className={s.unit}>{p.yepYetValue?.unit}</span>
                    </div>
                  </div>
                  <div className={s.yetData}>
                    <div className={s.label}>YET</div>
                    <div className={s.yetValue}>
                      <span className={s.value}>
                        {isNull(p.yepYetValue?.yet) ? "-" : p.yepYetValue.yet}
                      </span>
                      <span className={s.unit}>{p.yepYetValue?.unit}</span>
                    </div>
                  </div>
                  {SCORECARD_CHARTS.includes(sectionIdActive) &&
                    <img
                      className={s.popUpIcon}
                      alt='pop-up'
                      src='/img/icon/popup.svg'
                      onClick={() => openChart(p.key)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          openChart(p.key);
                        }
                      }}
                      tabIndex={0}
                    />
                  }
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DetailsModal;

import * as types from './actionType';

export const fetchGhgData = (level: number) => ({
    type: types.GHG_DATA_FETCH_REQUESTED,
    data: level
});

export const ghgMapLevelChange = (level: number) => ({
    type: types.GHG_MAP_LEVEL_CHANGE,
    data: level
})

export const postSustainabilitySummary = (data: any) => ({
    type: types.SUSTAINABILITY_SUMMARY_UPDATE_REQUESTED,
    data: data
});

export const fetchCallOutSustainability = () => ({
    type: types.SUSTAINSIBILITY_CALLOUT_REQUEST
})

export const fetchDisableCallOutSustainability = () => ({
    type: types.SUSTAINSIBILITY_CLOSE_CALLOUT_REQUEST
})

export const fetchSustainabilityDrilldownRGTSU = (payload?: any) => ({
    type: types.GET_DRILLDOWN_RGTSU_REQUESTED,
    payload
});

export const fetchSustainabilityDrilldownRGTP = (payload?: any) => ({
    type: types.GET_DRILLDOWN_RGTP_REQUESTED,
    payload
});

export const fetchSustainabilityDrilldownPFLNG = (payload?: any) => ({
    type: types.GET_DRILLDOWN_PFLNG_REQUESTED,
    payload
});

export const fetchSustainabilityDrilldownGT = (payload?: any) => ({
    type: types.GET_DRILLDOWN_GT_REQUESTED,
    payload
});
import React from 'react';
import { useLayoutEffect, useRef } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import s from './cargo.module.css';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { Desktop, Mobile, Tablet } from "../../../responsive/responesive";

am4core.useTheme(am4themes_animated);

const colorUnit = {
  TERM: '#20419a',
  SPOT: '#90a0cd',
  DES: '#8b5ca7cd',
  FOB: '#bb9fcc',
};

export default function CargoDonutChart(props: any) {

  const { data } = props;
  const chart = useRef<am4charts.PieChart>();

  const centerLabel = `<p style='font-weight: 300; margin: 0 auto; font-size: 10px; white-space: initial; text-align: center; width: 90px'>${data?.primaryLabel}</p>`;

  useLayoutEffect(() => {
    let x = am4core.create(`chartdiv ${data.primaryLabel}`, am4charts.PieChart);

    //Binding data to chart
    x.data = data?.chartValues?.map?.((item: any, i) => ({
      color: colorUnit[item?.label],
      ...item,
    }));
    let pieSeries = x.series.push(new am4charts.PieSeries()) as any;
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'label';
    pieSeries.slices.template.propertyFields.fill = 'color';
    //Style stroke
    pieSeries.slices.template.stroke = am4core.color('#4a2abb');
    pieSeries.slices.template.stroke = am4core.color('#ffffff');
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.strokeLinejoin = 'round';
    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.tooltip.disabled = true;
    pieSeries.slices.template.states.getKey('hover').properties.scale = 1;
    pieSeries.slices.template.states.getKey(
      'active'
    ).properties.shiftRadius = 0;
    pieSeries.slices.template.cornerRadius = 5;
    //Cut a hole in the center of the chart
    x.innerRadius = am4core.percent(67);

    // x.paddingRight = 20;

    const label = pieSeries.createChild(am4core.Label);
    label.html = centerLabel;
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'middle';

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, []);

  return (
    <>
    <Desktop>
    <div
      id={`chartdiv ${data.primaryLabel}`}
      style={{ width: '95px', height: '95px' }}
    ></div>
    </Desktop>
    
    <Mobile>
    <div
      id={`chartdiv ${data.primaryLabel}`}
      style={{ width: '51px', height: '95px' }}
    ></div>
    </Mobile>

    <Tablet>
    <div
      id={`chartdiv ${data.primaryLabel}`}
      style={{ width: '71px', height: '95px' }}
    ></div>
    </Tablet>



    </>
    
  );
}



import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { Spin } from "antd";
import { get, isEmpty } from "lodash";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { transformMapChartData } from "../../factory/mapChartComponent.factory";
import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import {
    createAxisAndSeries,
    customizeColor,
    customizeScollbar,
    customizeSlider,
    customizeXGrid,
} from "../../utils/chart.utils";
import s from "../Map/map.module.css";
import sMobile from "../Map/mapResponsive.module.css";

const chartColorCode = {
    blue: "#8FC8F8",
    yellow: "#DFEB98",
    green: "#50A4B9",
    purple: "#9E94DB",
    cerulean:"#009DDC"
}

const MapChartComponent = (props: any) => {
    const chart = useRef<am4charts.XYChart>();
    const callOutData = useSelector((state) => get(state, "callout.calloutData", null));
    const selectedPoint = useSelector((state) => get(state, "map.selectedPoint", null));
    const isLoading = useSelector((state) => get(state, "callout.isLoading", false));
    useEffect(() => {
        return () => {
            chart.current?.dispose();
        };
    }, []);

    useEffect(() => {
        if (callOutData.tabsInfo.secondaryInfo.dataFields?.length > 0) {
            const dataArray = transformMapChartData(callOutData, props.frequency);
            if (!isEmpty(dataArray)) {
                let chartdiv = am4core.create("chartdiv", am4charts.XYChart);
                chart.current = chartdiv;
                let chartCurrent = chart.current;
                if (chartCurrent) {
                    chartCurrent.data = dataArray;
                    chartCurrent.numberFormatter.numberFormat = '#,##a';
                    let chartContainer = chartCurrent.chartContainer;
                    if (chartContainer) {
                        chartContainer.paddingTop = 10;
                    }
                    customizeXGrid(chartCurrent, props.frequency);
                    customizeColor(chartCurrent, callOutData);
                    customizeSeries(chartCurrent);
                    chartCurrent.cursor = new am4charts.XYCursor();
                    customizeScollbar(chartCurrent);
                    customizeSlider(chartCurrent.scrollbarX.startGrip);
                    customizeSlider(chartCurrent.scrollbarX.endGrip);
                }
            }
        }
        if (callOutData.tabsInfo.secondaryInfo.dataFields?.length === 0) {
            chart.current?.dispose();
        }
    }, [callOutData])

    useEffect(() => {
        const chartCurrent = chart.current;
        for (let idx = 0; idx < 4; idx++) {
            let seriesNo = idx + 1;
            let isShow = props.legendUnActive[`series${seriesNo}`];
            if (chartCurrent) {
                if (!chartCurrent?.isDisposed()) {
                    let series = chartCurrent.series?.getIndex(idx)
                    if (isShow) {
                        series?.show()
                    } else {
                        series?.hide()
                    }
                }
            }
        }
    }, [props.legendUnActive])

    useEffect(() => {
        chart.current?.dispose();
    }, [selectedPoint]);

    function customizeSeries(chartCurrent: am4charts.XYChart) {
        let toolTipString = "";
        let isAxesPopulate = {
            left: false,
            right: false
        }
        let leftValueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
        let rightValueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
        rightValueAxis.renderer.opposite = true;
        let isRightAxisExist = false;
        for (let index = 0; index < callOutData?.tabsInfo?.secondaryInfo?.dataFields.length; index++) {
            let seriesNo = index + 1;
            const label = callOutData?.tabsInfo?.secondaryInfo?.dataFields[index]?.legendInfo.label;
            const isOpposite = callOutData?.tabsInfo?.secondaryInfo?.dataFields[index]?.chartAxesPosition === "right" ? true : false;
            if (index === 0)
                toolTipString += "<div style='line-height:5px;margin-top:2px;'><b style='font-size:10px;'>{tooltipLabel}</b><hr /><table style='line-height:10px;'>";

            let legendCss = ""
            switch (callOutData?.tabsInfo?.secondaryInfo?.dataFields[index]?.chartType) {
                case 'dash':
                    legendCss = `
                    width: 9px;
                    left: 1px;
                    border-bottom-style: solid;
                    color:${chartColorCode[callOutData?.tabsInfo?.secondaryInfo?.dataFields[index]?.chartColor]};`
                    break;
                case 'dot':
                    legendCss = `
                    width: 8px;
                    color: blue;
                    border-bottom-style: dotted;
                    color:${chartColorCode[callOutData?.tabsInfo?.secondaryInfo?.dataFields[index]?.chartColor]};`
                    break;
                case 'area':
                    legendCss = `
                    height: 10px;
                    width: 10px;
                    border-radius: 2px;
                    background-color:${chartColorCode[callOutData?.tabsInfo?.secondaryInfo?.dataFields[index]?.chartColor]};`
                    break;
                default:
                    break;
            }
            toolTipString += `
            <tr style='font-size:10px;'>
                <td><div style="${legendCss}" /></td>
                <td style="padding-left:13px;">{series${seriesNo}}</td>
                <td>{unit${seriesNo}}</td>
            </tr>`
            let valueAxis = isOpposite ? rightValueAxis : leftValueAxis;
            if (!isRightAxisExist) {
                isRightAxisExist = isOpposite;
            }
            createAxisAndSeries(chartCurrent, label, isOpposite, index, toolTipString, isAxesPopulate, valueAxis,callOutData);
            isAxesPopulate[callOutData?.tabsInfo?.secondaryInfo?.dataFields[index]?.chartAxesPosition] = true;
        }
        if (!isRightAxisExist) {
            rightValueAxis.width = 0
        }
    }

    function isDataChartAvail() {
        let returnBoolean = false;
        for (let index = 0; index < callOutData.tabsInfo.secondaryInfo.dataFields?.length; index++) {
            if (callOutData.tabsInfo.secondaryInfo.dataFields[index].chartData.length > 0) {
                returnBoolean = true;
            }
        }
        return returnBoolean;
    }

    function ChartComponent() {
        return <>
            {callOutData.tabsInfo.secondaryInfo.dataFields?.length > 0 && isDataChartAvail() ?
                <>
                    <Desktop><div id="chartdiv" className={s.mapRightPanelChart} /></Desktop>
                    <Tablet><div id="chartdiv" className={sMobile.mapRightPanelChart} /></Tablet>
                    <Mobile><div id="chartdiv" className={sMobile.mapRightPanelChart} /></Mobile>
                </>
                :
                <div className={s.chartNotAvailable}>Chart not available</div>}
        </>
    }

    return (
        <>
            {
                isLoading ?
                    <Spin size="large" className="chartSpin" />
                    :
                    ChartComponent()
            }
        </>
    )
}
export default MapChartComponent;
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from '../reducer/root.reducer';
import rootSaga from '../saga/root.saga';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  
});

export const store = createStore(rootReducer, 
  composeEnhancers(applyMiddleware(sagaMiddleware))
  
  );

sagaMiddleware.run(rootSaga);


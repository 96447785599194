import { combineReducers } from "redux";

import { calloutReducer } from "./callout.reducer";
import { forwardPriceReducer } from "./forwardPrice.reducer";
import { limitConfigReducer } from "./limitconfig.reducer";
import { loadingReducer } from "./loading.reducer";
import { mapReducer } from "./map.reducer";
import { mftReducer } from "./mft.reducer";
import { privateRouteReducer } from "./private-route.reducer";
import { scoreCardReducer } from "./score-card.reducer";
import { scoreCardOrderReducer } from "./scorecardorder.reducer";
import { userReducer } from "./user.reducer";
import { userManagementReducer } from "./usermanagement.reducer";
import { valueChainReducer } from "./value-chain.reducer";
import { contentReleaseReducer } from "./content-release.reducer";
import { dataPipelineReducer } from "./datapipeline.reducer";
import { sustainabilityReducer } from "./sustainability.reducer";
import { riskReducer } from "./risk.reducer";

export const rootReducer = combineReducers({
  user: userReducer,
  map: mapReducer,
  valuechain: valueChainReducer,
  scoreCard: scoreCardReducer,
  loading: loadingReducer,
  callout: calloutReducer,
  privateroute: privateRouteReducer,
  usermanagement: userManagementReducer,
  limitconfig: limitConfigReducer,
  sustainability: sustainabilityReducer,
  scorecardorder: scoreCardOrderReducer,
  mft: mftReducer,
  forwardPrice: forwardPriceReducer,
  contentRelease: contentReleaseReducer,
  dataPipeline: dataPipelineReducer,
  risk: riskReducer
});

export type RootState = ReturnType<typeof rootReducer>;

function transformInitData(result) {
  let newResult: any = [];
  for (let index = 0; index < result.length; index++) {
    const object = result[index];
    newResult.push({ id: object.accessId, name: object.accessRole });
  }

  return newResult;
}

export function transformAccessRoles(results) {
  return transformInitData(results);
}

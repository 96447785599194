import { Action } from "redux";

import * as types from "../action/actionType";

export const initialData = {
    isShowForwardPrice: false,
    forwardPriceData: {}
};

interface ForwardPriceAction extends Action {
    payload: string | any;
    data: string | any;
}

export function forwardPriceReducer(
    state = initialData,
    action: ForwardPriceAction
) {
    switch (action.type) {
        case types.SET_IS_SHOW_FP: {
            return {
                ...state,
                isShowForwardPrice: action.payload,
            };
        }
        case types.FP_GET_CHART_SUCCEEDED: {
            return {
                ...state,
                forwardPriceData: action.payload,
            };
        }
    }
    return state;
}

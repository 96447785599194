import { Spin, Tabs } from "antd";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchMftCardSection,
  selectPrimaryCard,
  setMftCurrentSubSection,
  setMftCurrentTabKey,
  setUnitIndex,
} from "../../../action/actionMft";
import { MFT_SECTION, MFT_SUSTAIN_SECTION, MFT_SUSTAIN_STATIC_KEY } from "../../../constants/mft.constants";
import { OPU } from "../../../constants/opu.constants";
import { MONTH_SHORT_TEXT } from "../../../constants/score-card.constants";
import { IBreakdown } from "../../../model/mft.model";
import { CHART_COLOR_PALLATE } from "../../../utils/chart.utils";
import { formatScoreCardNumber, formatScoreCardPercent } from "../../../utils/Number.utils";
import { getOpuApi } from "../../../utils/Url.utils";
import PopoverImageComponent from "../../PopoverImage/PopoverImageComponent";
import MftLineChartComponent from "../MftLineChart/MftLineChartComponent";
import MftWaveChartComponent from "../MftWaveChartComponent/MftWaveChartComponent";
import s from "./MftTabsComponent.module.css";

const breakPoints = [
  { width: 320, itemsToShow: 1 },
  { width: 480, itemsToShow: 2 },
  { width: 520, itemsToShow: 3 },
  { width: 900, itemsToShow: 4 },
  { width: 1024, itemsToShow: 5 },
  { width: 1200, itemsToShow: 5.8 },
  { width: 1400, itemsToShow: 6.8 },
  { width: 1500, itemsToShow: 7.5 },
];

const MftTabsComponent = (props: any) => {
  let colorRunnerIdx = 0;
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const [, setselectedUnit] = useState(null);
  const cardSectionData = useSelector((state) =>
    get(state, "mft.cardSectionData", {})
  );
  const isCardSectionLoading = useSelector((state) =>
    get(state, "mft.isCardSectionLoading", false)
  );
  const isChartLoading = useSelector((state) =>
    get(state, "mft.isChartLoading", false)
  );
  const currentSubSection = useSelector((state) =>
    get(state, "mft.currentSubSection", 0)
  );
  const currentTabKey = useSelector((state) =>
    get(state, "mft.currentTabKey", "")
  );

  const dynamicTab = useSelector((state) =>
    get(state, "mft.dynamicTab", undefined)
  );

  const subTabs = dynamicTab?.categories;

  const currentDate = useSelector((state) => get(state, "mft.currentDate"));
  const [activeSubTabNumber, setActiveSubTabNumber] = useState(0)

  const isDisabledActualValue = function () {
    const opuName = getOpuApi("MFT Scorecard")
    if ([OPU.PFLNG, OPU.MLNG].includes(opuName)) {
      return true;
    }
    return false;
  }
  const unitIndex = useSelector((state) => get(state, "mft.unitIndex", 0));
  const selectedPrimaryCard = useSelector((state) => get(state, "mft.selectedPrimaryCard"));
  const selectedPrimaryCardKey = selectedPrimaryCard?.key
  const selectedUnitKey = selectedPrimaryCard?.units?.[unitIndex]

  useEffect(() => {
    if (subTabs?.length && subTabs?.[activeSubTabNumber]?.categoryKey !== currentTabKey) {
      setActiveSubTabNumber(0)
    }
  }, [currentTabKey])

  useEffect(() => {
    const defaultKey = subTabs?.[0]?.categoryKey
    dispatch(setMftCurrentTabKey(defaultKey))
  }, [props.sectionId, subTabs])

  useEffect(() => {
    dispatch(setUnitIndex(0))
  }, [selectedPrimaryCardKey])

  function _renderPrimaryCardsActual(actualValue) {
    return <div className={s.primaryCardActual}>
      <p>{formatScoreCardNumber(actualValue?.value, 2)}</p>
      <div>
        <p>{actualValue?.currency || ""}</p>
        <p>{actualValue?.unit || ""}</p>
      </div>
    </div>
  }

  function _renderPrimaryCardSecondaryValue(secondaryValue, item, lastyear, deltaValue) {
    return (
      <div className={s.primaryCardsValues}>
        <p>
          {`${secondaryValue?.secondaryValueTitle}: ${secondaryValue?.currency || ""} ${formatScoreCardNumber(secondaryValue?.value, 2)} ${secondaryValue?.unit || ""}`}
          &nbsp;
          {item.name !== "Non-Traditional Revenue" && lastyear}
          {secondaryValue?.arrow && <i className={`mdi mdi-menu-${deltaValue?.arrow}`} />}
        </p>
      </div>
    );
  }

  function _renderPlanPrimaryCard(plannedValue, deltaValue) {
    return (
      <div className={s.primaryCardsValues}>
        <p>
          {`Plan: ${plannedValue?.currency || ""} ${formatScoreCardNumber(plannedValue?.value, 2)} ${plannedValue?.unit || ""}`}
          &nbsp;
          {`${deltaValue?.currency || ""} ${formatScoreCardNumber(deltaValue?.value, 2)} ${deltaValue?.unit || ""}`}
          {deltaValue?.arrow && <i className={`mdi mdi-menu-${deltaValue?.arrow}`} />}
        </p>
      </div>
    );
  }
  function _primaryCardsValues(item) {
    const isSelected = selectedPrimaryCardKey === item.key
    const values = isSelected ? item?.values[getSelectedUnit()] : item?.values[0]
    if (!values) {
      return null
    }
    const isUnitPercent = item?.units[getSelectedUnit()] === "%";
    const { plannedValue, deltaValue, actualValue, secondaryValue } = values;
    const dateLastYear = moment(`01-01-${cardSectionData.cards.year}`)
      .subtract(1, "years")
      .format("YYYY");
    const lastyear = `(Y${dateLastYear})`;
    const isAllowedSection = props.sectionId === MFT_SECTION.VALUE || props.sectionId === MFT_SECTION.GROWTH;
    return (
      <>
        {_renderPrimaryCardsActual(actualValue)}

        {!isUnitPercent && props.sectionId !== MFT_SECTION.SUSTAINIBILITY && _renderPlanPrimaryCard(plannedValue, deltaValue)}
        {!isUnitPercent && (isAllowedSection && !isDisabledActualValue() && secondaryValue !== null) && _renderPrimaryCardSecondaryValue(secondaryValue, item, lastyear, deltaValue)}
      </>
    );
  }

  function getActiveKey() {
    return props.activeKey === ""
      ? cardSectionData?.cards?.selectedKey || ""
      : props.activeKey;
  }

  function getSelectedUnit() {
    return unitIndex
  }

  function getActiveClassCard(item) {
    return item.key === getActiveKey() ? s.activeCardGreen : "";
  }

  function setSelectedUnit(index) {
    setselectedUnit(index);
    dispatch(setUnitIndex(index));
  }

  function _primaryCards() {
    const mftCard = cardSectionData.cards;
    return (
      <>
        {mftCard?.primaryCards &&
          mftCard?.primaryCards.map((item, idx) => (
            <div
              key={idx}
              className={`${s.primaryCards} ${getActiveClassCard(item)}`}
              onClick={() => {
                props.setactiveKey(item.key);
                dispatch(selectPrimaryCard(item))
              }}
            >
              <div className={s.primaryCardTitle}>
                <p>{item.name}</p>
                {item.units.length > 1 && (
                  <PopoverImageComponent
                    imageSrc="/img/icon/three_dots.svg"
                    arr={item.units}
                    selectedIdx={getSelectedUnit()}
                    selectFunc={(index) => {
                      setSelectedUnit(index)
                      dispatch(setUnitIndex(index))
                    }}
                  />
                )}
              </div>
              {_primaryCardsValues(item)}
            </div>
          ))}
      </>
    );
  }

  const myArrow = ({ type, onClick, isEdge }) => {
    return (
      <button
        onClick={onClick}
        hidden={isEdge}
        disabled={isEdge}
        className={`${type === "PREV" ? s.scoreCardButtonPrev : s.scoreCardButtonNext
          }`}
      >
        <img
          style={{ cursor: "pointer" }}
          src="/img/icon-score-card/Icon Back.svg"
          alt="back"
        />
      </button>
    );
  };

  function _breakdownCards(card: IBreakdown, idxChild) {
    const actualValue = card?.values[getSelectedUnit()]?.actualValue;
    const plannedValue = card?.values[getSelectedUnit()]?.plannedValue;
    const deltaValue = card?.values[getSelectedUnit()]?.deltaValue;
    const isUnitPercent = card?.values[getSelectedUnit()]?.unit === "%";
    const arrowDown = deltaValue?.arrow === "down" && s.arrowDown;
    const actualVal = formatScoreCardNumber(actualValue?.value, 2);
    const actualNumClass = actualVal === "-" ? s.whiteDash : "";
    return (
      <div
        key={idxChild}
        className={`${s.breakdownCards} ${getActiveClassCard(card)}`}
        onClick={() => props.setactiveKey(card.key)}
      >
        <div className={s.breakdownCardName}>
          <p>{card.name}</p>
        </div>
        <div className={`${s.breakdownCardValue} ${actualNumClass}`}>
          <p>{actualVal}</p>
          <div>
            <p>{actualValue?.currency || ""}</p>
            <p>{actualValue?.unit || ""}</p>
          </div>
        </div>
        {!isUnitPercent && props.sectionId !== MFT_SECTION.SUSTAINIBILITY && (
          <div className={s.breakdownCardPlan}>
            <p>
              {`Plan: ${plannedValue?.currency || ""} ${formatScoreCardNumber(plannedValue?.value, 2) || ""
                } ${plannedValue?.unit}`}
              &nbsp;
              {`${formatScoreCardPercent(deltaValue?.value, 2) || ""} ${deltaValue?.unit || ""
                }`}
            </p>
            {arrowDown && <i className={`mdi mdi-menu-up ${arrowDown}`} />}
          </div>
        )}
      </div>
    );
  }
  function _breakdownCardsContainer() {
    const mftCard = cardSectionData.cards;
    return (
      <>
        {mftCard?.primaryCards &&
          mftCard?.primaryCards.map((item, idx) => (
            <>
              {item?.breakdowns?.length > 0 && (
                <div key={idx} className={s.breakdownCardsContainer}>
                  <Carousel
                    isRTL={false}
                    breakPoints={breakPoints}
                    showEmptySlots
                    pagination={false}
                    renderArrow={myArrow}
                  >
                    {item.breakdowns.map((card, idxChild) => {
                      return <>{_breakdownCards(card, idxChild)}</>;
                    })}
                  </Carousel>
                </div>
              )}
            </>
          ))}
      </>
    );
  }

  function getColorPallete() {
    if (colorRunnerIdx > 4) {
      colorRunnerIdx = 0;
    }
    colorRunnerIdx++;
    return CHART_COLOR_PALLATE[colorRunnerIdx];
  }

  function _legendChart() {
    return (
      <div className={s.legendSection}>
        {cardSectionData.charts &&
          cardSectionData.charts.map((item, idx) => (
            <div
              className={s.legendGroup}
              style={{ opacity: item.key === getActiveKey() ? 1 : 0.1 }}
            >
              <div
                className={s.legend}
                style={{
                  backgroundColor: getColorPallete(),
                }}
              />
              <p>{item.chartLabel}</p>
            </div>
          ))}
      </div>
    );
  }

  function _chartUnit() {
    const currency = cardSectionData?.data[0]?.unit0 ? cardSectionData?.data[0]?.unit0 : "";
    const unit = cardSectionData?.data[0]?.subUnit0 ? cardSectionData?.data[0]?.subUnit0 : "";
    const chartLabel = `${currency} ${unit}`;
    return (
      <div className={s.chartUnit}>
        <p>
          {`${chartLabel}`}
        </p>
      </div>
    );
  }

  function _sustainChartComponent(currentActiveKey) {
    if (dynamicTab) {
      if (MFT_SUSTAIN_STATIC_KEY.includes(currentTabKey)) {
        return (
          <MftWaveChartComponent
            chartId={currentTabKey}
            chartData={cardSectionData}
            activeKey={currentActiveKey}
            selectedUnit={getSelectedUnit()}
          />)
      } else {
        return (
          <MftLineChartComponent
            chartId={currentTabKey}
            chartData={cardSectionData}
            activeKey={currentActiveKey}
            selectedUnit={getSelectedUnit()}
          />
        )
      }
    } else {
      const chartSubId = {
        1: "mftEmissionChart",
        2: "mftReductionChart",
        3: "mftIntensityChart",
      };
      if (currentSubSection === MFT_SUSTAIN_SECTION.INTENSITY) {
        return (
          <MftLineChartComponent
            chartId={chartSubId[currentSubSection]}
            chartData={cardSectionData}
            activeKey={currentActiveKey}
            selectedUnit={getSelectedUnit()}
          />
        );
      }
      return (
        <MftWaveChartComponent
          chartId={chartSubId[currentSubSection]}
          chartData={cardSectionData}
          activeKey={currentActiveKey}
          selectedUnit={getSelectedUnit()}
        />
      );
    }
  }
  function _mftChartComponent() {
    const currentActiveKey = getActiveKey();

    switch (props.sectionId) {
      case MFT_SECTION.VALUE:
        return (
          <MftLineChartComponent
            chartId="mftValueChart"
            chartData={cardSectionData}
            activeKey={currentActiveKey}
            selectedUnit={getSelectedUnit()}
          />
        );
      case MFT_SECTION.GROWTH:
        return (
          <MftLineChartComponent
            chartId="mftGrowthChart"
            chartData={cardSectionData}
            activeKey={currentActiveKey}
            selectedUnit={getSelectedUnit()}
          />
        );
      case MFT_SECTION.SUSTAINIBILITY:
        const SustainChartComponent = _sustainChartComponent(currentActiveKey);
        return <>{SustainChartComponent}</>;
    }
  }

  function _chartContainer() {
    return (
      <>
        {!isCardSectionLoading && (
          <div className={s.chartContainer}>
            {cardSectionData?.cards?.primaryCards &&
              cardSectionData?.cards?.primaryCards.length > 0 && (
                <>
                  {_legendChart()}
                  {_chartUnit()}
                </>
              )}
            {isChartLoading ? <Spin size="large" className="chartSpin" /> :
              _mftChartComponent()}
          </div>
        )}
      </>
    );
  }
  function setSubTabChange(subSectionId) {
    const subSection = parseInt(subSectionId);
    const data = {
      year: currentDate.year,
      month: MONTH_SHORT_TEXT[currentDate.month],
      sectionId: props.sectionId,
      subTabId: subSection,
      unit: selectedUnitKey,
    };
    dispatch(setMftCurrentSubSection(subSection));
    dispatch(fetchMftCardSection(data));
    props.setactiveKey("");
  }

  function _tabComponents() {
    return (
      <Tabs
        defaultActiveKey="1"
        activeKey={`${currentSubSection}`}
        onChange={(e) => setSubTabChange(e)}
      >
        <TabPane tab="GHG Emission" key={MFT_SUSTAIN_SECTION.EMISSION}>
          {_cardSection()}
        </TabPane>
        <TabPane tab="GHG Reduction" key={MFT_SUSTAIN_SECTION.REDUCTION}>
          {_cardSection()}
        </TabPane>
        <TabPane tab="GHG Intensity" key={MFT_SUSTAIN_SECTION.INTENSITY}>
          {_cardSection()}
        </TabPane>
      </Tabs>
    );
  }

  function setDynamicSubTabChange(subTabKey) {
    setActiveSubTabNumber(subTabKey)
    dispatch(setMftCurrentTabKey(subTabs?.[subTabKey]?.categoryKey))
    props.setactiveKey("");
  }

  function _tabOPUComponents() {
    if (subTabs?.length < 1) {
      return
    }
    return (
      <Tabs
        defaultActiveKey="0"
        activeKey={`${activeSubTabNumber}`}
        onChange={(e) => setDynamicSubTabChange(e)}
      >
        {subTabs.map((tab, index) => {
          return (
            <TabPane tab={tab.categoryName} key={`${index}`}>
              {_cardSection()}
            </TabPane>
          )
        })}
      </Tabs>
    );
  }

  function _cardSection() {
    return (
      <>
        {isCardSectionLoading ? (
          <Spin size="large" className="chartSpin" />
        ) : (
          <div className={s.cardsSection}>
            {cardSectionData?.cards?.primaryCards &&
              cardSectionData?.cards?.primaryCards.length > 0 && (
                <>
                  {_primaryCards()}
                  {_breakdownCardsContainer()}
                </>
              )}
          </div>
        )}
      </>
    );
  }

  function _renderMFTSustain() {
    return props.sectionId === MFT_SECTION.SUSTAINIBILITY
      ? _tabComponents()
      : _cardSection();
  }
  return (
    <>
      <div key={props.sectionId} className={s.mftPanel}>
        {dynamicTab
          ? <>{_tabOPUComponents()}
            {_chartContainer()}
          </>
          :
          <>
            {_renderMFTSustain()}
            {_chartContainer()}
          </>
        }
      </div>
    </>
  );
};
export default MftTabsComponent;

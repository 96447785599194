import { ENDPOINTS } from '../constants/endpoints';
import axiosClient from './axiosClient.service';

export function getScorecardOrder(sectionId, userOpuId) {
  return axiosClient.get(
    ENDPOINTS.GET_SCORECARDORDER_LIST + sectionId + userOpuId
  );
}

export function postScorecardOrder(sectionId, userOpuId, dataBody) {
  return axiosClient.post(
    ENDPOINTS.POST_SCORECARDORDER_LIST + sectionId + userOpuId,
    dataBody
  );
}

export function getScorecardOPU() {
  return axiosClient.get(ENDPOINTS.GET_SCORECARDORDER_OPUS);
}

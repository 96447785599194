import * as types from './actionType';

export const fetchRiskTop = (payload?: any) => ({
    type: types.GET_RISK_TOP_REQUESTED,
    payload
});

export const fetchAvailablePeriodRequest = () => ({
    type: types.GET_RISK_AVAILABLE_PERIOD_REQUESTED,
  });
  
export const fetchAvailablePeriodSuccess = (data) => ({
  type: types.GET_RISK_AVAILABLE_PERIOD_SUCCESS,
  payload: data,
});

export const fetchRiskKRICallout = (data) => ({
  type: types.GET_RISK_KRI_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchRiskKRICalloutSuccess = (data) => ({
  type: types.GET_RISK_KRI_CALLOUT_SUCCESS,
  payload: data,
});

export const fetchRiskMitigationCallout = (data) => ({
  type: types.GET_RISK_MITIGATION_CALLOUT_REQUESTED,
  payload: data,
});

export const fetchRiskMitigationCalloutSuccess = (data) => ({
  type: types.GET_RISK_MITIGATION_CALLOUT_SUCCESS,
  payload: data,
});
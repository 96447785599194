
import { useState } from 'react'
import { Col, Row } from 'antd';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { LMT_OPERATIONAL_SALES_UNITS, LMT_OPERATIONAL_SALES_UNITS_ENUMS } from '../../../../../constants/score-card.constants';
import { formatScoreCardTargetValue } from '../../../../../utils/Number.utils';

import s from './LmtSalesVolumeSecondary.module.css';
import { fetchLmtSalesCountryData, fetchScoreCardLmtChartDetail, openScorecardLmtChartModal } from '../../../../../action/actionScoreCard';
import BaseLmtSalesSummaryChart from '../../../DetailsChart/LMT/BaseLmtSalesSummaryChart';
import { COUNTRY_LIST } from '../../../../../constants/value-chain.constants';
import { LMT_SALES_VOLUME_TAB } from '../../../../../constants/value-chain.constants';

export interface LmtSalesVolumeSecondaryProps {
  unitIndex: number;
}

export default function LmtSalesVolumeSecondary({ unitIndex }: LmtSalesVolumeSecondaryProps) {
  const dispatch = useDispatch();
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY_LIST[0])
  const [selectedTab, setSelectedTab] = useState(LMT_SALES_VOLUME_TAB.GENERAL);


  const handleCancelModal = () => {
    setIsVisibleModal(false)
  }

  const { secondaryCardData } = useSelector((state) => {
    const scorecard = get(state, 'scoreCard', null);
    return {
      secondaryCardData: scorecard.secondaryCardDetail
    };
  });


  const lmtSalesVolume = secondaryCardData.lMTScorecardSalesVolumes;

  function formatValueNumber(value) {
    return LMT_OPERATIONAL_SALES_UNITS[unitIndex] === LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE
      ? formatScoreCardTargetValue(value, 0) :
      formatScoreCardTargetValue(value, 1)
  }

  function getCardColor(cardName, actual, target) {
    if (cardName != 'Third Party Purchased') {
      if (actual > target) return 'green';
      return 'red';
    }
    else {
      if (actual > target) return 'red';
      return 'green';
    }
  }

  function openLmtChartModal() {
    // dispatch(fetchLmtSalesCountryData());

    // const currentYear = new Date().getFullYear();
    // let request = {
    //   from: currentYear - 4,
    //   to: currentYear,
    //   unit: 0,
    //   listCountry: [],
    //   listCustomer: [],
    //   year: 2022,
    //   month: 'Feb'
    // };
    // dispatch(fetchScoreCardLmtChartDetail(request));
    // dispatch(openScorecardLmtChartModal());
    setIsVisibleModal(true);
    setSelectedTab(LMT_SALES_VOLUME_TAB.GENERAL);
  }

  return (
    <>
      <div className={s.scoreCardsCol}>
        <Row gutter={[5, 5]}>
          {lmtSalesVolume?.boxLMTSalesVolumeReponse &&
            lmtSalesVolume.boxLMTSalesVolumeReponse.map((card) => {
              return (
                <>
                  {/* {card.cardName === "LMT Sales Volume" && <BaseLmtSalesSummaryChart />} */}
                  <Col span={12} key={card.cardName}>
                    <div className={s.cardContainer}>
                      <div className={s.cardInner}>
                        <div className={s.cardPrimarySection}>
                          <div className={`${s.statusBar} 
                        ${card.values[unitIndex].target != null ?
                              s[getCardColor(card.cardName, card.values[unitIndex].actual, card.values[unitIndex].target)]
                              : s.defaultColor}`} />
                          <div className={s.primaryInfo}>
                            <div className={s.headline}>
                              {card.cardName}
                            </div>
                            <div className={s.primaryValue}>
                              <div className={s.primaryNumber} >
                                {formatValueNumber(card.values[unitIndex].actual)}
                              </div>
                              <div className={s.unit}>
                                {card.units[unitIndex]}
                              </div>
                            </div>
                            <div className={s.comparison}>
                              <span className={s.comparisonText}>
                                {card.values[unitIndex].target != null ?
                                  card.values[unitIndex].actual > card.values[unitIndex].target ? "Higher than" : "Lower than"
                                  : "Lower than"
                                }
                              </span>
                              &nbsp;&nbsp;
                              <span className={s.comparisonNumber}>
                                {formatValueNumber(card.values[unitIndex].target)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={s.cardSecondarySection}>
                          <div className={s.YEP}>
                            <div className={s.subLabel}>YEP</div>
                            <div className={s.subNumber}>
                              {formatValueNumber(card.values[unitIndex].yep)}
                            </div>
                          </div>
                          <div className={s.YET}>
                            <div className={s.subLabel}>YET</div>
                            <div className={s.subNumber}>
                              {formatValueNumber(card.values[unitIndex].yet)}
                            </div>
                          </div>

                          {/* <div className={s.YEP}>
                          <div className={s.subLabel}>SPLY</div>
                          <div className={s.subNumber}>
                            {formatValueNumber(card.values[unitIndex].sply)}
                          </div>
                        </div> */}
                          <div className={s.SPLY}>
                            <div className={s.subLabel}>SPLY</div>
                            <div className={s.subNumberWithArrow}>
                              <div className={s.subNumber}>
                                {formatValueNumber(card.values[unitIndex].sply)}
                              </div>
                              <div className={s.subDirection}>
                                <span>{card.values[unitIndex].splyDeltaValue?.value ?? "-"} % &nbsp;</span>
                                {card.values[unitIndex].splyDeltaValue?.arrowDirection === 'up' ?
                                  <img
                                    alt='arrow-up'
                                    src='\img\icon\arrow-up.svg'
                                    className={s.subDirectionIcon}
                                  /> :
                                  <img
                                    alt='arrow-down'
                                    src='\img\icon\arrow-down.svg'
                                    className={s.subDirectionIcon}
                                  />
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        {card.cardName === "LMT Sales Volume" && (
                          <div>
                            <img
                              className={s.popUpIcon}
                              alt='pop-up'
                              src='/img/icon/popup-with-bg.svg'
                              onClick={() => card.cardName === "LMT Sales Volume" ? openLmtChartModal() : {}}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter' && card.cardName === "LMT Sales Volume") {
                                  openLmtChartModal();
                                }
                              }}
                              tabIndex={0}
                            />
                            <BaseLmtSalesSummaryChart
                              isVisibleModal={isVisibleModal}
                              handleCancelModal={handleCancelModal}
                              name='LMT Sales Volume'
                              selectedTab={selectedTab}
                              setSelectedCountry={setSelectedCountry}
                              country={selectedCountry}
                              setSelectedTab={setSelectedTab}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                </>
              )
            })}
        </Row>
      </div>

    </>
  );
}


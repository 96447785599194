import React, { ReactElement } from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import MainPageComponent from "./MainPageComponent";
import { Provider } from "react-redux";
import ReactGA from "react-ga4";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { store } from "../store/store";

export default function GoDashboardComponent(): ReactElement {
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <MainPageComponent />
        </DndProvider>
      </Provider>
    </Router>
  );
}

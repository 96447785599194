import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import {
  fetchMapData,
  isMapTypeMobile,
  mapTypeChange,
} from "../../action/actionMap";
import { useDispatch, useSelector } from "react-redux";

import { APP_CONSTANTS } from "../../constants/app";
import { buildUrl } from "../../utils/Url.utils";
import { get } from "lodash";
import { isAllowLocation } from "../../utils/map.utils";
import s from "../Map/map.module.css";
import sMobile from "./mapResponsive.module.css";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb } from "antd";

const MapActionsComponent = (props: any) => {
  const history = useHistory();
  const handle = props.handle;
  const isSatelliteMap = useSelector((state) =>
    get(state, "map.isSatelliteMap", true)
  );
  const mapLevel = useSelector((state) =>
    get(state, "map.mapLevel", APP_CONSTANTS.DEFAULT_LEVEL)
  );
  const currentLocationId = useSelector((state) =>
    get(state, "map.currentLocationId", APP_CONSTANTS.DEFAULT_LOCATION_ID)
  );
  const selectedPointId = useSelector((state) =>
    get(state, "map.selectedPointId", null)
  );
  const { isMapTypeVisible } = useSelector((state: any) => state.map);
  const { isOpenSideBar } = useSelector((state: any) => state.map);

  const navLocations = useSelector((state)=> get(state, "map.mapData.result", null));
  
  const locationList = function(){
    if(navLocations){
      return [...navLocations.parentLevel, {locationId: navLocations.locationId, locationName: navLocations.locationDesc}];
    } 
      return [];
  }()

  const dispatch = useDispatch();

  useEffect(() => {
    history.push(buildUrl(currentLocationId, isSatelliteMap, mapLevel));
  }, [currentLocationId, selectedPointId]);


  function setSideBar() {
    return isOpenSideBar
      ? sMobile.closeMapTypeBySideBar
      : sMobile.mapToggleMode;
  }

  function setSideBarTablet() {
    return isOpenSideBar
      ? sMobile.closeMapTypeBySideBar
      : sMobile.mapToggleModeTablet;
  }

  const handleCloseMapType = (isOpen: boolean) => {
    dispatch(isMapTypeMobile(isOpen));
  };


  function breadcrumbItemClassName(location) {
    if(location.locationId === currentLocationId){
      return `${s.mapBreadcrumbItem} ${s.currentLocationBcItem}`;
    } else {
      return s.mapBreadcrumbItem
    }
  }

  function handleClickBreadcrumb(locationId){
    // if (!isAllowLocation(locationId)) return;
    dispatch(fetchMapData(locationId));
  }

  function handleQuickJump(locationId) {
    // if (!isAllowLocation(locationId)) return;
    dispatch(fetchMapData(locationId));
  }

  return (
    <>
      <Desktop>
        <div className='page-action-left'>
          {locationList.length > 0 && (
            <div className={s.mapBreadcrumbContainer}>
              <Breadcrumb
                className={s.mapBreadcrumb}
                separator={
                  <img
                    className={s.breadcrumbSeparator}
                    alt='separator-icon'
                    src='/img/icon/arrow-right.svg'
                  />
                }
              >
                {locationList.map((l, index) => {
                  return (
                    <Breadcrumb.Item
                      className={breadcrumbItemClassName(l)}
                      key={l.locationId}
                      onClick={() => handleClickBreadcrumb(l.locationId)}
                    >
                      {l.locationName}
                    </Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
            </div>
          )}
        </div>
        <div className='page-action-right'>
          <div className='quick-jump-container'>
            {navLocations?.locationQuickJump?.locationBackward && (
              <div
                className='quick-jump-btn backward'
                onClick={() => handleQuickJump(navLocations?.locationQuickJump?.locationBackward?.locationId)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleQuickJump(navLocations?.locationQuickJump?.locationBackward?.locationId);
                  }
                }}
                tabIndex={0}
              >
                <img alt='fw-ic' src='\img\icon\forward.svg' />
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span>
                  {
                    navLocations?.locationQuickJump?.locationBackward
                      ?.locationName
                  }
                </span>
              </div>
            )}
            {navLocations?.locationQuickJump?.locationForward && (
              <div
                className='quick-jump-btn'
                onClick={() => handleQuickJump(navLocations?.locationQuickJump?.locationForward?.locationId)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleQuickJump(navLocations?.locationQuickJump?.locationForward?.locationId);
                  }
                }}
                tabIndex={0}
              >
                <span>
                  {
                    navLocations?.locationQuickJump?.locationForward
                      ?.locationName
                  }
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <img alt='fw-ic' src='\img\icon\forward.svg' />
              </div>
            )}
          </div>
          <div
            className={`full-screen ${handle.active ? 'full-screen-exit' : ''}`}
            onClick={!handle.active ? handle.enter : handle.exit}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                !handle.active ? handle.enter() : handle.exit();
              }
            }}
            tabIndex={0}
          >
            <img alt='full_screen' src='\img\icon\full_view.svg' />
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className={isMapTypeVisible ? setSideBar() : sMobile.closeMaptype}>
          <div className={sMobile.mapToggleTxt}>Map Type</div>
          <div></div>

          <div 
            onClick={() => handleCloseMapType(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCloseMapType(false);
              }
            }}
            tabIndex={0}
          >
            <img src='\img\icon\ic_close.svg' alt='' />
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div
          className={`${
            isMapTypeVisible ? setSideBarTablet() : sMobile.closeMaptype
          }`}
        >
          <div className={sMobile.mapToggleTxt}>Map Type</div>
          <div></div>
          <div
            onClick={() => handleCloseMapType(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCloseMapType(false);
              }
            }}
            tabIndex={0}
          >
            <img src='\img\icon\ic_close.svg' alt='' />
          </div>
        </div>
      </Tablet>
    </>
  );
};
export default MapActionsComponent;

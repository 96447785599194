import { Modal } from "antd";
import { useSelector } from "react-redux";

import { TAB_SCORE_CARD } from "../../../constants/score-card.constants";
import { Desktop, Mobile, Tablet } from "../../../responsive/responesive";
import { toFixedNumber } from "../../../utils/Number.utils";
import ModalWaterfallChart from "../../ModalWaterfallChart/ModalWaterfallChart";
import CardBreakdownLevel3Component from "./CardBreakdownLevel3Component";
import CardSecondaryComponent from "./CardSecondaryComponent";
import s from "./ChartComponent.module.css";
import sMobile from "./ChartResponsiveMobile.module.css";
import SumaryComponent from "./SummaryComponent";
import TrendChartLevel3 from "./TrendChartLevel3";
import WaterfallChartLevel3 from "./WaterfallChartLevel3";

interface Props {
    onCloseModal: () => void,
    isModalVisible: boolean,
    onclickBreakdown: any,
    setActiveCard: any,
    activeCard: any
}

const ModalBreakdownCardComponent = ({ onCloseModal, isModalVisible, onclickBreakdown, setActiveCard, activeCard }: Props) => {
    const { sectionIdActive, waterfallChartLvl3, primaryCardInforDetail } = useSelector((state: any) => state.scoreCard)
    const breakDownCardDetailLevel3 = useSelector((state: any) => state.scoreCard.breakDownCardDetailLevel3)
    const executiveSummary = breakDownCardDetailLevel3.data?.executiveSummary
    const { breakdownCards, cardActive } = breakDownCardDetailLevel3;

    function _renderDeltaValue(value: number) {
        const formatValue = toFixedNumber(value < 0 ? Math.abs(value) : value, 1);
        return value < 0 ? `(${formatValue})` : formatValue;
    }

    function PrimaryCardBackgroundComponent(breakDownCardLevel3, cssModule) {
        return <div className={cssModule.primaryCardBackground}>
            {
                breakDownCardLevel3.data && breakDownCardLevel3.data?.secondaryValue?.map((card) => {
                    return (
                        <CardSecondaryComponent card={card} key={card.key} primaryCardInforDetail={breakDownCardLevel3} />
                    )
                })
            }
        </div>
    }

    function ListButtonBreakdownComponent(cssModule, breakdownCardList, activeCardList, onclickBreakdownList) {
        return <div className={cssModule.listButtonBreakdownModal}>
            {
                breakdownCardList.map((card: any, idx) => {
                    return (
                        <div key={idx} className={s.buttonBreakdownCard}>
                            <CardBreakdownLevel3Component card={card} activeCard={activeCardList} key={card.key} onclickBreakdown={onclickBreakdownList} />
                        </div>
                    )
                })
            }
        </div>
    }

    function CardActiveComponent() {
        return cardActive && (<div onClick={() => onclickBreakdown(cardActive.key)} className={activeCard === cardActive.key ? s.miniCardActiveModal : s.miniCardModal}>
            <div className={s.miniTitleCardActiveModal}>{cardActive.title}</div>
            <div className={s.miniUnitCardActiveModal}>{cardActive.unit}</div>
            <div className={s.miniValueCardActiveModal}>{_renderDeltaValue(cardActive.value)}</div>
        </div>)
    }

    function isWaterfallAvailPlant() {
        return waterfallChartLvl3?.data?.length > 0 && sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE
    }

    function isSectionFinanceOrProd() {
        return sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE || sectionIdActive === TAB_SCORE_CARD.PRODUCTION_GENERATION
    }

    function isWaterfallNotAvailPlant() {
        return (waterfallChartLvl3?.data?.length === 0 || waterfallChartLvl3?.length === 0) && sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE
    }

    function isBreakdownAvail() {
        return breakdownCards?.length > 0
    }

    function isNotHSSE() {
        return sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE || sectionIdActive === TAB_SCORE_CARD.PRODUCTION_GENERATION || sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE;
    }

    function setModalWidth() {
        return waterfallChartLvl3?.data?.length > 0 ? 1400 : 1000
    }

    function _renderMobileTablet() {
        return (
            <Modal title="" visible={isModalVisible} width={setModalWidth()} zIndex={998} onCancel={onCloseModal} centered={true} maskStyle={{ backgroundColor: "rgba(40,40,40,0.7)" }}
                footer={null} className={s.backgroundModal} bodyStyle={{ background: "linear-gradient(180deg, rgba(6,25,25,0.5) 0%, #000000 100%)" }}>
                <div className={s.headerModal}>{primaryCardInforDetail?.title}</div>
                <div className={s.listMiniCardModal}>
                    {CardActiveComponent()}
                    {
                        isNotHSSE() && isBreakdownAvail() &&
                        ListButtonBreakdownComponent(sMobile, breakdownCards, activeCard, onclickBreakdown)
                    }
                </div>
                {
                    isNotHSSE() && breakdownCards?.length === 0 &&
                    PrimaryCardBackgroundComponent(breakDownCardDetailLevel3, sMobile)
                }
                <div className={sMobile.mainLayoutModal}>
                    {
                        isWaterfallNotAvailPlant() && (
                            <div style={{ width: "inherit" }}>
                                <div className={s.mainLayoutModalProduc}>
                                    <SumaryComponent sectionIdActive={sectionIdActive} executiveSummary={executiveSummary} />
                                    {isBreakdownAvail() && PrimaryCardBackgroundComponent(breakDownCardDetailLevel3, sMobile)}
                                </div>
                                <TrendChartLevel3 />
                            </div>
                        )
                    }
                    {
                        isSectionFinanceOrProd() && (
                            <>
                                <div className={s.noWaterfallLayout}>
                                    <SumaryComponent sectionIdActive={sectionIdActive} executiveSummary={executiveSummary} />
                                    {isBreakdownAvail() && PrimaryCardBackgroundComponent(breakDownCardDetailLevel3, sMobile)}
                                    <TrendChartLevel3 />
                                </div>
                            </>
                        )
                    }

                    {
                        isWaterfallAvailPlant() && (
                            <>
                                <div style={{ width: "inherit" }}>
                                    <div className={s.mainLayoutModalProduc}>
                                        <SumaryComponent sectionIdActive={sectionIdActive} executiveSummary={executiveSummary} />
                                        {PrimaryCardBackgroundComponent(breakDownCardDetailLevel3, sMobile)}
                                    </div>
                                    <TrendChartLevel3 />
                                    <div className={sMobile.waterfallChartModal}>
                                        <WaterfallChartLevel3 waterfallChart={waterfallChartLvl3} />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </Modal>)
    }
    return (
        <div>
            <Desktop>
                <Modal title="" visible={isModalVisible} width={setModalWidth()} zIndex={998} onCancel={onCloseModal} centered={true} maskStyle={{ backgroundColor: "rgba(40,40,40,0.7)" }}
                    footer={null} className={s.backgroundModal} bodyStyle={{ background: "linear-gradient(180deg, rgba(6,25,25,0.5) 0%, #000000 100%)" }}>
                    <div className={s.headerModal}>{primaryCardInforDetail?.title}</div>
                    <div className={s.listMiniCardModal}>
                        {CardActiveComponent()}
                        {
                            isNotHSSE() && isBreakdownAvail() ?
                                ListButtonBreakdownComponent(s, breakdownCards, activeCard, onclickBreakdown) : PrimaryCardBackgroundComponent(breakDownCardDetailLevel3, s)
                        }
                    </div>
                    <div className={s.mainLayoutModal}>
                        {
                            isWaterfallNotAvailPlant() && (
                                <>
                                    <div className={s.mainLayoutModalPlant}>
                                        <SumaryComponent sectionIdActive={sectionIdActive} executiveSummary={executiveSummary} />
                                        {isBreakdownAvail() && PrimaryCardBackgroundComponent(breakDownCardDetailLevel3, s)}
                                    </div>
                                    <TrendChartLevel3 />
                                </>
                            )
                        }
                        {
                            isSectionFinanceOrProd() && (
                                <>
                                    <div className={s.noWaterfallLayout}>
                                        <SumaryComponent sectionIdActive={sectionIdActive} executiveSummary={executiveSummary} />
                                        {isBreakdownAvail() && PrimaryCardBackgroundComponent(breakDownCardDetailLevel3, s)}
                                        <TrendChartLevel3 />
                                    </div>
                                </>
                            )
                        }

                        {
                            isWaterfallAvailPlant() && (
                                <>
                                    <div className={s.mainLayoutModal}>
                                        <div style={{ width: "inherit" }}>
                                            <div className={s.mainLayoutModalProduc}>
                                                <SumaryComponent sectionIdActive={sectionIdActive} executiveSummary={executiveSummary} />
                                                {PrimaryCardBackgroundComponent(breakDownCardDetailLevel3, s)}
                                            </div>
                                            <TrendChartLevel3 />
                                        </div>
                                        <div className={s.waterfallChartModal}>
                                            <WaterfallChartLevel3 waterfallChart={waterfallChartLvl3} />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </Modal>
            </Desktop>
            <Mobile>{_renderMobileTablet()}</Mobile>
            <Tablet>{_renderMobileTablet()}</Tablet>
            <ModalWaterfallChart data={waterfallChartLvl3} />
        </div>
    )
}

export default ModalBreakdownCardComponent

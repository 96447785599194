import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { get, isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { MOBILE_RESPONSIVE, TABLET_RESPONSIVE } from "../../../constants/responsive.constants";
import { customizeScollbar } from "../../../utils/chart.utils";
import s from "./ChartComponent.module.css";

const chartColorCode = {
  blue: "#8FC8F8",
  yellow: "#DFEB98",
  green: "#50A4B9",
  purple: "#9E94DB",
  orange: "#F5A623",
  cerulean:"#009DDC"
};

const TrendChartLevel2 = (props: any) => {
  const chart = useRef<am4charts.XYChart>();
  // const revenueChart = useSelector((state) =>
  //   get(state, "scoreCard.revenueChart", {})
  // );

  // const currentDate = useSelector((state) =>
  //   get(state, "scoreCard.currentDate", 1)
  // );

  const { revenueChart, currentDate, sectionIdActive } = useSelector((state) => {
    const scorecard = get(state, 'scoreCard', null);
    return {
      revenueChart: scorecard.revenueChart,
      currentDate: scorecard.currentDate,
      sectionIdActive: scorecard.sectionIdActive
    };
  });

  const isMobile = useMediaQuery(MOBILE_RESPONSIVE);
  const isTablet = useMediaQuery(TABLET_RESPONSIVE);

  useEffect(() => {
    if (!isEmpty(revenueChart)) {
      const chartdiv = am4core.create("chartdiv", am4charts.XYChart);
      chart.current = chartdiv;
      const chartCurrent = chart.current;
      chartCurrent.zoomOutButton.disabled = true;
      if (chartCurrent) {
        if (revenueChart?.charts && revenueChart?.charts.length > 0) {
          const data = revenueChart.data;
          chartCurrent.data = data;
          chartCurrent.numberFormatter.numberFormat = "#.##a";
          chartCurrent.numberFormatter.smallNumberThreshold = 0;
          chartCurrent.background.fillOpacity = 0;
          chartCurrent.maskBullets = false;
          chartCurrent.height = 239;
          chartCurrent.cursor = new am4charts.XYCursor();
          chartCurrent.cursor.lineY.disabled = true;
          chartCurrent.cursor.behavior = "none";

          customizeDateAxis(chartCurrent);
          const tooltipHtml = customizeTooltip();
          customizeValueAxis(chartCurrent, tooltipHtml);
        }
      }
    }

    // Add data
    return () => {
      chart.current?.dispose();
    };
  }, [revenueChart]);

  function customizeDateAxis(chartCurrent: am4charts.XYChart) {
    const dateAxis = chartCurrent.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.fullWidthTooltip = true;
    dateAxis.renderer.ticks.template.disabled = false;
    dateAxis.baseInterval = { timeUnit: "month", count: 1 };
    dateAxis.renderer.labels.template.fill = am4core.color("#A09F9B");
    dateAxis.fontSize = 11;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.dateFormatter.dateFormat = "YYYY-MM-DD HH:NN:SS";
    dateAxis.events.on("datavalidated", (ev) => {
      const axis = ev.target;
      const start = axis.positionToDate(0);
      const end = axis.positionToDate(1);
      const current = new Date(start);

      while (current < end) {
        const range = axis.axisRanges.create();
        range.label.dataItem.text = `${moment(current).format(
          "MMM"
        )}`.toUpperCase();

        range.label.dx = !props.isWaterFallChart ? 65 : 30;
        range.grid.strokeOpacity = 0;
        const nextMonth = new Date(current);
        nextMonth.setMonth(current.getMonth() + 1);
        range.date = current;
        range.endDate = nextMonth;

        if (current.getMonth() === currentDate?.month - 1) {
          range.axisFill.fill = am4core.color("#FFFFFF");
          range.axisFill.fillOpacity = 0.2;
          range.grid.dx = 20;
          range.label.fill = am4core.color("#FFFFFF");
        }
        //Iterate
        current.setMonth(current.getMonth() + 1);
      }
    });
    (isMobile || isTablet) &&
      chartCurrent.events.on("ready", function () {
        const year = new Date().getFullYear();
        dateAxis.zoomToDates(
          new Date(year, 0, 1),
          new Date(year, 5, 1),
          false,
          true
        );
        chartCurrent.zoomOutButton.disabled = true;
        customizeScollbar(chartCurrent, true);
      });
  }

  function customizeTooltip() {
    let tooltipHTML = "<table style='line-height:14px;'>";
    for (let idx = 0; idx < revenueChart.charts.length; idx++) {
      const data = revenueChart.charts[idx];
      const seriesNo = idx + 1;
      tooltipHTML += `<tr style='font-size:13px;'>
                                <td>
                                    <span style="height: 14px;
                                                width: 24px;
                                                color: #C8CCCD;
                                                font-family: "Museo Sans";">
                                        {label${seriesNo}}
                                    </span>
                                </td>
                                <td>
                                    <span style="height: 14px;
                                                width: 18px;
                                                color: #9B9B9B;
                                                font-family: "Museo Sans";">
                                                {unit${seriesNo}}
                                    </span>
                                </td>
                                <td>
                                    <span style="height: 14px;
                                    width: 14px;
                                    color: ${chartColorCode[data.chartColor]};
                                    font-family: "Museo Sans";
                                    text-align: center;">
                                        {series${seriesNo}}
                                    </span>
                                </td>
                                <td>
                                    <span style="height: 14px;
                                    width: 16px;
                                    color: #9B9B9B;
                                    font-family: "Museo Sans";">
                                        {subUnit${seriesNo}}
                                    </span>
                                    
                                </td>
                            </tr>`;
    }
    tooltipHTML += "</table>";
    return tooltipHTML;
  }

  function customizeValueAxis(
    chartCurrent: am4charts.XYChart,
    tooltipHtml: string
  ) {
    // Create series
    let isTooltip = false;
    const valueAxis = chartCurrent.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.cursorTooltipEnabled = false;
    for (let idx = 0; idx < revenueChart.charts.length; idx++) {
      const data = revenueChart.charts[idx];
      const seriesNo = idx + 1;

      switch (data.chartType) {
        case "dot":
          createDotChart(
            chartCurrent,
            `series${seriesNo}`,
            data.chartColor,
            tooltipHtml,
            isTooltip,
            valueAxis
          );
          isTooltip = true;
          break;
        case "bar":
          createBarChart(
            chartCurrent,
            `series${seriesNo}`,
            data.chartColor,
            tooltipHtml,
            valueAxis
          );
          break;
        case "line":
          createLineChart(
            chartCurrent,
            `series${seriesNo}`,
            data.chartColor,
            tooltipHtml,
            isTooltip,
            valueAxis
          );
          isTooltip = true;
          break;
        case "dash":
          createDashChart(
            chartCurrent,
            `series${seriesNo}`,
            data.chartColor,
            tooltipHtml,
            isTooltip,
            valueAxis
          );
          break;
        default:
          break;
      }
    }
  }
  function createLineChart(
    chartCurrent: am4charts.XYChart,
    seriesNo: string,
    chartColor: string,
    tooltipHtml: string,
    isTooltip: boolean,
    valueAxis: am4charts.ValueAxis
  ) {
    var lineSeries = chartCurrent.series.push(new am4charts.LineSeries());
    lineSeries.dataFields.valueY = seriesNo;
    lineSeries.dataFields.dateX = "date";
    lineSeries.yAxis = valueAxis;
    lineSeries.stroke = am4core.color(chartColorCode[chartColor]);
    lineSeries.strokeWidth = 2;
    lineSeries.showOnInit = true;
    if (!isTooltip) {
      lineSeries.tooltipHTML = tooltipHtml;
      const tooltip = lineSeries.tooltip;
      if (tooltip) {
        tooltip.getFillFromObject = false;
        tooltip.background.fill = am4core.color("#000000");
        tooltip.background.strokeOpacity = 0;
      }
    }
  }
  function createBarChart(
    chartCurrent: am4charts.XYChart,
    seriesNo: string,
    chartColor: string,
    tooltipHtml: string,
    valueAxis: am4charts.ValueAxis
  ) {
    var barSeries = chartCurrent.series.push(new am4charts.ColumnSeries());
    barSeries.dataFields.valueY = seriesNo;
    barSeries.dataFields.dateX = "date";
    barSeries.yAxis = valueAxis;
    barSeries.columns.template.fill = am4core.color(chartColorCode[chartColor]);
    barSeries.columns.template.stroke = am4core.color(
      chartColorCode[chartColor]
    );
    barSeries.columns.template.width = 14;
  }

  function createDotChart(
    chartCurrent: am4charts.XYChart,
    seriesNo: string,
    chartColor: string,
    tooltipHtml: string,
    isTooltip: boolean,
    valueAxis: am4charts.ValueAxis
  ) {
    var dotSeries = chartCurrent.series.push(new am4charts.LineSeries());
    dotSeries.dataFields.valueY = seriesNo;
    dotSeries.dataFields.dateX = "date";
    dotSeries.yAxis = valueAxis;
    dotSeries.stroke = am4core.color(chartColorCode[chartColor]);
    dotSeries.strokeWidth = 2;
    dotSeries.strokeDasharray = "3,3";
    dotSeries.propertyFields.strokeDasharray = "3,3";
    dotSeries.showOnInit = true;
    if (!isTooltip) {
      dotSeries.tooltipHTML = tooltipHtml;
      const tooltip = dotSeries.tooltip;
      if (tooltip) {
        tooltip.getFillFromObject = false;
        tooltip.background.fill = am4core.color("#000000");
        tooltip.background.strokeOpacity = 0;
      }
    }

    const latitudeBullet = dotSeries.bullets.push(new am4charts.CircleBullet());
    latitudeBullet.circle.radius = 5;
    latitudeBullet.circle.stroke = am4core.color("#000000");
    latitudeBullet.circle.strokeWidth = 2;
  }

  function createDashChart(
    chartCurrent: am4charts.XYChart,
    seriesNo: string,
    chartColor: string,
    tooltipHtml: string,
    isTooltip: boolean,
    valueAxis: am4charts.ValueAxis
  ) {
    var dashSeries = chartCurrent.series.push(new am4charts.LineSeries());
    dashSeries.dataFields.valueY = seriesNo;
    dashSeries.dataFields.dateX = "date";
    dashSeries.yAxis = valueAxis;
    dashSeries.stroke = am4core.color(chartColorCode[chartColor]);
    dashSeries.strokeWidth = 2;
    dashSeries.strokeDasharray = "5,5";
    dashSeries.propertyFields.strokeDasharray = "5,5";
    dashSeries.showOnInit = true;
  }

  return (
    <div className={sectionIdActive === 2 ? `${s.financialTrendChartContainer}` : `${s.trendChartContainer}`}>
      <div className={s.topTrendChartDiv}>
        <div className={s.topLeftTrendDiv}>
          {revenueChart?.externalLink && (
            <>
              {" "}
              <img src="/img/icon/Icon Link.svg" alt="Link to others app" />
              <a
                href={revenueChart?.externalLink}
                target="_blank"
                rel="noreferrer"
              >
                More Details
              </a>
            </>
          )}
        </div>
        <div className={s.topRightTrendDiv}>
          {revenueChart?.charts &&
            revenueChart?.charts.length > 0 &&
            revenueChart?.charts.map((value, idx) => {
              const legendChartClass = s[`legend${value.chartType}`];
              return (
                <React.Fragment key={idx}>
                  <div
                    className={legendChartClass}
                    style={{ backgroundColor: chartColorCode[value.chartColor] }}
                  ></div>
                  <span>{value.chartTitle}</span>
                </React.Fragment>
              )
            })}
        </div>
      </div>
      <div className={s.subTrendChartDiv}>{`${revenueChart?.currency ? revenueChart?.currency : ""
        } ${revenueChart?.unit ? revenueChart?.unit : ""}`}</div>
      {revenueChart?.charts && revenueChart?.charts.length > 0 ? (
        <div id="chartdiv" className={s.trendChart} />
      ) : (
        <span className={s.chartNotAvailable}>Chart not available</span>
      )}
    </div>
  );
};
export default TrendChartLevel2;

import * as types from "../action/actionType";

import { Action } from "redux";
import { VIEWSCONFIGID } from "../constants/usermanagement.constanst";
import { STATUS_STR } from "../constants/endpoints";

export const initialData = {
  addMapList: [],
  isPermissionLoading: false,
  isSCPermissionLoading: false,
  isSCTableLoading: false,
  isMFTPermissionLoading: false,
  isMFTTableLoading: false,
  isUserTableLoading: false,
  isVCPermissionLoading: false,
  isVCTableLoading: false,
  isWMTableLoading: false,
  opus: [],
  personas: [],
  personaSCList: [],
  personaMFTList: [],
  personaVCList: [],
  roles: [],
  scTableData: [],
  userTableData: [],
  vcTableData: [],
  wmTableData: [],
  mftTableData: [],
  userTableStatus: 0,
  usersStatus: [],
  activeButton: null,
};

interface UserManagementAction extends Action {
  payload: string | any;
  data: string | any;
}

export function userManagementReducer(
  state = initialData,
  action: UserManagementAction
) {
  switch (action.type) {
    case types.USERMANAGEMENT_GET_USERS_REQUESTED: {
      return {
        ...state,
        isUserTableLoading: true,
      };
    }
    case types.USERMANAGEMENT_GET_USERS_SUCCEEDED: {
      return {
        ...state,
        userTableData: action.payload,
        isUserTableLoading: false,
      };
    }
    case types.USERMANAGEMENT_GET_OPUS_SUCCEEDED: {
      return {
        ...state,
        opus: action.payload,
      };
    }
    case types.USERMANAGEMENT_GET_ROLES_SUCCEEDED: {
      return {
        ...state,
        roles: action.payload,
      };
    }
    case types.USERMANAGEMENT_GET_PERSONAS_SUCCEEDED: {
      return {
        ...state,
        personas: action.payload,
      };
    }
    case types.USERMANAGEMENT_PUT_USER_REQUESTED: {
      return userPostRequest(state);
    }
    case types.USERMANAGEMENT_PUT_USER_SUCCEEDED: {
      return {
        ...state,
        isUserTableLoading: false,
        userTableStatus: STATUS_STR.UPDATE
      }
    }
    case types.USERMANAGEMENT_PERSONA_WM_SUCCEEDED: {
      return {
        ...state,
        wmTableData: action.payload,
        isWMTableLoading: false,
      };
    }
    case types.USERMANAGEMENT_PERSONA_WM_REQUESTED: {
      return {
        ...state,
        isWMTableLoading: true,
      };
    }
    case types.USERMANAGEMENT_PERSONA_VC_SUCCEEDED: {
      return {
        ...state,
        vcTableData: action.payload,
        isVCTableLoading: false,
      };
    }
    case types.USERMANAGEMENT_PERSONA_SC_SUCCEEDED: {
      return {
        ...state,
        scTableData: action.payload,
        isSCTableLoading: false,
      };
    }
    case types.USERMANAGEMENT_PERSONA_MFT_SUCCEEDED: {
      return {
        ...state,
        mftTableData: action.payload,
        isMFTTableLoading: false,
      };
    }
    case types.USERMANAGEMENT_ADD_MAP_LIST_SUCCEEDED: {
      return {
        ...state,
        addMapList: action.payload,
      };
    }
    case types.USERMANAGEMENT_UPDATE_PERMISSION_REQUESTED: {
      return {
        ...state,
        isPermissionLoading: true,
        isWMTableLoading: action.data.isTableLoading,
      };
    }
    case types.USERMANAGEMENT_UPDATE_PERMISSION_SUCCEEDED: {
      return {
        ...state,
        isPermissionLoading: false,
        isWMTableLoading: false,
      };
    }
    case types.USERMANAGEMENT_PERSONSCLIST_SUCCEEDED: {
      return {
        ...state,
        personaSCList: action.payload,
      };
    }
    case types.USERMANAGEMENT_PERSONMFTLIST_SUCCEEDED: {
      return {
        ...state,
        personaMFTList: action.payload,
      };
    }
    case types.USERMANAGEMENT_PERSONVCLIST_SUCCEEDED: {
      return {
        ...state,
        personaVCList: action.payload,
      };
    }
    case types.USERMANAGEMENT_UPDT_PERSONA_REQUESTED: {
      if (action.data.viewTypeId === VIEWSCONFIGID.VALUECHAIN) {
        return {
          ...state,
          isVCPermissionLoading: true,
          isVCTableLoading: action.data.isShowTableLoading,
        };
      }
      else if (action.data.viewTypeId === VIEWSCONFIGID.MFT) {
        return {
          ...state,
          isMFTPermissionLoading: true,
          // isMFTTableLoading: action.data.isShowTableLoading,
        };
      }
      else {
        return {
          ...state,
          isSCPermissionLoading: true,
        };
      }
    }
    case types.USERMANAGEMENT_UPDT_PERSONA_SUCCEEDED: {
      if (action.data === VIEWSCONFIGID.VALUECHAIN) {
        return {
          ...state,
          isVCPermissionLoading: false,
        };
      }
      else if (action.data === VIEWSCONFIGID.MFT) {
        return {
          ...state,
          isMFTPermissionLoading: false,
        };
      }
      else {
        return {
          ...state,
          isSCPermissionLoading: false,
        };
      }
    }
    case types.USERMANAGEMENT_PERSONA_VC_REQUESTED: {
      return {
        ...state,
        isVCTableLoading: true,
      };
    }
    case types.USERMANAGEMENT_PERSONA_SC_REQUESTED: {
      return {
        ...state,
        isSCTableLoading: true,
      };
    }
    case types.USERMANAGEMENT_PERSONA_MFT_REQUESTED: {
      return {
        ...state,
        isMFTTableLoading: true,
      };
    }
    case types.USERMANAGEMENT_POST_USER_REQUESTED: {
      return userPostRequest(state);
    }
    case types.USERMANAGEMENT_POST_USER_SUCCEEDED: {
      return {
        ...state,
        isUserTableLoading: false,
        userTableStatus: STATUS_STR.CREATED
      };
    }
    case types.USERMANAGEMENT_DELETE_USER_SUCCEEDED: {
      return {
        ...state,
        isUserTableLoading: false,
        userTableStatus: STATUS_STR.DELETE
      };
    }
    case types.USERMANAGEMENT_POST_USER_FAILED: {
      return {
        ...state,
        isUserTableLoading: false,
        userTableStatus: STATUS_STR.ERROR
      };
    }
    case types.USERMANAGEMENT_DOWNLOAD_REQUESTED: {
      return {
        ...state,
        isUserTableLoading: true,
      };
    }
    case types.USERMANAGEMENT_DOWNLOAD_SUCCEEDED: {
      return {
        ...state,
        isUserTableLoading: false,
      };
    } case types.USERMANAGEMENT_DOWNLOAD_FAILED: {
      return {
        ...state,
        isUserTableLoading: false,
      };
    } case types.USERMANAGEMENT_GET_STATUS_SUCCEEDED: {
      return {
        ...state,
        userStatus: action.payload,
      };
    } case types.REINITIALIZE_USERTABLE_STATUS: {
      return {
        ...state,
        userTableStatus: 0,
      }
    } case types.SET_ACTIVE_BUTTON: {
      return {
        ...state,
        activeButton: action.data,
      };
    }
  }
  return state;
}

function userPostRequest(state) {
  return {
    ...state,
    isUserTableLoading: true,
    userTableStatus: 0
  };
}

import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import s from "../Sustainability/sustainability.module.css";
import { toFixedNumber, toFixedSustainabilityNumber } from "../../utils/Number.utils";
import { fetchSustainabilityCalloutData } from "../../action/actionMap";
import PLCListEquipment from "./PLCListEquipmentComponent";
import GHGListEquipmentGTComponent from "../../components/Sustainability/GHGListEquipment/GHGListEquipmentGTComponent";
import { Xwrapper } from "react-xarrows";
import Xarrow from "react-xarrows";
import { useRef } from "react";
import { fetchCallOutSustainability } from "../../action/actionSustainability";
import { sanitizeNumber } from "../../utils/common.utils";

const GHGPointComponent = (props: any) => {
  let location = props.data;
  let setLocationName = props.setLocationName
  const dispatch = useDispatch();
  const selectedPoint = useSelector((state) =>
    get(state, 'sustainability.selectedPoint', null)
  );
  function _renderPointGroup() {
    const pointGroupClassName = (location.locationId === selectedPoint?.locationId && location.locationName === selectedPoint?.locationName) ? `${s.pointGroupDot} ${s.selectedDot}` : s.pointGroupDot;
    return (
      <div
        id={location.locationId}
        className={pointGroupClassName}
        onClick={() => handleViewDetail()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleViewDetail();
          }
        }}
        tabIndex={0}
      >
        <span ></span>
      </div>
    )
  }

  // Click point
  function handleViewDetail() {
    if (location?.locationId && !location?.isCummulative) {
      const isDaily = true;
      dispatch(fetchSustainabilityCalloutData(location?.locationId, isDaily))
      setLocationName(location?.locationName)
      dispatch(fetchCallOutSustainability())
    }
  }

  function _renderDataPoint() {
    return _renderPointGroup();
  }

  function locationName() {
    return location.locationName;
  }

  function renderUnitSubscript(i: string) {
    if (i === 'tCO2e') {
      return <span className={s.co2Unit}>{<span>tCO<sub>2</sub>e</span>}</span>;
    }
    else if (i === 'Mil tCO2e'){
      return <span className={s.co2Unit}>{<span>M tCO<sub>2</sub>e</span>}</span>;
    }
    else {
      return <span className={s.co2Unit}>{i}</span>;
    }
  } 
  
  const equipmentPointsPLC = ["MLNG", "MLNG DUA", "MLNG TIGA", "TRAIN 9", "LPG TERMINAL"];
  const equipmentPointsGT = ["GURUN RO"];
  return (
    <>
    {/* Equipment PLC Drilldown */}
    {equipmentPointsPLC.includes(locationName()) ? <PLCListEquipment data={locationName()} location={location}/> : null}

    {/* Equipment GT Drilldown */}
    {equipmentPointsGT.includes(locationName()) ? <GHGListEquipmentGTComponent/> : null}

    <div className={`${s.pointGroupWrap} ${location?.position ? s[location?.position] : s.Left} ${s.green}`}
      style={{ top: (sanitizeNumber(location.Y) + "px"), left: sanitizeNumber(location.X) + "px" }}>
      <div className={s.pointGroup}>
        {_renderDataPoint()}
        
        <div className={s.pointGroupInfo}>
          <div className={`${s.pointGroupInfoTop} ${["GPS", "UK", "PFLNG DUA"].includes(locationName()) ? s.alignRight : ''}`}>
            <span>{locationName()}</span>
            <div className={s.pointGroupContent}>
              {
                location?.parameters.map((param: any, idx: any) => {
                  if (location?.parameters.length > 1) {
                    if (param?.unit === 'tCO2e') {
                      return (
                        <div key={'y' + idx}>
                          <span>{param?.displayLabel}</span>
                          <div className={s.pointGroupInfoBodyText} style={{ marginRight: '10px' }} key={'x' + idx}>
                            <b>{toFixedSustainabilityNumber(param?.value, 3)}</b>
                            <span>{param?.unit}</span>
                            <img
                              src='/img/icon/pi.svg'
                              alt='Notifications'
                              className={s.piIcon}
                            />
                          </div>
                        </div>
                      )
                    }
                    return (
                      <div key={'y' + idx}>
                        <span>{param?.displayLabel}</span>
                        <div className={s.pointGroupInfoBodyText} key={'x' + idx}>
                          <b>{toFixedSustainabilityNumber(param?.value, 3)}</b>
                          <span>{param?.unit?.split(' ')[0]}</span>
                          <img
                            src='/img/icon/database.svg'
                            alt='Notifications'
                          />
                        </div>
                      </div>
                    )
                  }
                  if (location?.calloutTypeId === 1) {
                    return (
                      <div key={'y' + idx}>
                        <div className={s.pointGroupInfoBodyText} key={'x' + idx}>
                          <b>{toFixedSustainabilityNumber(param?.value, 3)}</b>
                          {/* {<span>{param?.unit}</span>} */}
                          {param ? renderUnitSubscript(param?.unit) : param?.unit}
                          <img
                            src='/img/icon/pi.svg'
                            alt='Notifications'
                            className={s.piIcon}
                          />
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div key={'y' + idx}>
                      <div className={s.pointGroupInfoBodyText} key={'x' + idx}>
                        <b>{toFixedSustainabilityNumber(param?.value, 3)}</b>
                        {/* <span>{param?.unit?.split(' ')[0]}</span> */}
                        {param ? renderUnitSubscript(param?.unit) : param?.unit}
                        {/* <img
                          src='/img/icon/database.svg'
                          alt='Notifications'
                        /> */}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
export default GHGPointComponent;
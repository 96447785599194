function transformInitData(datas) {
    let transformData: { key: number, actualValue: number, startValue: number, endValue: number, label: string, color: string }[] = [];
    for (let index = 0; index < datas.length; index++) {
        const data = datas[index];
        transformData.push({
            key: index,
            actualValue: data.actualValue ? data.actualValue : 0,
            startValue: data.startValue ? data.startValue : 0,
            endValue: data.endValue ? data.endValue : 0,
            label: data.label,
            color: data.color
        })
    }
    return transformData;
}
export function transformWaterfallChartLevel2(results) {
    let resultData = transformInitData(results);
    resultData = { ...results, data: resultData }
    return resultData
}
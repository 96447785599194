import { Popover } from "antd";
import React, { useState } from "react";

import s from "./PopoverImageComponent.module.css";

interface Props {
  imageSrc: string;
  arr: any;
  selectedIdx: number;
  selectFunc?: any;
}

const PopoverImageComponent = ({
  imageSrc,
  arr,
  selectedIdx,
  selectFunc,
}: Props) => {
  const [isVisible, setVisible] = useState(false);
  function setFunc(index) {
    selectFunc(index);
    setVisible(false);
  }

  function isSelectedClass(index) {
    return index === selectedIdx ? s.activePop : "";
  }
  function _renderContent() {
    return (
      <div className={s.popoverContent}>
        {arr.map((value, index) => (
          <p
            className={isSelectedClass(index)}
            onClick={() => setFunc(index)}
            key={index}
          >
            {value}
          </p>
        ))}
      </div>
    );
  }
  return (
    <Popover
      overlayClassName="popoverImageComponent"
      placement="bottom"
      trigger="click"
      content={_renderContent()}
      visible={isVisible}
      onVisibleChange={(visible) => setVisible(visible)}
    >
      <img src={imageSrc} alt="images" onClick={()=>setVisible(true)}/>
    </Popover>
  );
};

export default PopoverImageComponent;

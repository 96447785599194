import s from '../PrimaryInfo/primary-info.module.css';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { useState } from 'react';
import EmissionSummaryComponent from './EmissionSummaryComponent';
import EmissionSourcesComponent from './EmissionSourcesComponent';
import { toFixedSustainabilityNumber } from '../../../utils/Number.utils';

function SustainabilityPrimaryInfo() {
  const { callOutData } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout?.sustainabilityCalloutData,
    };
  });
  const woInherentCo2Data: any[] = callOutData?.primaryTags || [];

  const [tabIndex, setTabIndex] = useState(0);

  function tabClassName(i) {
    if (i === tabIndex) {
      return s.active;
    }
    return;
  }

  function onTabChange(i: number) {
    setTabIndex(i);
  }

  const unit = woInherentCo2Data[1]?.primaryValue.unit === 'tCO2' ? 'tCO2e' : woInherentCo2Data[1]?.primaryValue.unit;

  return (
    <div className={s.emissionSourcesSummaryInfo}>
      <div className={s.calloutTab}>
        <ul>
            <li
                key="Emission Sources"
                className={tabClassName(0)}
                onClick={() => onTabChange(0)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onTabChange(0);
                  }
                }}
                tabIndex={0}
            >
                Emission Sources
            </li>
            {/* <li
                key="Summary"
                className={tabClassName(1)}
                onClick={() => onTabChange(1)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onTabChange(1);
                  }
                }}
                tabIndex={0}
            >
                Summary
            </li> */}
        </ul>
      </div>
      <div className={s.calloutGhgSecondaryInfo}>
        {tabIndex == 0 ? <EmissionSourcesComponent/> : <EmissionSummaryComponent/>}
      </div>
      <div className={s.woInherent}>
      {woInherentCo2Data[1] && ["GPS", "GPK", "PLC", "PFLNG SATU", "PFLNG DUA"].includes(callOutData?.locationName) ? (
        <>
          {`${woInherentCo2Data[1]?.tagName}: ${toFixedSustainabilityNumber(woInherentCo2Data[1]?.primaryValue.value, 3)} `}
          {unit === 'tCO2e' ? <span>&nbsp;tCO<sub>2</sub>e</span> : <span>&nbsp;M&nbsp;tCO<sub>2</sub>e</span>}
        </>
      ) : null}
      </div>
    </div>
  );
}

export default SustainabilityPrimaryInfo;
import { ASCENDING, DESCENDING } from "../constants/usermanagement.constanst";

import { isEmpty } from "lodash";

export function sortTable(data, attribute, direction) {
  let sortedRows = [...data];
  sortedRows.sort((a, b) => {
    const sortNullA = isEmpty(a[attribute]) ? undefined : a[attribute];
    const sortNullB = isEmpty(b[attribute]) ? undefined : b[attribute];
    if (sortNullA < sortNullB) {
      return direction === ASCENDING ? -1 : 1;
    }
    if (sortNullA > sortNullB) {
      return direction === DESCENDING ? 1 : -1;
    }
    return 0;
  });
  return sortedRows;
}

export function searchTable(data, search: string) {
  let returnData: any = data.filter((o, idx) => {
    return (
      JSON.stringify(o)
        .toLocaleLowerCase()
        .indexOf(search.toLocaleLowerCase()) > -1
    );
  });
  return returnData;
}

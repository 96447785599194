import "antd/dist/antd.css";

import { DatePicker, Space } from "antd";
import { Desktop, Mobile } from "../../../responsive/responesive";
import {
  FINANCIAL_EXCELLENCE_URL_MORE_DETAILS,
  MONTH_SHORT_TEXT,
  TAB_SCORE_CARD,
  TAB_SCORE_CARD_CONFIG,
} from "../../../constants/score-card.constants";
import React, { useEffect, useState } from "react";
import {
  fetchScoreCardBigCard,
  fetchScoreCardBreakdownCard,
  fetchScoreCardExecutiveSummary,
  fetchScoreCardPeriodsList,
  fetchScoreCardPrimaryCardDetail,
  fetchScoreCardRevenueChart,
  fetchScoreCardSmallCard,
  fetchScoreCardWaterfallChart,
  setCurrentDate,
  setScoreCardSectionIdActive,
  updateLevelAndKeyScoreCard,
} from "../../../action/actionScoreCard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

import ExecutiveSummaryComponent from "../../../components/ScoreCards/ExecutiveSummary/ExecutiveSummaryComponent";
import FinancialExcellenceComponent from "../../../components/ScoreCards/FinancialExcellenceComponent";
import HSSEPerformanceComponent from "../../../components/ScoreCards/HSSEPerformanceComponent";
import PlantPerformanceComponent from "../../../components/ScoreCards/PlantPerformanceComponent";
import ProductionAndGenerationComponent from "../../../components/ScoreCards/ProductionAndGenerationComponent";
import ScrollContainer from "react-indiana-drag-scroll";
import { URL_SIDE_BAR } from "../../../constants/map.constants";
import moment from "moment";
import queryString from "query-string";
import { renderSectionBySectionId } from "../../../utils/common.utils";
import s from "./scorecards.module.css";
import sMobile from "./scorecardsResponsiveMobile.module.css";
import { updateActiveTab } from "../../../action/actionMap";

const ScoreCardsComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [dateFilterValue, setDateFilterValue] = useState({
    year: NaN,
    month: NaN,
  });
  const [showPeriodFilter, setShowPeriodFilter] = useState(false);
  const [viewSummary, setViewSummary] = useState(false);
  const [isBigCarLv2, setIsBigCarLv2] = useState(0);
  const { MonthPicker } = DatePicker;

  const scoreCard = useSelector((state: any) => state.scoreCard);
  const {
    periodsList,
    keyScoreCard,
    levelScoreCard,
    executiveSummary,
    sectionIdActive,
  } = scoreCard;

  const buildUrlScoreCard = () => {
    const key =
      levelScoreCard !== 1 && keyScoreCard ? `&key=${keyScoreCard}` : "";
    return `?level=${levelScoreCard}&tab=${sectionIdActive}${key}`;
  };

  useEffect(() => {
    if (levelScoreCard === 1 && !isNaN(dateFilterValue.month)) {
      fetchScoreCardDataLevelOne();
    } else if (!isNaN(dateFilterValue.month)) {
      fetchScoreCardDataLevelTwo();
    }
    history.push(buildUrlScoreCard());
  }, [dateFilterValue, sectionIdActive]);

  useEffect(() => {
    history.push(buildUrlScoreCard());
    if (!levelScoreCard) {
      dispatch(updateLevelAndKeyScoreCard({ level: 1 }));
    }
  }, [levelScoreCard]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    let payload = {};
    if (location.search) {
      payload = {
        level: Number(query.get("level")),
        tab: Number(query.get("tab")),
      };
      if (query.get("key")) {
        payload["key"] = query.get("key");
      }
      dispatch(updateLevelAndKeyScoreCard(payload));
    }

    dispatch(updateActiveTab(URL_SIDE_BAR.SCORECARDS));
    dispatch(fetchScoreCardPeriodsList());
    history.push(buildUrlScoreCard());
    return () => {
      dispatch(updateLevelAndKeyScoreCard({ level: 1, tab: 1 }));
    };
  }, []);

  // get periods list
  useEffect(() => {
    if (periodsList && periodsList.length > 0) {
      const dateFilter = periodsList?.filter(
        (item: any) => item.defaultSelection === true
      )[0];
      const payload = {
        year: dateFilter?.year,
        month: dateFilter?.month,
      };

      setDateFilterValue(payload);
      dispatch(setCurrentDate(payload));
    }
  }, [periodsList]);

  useEffect(() => {
    if (levelScoreCard === 2) {
      const sectionName = renderSectionBySectionId(sectionIdActive) as any;
      const keyExistInData =
        scoreCard[sectionName] &&
        scoreCard[sectionName]?.parameters.filter(
          (v) => v.key === keyScoreCard
        );
      const keyExistInMiniCardData =
        scoreCard.smallCardInfor &&
        scoreCard.smallCardInfor?.categories?.filter(
          (v) => v?.parameters.filter((a) => a.key === keyScoreCard).length > 0
        );
      if (
        keyExistInData &&
        keyExistInData.length === 0 &&
        keyExistInMiniCardData &&
        keyExistInMiniCardData?.length === 0
      ) {
        const payload = {
          level: levelScoreCard,
          key: scoreCard[sectionName]?.parameters[0]?.key,
          tab: sectionIdActive,
        };
        dispatch(updateLevelAndKeyScoreCard(payload));
      } else if (keyExistInData && keyExistInData.length > 0) {
        setIsBigCarLv2(1);
      } else if (keyExistInMiniCardData && keyExistInMiniCardData.length > 0) {
        setIsBigCarLv2(2);
      }
    }
  }, [scoreCard]);

  useEffect(() => {
    if (levelScoreCard === 2) {
      const sectionName = renderSectionBySectionId(sectionIdActive) as any;
      const param = {
        paramKey: keyScoreCard,
        year: scoreCard[sectionName]?.year,
        month: scoreCard[sectionName]?.month,
      };
      dispatch(
        fetchScoreCardPrimaryCardDetail({
          params: param,
          tabActive: sectionIdActive,
        })
      );
      dispatch(
        fetchScoreCardBreakdownCard({
          params: { ...param },
          tabActive: sectionIdActive,
        })
      );
      dispatch(
        fetchScoreCardRevenueChart({
          params: param,
          tabActive: sectionIdActive,
        })
      );
      dispatch(
        fetchScoreCardWaterfallChart({
          params: param,
          tabActive: sectionIdActive,
        })
      );
    }
    history.push(buildUrlScoreCard());
  }, [keyScoreCard]);

  const buildParam = (hasSection: boolean, hasLevel: boolean) => {
    return (
      `?year=${dateFilterValue.year}&month=${
        MONTH_SHORT_TEXT[dateFilterValue.month - 1]
      }&` +
      (hasLevel ? `level=${levelScoreCard}&` : "") +
      (hasSection && `section=${sectionIdActive}`)
    );
  };

  const buildPayLoad = (hasSection, hasLevel) => {
    return {
      tabActive: sectionIdActive,
      params: buildParam(hasSection, hasLevel),
    };
  };

  const fetchScoreCardDataLevelTwo = () => {
    const payloadBigCard = buildPayLoad(false, false);
    const payloadMiniCard = buildPayLoad(false, true);
    const param = {
      paramKey: keyScoreCard,
      year: dateFilterValue.year,
      month: MONTH_SHORT_TEXT[dateFilterValue.month - 1],
    };

    dispatch(
      fetchScoreCardPrimaryCardDetail({
        params: param,
        tabActive: sectionIdActive,
      })
    );
    dispatch(fetchScoreCardBigCard(payloadBigCard));
    dispatch(
      fetchScoreCardBreakdownCard({
        params: { ...param },
        tabActive: sectionIdActive,
      })
    );
    if (sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE) {
      dispatch(fetchScoreCardSmallCard(payloadMiniCard));
    }
    dispatch(
      fetchScoreCardRevenueChart({ params: param, tabActive: sectionIdActive })
    );
    dispatch(
      fetchScoreCardWaterfallChart({
        params: param,
        tabActive: sectionIdActive,
      })
    );
  };

  const fetchScoreCardDataLevelOne = () => {
    const payloadBigCard = buildPayLoad(false, false);
    const payloadMiniCard = buildPayLoad(false, true);

    const paramsExecutiveSummary = buildParam(true, false);
    dispatch(fetchScoreCardExecutiveSummary(paramsExecutiveSummary));
    dispatch(fetchScoreCardBigCard(payloadBigCard));
    if (sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE) {
      dispatch(fetchScoreCardSmallCard(payloadMiniCard));
    }
  };

  const makeText = (m) => {
    if (m && m.year && m.month)
      return MONTH_SHORT_TEXT[m.month - 1] + " " + m.year;
    return "?";
  };

  function handleDateSelect($event) {
    setDateFilterValue({ month: $event.month() + 1, year: $event.year() });
    dispatch(
      setCurrentDate({ month: $event.month() + 1, year: $event.year() })
    );
    setShowPeriodFilter(false);
  }

  function disabledDate(current: moment.Moment) {
    return (
      current <
        moment(`${periodsList[0].month}-${periodsList[0].year}`, "MM-YYYY") ||
      current >
        moment(
          `${periodsList[periodsList.length - 1].month}-${
            periodsList[periodsList.length - 1].year
          }`,
          "MM-YYYY"
        )
    );
  }

  function handleChangeTab(value) {
    dispatch(setScoreCardSectionIdActive(value));
  }

  function handleBackLevel() {
    if (levelScoreCard !== 1) {
      dispatch(updateLevelAndKeyScoreCard({ level: 1, tab: sectionIdActive }));
    }
  }
  const handleViewSummary = () => {
    setViewSummary(!viewSummary);
  };

  return (
    <>
      <Desktop>
        <div
          className={`score-carrds-page fullscreen-pt-0 ${s.scoreCardsRoot}`}
        >
          <ScrollContainer
            className={`page-container bg-map ${s.scoreCardsContainer}`}
            style={{ backgroundImage: "url(/img/scorecards_bg1.jpg)" }}
          >
            <div className="page-content">
              <div className={s.scoreCardsContent}>
                {levelScoreCard === 1 && (
                  <div className={s.scoreCardsLeft}>
                    <div className={s.scoreCardsSectionTitle}>
                      Executive <span>Summary</span>
                    </div>
                    <div className={s.scoreCardsLeftList}>
                      {executiveSummary && (
                        <ExecutiveSummaryComponent data={executiveSummary} />
                      )}
                    </div>
                    {sectionIdActive !== 1 && (
                      <div className={s.moreDetailText}>
                        <img
                          src="\img\icon\Icon Link.svg"
                          alt="Icon More Detail"
                        />
                        <a
                          href={FINANCIAL_EXCELLENCE_URL_MORE_DETAILS}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>More Details (Power BI)</span>
                        </a>
                      </div>
                    )}
                  </div>
                )}
                <div className={s.scoreCardsRight}>
                  <div
                    className={`${s.scoreCardsSectionTitle} ${s.scoreCardsSectionTitleWithAction}`}
                  >
                    <div
                      onClick={() => handleBackLevel()}
                      style={{ cursor: "pointer" }}
                    >
                      G+NE <span>Scorecards</span>
                      {levelScoreCard !== 1 && (
                        <span>
                          {" "}
                          <img
                            src="/img/icon-score-card/Icon Back.svg"
                            alt="Back"
                          />{" "}
                          Overview
                        </span>
                      )}{" "}
                    </div>
                    <div className={s.filterBlocks}>
                      <div className={s.filterBlock}>
                        <span>PERIOD</span>
                        <div className={`${s.filterBlockDropdown}`}>
                          <button
                            type="button"
                            style={{ width: "110px" }}
                            onClick={() =>
                              setShowPeriodFilter(!showPeriodFilter)
                            }
                          >
                            {makeText(dateFilterValue)}
                            <i className="mdi mdi-menu-down"></i>
                          </button>
                          <div className="scorecards-month-picker">
                            <Space direction="vertical">
                              <MonthPicker
                                disabledDate={disabledDate}
                                value={moment(
                                  `${dateFilterValue.year}-${dateFilterValue.month}`,
                                  "YYYY-MM"
                                )}
                                onChange={handleDateSelect}
                                open={showPeriodFilter}
                              />
                            </Space>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={s.scoreCardsRightTab}>
                    <ul>
                      {TAB_SCORE_CARD_CONFIG.map((v, i) => {
                        return (
                          <li
                            key={i}
                            onClick={() => {
                              handleChangeTab(v.value);
                            }}
                            className={
                              (sectionIdActive === v.value ? s.active : "") +
                              (levelScoreCard === 2 &&
                              v.value === TAB_SCORE_CARD.HSSE_PERFORMANCE
                                ? s.disableTabLi
                                : "")
                            }
                          >
                            {v.label}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className={s.scoreCardsRightTabContent}>
                    {sectionIdActive === TAB_SCORE_CARD.HSSE_PERFORMANCE && (
                      <HSSEPerformanceComponent />
                    )}
                    {sectionIdActive ===
                      TAB_SCORE_CARD.FINANCIAL_EXCELLENCE && (
                      <FinancialExcellenceComponent />
                    )}
                    {sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE && (
                      <PlantPerformanceComponent />
                    )}
                    {sectionIdActive ===
                      TAB_SCORE_CARD.PRODUCTION_GENERATION && (
                      <ProductionAndGenerationComponent />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ScrollContainer>
        </div>
      </Desktop>
      <Mobile>
        <div
          className={`score-carrds-page fullscreen-pt-0 ${sMobile.scoreCardsRoot}`}
        >
          <ScrollContainer
            className={`page-container bg-map ${sMobile.scoreCardsContainer}`}
            style={{ backgroundImage: "url(/img/scorecards_bg1.jpg)" }}
          >
            {levelScoreCard === 1 && (
              <>
                <div className={sMobile.filterBlocks}>
                  <div className={sMobile.filterBlock}>
                    <span>PERIOD</span>
                    <div className={`${s.filterBlockDropdown}`}>
                      <button
                        type="button"
                        style={{ width: "110px" }}
                        onClick={() => setShowPeriodFilter(!showPeriodFilter)}
                      >
                        {makeText(dateFilterValue)}
                        <i className="mdi mdi-menu-down"></i>
                      </button>
                      <div className="scorecards-month-picker">
                        <Space direction="vertical">
                          <MonthPicker
                            disabledDate={disabledDate}
                            value={moment(
                              `${dateFilterValue.year}-${dateFilterValue.month}`,
                              "YYYY-MM"
                            )}
                            onChange={handleDateSelect}
                            open={showPeriodFilter}
                          />
                        </Space>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={sMobile.scoreCardsRightTab}>
                  <ul>
                    {TAB_SCORE_CARD_CONFIG.map((v, i) => {
                      return (
                        <li
                          key={i}
                          onClick={() => {
                            handleChangeTab(v.value);
                          }}
                          className={
                            (sectionIdActive === v.value
                              ? sMobile.active
                              : "") +
                            (levelScoreCard === 2 &&
                            v.value === TAB_SCORE_CARD.HSSE_PERFORMANCE
                              ? sMobile.disableTabLi
                              : "")
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleChangeTab(v.value);
                            }
                          }}
                          tabIndex={0}
                        >
                          {v.label}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            )}
            {levelScoreCard === 1 && (
              <>
                <div className={sMobile.scoreCardsLeft}>
                  <div>
                    <div className={sMobile.scoreCardsSectionTitle}>
                      Executive <span>Summary</span>
                    </div>
                    <div
                      className={sMobile.viewSummary}
                      onClick={handleViewSummary}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleViewSummary();
                        }
                      }}
                      tabIndex={0}
                    >
                      VIEW FULL
                    </div>
                  </div>
                  <div
                    className={
                      viewSummary
                        ? sMobile.scoreCardsLeftListViewFull
                        : sMobile.scoreCardsLeftListViewLess
                    }
                  >
                    {executiveSummary && (
                      <ExecutiveSummaryComponent data={executiveSummary} />
                    )}
                  </div>
                </div>
                <div
                  className={`${sMobile.scoreCardsSectionTitle} ${sMobile.scoreCardsSectionTitleWithAction}`}
                >
                  <div
                    onClick={() => handleBackLevel()}
                    style={{ cursor: "pointer" }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleBackLevel();
                      }
                    }}
                    tabIndex={0}
                  >
                    G+NE <span>Scorecards</span>
                  </div>
                </div>
              </>
            )}
            {levelScoreCard !== 1 && (
              <div
                className={sMobile.titleScoreCardLv2}
                onClick={() => handleBackLevel()}
                style={{ cursor: "pointer" }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleBackLevel();
                  }
                }}
                tabIndex={0}
              >
                {sectionIdActive === TAB_SCORE_CARD.HSSE_PERFORMANCE && (
                  <div>
                    <img src="/img/icon-score-card/Icon Back.svg" alt="Back" />{" "}
                    <p>HSSE</p> <span>Performance</span>
                  </div>
                )}
                {sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE && (
                  <div>
                    <img src="/img/icon-score-card/Icon Back.svg" alt="Back" />{" "}
                    <p>Financial</p> <span>Excellence</span>
                  </div>
                )}
                {sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE && (
                  <div>
                    <img src="/img/icon-score-card/Icon Back.svg" alt="Back" />{" "}
                    <p>Plant</p> <span>Performance</span>
                  </div>
                )}
                {sectionIdActive === TAB_SCORE_CARD.PRODUCTION_GENERATION && (
                  <div>
                    <img src="/img/icon-score-card/Icon Back.svg" alt="Back" />{" "}
                    <p>Production & Generation</p>
                  </div>
                )}
              </div>
            )}{" "}
            <div className={sMobile.scoreCardsRightTabContent}>
              {sectionIdActive === TAB_SCORE_CARD.HSSE_PERFORMANCE && (
                <HSSEPerformanceComponent />
              )}
              {sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE && (
                <FinancialExcellenceComponent isBigCarLv2={isBigCarLv2} />
              )}
              {sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE && (
                <PlantPerformanceComponent />
              )}
              {sectionIdActive === TAB_SCORE_CARD.PRODUCTION_GENERATION && (
                <ProductionAndGenerationComponent />
              )}
            </div>
          </ScrollContainer>
        </div>
      </Mobile>
    </>
  );
};
export default ScoreCardsComponent;

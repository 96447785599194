import React from 'react';
import PropTypes from 'prop-types';
import s from './GHGTimestamp.module.css';


const formatIsoDatetime = (isoDateTime) => {
    if (!isoDateTime) return 'N/A';

    const date = new Date(isoDateTime);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;

    return `Last updated on ${day} ${month} ${year} ${formattedHours}:${formattedMinutes} ${period}`;
};

    
export const PiTagLegend = ({ param, ghgData }) => {
    if (
        typeof param.location !== 'string' || !['1', '2', '3', '4', '5', '6', '7'].includes(param.location)
    ) {
        return null;
    }

    return (
        <div className={s.timeStamp}>
            {['2', '3', '4', '5', '6', '7'].includes(param.location) && (
                <div>
                    <img src='/img/icon/ic-time.svg' alt='time' />
                    <span>{ghgData?.lastUpdated ? formatIsoDatetime(ghgData?.lastUpdated) : 'N/A'}</span>
                </div>
            )}
        </div>
    );    
};

export default PiTagLegend;
import s from '../Map/map.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { mapModalChange } from '../../action/actionMap';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

const MapScrollComponent = () => {
    const callOutData = useSelector((state) => get(state, "callout.calloutData", null));
    const highlightData = useSelector((state) => get(state, "callout.highlightData", []));
    const deliverHistoryData = useSelector((state) => get(state, "callout.deliverHistoryData", []));
    const dispatch = useDispatch();
    function scrollInfo() {
        switch (callOutData?.tabsInfo?.scrollInfo?.scrollType) {
            case "HG":
                return HGScrollInfo();
            case "DO":
                return DOScrollInfo();
            case "DD":
                return DDScrollInfo();
            default:
                break;
        }
    }
    function locationComponent(text) {
        return <div className={s.tableRowLocation}>
            <div><img src={text.imageUrl !== "" ? text.imageUrl : '/img/icon/Blank Flag.svg'} alt="Flag" /></div>
            <div className={s.locationText}><span>{text.sublabel}</span></div></div>
    }

    function getSectionHeading() {        
        let sectionHeading = 'DELIVERY HISTORY';

        if(callOutData && callOutData.sectionHeading)
        {
            sectionHeading = callOutData.sectionHeading;
        }
        return sectionHeading;
    }

    function getHighlightsSectionHeading() {        
        let sectionHeading = 'HIGHLIGHTS';

        if(callOutData && callOutData.highlightsSectionHeading)
        {
            sectionHeading = callOutData.highlightsSectionHeading;
        }
        return sectionHeading;
    }

    function HGScrollInfo() {
        return (
            <>
                <div className={s.scrollInfoTitle}>
                    <div className={s.pointNote}>{getHighlightsSectionHeading()}</div>
                    <div className={s.pointNote}>{moment(highlightData?.lastUpdated).format('D MMM YYYY [at] HH:mm A')}</div>
                </div>
                <div className={s.pointNoteDetail}>
                    {highlightData?.result?.highlightTextArr?.length > 0 ? <ul>
                        {highlightData?.result?.highlightTextArr.map((value, i) => (<li key={i}>{value}</li>))}
                    </ul> : <span className={s.noHighlight}>No highlights available</span>}

                </div>
            </>
        )
    }
    function DOScrollInfo() {
        const templateTypeId: number | null = callOutData?.typeId;
        let destinationColTitle = "DESTINATION";

        if (templateTypeId && templateTypeId === 5) {
            // RGTSU
            destinationColTitle = 'ORIGIN/DESTINATION';
        }

        const columns: any[] = [
            {
                title: 'VESSEL',
                dataIndex: 'vessel',
                render: text => <div className={s.tableRow}><span>{text.label}</span><br /><span>{text.sublabel}</span></div>,
                sorter: (a, b) => `${a?.vessel?.label}${a?.vessel?.sublabel}`.localeCompare(`${b?.vessel?.label}${b?.vessel?.sublabel}`)
            },
            {
                title: destinationColTitle,
                dataIndex: 'location',
                render: text => locationComponent(text),
                sorter: (a, b) => `${a?.location?.label}${a?.location?.sublabel}`.localeCompare(`${b?.location?.label}${b?.location?.sublabel}`)
            }
        ];
        let data = [...deliverHistoryData]
        data.length = 20;

        return (
            <>
                <div className={s.scrollInfoTitle}>
                    <div className={s.pointNote}>{getSectionHeading()}</div>
                    <div role="button" className={s.pointNoteModal} onClick={() => dispatch(mapModalChange(true))}>See full info {'>'}</div>
                </div>
                <div className={s.scrollInfoAntDesignTable}>
                    <Table rowKey={record => uuidv4()} className="scrollInfoTable" columns={columns} dataSource={data} pagination={false} showSorterTooltip={false} scroll={{ y: "33vh" }} />
                </div>
            </>
        )
    }

    function DDScrollInfo() {
        const columns: any[] = [
            {
                title: 'VESSEL',
                dataIndex: 'vessel',
                render: text => <div className={s.tableRow}><span>{text.label}</span><br /><span>{text.sublabel}</span></div>,
                sorter: (a, b) => `${a?.vessel?.label}${a?.vessel?.sublabel}`.localeCompare(`${b?.vessel?.label}${b?.vessel?.sublabel}`)
            },
            {
                title: 'DESTINATION',
                dataIndex: 'location',
                render: text => locationComponent(text),
                sorter: (a, b) => `${a?.location?.label}${a?.location?.sublabel}`.localeCompare(`${b?.location?.label}${b?.location?.sublabel}`)
            }
        ];
        let data = [...deliverHistoryData]
        data.length = 20;

        return (
            <>
                <div className={s.scrollInfoTitle}>
                    <div className={s.pointNote}>{getSectionHeading()}</div>
                    <div role="button" className={s.pointNoteModal} onClick={() => dispatch(mapModalChange(true))}>See full info {'>'}</div>
                </div>
                <div className={s.scrollInfoAntDesignTable}>
                    <Table rowKey={record => uuidv4()} className="scrollInfoTable" columns={columns} dataSource={data} pagination={false} showSorterTooltip={false} scroll={{ y: "33vh" }} />
                </div>
            </>
        )
    }


    return (<>{scrollInfo()}</>)
}
export default MapScrollComponent;
import * as types from '../action/actionType';

export const INITIAL_STATE = {
    totalRequestStart: 0,
    totalRequestFinish: 0,
};

export function loadingReducer(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case types.SEND_A_REQUEST: {
            return {
                ...state,
                totalRequestStart: state.totalRequestStart + 1
            }
        }
        case types.FINISH_A_REQUEST: {
            return {
                ...state,
                totalRequestFinish: state.totalRequestFinish + 1
            }
        }
    }
    return state;
}
import { HIS_TREND_CHART_DATA_SUCCEEDED } from './../action/actionType';
import { Action } from 'redux';
import * as types from '../action/actionType';

export const INITIAL_STATE = {
    isLoading: true,
    isCalloutDataLoading: true,
    isModalVisible: false,
    calloutData: {
        tabsInfo: { secondaryInfo: { dataFields: [] } },
    },
    sustainabilityCalloutData: null,
    highlightData: {},
    deliverHistoryData: [],
    berthingData: [],
    isOpenHisTrendModal: false,
    hisTrendData: {},
    hisIsLoading:false,
    isOpenCallOut: false
};
interface CalloutAction extends Action {
    data: string | any;
    payload: any;
}

export function calloutReducer(state = INITIAL_STATE, action: CalloutAction) {
    switch (action.type) {
        case types.MAP_MODAL_CHANGE: {
            return {
                ...state,
                isModalVisible: action.data
            }
        }
        case types.MAP_CALLOUT_FETCH_REQEUSTED: {
            return {
                highlightData: {},
                deliverHistoryData: [],
                isCalloutDataLoading: true
            }
        }
        case types.MAP_CALLOUT_FETCH_SUCCEEDED: {
            return {
                ...state,
                calloutData: action?.payload.result,
                isCalloutDataLoading: false
            }
        }
        case types.MAP_CALLOUT_FETCH_FAILED: {
            return {
                ...state,
                calloutData: {},
                isLoading: false,
                isCalloutDataLoading: false
            }
        }
        case types.SUSTAINABILITY_CALLOUT_FETCH_REQUESTED: {
            return {
                isCalloutDataLoading: true
            }
        }
        case types.SUSTAINABILITY_CALLOUT_FETCH_SUCCEEDED: {
            return {
                ...state,
                sustainabilityCalloutData: action?.payload?.result,
                lastUpdated: action?.payload?.lastUpdated,
                isCalloutDataLoading: false,
            }
        }
        case types.SUSTAINSIBILITY_CALLOUT_REQUEST: {
            return {
              ...state,
              isOpenCallOut: true
            }
        }
        case types.SUSTAINSIBILITY_CLOSE_CALLOUT_REQUEST: {
            return {
                ...state,
                isOpenCallOut: false
            }
        }
        case types.SUSTAINABILITY_CALLOUT_FETCH_FAILED: {
            return {
                ...state,
                sustainabilityCalloutData: action?.payload?.result,
                isCalloutDataLoading: false
            }
        }
        case types.MAP_CALLOUT_FETCH_REQUESTED_SEC: {
            return {
                highlightData: {},
                deliverHistoryData: [],
                isCalloutDataLoading: true
            }
        }
        case types.MAP_CALLOUT_FETCH_SUCCEEDED_SEC: {
            return {
                ...state,
                calloutData: action?.payload.result,
                isCalloutDataLoading: false
            }
        }
        case types.MAP_CALLOUT_FETCH_FAILED_SEC: {
            return {
                ...state,
                calloutData: {},
                isLoading: false,
                isCalloutDataLoading: false
            }
        }
        case types.MAP_HIGHLIGHT_FETCH_SUCCEEDED: {
            return {
                ...state,
                highlightData: action?.data,
            }
        }
        case types.MAP_HIGHLIGHT_FETCH_FAILED: {
            return {
                ...state,
                highlightData: {},
                isLoading: false
            }
        }
        case types.MAP_DELIVER_FETCH_SUCCEEDED: {
            return {
                ...state,
                deliverHistoryData: action?.data,
            }
        }
        case types.MAP_DELIVER_FETCH_FAILED: {
            return {
                ...state,
                deliverHistoryData: [],
                isLoading: false
            }
        }
        case types.CALLOUT_CARGO_DETAILS_SUCCEEDED: {
            return {
                ...state,
                berthingData: action?.data,
            }
        }
        case types.CALLOUT_CARGO_DETAILS_FAILED: {
            return {
                ...state,
                berthingData: [],
                isLoading: false
            }
        }
        case types.MAP_CHART_FETCH_REQUESTED: {
            return {
                ...state,
                isLoading: true,
                calloutData: {
                    ...state.calloutData,
                    tabsInfo: {
                        ...state.calloutData.tabsInfo,
                        secondaryInfo: {
                            ...state.calloutData.tabsInfo.secondaryInfo,
                            dataFields: []
                        }
                    }
                }
            }
        }
        case types.MAP_CHART_FETCH_SUCCEEDED: {
            return {
                ...state,
                isLoading: false,
                calloutData: {
                    ...state.calloutData,
                    tabsInfo: {
                        ...state.calloutData.tabsInfo,
                        secondaryInfo: {
                            ...state.calloutData.tabsInfo.secondaryInfo,
                            dataFields: action.data
                        }
                    }
                }
            }
        }
        case types.MAP_CHART_FETCH_FAILED: {
            return {
                ...state,
                isLoading: false
            }
        }
        case types.MAP_PRIMARY_FETCH_SUCCEEDED: {
            return {
                ...state,
                calloutData: {
                    ...state.calloutData,
                    tabsInfo: {
                        ...state.calloutData.tabsInfo,
                        primaryInfo: action.data
                    }
                }
            }
        }
        case types.MAP_PRIMARY_FETCH_FAILED: {
            return {
                ...state,
                isLoading: false
            }
        }
        case types.MAP_POINT_CLOSE_VIEW_DETAIL: {
            return {
                ...state,
                calloutData: {},
                sustainabilityCalloutData: null,
                highlightData: {},
                deliverHistoryData: []
            }
        }
        case types.HIS_TREND_CHART_DATA_REQUESTED: {
            return {
                ...state,
                isLoading: true,
                hisIsLoading: true,
            }
        }         
        case types.HIS_TREND_CHART_DATA_SUCCEEDED: {
            return {
                ...state,
                isLoading: false,
                hisTrendData: action.data,
                hisIsLoading: false,
            }
        }
        case types.HIS_TREND_CHART_DATA_FAILED: {
            return {
                ...state,
                isLoading: false,
                hisIsLoading: false,
            }
        }
        case types.OPEN_MODAL_HIS_TREND: {
            return {
                ...state,
                isOpenHisTrendModal: true,
            }
        }
        case types.CLOSE_MODAL_HIS_TREND: {
            return {
                ...state,
                isOpenHisTrendModal: false,
            }
        }
    }
    return state;
}



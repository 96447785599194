import * as types from "./actionType";

export const setShowFP = (payload: boolean) => ({
    type: types.SET_IS_SHOW_FP,
    payload
})

export const setForwardPriceData = (payload: any) => ({
    type: types.FP_GET_CHART_SUCCEEDED,
    payload
})

export const fetchFPChart = (payload: Date) => ({
    type: types.FP_GET_CHART_REQUESTED,
    data: payload
})

function transformInitData(datas) {
    let transformData: { key: number, actualValue: number, startValue: number, endValue: number, label: string, color: string }[] = [];
    for (let index = 0; index < datas.length; index++) {
        const data = datas[index];
        transformData.push({ key: index, actualValue: data.actualValue, startValue: data.startValue, endValue: data.endValue, label: data.label, color: data.color })
    }
    return transformData;
}

export function transformWaterfallChartLevel3(results) {
    let resultData = transformInitData(results);
    return { ...results, data: resultData }
}
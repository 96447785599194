import { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../action/actionType';
import { getMapData } from '../services/map.service';
interface MapAction extends Action {
  data: string | any;
}

function* fetchMapData(action: MapAction) {
  try {
    const data = yield call(getMapData, action.data);
    yield put({ type: types.MAP_DATA_FETCH_SUCCEEDED, payload: data });
  } catch (error: any) {
    yield put({ type: types.MAP_DATA_FETCH_FAILED, message: error.message });
  }
}

export function* workerFetchMapData() {
  yield takeLatest(types.MAP_DATA_FETCH_REQUESTED, fetchMapData);
}
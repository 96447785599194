import { Action } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as types from '../action/actionType';
import { STATUS } from '../constants/endpoints';
import { transformLimitWmTable } from '../factory/limitWmTable.factory';
import { IUserPersona } from '../model/user.model';
import { getLimitVCTable, getLimitWMTable, postLimitWMTable } from '../services/limitconfig.service';
import { getUserPersona, isSuperAdmin } from '../utils/user.utils';

interface LimitAction extends Action {
  payload: string | any;
  data: any;
}

function* fetchLimtiWMTable(action: LimitAction) {
  try {
    const userPersona: IUserPersona = getUserPersona();
    const getLimitWM =
      userPersona?.opuId && !isSuperAdmin() ? `/${userPersona?.opuId}` : "";
    const response = yield call(getLimitWMTable, getLimitWM);
    const transformWmTableData = transformLimitWmTable(response.result);
    yield put({
      type: types.LIMITCONFIG_GET_WM_TABLE_SUCCEEDED,
      payload: transformWmTableData,
    });
  } catch (error: any) {
    yield put({
      type: types.LIMITCONFIG_GET_WM_TABLE_FAILED,
      message: error.message,
    });
  }
}

function* fetchLimtitVCTable(action: LimitAction) {
  try {
    const userPersona: IUserPersona = getUserPersona();
    const userOpuId =
      userPersona?.opuId && !isSuperAdmin() ? `/${userPersona?.opuId}` : "";
    const response = yield call(getLimitVCTable, userOpuId);
    const transformWmTableData = transformLimitWmTable(response.result);
    yield put({
      type: types.LIMITCONFIG_GET_VC_TABLE_SUCCEEDED,
      payload: transformWmTableData,
    });
  } catch (error: any) {
    yield put({
      type: types.LIMITCONFIG_GET_VC_TABLE_FAILED,
      message: error.message,
    });
  }
}

function* fetchUpdateRules(action: LimitAction) {
  try {
    const response = yield call(postLimitWMTable, action.data);
    if (response.status === STATUS.UPDATE) {
      if (action.data.type === "WM") {
        yield put({
          type: types.LIMITCONFIG_GET_WM_TABLE_REQUESTED,
        });
      } else {
        yield put({
          type: types.LIMITCONFIG_GET_VC_TABLE_REQUESTED,
        });
      }
    }
  } catch (error: any) {
    yield put({
      type: types.LIMITCONFIG_UPDATE_RULES_FAILED,
      message: error.message,
    });
  }
}

export function* workerLimitConfigData() {
  yield all([
    takeLatest(types.LIMITCONFIG_GET_WM_TABLE_REQUESTED, fetchLimtiWMTable),
    takeLatest(types.LIMITCONFIG_GET_VC_TABLE_REQUESTED, fetchLimtitVCTable),
    takeLatest(types.LIMITCONFIG_UPDATE_RULES_REQUESTED, fetchUpdateRules),
  ]);
}

import { Space, Spin, Switch } from "antd";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearFailedAction,
  deleteAnnouncement,
  fetchRCTable,
  setCurrentRecord,
  toggleAnnouncementForm,
  togglePublishStatus,
} from "../../../action/actionContentRelease";
import { CONFIRM_MODAL } from "../../../constants/content-release.constants";
import ModalConfirm from "../../ModalConfirm/ModalConfirm";
import CreateForm from "../CreateUpdateForm/CreateForm";
import UpdateForm from "../CreateUpdateForm/UpdateForm";
import s from "./ReleaseContentTableComponent.module.css";

const ReleaseContentTableComponent = (props: any) => {
  const dispatch = useDispatch();
  const [confirmKey, setConfirmKey] = useState<any>(null);
  const isTableLoading = useSelector((state) =>
    get(state, 'contentRelease.isRCTableLoading', false)
  );
  const tableData = useSelector((state) =>
    get(state, 'contentRelease.rcTableData.announcements', [])
  );
  const isOpenForm = useSelector((state) =>
    get(state, 'contentRelease.isOpenForm', false)
  );
  const currentRecord = useSelector((state) =>
    get(state, 'contentRelease.currentRecord')
  );
  const actionFailedMessage = useSelector((state) =>
    get(state, 'contentRelease.actionFailedMessage')
  );

  useEffect(() => {
    dispatch(fetchRCTable({ pageSize: 10000, pageNumber: 1 }));
  }, []);

  function saveRecord(record) {
    dispatch(setCurrentRecord(record));
  }

  function _theadColumn(theadText, column) {
    if (!column) {
      return (
        <th>
          <div>{theadText}</div>
        </th>
      );
    }
    return (
      <th>
        <div>{theadText}</div>
      </th>
    );
  }

  function _renderTableHead() {
    return (
      <>
        <tr>
          {_theadColumn('NO.', null)}
          {_theadColumn('TITLE CONTENT', 'titleContent')}
          {_theadColumn('PUBLISHER', 'publisher')}
          {_theadColumn('LAST UPDATED', 'lastUpdatedDate')}
          {_theadColumn('PUBLISH DATE', 'publishDate')}
          <th>{_theadColumn('PUBLISH', 'status')}</th>
        </tr>
      </>
    );
  }

  function _tbodyText(item, attr, idx) {
    let rowPublish = !item.status ? s.rowUnPublish : '';
    if (attr === 'no.') {
      return (
        <td className={rowPublish}>
          <div className={s.tbodyText}>{idx + 1}</div>
        </td>
      );
    }
    return (
      <>
        <td className={rowPublish}>
          <div className={s.tbodyText}>{item[attr]}</div>
        </td>
      </>
    );
  }

  function _tbodyTimeDate(item, attr, idx) {
    const rowPublish = !item.status ? s.rowUnPublish : '';
    const date = item[attr]
      ? moment(new Date(item[attr])).format('DD/MM/YYYY hh:mm A')
      : null;
    return (
      <>
        <td className={rowPublish}>
          <div className={s.tbodyText}>{date}</div>
        </td>
      </>
    );
  }

  function _tbodyPublish(item) {
    const status = {
      1: true,
      0: false,
    };
    let btnClass = status[item.status] ? s.switchOnBtn : s.switchOffBtn;
    return (
      <td className={`${s.switchBtn} ${btnClass} `}>
        <div>
          <Switch
            className={s.publishSwitch}
            checked={status[item.status]}
            checkedChildren='On'
            unCheckedChildren='Off'
            onChange={() => {
              saveRecord(item);
              setConfirmKey(CONFIRM_MODAL.PUBLISH.KEY);
            }}
          />
        </div>
      </td>
    );
  }

  function toggleStatus() {
    if (currentRecord) {
      dispatch(
        togglePublishStatus({
          id: currentRecord.announcementId,
          isPublish: currentRecord.status ? false : true,
        })
      );
    }
  }

  function deleteRecord() {
    if (currentRecord) {
      dispatch(
        deleteAnnouncement({
          id: currentRecord.announcementId,
        })
      );
    }
  }

  function _tbodyBtn(item) {
    const { isEdit } = item;
    const rowHighlight = isEdit ? s.rowHighlight : '';

    return (
      <td className={`${s.groupBtn} ${rowHighlight}`}>
        <div>
          <Space>
            <img
              onClick={() => {
                saveRecord(item);
                toggleForm(true);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  saveRecord(item);
                  toggleForm(true);
                }
              }}
              tabIndex={0}
              className={s.arrow}
              src='/img/icon/Edit(Green).svg'
              alt='arrow'
              />
            <img
              onClick={() => {
                saveRecord(item);
                setConfirmKey(CONFIRM_MODAL.DELETE.KEY);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  saveRecord(item);
                  setConfirmKey(CONFIRM_MODAL.DELETE.KEY);
                }
              }}
              tabIndex={0}
              className={s.arrow}
              src='/img/icon/Delete(Green).svg'
              alt='arrow'
            />
          </Space>
        </div>
      </td>
    );
  }

  function _renderTableBody() {
    const tableRows = tableData
    if (!tableRows?.length) {
      return (
        <div className={s.noDataMessage}>
          <img alt='no-data' src='/img/icon/table-no-data.svg' />
          <p>No content!</p>
          <p>Create new slide.</p>
        </div>
      );
    }
    return (
      <>
        {tableRows.map((item, idx) => (
          <tr key={idx}>
            {_tbodyText(item, 'no.', idx)}
            {_tbodyText(item, 'titleContent', idx)}
            {_tbodyText(item, 'publisher', idx)}
            {_tbodyTimeDate(item, 'lastUpdatedDate', idx)}
            {_tbodyTimeDate(item, 'publishDate', idx)}
            <td className={s.lastRow}>
              {_tbodyPublish(item)}
              {_tbodyBtn(item)}
            </td>
          </tr>
        ))}
      </>
    );
  }

  function _renderUserTableSearch() {
    return (
      <div className={s.userTableSearch}>
        <div>
          <button
            className={s.userTableSearchButton}
            onClick={() => {
              toggleForm(true);
            }}
            type='button'
          >
            CREATE NEW
          </button>
        </div>
      </div>
    );
  }

  function closeModal() {
    dispatch(clearFailedAction());
    setConfirmKey(null);
    saveRecord({});
  }

  function _renderConfirmModal() {
    if (confirmKey) {
      switch (confirmKey) {
        case CONFIRM_MODAL.PUBLISH.KEY: {
          return (
            <ModalConfirm
              message={CONFIRM_MODAL.PUBLISH.MESSAGE}
              type={CONFIRM_MODAL.PUBLISH.KEY}
              onConfirm={() => {
                toggleStatus();
              }}
              onClose={closeModal}
            />
          );
        }
        case CONFIRM_MODAL.DELETE.KEY: {
          return (
            <ModalConfirm
              message={CONFIRM_MODAL.DELETE.MESSAGE}
              type={CONFIRM_MODAL.DELETE.KEY}
              onConfirm={() => {
                deleteRecord();
              }}
              onClose={closeModal}
            />
          );
        }
        default: {
          return;
        }
      }
    }
  }

  function _renderCreateUpdateForm() {
    if (currentRecord?.announcementId) {
      return (
        <UpdateForm
          record={currentRecord}
          onClose={() => toggleForm(false)}
        />
      );
    }
    return <CreateForm onClose={() => toggleForm(false)} />;
  }

  function toggleForm(isOpen) {
    dispatch(toggleAnnouncementForm(isOpen));
    if (!isOpen) {
      saveRecord({});
    }
  }

  function _renderErrorMessage() {
    if (actionFailedMessage) {
      return (
        <ModalConfirm
          message={actionFailedMessage}
          type='info'
          noActions
          onClose={() => {
            dispatch(clearFailedAction());
          }}
        />
      );
    }
  }

  const isShowLoadingTable = isTableLoading ? (
    <Spin size='large' className='chartSpin' />
  ) : (
    _renderTableBody()
  )

  return (
    <>
      {isOpenForm ? (
        _renderCreateUpdateForm()
      ) : (
        <div className={s.userManagementPanel}>
          <div className={s.userManagementPanelContainer}>
            <h2>RELEASE CONTENT</h2>
            <div className={s.userManagementDiv} />
            <div className={s.userTable}>
              {_renderUserTableSearch()}
              <div className='table'>
                <table>
                  <thead>{_renderTableHead()}</thead>
                  <tbody>
                    {isShowLoadingTable}
                  </tbody>
                </table>
              </div>
            </div>
            {_renderConfirmModal()}
          </div>
        </div>
      )}
      {_renderErrorMessage()}
    </>
  );
};
export default ReleaseContentTableComponent;

import React, { useEffect } from 'react';
import { Spin } from "antd";
import Modal from 'antd/lib/modal/Modal';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { closeHisTrendChartModal, openHisTrendChartModal } from '../../../action/actionMap';
import HisTrendChart from './HistoricalTrendChart';
import s from './historical-trend.module.css';
import { createAxisAndSeries } from '../../../utils/chart.utils';

const ModalHistoricalTrends = (props: any) => {
  const dispatch = useDispatch();
  const { callOutData, isVisibleModal, data, lastUpdated } = useSelector(
    (state) => {
      const callout = get(state, 'callout', null);
      return {
        callOutData: callout.calloutData,
        isVisibleModal: callout.isOpenHisTrendModal,
        data: callout?.hisTrendData?.data,
        lastUpdated: callout?.hisTrendData?.lastUpdated,
      };
    }
  );

  const hisIsLoading = useSelector((state) => 
  get(state, "callout.hisIsLoading", false)
  );
 
  const handleCancel = () => {
    dispatch(closeHisTrendChartModal());
  };

  const CloseBtn = () => <img alt='close-icon' src='/img/icon/close-btn.svg' />;

  return (
      <Modal
        className='historicalTrendModal'
        visible={isVisibleModal}
        footer={null}
        onCancel={handleCancel}
        width='60%'
        centered={true}
        zIndex={1000}
        closeIcon={<CloseBtn />}
        maskClosable={false}
      >
        <div className={s.modalHeader}>
          <p>Demand by industrial sector</p>
          <p>Historical Trend</p>
        </div>

        <div className={s.chartContainer}>
          {hisIsLoading ? (
            <Spin size="large" className="chartSpin" />
          ) : (
            <HisTrendChart />
          )}
        </div>

        <div className={s.modalFooter}>
          <span>
            <img alt='time' src='\img\icon\ic-time.svg' />
            &nbsp; {moment(lastUpdated).format('DD MMM YYYY H:mm A')}
          </span>
        </div>
      </Modal>
  );
};

export default ModalHistoricalTrends;

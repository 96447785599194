import { Modal } from 'antd';
import { get } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useSelector, useDispatch } from 'react-redux';
import {
  SESSION_STORAGE_KEY,
  USER_NAME_MAPPING,
} from '../../constants/user.constants';

//For non functional header component
import PlainHeaderComponent from '../Header/PlainHeaderComponent';

import { signOut } from '../../AuthService';
import {
  fetchAdminsUserAccess,
  fetchOPUsUserAccess,
  submitUserAccess,
} from '../../action/actionUsers';
import s from './ModalAuthComponent.module.css';

export default function ModalAuthComponent(): ReactElement {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) =>
    get(state, 'privateroute.isAuth', null)
  );
  const opuList = useSelector((state) => get(state, 'user.opusUserAccess', []));
  const adminList = useSelector((state) =>
    get(state, 'user.adminsUserAccess', [])
  );
  const imageAvatar = useSelector((state) => get(state, 'user.image'));
  const userProperties = useSelector((state) =>
    get(state, 'user.user_properties')
  );
  const responseAlert = useSelector((state) =>
    get(state, 'user.responseAlert')
  );
  const isRequesting = useSelector((state) =>
    get(state, 'user.isRequesting')
  );
  const [image, setImage] = useState('/img/avatar.png');
  const [userName, setUserName] = useState('Go User');
  const [opu, setOPU] = useState<any>();
  const [systemAdmin, setSystemAdmin] = useState<any>();
  const [reason, setReason] = useState('');
  const [isValidated, setValidate] = useState(false);
  const isAuthRequired =
    (isAuth !== null && isAuth !== 'error') || isAuth === 'Unauthorized';
  useEffect(() => {
    if (imageAvatar) {
      const reader = new FileReader();
      reader.readAsDataURL(imageAvatar);
      reader.onload = () => storeImage(reader.result as string);
    }
  }, [imageAvatar]);

  function formatName(name: string) {
    const charIndex: number = name.indexOf('(', 0);
    return name.substring(0, charIndex - 1);
  }

  useEffect(() => {
    let userName: string = '';
    if (userProperties) {
      const domainBankPattern = /pethlab|pethstag|dev.petronas|stag.petronas/g;
      if (userProperties.mail.toLowerCase().match(domainBankPattern)) {
        const userNameMapping = USER_NAME_MAPPING;
        userName =
          userNameMapping.get(userProperties.mail.toLowerCase()) || 'Go User';
      } else {
        userName = formatName(userProperties.displayName);
      }
      setUserName(userName);
    }
  }, [userProperties]);

  useEffect(() => {
    if (isAuthRequired) {
      dispatch(fetchOPUsUserAccess());
      dispatch(fetchAdminsUserAccess());
    }
  }, [isAuth]);

  useEffect(() => {
    if (opu && systemAdmin && reason) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [opu, systemAdmin, reason]);

  function storeImage(imageString: string) {
    if (!sessionStorage.getItem(SESSION_STORAGE_KEY.USER_IMAGE)) {
      sessionStorage.setItem(SESSION_STORAGE_KEY.USER_IMAGE, imageString);
    }
    setImage(imageString);
  }

  function onSelectOPU(event: any) {
    const selectedOPU = opuList.find((o) => o.id === Number(event.target.value));
    setOPU(selectedOPU);
  }

  function onSelectAdmin(event: any) {
    const selectedAdmin = adminList.find(
      (a) => a.id === Number(event.target.value)
    );
    setSystemAdmin(selectedAdmin);
  }

  function onSubmit() {
    if (isValidated) {
      const userData = {
        userName: userProperties?.displayName,
        userEmail: userProperties?.mail,
        opuName: opu.name,
        opuId: opu.id,
        systemAdminEmail: systemAdmin.email,
        systemAdminName: systemAdmin.name,
        reason,
      };
      dispatch(submitUserAccess(userData));
    }
  }

  return (
    <div className={`fullscreen-pt-0 ${s.modalRoot}`}>
      <ScrollContainer className={`page-container bg-map ${s.modalContainer}`}>
        <div className={s.plainHeader}>
          <PlainHeaderComponent />
        </div>
        {isAuthRequired ? (
          <Modal
            className={'auth-modal'}
            visible={true}
            centered={true}
            mask={false}
            footer={null}
            closable={false}
            width={600}
          >
            <div className='auth-modal-container'>
              {!responseAlert?.isRequestSent ? (
                <>
                  <div className='auth-modal-alert'>
                    <div className='auth-modal-sub'>
                      <div className='auth-modal-alert-icon'>
                        <img src='/img/icon/Alert.svg' alt='Alert logo' />
                      </div>
                      <div className='auth-modal-alert-text'>
                        <span>ACCESS DENIED</span>
                        <span>
                          The page you’re trying to access has restricted
                          access.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={s.authAlertDetailText}>
                    <b>You Need Permission To Access This Site</b>
                    <p>
                      To request access, you need to enter the details as
                      follows:
                    </p>
                  </div>
                  <div className={s.authUserInfo}>
                    <img alt='avatar' src={image} />
                    <div className={s.userBasicInfo}>
                      <b>{userName}</b>
                      <span>{userProperties?.mail}</span>
                    </div>
                  </div>
                  <div className={s.authUserForm}>
                    <form>
                      <div className={s.authUserFormItem}>
                        <label>OPU</label>
                        <select
                          placeholder='Select OPU'
                          onChange={(event) => {
                            onSelectOPU(event);
                          }}
                        >
                          {!opu && (
                            <option disabled selected>
                              Select OPU
                            </option>
                          )}
                          {opuList.map((opu) => (
                            <option value={opu.id} key={opu.id}>
                              {opu.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={s.authUserFormItem}>
                        <label>ADMIN TO BE NOTIFIED</label>
                        <select
                          placeholder='Select Admin'
                          onChange={(event) => onSelectAdmin(event)}
                        >
                          {!systemAdmin && (
                            <option disabled selected>
                              Select Admin
                            </option>
                          )}
                          {adminList.map((admin) => (
                            <option value={admin.id} key={admin.id}>
                              {admin.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className={s.authUserFormItem}>
                        <label>REASON TO ACCESS</label>
                        <textarea
                          placeholder='I would like to access GO Dashboard because...'
                          value={reason}
                          onChange={(event) => setReason(event.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                  <div className='auth-modal-button'>

                    <span
                      className={
                        isValidated && !isRequesting
                          ? s.submitBtn
                          : `${s.submitBtn} ${s.disabled}`
                      }
                      onClick={onSubmit}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onSubmit();
                        }
                      }}
                      tabIndex={0}
                    >
                      Request access
                    </span>
                  </div>
                </>
              ) : (
                <div className='auth-modal-alert success'>
                  <div className='auth-modal-sub'>
                    <div className='auth-modal-alert-icon'>
                      <img src='/img/icon/Alert_Success.svg' alt='Alert logo' />
                    </div>
                    <div className='auth-modal-alert-text'>
                      <span>REQUEST SENT</span>
                      <span>You will be notified via email, Thank you.</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        ) : (
          <div className={`page-loading show`}>
            <img src='/img/spinner/spinner.gif' className="plus" alt="loading..." style={{ width: 70 }} />
          </div>
        )}
      </ScrollContainer>
    </div>
  );
}

import React, { Fragment } from 'react';
import DOMPurify from 'dompurify';

export const formatUnit = (unit) => {
    const regexUnit = new RegExp('(m3)')
    if (regexUnit.test(unit)) {
      unit = unit.replace(regexUnit, "m<sup>3</sup>")
    }
    const sanitizedUnit = DOMPurify.sanitize(unit);
    return <Fragment><span dangerouslySetInnerHTML={{__html: sanitizedUnit}} /></Fragment>;
  };
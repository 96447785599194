import * as msal from "@azure/msal-browser";

import { fetchUserImage, fetchUserName } from "./action/actionCreator";
import { SESSION_STORAGE_KEY } from "./constants/user.constants";
import { store } from "./store/store";
import { getUserUPN } from "./utils/user.utils";

const msalConfig = {
    auth: {
        validateAuthority: true,
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID + "/",
        redirectUri: getRedirectURI(),
        postLogoutredirectUri: getRedirectURI(),
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
        secureCookies: false
    },
    system: {
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
    }
}



function getRedirectURI() {
    let currentURL = window.location.href;
    let redirectURI;

    if (currentURL.includes("localhost")) {
        redirectURI = process.env.REACT_APP_REDIRECT_URI_LOCAL;
    }
    else if (currentURL.includes("cloudfront")) {
        redirectURI = process.env.REACT_APP_REDIRECT_URI_CF;
    }
    else {
        redirectURI = process.env.REACT_APP_REDIRECT_URI;
    }

    return redirectURI;
}

const myMSALObj = new msal.PublicClientApplication(msalConfig);

myMSALObj.handleRedirectPromise().then(handleResponse).catch();

export function handleResponse(resp) {
    if (resp !== null) {
        myMSALObj.setActiveAccount(resp.account);
        getAPIaccessToken();
        getGraphAccessToken();
        sessionStorage.setItem('userEmail', resp.account.idTokenClaims.email.toLowerCase());
        sessionStorage.setItem('userUPN', resp?.account?.idTokenClaims?.upn?.toLowerCase())
    } else {
        const currentAccounts = myMSALObj.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
            return;
        } else if (currentAccounts.length > 1) {
            // Add choose account code here
        } else if (currentAccounts.length === 1) {
            const activeAccount = currentAccounts[0];
            myMSALObj.setActiveAccount(activeAccount);
            getAPIaccessToken();
            getGraphAccessToken();
        }
    }
}

export async function signIn() {
    let loginRequest = {
        scopes: ["User.Read", "User.Read.All", "Directory.Read.All"]
    };

    sessionStorage.setItem('loginState', 'true')

    myMSALObj.loginRedirect(loginRequest);
}
const logoutChannel = typeof BroadcastChannel !== 'undefined' ? new BroadcastChannel("logout") : {}

export function signOut() {
    logoutChannel.postMessage({ logoutMessage: "logged out" });
    localStorage.clear()
    sessionStorage.clear()
    myMSALObj.logout();
}

export const logoutAllTabsEventListener = () => {
    logoutChannel.onmessage = event => {
        if (event.data.logoutMessage === "logged out") {
            signOut();
            logoutChannel.close();
        }
    };
};

export async function getAPIaccessToken() {
    let request = {
        scopes: ["api://" + process.env.REACT_APP_CLIENT_ID + "/GO-Dashboard"]
    };

    return myMSALObj.acquireTokenSilent(request).then(
        response => {
            sessionStorage.setItem(`${SESSION_STORAGE_KEY.API_ACCESS_TOKEN}-${process.env.REACT_APP_STAGE}`, response.accessToken)
            store.dispatch(fetchUserImage(getUserUPN()));
            store.dispatch(fetchUserName());
            return response.accessToken;
        },
    );

}

export async function refreshAPIaccessToken() {
    let request = {
        scopes: ["api://" + process.env.REACT_APP_CLIENT_ID + "/GO-Dashboard"]
    };

    return myMSALObj.acquireTokenSilent(request).then(
        response => {
            sessionStorage.setItem(`${SESSION_STORAGE_KEY.API_ACCESS_TOKEN}-${process.env.REACT_APP_STAGE}`, response.accessToken)
            return response.accessToken;
        },
    ).catch(() => {
        return null;
    });

}
/**
 * Get the Azure AD Access Token. Internally handle the token refresh mechanism using acquireTokenSilent
 * @returns Azure AD Access Token
 */
export const getAzureADToken = async () => {
    let request = {
        scopes: ["api://" + process.env.REACT_APP_CLIENT_ID + "/GO-Dashboard"]
    };

    try {
        const token = await myMSALObj.acquireTokenSilent(request);
        return token.accessToken;
    } catch (error) {
        if (error instanceof msal.InteractionRequiredAuthError) {
            return signIn();
        }
    }
};


export async function getGraphAccessToken() {
    let request = {
        scopes: ["User.Read", "User.Read.All", "Directory.Read.All"]
    };

    return myMSALObj.acquireTokenSilent(request).then(
        response => {
            sessionStorage.setItem(`${SESSION_STORAGE_KEY.GRAPH_ACCESS_TOKEN}-${process.env.REACT_APP_STAGE}`, response.accessToken)
            store.dispatch(fetchUserImage(getUserUPN()));
            store.dispatch(fetchUserName());
            return response.accessToken;
        },
    );
}

export function getAccount() {
    return myMSALObj.getAllAccounts();
}
import { VALUECHAIN_API_BASE_URL, ENDPOINTS } from "../constants/endpoints";
import axiosClient from "./axiosClient.service";
import { buildApiEndPoint, getOpuApi } from "../utils/Url.utils";
import { OPU } from "../constants/opu.constants";

export function getValueChain() {
  const opu = getOpuApi("Value Chain");
  const url = buildApiEndPoint(
    opu as string,
    VALUECHAIN_API_BASE_URL,
    ENDPOINTS.GET_VALUE_CHAIN_DATA,
    ENDPOINTS.GET_VALUE_CHAIN_DATA_OPU,
    [OPU.PFLNG, OPU.MLNG, OPU.LNGA]
  );
  return axiosClient.get(url);
}

export function getValueChainOverview() {
  const opu = getOpuApi("Value Chain");
  const url = buildApiEndPoint(
    opu as string,
    VALUECHAIN_API_BASE_URL,
    ENDPOINTS.GET_VALUE_CHAIN_OVERVIEW,
    ENDPOINTS.GET_VALUE_CHAIN_OVERVIEW_OPU,
    [OPU.PFLNG, OPU.MLNG, OPU.LNGA]
  );
  return axiosClient.get(url);
}

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

import React from 'react'
import s from './ChartComponent.module.css'
import { toFixedNumber } from '../../../utils/Number.utils'

interface Props {
  card?: any
  primaryCardInforDetail?: any
}
const CardSecondaryComponent = ({ card, primaryCardInforDetail }: Props) => {
  function _renderDeltaValue(value: number) {
    const formatValue = toFixedNumber(value < 0 ? Math.abs(value) : value, 2);
    return formatValue;
  }
  return (
    <div className={s.secondaryValueItem}>
      <div className={s.secondaryValueItemTitle}>{card.type}</div>
      <div className={s.secondaryValueItemUnit}>{card.unit}</div>
      <div className={s.secondaryValueLayout}>
        <div className={s.secondaryValueItemValue}>{card.value === 0 ? '-' : _renderDeltaValue(card.value)}</div>
        {
          primaryCardInforDetail.deltaValue && <div className={s.secondaryValueLayout}>
            <div className={s.secondaryDeltaValue}>
              <span>{_renderDeltaValue(primaryCardInforDetail.deltaValue.value)}</span>
              <span className={s.secondaryDeltaValueUnit}>&nbsp;{primaryCardInforDetail.deltaValue.unit}</span>
            </div>
            <div className={s.secondaryDeltaValueArrow}>
              {
                (primaryCardInforDetail.deltaValue.arrowDirection === 'up' ? (
                  <CaretUpOutlined style={{ position: "absolute", bottom: "6px" }} />
                ) : (
                  <CaretDownOutlined style={{ position: "absolute", bottom: "6px" }} />
                ))}
            </div>
          </div>
        }
      </div>
    </div>
  )
}
export default CardSecondaryComponent

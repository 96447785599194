import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';

import {
  mapPointCloseViewDetail,
  fetchCargoDetails,
  fetchHisTrendChartData
} from '../../action/actionMap';
import {
  Desktop,
  Mobile,
  Tablet,
  TabletSec,
} from '../../responsive/responesive';
import CargoInformation from './CargoInformation/CargoInformationSection';
import BerthingComponent from './Berthing/BerthingComponent';
import HisTrendChartModal from './HistoricalTrend/HistoricalTrendChartModal';
import PrimaryInfo from './PrimaryInfo/PrimaryInfo';
import PrimaryInfoMobile from './PrimaryInfo/PrimaryInfo.mobile';
import DemandValue from './DemandValue/DemandValue';
import Highlight from './Highlight/Highlight';
import Footer from './Footer/CalloutFooter';
import CargoDetailsModal from './CargoDetailsModal/CargoDetailsModal';
import s from './callout.module.css';
import sMobile from './callout.mobile.module.css';
import { CALLOUT_TYPE, CALLOUT_BERTH } from '../../constants/location.constants';

const CalloutComponent = () => {
  const selectedPoint = useSelector((state) =>
    get(state, 'map.selectedPoint', null)
  );
  const { callOutData, isCalloutDataLoading } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.calloutData,
      isCalloutDataLoading: callout.isCalloutDataLoading,
    };
  });
  const mapInfo = useSelector((state) => get(state, 'map.selectedPoint', null));

  const dispatch = useDispatch();

  const isCompactCalloutLayout = [CALLOUT_TYPE.RGT].includes(
    selectedPoint?.calloutTypeId
  );

  const isBerth = CALLOUT_BERTH.includes(selectedPoint?.locationId);

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(mapPointCloseViewDetail());
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (selectedPoint.calloutTypeId === 9) {
      if (selectedPoint.locationId === 14) {
        dispatch(fetchCargoDetails(selectedPoint.locationId, '', '', 'true'));
      }
      else if ([19, 67].includes(selectedPoint.locationId) || isBerth) {
        dispatch(fetchCargoDetails(selectedPoint.locationId, '', '3', 'false'));
      }
    }
    if (selectedPoint.locationId === 103) {
      dispatch(fetchHisTrendChartData(selectedPoint.locationId, '12hour'));
    }
  }, [selectedPoint]);

  const CloseButton = () => {
    function close() {
      dispatch(mapPointCloseViewDetail());
    }
    return (
      <button
        type='button'
        className={s.callOutCloseBtn}
        onClick={() => close()}
      >
        <img alt='Close' src='/img/icon/ic-close-secondary.svg' />
      </button>
    );
  };

  const calloutClassName = isCompactCalloutLayout
    ? `${s.calloutContent} ${s.compact}`
    : `${s.calloutContent}`;

  function setRightPanelClass(cssModule) {
    return `${mapInfo ? cssModule.show : ''}`;
  }

  function containerClassName(cssModule) {
    if ([CALLOUT_TYPE.RGT].includes(mapInfo?.calloutTypeId) && ![5, 7].includes(mapInfo?.locationId)) {
      return `${cssModule.container} ${cssModule.compact}  ${setRightPanelClass(
        cssModule
      )}`;
    }
    return `${cssModule.container}  ${setRightPanelClass(cssModule)}`;
  }

  return (
    <>
      <Desktop>
        <div className={containerClassName(s)}>
          <div className={calloutClassName}>
            <div className={s.calloutHeader}>
              <div className={s.headerTitle}>
                <div className={s.pointTitle}>
                  <span>{callOutData?.locationName}</span>
                </div>
              </div>
              <CloseButton />
            </div>
            <div className={s.callOutBody}> 
              {isCalloutDataLoading ? (
                <div className={s.calloutLoadingIconContainer}>
                  <Spin size='large' className='chartSpin' />
                </div>
              ) : (
                <>
                  <PrimaryInfo />
                  {isBerth ? <><br/></> : <Highlight />}
                  <DemandValue />
                  <CargoInformation />
                  <BerthingComponent isBerth={isBerth}/>
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </Desktop>
      <Tablet>
        <div className={containerClassName(sMobile)}>
          <div className={sMobile.calloutContent}>
            <div className={sMobile.calloutHeader}>
              <div className={sMobile.headerTitle}>
                <div className={sMobile.pointTitle}>
                  <span>{callOutData?.locationName}</span>
                </div>
              </div>
              <CloseButton />
            </div>
            <div className={sMobile.callOutBody}>
              {isCalloutDataLoading ? (
                <div className={sMobile.calloutLoadingIconContainer}>
                  <Spin size='large' className='chartSpin' />
                </div>
              ) : (
                <>
                  <PrimaryInfoMobile />
                  {isBerth ? <><br/></> : <Highlight />}
                  <DemandValue />
                  <CargoInformation />
                  <BerthingComponent />
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </Tablet>
      <Mobile>
        <div className={containerClassName(sMobile)}>
          <div className={sMobile.calloutContent}>
            <div className={sMobile.calloutHeader}>
              <div className={sMobile.headerTitle}>
                <div className={sMobile.pointTitle}>
                  <span>{callOutData?.locationName}</span>
                </div>
              </div>
              <CloseButton />
            </div>
            <div className={sMobile.callOutBody}>
              {isCalloutDataLoading ? (
                <div className={sMobile.calloutLoadingIconContainer}>
                  <Spin size='large' className='chartSpin' />
                </div>
              ) : (
                <>
                  <PrimaryInfoMobile />
                  {isBerth ? <><br/></> : <Highlight />}
                  <DemandValue />
                  <CargoInformation />
                  <BerthingComponent />
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </Mobile>
      <HisTrendChartModal />
      <CargoDetailsModal />
    </>
  );
};
export default CalloutComponent;

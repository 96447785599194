import React, { useEffect, useState, useRef } from 'react';

import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import {
  fetchSustainabilityCalloutData,
  mapPointCloseViewDetail
} from '../../action/actionMap';
import {
  Desktop,
  Mobile,
  Tablet
} from '../../responsive/responesive';
import Footer from './Footer/SustainabilityCalloutFooter';
import s from './callout.module.css';
import sMobile from './callout.mobile.module.css';
import SustainabilityPrimaryInfo from './PrimaryInfo/SustainabilityPrimaryInfo';
import EmissionBaseComponent from './Sustainability/EmissionBaseComponent';
import { toggleAnnouncementForm } from '../../action/actionContentRelease';
import { CALLOUT_DAILY_CUMULATIVE_OPTIONS, CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS } from '../../constants/location.constants';
import { useHistory } from 'react-router-dom';
import { fetchCallOutSustainability, fetchDisableCallOutSustainability } from '../../action/actionSustainability';

const SustainabilityCalloutComponent = (props) => {
  const {specificLocation} = props
  const dispatch = useDispatch();
  const { callOutData, isCalloutDataLoading, locationId, isOpenCallOut } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.sustainabilityCalloutData,
      isCalloutDataLoading: callout.isCalloutDataLoading,
      locationId: callout.sustainabilityCalloutData?.locationId,
      isOpenCallOut: callout.isOpenCallOut
    };
  });
  const [showDailyOptions, setShowDailyOptions] = useState(false);
  const [dailyOptionsIndex, setDailyOptionsIndex] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(mapPointCloseViewDetail());
    });

    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => { }, [callOutData])

  useEffect(() => {
    dispatch(toggleAnnouncementForm(false));
  }, [callOutData])

  const CloseButton = () => {
    function close() {
      dispatch(mapPointCloseViewDetail());
      dispatch(toggleAnnouncementForm(false));
      dispatch(fetchDisableCallOutSustainability())
      setShowDailyOptions(false)
      setDailyOptionsIndex(0);
    }
    return (
      <button
        type='button'
        className={s.callOutCloseBtn}
        onClick={() => close()}
      >
        <img alt='Close' src='/img/icon/ic-close-secondary.svg' />
      </button>
    );
  };

  const calloutClassName = `${s.calloutContent}`;

  const dailyOptions = CALLOUT_DAILY_CUMULATIVE_OPTIONS;

  function handleDailyOptions(option: string) {
    let isDaily;
    switch (option) {
      case CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS.HOURLY:
          isDaily = true;
          dispatch(fetchSustainabilityCalloutData(locationId, isDaily));
          dispatch(fetchCallOutSustainability())
        break;
        case CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS.CUMULATIVE:
          isDaily = false;
          dispatch(fetchSustainabilityCalloutData(locationId, isDaily));
          dispatch(fetchCallOutSustainability())
        break;
      default:
        break;
    }
    let selectedDailyOptionsIndex = dailyOptions.findIndex(v => v === option);
    setDailyOptionsIndex(selectedDailyOptionsIndex);
    setShowDailyOptions(false);
  };

  function setRightPanelClass(cssModule) {
    return `${isOpenCallOut ? cssModule.show : ''}`;
  }

  function containerClassName(cssModule) {
    return `${cssModule.sustainabilityCalloutContainer} ${setRightPanelClass(cssModule)}`;
  }

  function locationName() {
    return callOutData?.locationName;
  }

  function renderSourceFilter() {
    return (
      <div className={s.filterBlock}>
        <div className={`${s.filterBlockDropdown} ${showDailyOptions ? s.show : ''}`}>
          <button
            type="button"
            onClick={() => setShowDailyOptions(!showDailyOptions)}
          >
            {dailyOptions[dailyOptionsIndex]}
            <i className="mdi mdi-menu-down"></i>
          </button>
          <div className={s.filterBlockDropdownMenu}>
            <ul>
              <li>
                <button type="button" onClick={() => handleDailyOptions(CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS.HOURLY)}>
                  {CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS.HOURLY}
                </button>
              </li>
              <li>
                <button type="button" onClick={() => handleDailyOptions(CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS.CUMULATIVE)}>
                  {CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS.CUMULATIVE}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  const locationNameValue = locationName();
  const isLocationName = !["GT", "RGTSU", "RGTP"].includes(locationNameValue);
  return (
    <>
      <Desktop>
        <div className={containerClassName(s)}>
          <div className={calloutClassName}>
            <div className={s.calloutHeader}>
              <div className={s.headerTitle}>
                <div className={s.pointTitle}>
                  <span>{specificLocation}</span>
                </div>
                {/* <div>
                  {isLocationName && renderSourceFilter()}
                </div> */}
              </div>
              <CloseButton />
            </div>
            <div className={s.sustainabilityCallOutBody}>
              {isCalloutDataLoading ? (
                <div className={s.calloutLoadingIconContainer}>
                  <Spin size='large' className='chartSpin' />
                </div>
              ) : (
                <>
                  {/* Inherent CO2 */}
                  <SustainabilityPrimaryInfo />
                  {/* Emission Source & Summary */}
                  <EmissionBaseComponent />
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </Desktop>
      <Tablet>
        <div className={containerClassName(sMobile)}>
          <div className={sMobile.calloutContent}>
            <div className={sMobile.calloutHeader}>
              <div className={sMobile.headerTitle}>
                <div className={sMobile.pointTitle}>
                  <span>{callOutData ? callOutData?.locationName: 'N/A'}</span>
                </div>
              </div>
              <CloseButton />
            </div>
            <div className={sMobile.sustainabilityCallOutBody}>
              {isCalloutDataLoading ? (
                <div className={sMobile.calloutLoadingIconContainer}>
                  <Spin size='large' className='chartSpin' />
                </div>
              ) : (
                <>
                  {/* Inherent CO2 */}
                  <SustainabilityPrimaryInfo />
                  {/* Emission Source & Summary */}
                  <EmissionBaseComponent />
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </Tablet>
      <Mobile>
        <div className={containerClassName(sMobile)}>
          <div className={sMobile.calloutContent}>
            <div className={sMobile.calloutHeader}>
              <div className={sMobile.headerTitle}>
                <div className={sMobile.pointTitle}>
                  <span>{callOutData ? callOutData?.locationName: 'N/A'}</span>
                </div>
              </div>
              <CloseButton />
            </div>
            <div className={sMobile.sustainabilityCallOutBody}>
              {isCalloutDataLoading ? (
                <div className={sMobile.calloutLoadingIconContainer}>
                  <Spin size='large' className='chartSpin' />
                </div>
              ) : (
                <>
                  {/* Inherent CO2 */}
                  <SustainabilityPrimaryInfo />
                  {/* Emission Source & Summary */}
                  <EmissionBaseComponent />
                </>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </Mobile>
    </>
  );
};
export default SustainabilityCalloutComponent;

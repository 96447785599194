import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BigCardInforComponent from "./BigCard/BigCardInforComponent";
import BigCardInforComponentLevel2List from "./BigCardLv2/BigCardInforComponentLevel2List";
import { CaretDownOutlined } from "@ant-design/icons";
import ChartComponent from "./Chart/ChartComponent";
import MiniCardInforComponent from "./MiniCard/MiniCardInforComponent";
import MiniCardInforComponentLevel2 from "./MiniCard/MiniCardInforComponentLevel2";
import MiniCardInforItemComponent from "./MiniCard/MiniCardInforItemComponent";
import { Select } from "antd";
import { get } from "lodash";
import s from "./scorecards.module.css";
import sMobile from "./scorecardsResponsiveMobile.module.css";
import { setKeyListMiniCardDropdownLv2 } from "../../action/actionScoreCard";

const FinancialExcellenceComponent = (props: any) => {
  const financialExcellenceData = useSelector((state) =>
    get(state, "scoreCard.financialExcellenceData", [])
  );
  const financialExcellenceSmallCardInforData = useSelector((state) =>
    get(state, "scoreCard.smallCardInfor", [])
  );
  const levelScoreCard = useSelector((state) =>
    get(state, "scoreCard.levelScoreCard", 1)
  );
  const keyListDropDownMiniCardLv2 = useSelector((state) =>
    get(state, "scoreCard.keyListDropDownMiniCardLv2", 1)
  );

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(-1);
  const [currentData, setCurrentData] = useState([]);

  const [totalPage, setTotalPage] = useState(0);
  const [pageSize, setPageSize] = useState(6);

  const isBigCarLv2 = props.isBigCarLv2;

  useEffect(() => {
    if (
      financialExcellenceData?.parameters &&
      financialExcellenceData?.parameters.length > 0
    ) {
      const { paramCount, pageSize: page } = financialExcellenceData;
      setPageSize(page);
      initPage(page, paramCount, 0);
    }
  }, [financialExcellenceData]);

  function initPage(page, totalItems, cPage) {
    const totalPages =
      totalItems % page !== 0
        ? parseInt((totalItems / page).toString()) + 1
        : totalItems / page;
    const newData: any = financialExcellenceData?.parameters?.filter(
      (item, idx) => {
        return idx >= cPage * page && idx < cPage * page + page;
      }
    );

    setCurrentPage(cPage);
    setCurrentData(newData);
    setTotalPage(totalPages);
  }

  function pageChanged(isNextPage) {
    let cPage = currentPage;
    if (isNextPage) {
      if (cPage < totalPage - 1) cPage += 1;
    } else {
      if (cPage > 0) cPage -= 1;
    }
    const newData: any = financialExcellenceData?.parameters?.filter(
      (item, idx) => {
        return idx >= cPage * pageSize && idx < cPage * pageSize + pageSize;
      }
    );
    if (cPage !== currentPage) {
      setCurrentPage(cPage);
    }
    setCurrentData(newData);
  }

  const infoRowsLevel =
    financialExcellenceSmallCardInforData?.categories?.filter(
      (miniCards: any) => keyListDropDownMiniCardLv2 === miniCards.categoryKey
    )[0];

  const onChange = (value) => {
    const payload = {
      level: 2,
      key: value,
    };
    dispatch(setKeyListMiniCardDropdownLv2(payload));
  };

  function ScoreCardColComponent() {
    return (
      <>
        <div className={s.scoreCardsCol}>
          {currentData &&
            currentData.map((card, idx) => {
              return idx % 3 === 0 ? (
                <BigCardInforComponent key={idx} data={card} />
              ) : (
                ""
              );
            })}
        </div>
        <div className={s.scoreCardsCol}>
          {currentData &&
            currentData.map((card, idx) => {
              return idx % 3 === 1 ? (
                <BigCardInforComponent key={idx} data={card} />
              ) : (
                ""
              );
            })}
        </div>
        <div className={s.scoreCardsCol}>
          {currentData &&
            currentData.map((card, idx) => {
              return idx % 3 === 2 ? (
                <BigCardInforComponent key={idx} data={card} />
              ) : (
                ""
              );
            })}
        </div>
      </>
    );
  }

  function ScoreCardPageComponent(cssModule) {
    return (
      <div className={cssModule.scoreCardsPagination}>
        <button
          disabled={currentPage <= 0}
          type="button"
          onClick={() => pageChanged(false)}
        >
          <i className="mdi mdi-menu-left"></i>
        </button>
        <button
          disabled={currentPage >= totalPage - 1}
          type="button"
          onClick={() => pageChanged(true)}
        >
          <i className="mdi mdi-menu-right"></i>
        </button>
      </div>
    );
  }

  function MiniCardComponent(cssModule) {
    return (
      <div className={cssModule.miniCardsList}>
        <div className={cssModule.miniCardsList}>
          {financialExcellenceSmallCardInforData?.categories?.map(
            (card, idx) => {
              return <MiniCardInforComponent key={idx} data={card} />;
            }
          )}
        </div>
      </div>
    );
  }

  function _renderMobileTablet () {
    return (
      <>
      {levelScoreCard === 1 && (
        <div>
          <div>
            {levelScoreCard === 1 && ScoreCardPageComponent(sMobile)}
            {levelScoreCard === 1 && (
              <div className={sMobile.scoreCardsItems}>
                {ScoreCardColComponent()}
              </div>
            )}
          </div>
          {/* Mini card list */}
          {MiniCardComponent(sMobile)}
        </div>
      )}

      {levelScoreCard === 2 && (
        <div>
          {isBigCarLv2 === 1 ? (
            <BigCardInforComponentLevel2List
              listBigCard={financialExcellenceData?.parameters}
            />
          ) : (
            <>
              <Select
                defaultValue={infoRowsLevel?.categoryName}
                onChange={onChange}
                className={sMobile.selectedMiniCardlv2}
                bordered={false}
                suffixIcon={() => (
                  <div className={sMobile.iconDropdown}>
                    <CaretDownOutlined />
                  </div>
                )}
                dropdownStyle={{
                  background:
                    "linear-gradient(180deg, rgba(6,25,25,0.7) 0%, #181717ce 70%)",
                }}
              >
                {financialExcellenceSmallCardInforData?.categories?.map(
                  (card: any, idx) => {
                    return (
                      <Select.Option
                        key={idx}
                        className={sMobile.optionSelecte}
                        style={{ color: "#ffff" }}
                        value={card?.categoryKey}
                      >
                        {card?.categoryName}
                      </Select.Option>
                    );
                  }
                )}
              </Select>
              <div className={sMobile.miniCardsListLevel2}>
                {infoRowsLevel?.parameters.map((miniCard: any) => (
                  <MiniCardInforItemComponent
                    miniCard={miniCard}
                    key={miniCard.key}
                  />
                ))}
              </div>
            </>
          )}

          {levelScoreCard !== 1 && (
            <div className={sMobile.backGroundChart}>
              <ChartComponent tab="finacial"/>
            </div>
          )}
        </div>
      )
  }
  </>)}

  return (
    <>
      <Desktop>
        {/* Big card list */}
        {levelScoreCard === 1 && (
          <div>
            <div>
              {levelScoreCard === 1 && ScoreCardPageComponent(s)}
              {levelScoreCard === 1 && (
                <div className={s.scoreCardsItems}>
                  {ScoreCardColComponent()}
                </div>
              )}
            </div>
            {/* Mini card list */}
            {MiniCardComponent(s)}
          </div>
        )}

        {levelScoreCard === 2 && (
          <div>
            <BigCardInforComponentLevel2List
              listBigCard={financialExcellenceData?.parameters}
            />
            <div className={s.miniCardsListLevel2}>
              {financialExcellenceSmallCardInforData?.categories?.map(
                (card, idx) => {
                  return <MiniCardInforComponentLevel2 key={idx} data={card} />;
                }
              )}
            </div>
            <div className={s.backGroundChart}>
              <ChartComponent tab="finacial"/>
            </div>
          </div>
        )}
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>
    </>
  );
};

export default FinancialExcellenceComponent;

import StrategicCard from "./StrategicCard";
import s from "./strategicList.module.css";
import sc from "../scorecards.module.css";
const StrategicCardList = ({
  objectives,
  handleOpenHighlight,
  activeHighlight,
  category,
}) => {
  return (
    <div className={s.strategicList}>
      <div className={sc.scoreCardsCol}>
        {objectives &&
          objectives.length > 0 &&
          objectives.map((objecttive, idx) => {
            return idx % 3 === 0 ? (
              <StrategicCard
                key={idx}
                objecttive={objecttive}
                keyCard={`${category}-${idx}`}
                activeHighlight={activeHighlight}
                handleOpenHighlight={handleOpenHighlight}
              />
            ) : (
              ""
            );
          })}
      </div>
      <div className={sc.scoreCardsCol}>
        {objectives &&
          objectives.length > 0 &&
          objectives.map((objecttive, idx) => {
            return idx % 3 === 1 ? (
              <StrategicCard
                key={idx}
                objecttive={objecttive}
                keyCard={`${category}-${idx}`}
                activeHighlight={activeHighlight}
                handleOpenHighlight={handleOpenHighlight}
              />
            ) : (
              ""
            );
          })}
      </div>
      <div className={sc.scoreCardsCol}>
        {objectives &&
          objectives.length > 0 &&
          objectives.map((objecttive, idx) => {
            return idx % 3 === 2 ? (
              <StrategicCard
                key={idx}
                objecttive={objecttive}
                keyCard={`${category}-${idx}`}
                activeHighlight={activeHighlight}
                handleOpenHighlight={handleOpenHighlight}
              />
            ) : (
              ""
            );
          })}
      </div>
    </div>
  );
};

export default StrategicCardList;

import React from 'react'
import s from './ChartComponent.module.css'
import { toFixedNumber } from '../../../utils/Number.utils'

interface Props {
  card?: any,
  onclickBreakdown?: any,
}
const CardBreakdownCardComponent = ({ card, onclickBreakdown }: Props) => {
  function _renderDeltaValue(value: number) {
    const formatValue = toFixedNumber(value < 0 ? Math.abs(value) : value, 1);
    return formatValue;
  }
  return (
    <>
      <div key={card.key} className={s.cardThirdValueItem} onClick={() => { onclickBreakdown(card.key) }}>
        <div className={s.cardThirdValueItemTitle} >{card.title}</div>
        <div className={s.cardThirdValueItemUnit}>{card.unit}</div>
        <div className={s.cardThirdValueItemValue}>{_renderDeltaValue(card.value)}</div>
      </div>
    </>
  )
}

export default CardBreakdownCardComponent

import Modal from "antd/lib/modal/Modal";
import { get } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFPChart, setShowFP } from "../../../action/actionForwardPrice";
import s from "./ModalForwardPriceComponent.module.css";
import ForwardPriceChartV2Component from "../ForwardPriceChart/ForwardPriceChartV2Component";

const ModalForwardPriceComponent = () => {
  const dispatch = useDispatch();

  const isShowForwardPrice = useSelector((state) =>
    get(state, "forwardPrice.isShowForwardPrice", false)
  );
  const forwardPriceData = useSelector((state) =>
    get(state, "forwardPrice.forwardPriceData", false)
  );

  useEffect(() => {
    if (isShowForwardPrice) {
      dispatch(fetchFPChart(moment().toDate()));
    }
  }, [isShowForwardPrice]);
  const handleCancel = () => {
    dispatch(setShowFP(false));
  };


  return (
    <>
      <Modal
        className="forwardPriceModal"
        visible={isShowForwardPrice}
        footer={null}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        width={1060}
      >
        <div className={s.header}>
          <p>Price Movement and Outlook</p>
          <img
            alt='Close'
            src='/img/icon/close-btn.svg'
            onClick={() => dispatch(setShowFP(false))}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                dispatch(setShowFP(false));
              }
            }}
            tabIndex={0}
          />
        </div>
        <div className={s.divBorder} />
        <ForwardPriceChartV2Component forwardPriceData={forwardPriceData} />
      </Modal>
    </>
  );
};
export default ModalForwardPriceComponent;

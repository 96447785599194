import * as types from "./actionType";

export const fetchUsersManagement = (data) => ({
  type: types.USERMANAGEMENT_GET_USERS_REQUESTED,
  data
});

export const fetchOpus = () => ({
  type: types.USERMANAGEMENT_GET_OPUS_REQUESTED,
});

export const fetchRoles = () => ({
  type: types.USERMANAGEMENT_GET_ROLES_REQUESTED,
});

export const fetchPersonas = () => ({
  type: types.USERMANAGEMENT_GET_PERSONAS_REQUESTED,
});

export const fetchUpdateUser = (data) => ({
  type: types.USERMANAGEMENT_PUT_USER_REQUESTED,
  data: data,
});

export const fetchCreateUser = (data) => ({
  type: types.USERMANAGEMENT_POST_USER_REQUESTED,
  data: data,
});

export const fetchDeleteUser = (data) => ({
  type: types.USERMANAGEMENT_DELETE_USER_REQUESTED,
  data: data,
});

export const fetchWMTable = () => ({
  type: types.USERMANAGEMENT_PERSONA_WM_REQUESTED,
});

export const fetchVCTable = () => ({
  type: types.USERMANAGEMENT_PERSONA_VC_REQUESTED,
});

export const fetchSCTable = () => ({
  type: types.USERMANAGEMENT_PERSONA_SC_REQUESTED,
});

export const fetchMFTTable = () => ({
  type: types.USERMANAGEMENT_PERSONA_MFT_REQUESTED,
});

export const fetchAddMapList = () => ({
  type: types.USERMANAGEMENT_ADD_MAP_LIST_REQUESTED,
});

export const fetchUpdatePermission = (data) => ({
  type: types.USERMANAGEMENT_UPDATE_PERMISSION_REQUESTED,
  data: data,
});

export const fetchPersonaVCList = () => ({
  type: types.USERMANAGEMENT_PERSONVCLIST_REQUESTED,
});

export const fetchPersonaSCList = () => ({
  type: types.USERMANAGEMENT_PERSONSCLIST_REQUESTED,
});

export const fetchPersonaMFTList = () => ({
  type: types.USERMANAGEMENT_PERSONMFTLIST_REQUESTED,
});

export const fetchUpdatePersona = (data) => ({
  type: types.USERMANAGEMENT_UPDT_PERSONA_REQUESTED,
  data: data,
});

export const fetchUsersDownload = () => ({
  type: types.USERMANAGEMENT_DOWNLOAD_REQUESTED,
});

export const fetchUsersStatus = () => ({
  type: types.USERMANAGEMENT_GET_STATUS,
});

export const reInitUserTableStatus = () => ({
  type: types.REINITIALIZE_USERTABLE_STATUS,
});


export const setActiveButton = (buttonPath) => ({
  type: types.SET_ACTIVE_BUTTON,
  data: buttonPath,
})
import { Col, Modal, Row } from "antd";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeScorecardDetailsModal, fetchScoreCardGnePerformanceSecondaryCardDetail } from "../../../action/actionScoreCard";
import s from './PerformanceDetailsModal.module.css'

const PerformanceDetailsModal = () => {
	const dispatch = useDispatch();

	const { isVisibleModal, secondaryCardDetail } = useSelector(
		(state) => {
			const scoreCard = get(state, 'scoreCard', null);
			return {
				isVisibleModal: scoreCard.isOpenScorecardDetailsModal,
				secondaryCardDetail: scoreCard.secondaryCardDetail,
			};
		}
	);
	
	const handleCancel = () => {
		dispatch(closeScorecardDetailsModal());
	};

	const CloseButton = () => (
		<img alt='close-icon' src='/img/icon/close-btn.svg' />
	);

  function getCardColor(cardName, actual, target) {
    if (cardName != 'Third Party Purchased') {
      if (actual > target) return 'green';
      return 'red';
    }
    else {
      if (actual > target) return 'red';
      return 'green';
    }
  }

	return (
		<>
			<Modal
				className='scorecardSecondaryModal'
				visible={isVisibleModal}
				footer={null}
				onCancel={handleCancel}
				zIndex={1000}
				closeIcon={<CloseButton />}
				maskClosable={false}
				transitionName=""
			>
				<div className={s.modalHeader}>
					<p>Details</p>
				</div>

				<div className={s.scoreCardsCol}>
					<Row gutter={[5, 8]}>
						{secondaryCardDetail?.parameters && secondaryCardDetail.parameters.length > 0 ? ( 
							secondaryCardDetail.parameters.map((card) => {
								return (
									<Col span={12}>
										<div className={s.cardContainer}>
											<div className={s.cardInner}>
												<div className={s.cardPrimarySection}>
													<div className={`${s.statusBar} ${s[card.values[0].color]}`} />
													<div className={s.primaryInfo}>
														<div className={s.headline}>
															<span>{card.name}</span>
														</div>
														<div className={s.primaryValue}>
															<div className={s.primaryNumber} >
																<span>{card.values[0].actualValue.value}</span>
															</div>
															<div className={s.unit}>
																<span>{card.values[0].actualValue.unit}</span>
															</div>
														</div>
														<div className={s.comparison}>
															<span className={s.comparisonText}>
															{card.values[0].secondaryValue?.value == null 
																? "No Target Set" 
																: card.values[0].actualValue.value > card.values[0].secondaryValue.value ? "Higher than" : "Lower than"
															}
															</span>
															&nbsp;&nbsp;
															<span className={s.comparisonNumber}>{card.values[0].secondaryValue.value}</span>
														</div>
													</div>
												</div>
												<div className={s.cardSecondarySection}>
													<div className={s.YEP}>
														<div className={s.subLabel}>YEP</div>
														<div className={s.subNumber}>{card.values[0].projectionValues[0].value}</div>
													</div>
													<div className={s.YET}>
														<div className={s.subLabel}>YET</div>
														<div className={s.subNumber}>{card.values[0].projectionValues[1].value}</div>
													</div>
													<div className={s.SPLY}>
														<div className={s.subLabel}>SPLY</div>
														<div className={s.subNumberWithArrow}>
															<div className={s.subNumber}>{card.values[0].splyValue?.value ?? "-"}</div>
															<div className={s.subDirection}>
																<span>{card.values[0].splyDeltaValue.value ?? "-"} {card.values[0].splyDeltaValue.unit} &nbsp;</span>
																{card.values[0].splyDeltaValue?.arrowDirection === 'up' ?
																	<img
																		alt='arrow-up'
																		src='\img\icon\arrow-up.svg'
																		className={s.subDirectionIcon}
																	/> :
																	<img
																		alt='arrow-down'
																		src='\img\icon\arrow-down.svg'
																		className={s.subDirectionIcon}
																	/>
																}
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Col>
								)
							})
						) : (
							<div className={s.noDataAvail}>
								No additional information
							</div>
						)}
					</Row>
				</div>
			</Modal>
		</>
	);
};

export default PerformanceDetailsModal;

import { userRoutes } from "../utils/user.utils";
import { URL_SIDE_BAR } from "./map.constants";
import { VIEW_TYPE } from "./user.constants";

export const URL_SIDE_BAR_CONFIG = [
  {
    path: URL_SIDE_BAR.MAP,
    icon: "/img/icon/Icon Maps.svg",
    alt: "Toggle map",
    viewType: VIEW_TYPE.MAP,
    text: "MAP",
  },
  {
    path: URL_SIDE_BAR.VALUE_CHAIN,
    icon: "/img/icon/Icon Value Chain.svg",
    alt: "Value Chain",
    viewType: VIEW_TYPE.VALUE_CHAIN,
    text: "VALUE CHAIN",
  },
  {
    path: URL_SIDE_BAR.SCORECARDS,
    icon: "/img/icon/Icon Scorecard.svg",
    alt: "Scorecards",
    viewType: VIEW_TYPE.SCORE_CARDS,
    text: "SCORECARDS",
  },
  {
    path: URL_SIDE_BAR.MFT,
    icon: "/img/icon/Mft.svg",
    alt: "MFT Scorecards",
    viewType: VIEW_TYPE.MFT_SCORE_CARDS,
    text: "MFT",
  },
  {
    path: URL_SIDE_BAR.RISK_PROFILE,
    icon: "/img/icon/laptop-warning.svg",
    alt: "Risk Management",
    viewType: VIEW_TYPE.RISK_PROFILE,
    text: "RISK PROFILE",
  }
];

export const URL_NAV_BAR_CONFIG = [
  {
    path: URL_SIDE_BAR.MAP,
    icon: "/img/icon/map-default.svg",
    iconActive: "/img/icon/map-active.svg",
    alt: "Toggle map",
    viewType: VIEW_TYPE.MAP,
    text: "Map",
  },
  {
    path: URL_SIDE_BAR.VALUE_CHAIN,
    icon: "/img/icon/value-chain-default.svg",
    iconActive: "/img/icon/value-chain-active.svg",
    alt: "Value Chain",
    viewType: VIEW_TYPE.VALUE_CHAIN,
    text: "Value Chain",
  },
  {
    path: URL_SIDE_BAR.SCORECARDS,
    icon: "/img/icon/scorecard-default.svg",
    iconActive: "/img/icon/scorecard-active.svg",
    alt: "Scorecards",
    viewType: VIEW_TYPE.SCORE_CARDS,
    text: "Scorecards",
  },
  {
    path: URL_SIDE_BAR.MFT,
    icon: "/img/icon/mft-default.svg",
    iconActive: "/img/icon/mft-active2.svg",
    alt: "MFT Scorecards",
    viewType: VIEW_TYPE.MFT_SCORE_CARDS,
    text: "MFT",
  },
  {
    path:URL_SIDE_BAR.SUSTAINABILITY,
    icon: "/img/icon/map-default.svg",
    iconActive: "/img/icon/map-active.svg",
    alt: "SUSTAINABILITY",
    viewType: VIEW_TYPE.SUSTAINABILITY,
    text: "Sustainability",
  }
];

export let URL_SIDE_BAR_ADMIN = [
  {
    link: userRoutes()[3]?.path,
    icon: "/img/icon/UserManagement.svg",
    alt: "usermanagement",
    text: "USER MANAGEMENT",
  },
  {
    link: userRoutes()[4]?.path,
    icon: "/img/icon/Configuration.svg",
    alt: "limitconfig",
    text: "LIMIT CONFIGURATION",
  },
  {
    link: userRoutes()[5]?.path,
    icon: "/img/icon/ScorecardOrdering.svg",
    alt: "scorecardsordering",
    text: "SCORECARD ORDERING",
  },
  {
    link: userRoutes()[0]?.path,
    icon: "/img/icon/Map.svg",
    alt: "Toggle map",
    text: "MAP",
  },
  {
    link: userRoutes()[1]?.path,
    icon: "/img/icon/ValueChain.svg",
    alt: "Value Chain",
    text: "VALUE CHAIN",
  },
  {
    link: userRoutes()[2]?.path,
    icon: "/img/icon/Scorecards.svg",
    alt: "Scorecards",
    text: "SCORECARDS",
  }
];

export let USER_UPN_MAPPING = new Map([
  [
    "go.user01@dev.petronas.com",
    "nurulaida.ngadiso@peth365dev.onmicrosoft.com",
  ],
  ["go.user02@dev.petronas.com", "faradina.mohamed@peth365dev.onmicrosoft.com"],
  ["go.user03@dev.petronas.com", "azlina.mathussin@peth365dev.onmicrosoft.com"],
  ["go.user04@dev.petronas.com", "juliana.nazri@peth365dev.onmicrosoft.com"],
  ["go.user05@dev.petronas.com", "mfauzi_ayub@peth365dev.onmicrosoft.com"],
  ["go.user06@dev.petronas.com", "huajin.tan@peth365dev.onmicrosoft.com"],
  ["go.user07@dev.petronas.com", "mehmood.mohsin@peth365dev.onmicrosoft.com"],
  [
    "go.user08@dev.petronas.com",
    "muhamadhafiz.muhama@peth365dev.onmicrosoft.com",
  ],
  ["go.user09@dev.petronas.com", "adizafran.zabidi@peth365dev.onmicrosoft.com"],
  [
    "go.user10@dev.petronas.com",
    "akmaluddin.mohtar@peth365dev.onmicrosoft.com",
  ],
  ["go.user01@stag.petronas.com", "nurulaida.ngadiso@stag.petronas.com"],
  ["go.user02@stag.petronas.com", "faradina.mohamed@peth365.onmicrosoft.com"],
  ["go.user03@stag.petronas.com", "azlina.mathussin@stag.petronas.com"],
  ["go.user04@stag.petronas.com", "juliana.nazri@stag.petronas.com"],
  ["go.user05@stag.petronas.com", "mfauzi_ayub@peth365dev.onmicrosoft.com"],
  [
    "adizafran.zabidi@dev.petronas.com",
    "adizafran.zabidi@peth365dev.onmicrosoft.com",
  ],
]);

export let USER_NAME_MAPPING = new Map([
  ["go.user01@dev.petronas.com", "Nurul Aida Ngadiso"],
  ["go.user02@dev.petronas.com", "Nur Faradina Mohamed"],
  ["go.user03@dev.petronas.com", "Azlina Bt Mat Hussin"],
  ["go.user04@dev.petronas.com", "Juliana Nazri"],
  ["go.user05@dev.petronas.com", "M Fauzi B Ayub"],
  ["go.user06@dev.petronas.com", "Tan Hua Jin"],
  ["go.user07@dev.petronas.com", "Mehmood Mohsin"],
  ["go.user08@dev.petronas.com", "M Hafiz M Sallehin"],
  ["go.user09@dev.petronas.com", "Adi Zafran Zabidi"],
  ["go.user10@dev.petronas.com", "Akmaluddin Mohtar"],
  ["go.user01@stag.petronas.com", "Nurul Aida Ngadiso"],
  ["go.user02@stag.petronas.com", "Nur Faradina Mohamed"],
  ["go.user03@stag.petronas.com", "Azlina Bt Mat Hussin"],
  ["go.user04@stag.petronas.com", "Juliana Nazri"],
  ["go.user05@stag.petronas.com", "M Fauzi B Ayub"],
  ["adizafran.zabidi@dev.petronas.com", "Adi Zafran Zabidi"],
]);

export const SESSION_STORAGE_KEY = {
  USER_NAME: "userName",
  USER_EMAIL: "userEmail",
  USER_UPN: "userUPN",
  USER_IMAGE: "userImage",
  GRAPH_ACCESS_TOKEN: "god-graphAccessToken",
  API_ACCESS_TOKEN: "god-apiAccessToken",
  PERSONA_TOKEN: "personaToken",
  USER_ROLE: "roles",
  USER_MAP: "mapLocations",
  USER_PERSONA: "persona",
  VIEW_PERMISSION: "viewPermissions",
  DEFAULTMAP: "DEFAULTMAP",
  DEFAULTLEVEL: "DEFAULTLEVEL",
  VALUECHAIN_PEMISSION: "VALUECHAIN_PEMISSION",
  SCORECARD_PERMISSION: "SCORECARD_PERMISSION",
  MFT_PERMISSION: "MFT_PERMISSION",
  SELECTED_PERSONA: "SELECTED_PERSONA"
};

export const VIEW_TYPE = {
  MAP: "Map",
  VALUE_CHAIN: "Value Chain",
  SCORE_CARDS: "Scorecards",
  MFT_SCORE_CARDS: "MFT Scorecard",
  SUSTAINABILITY: "Sustainability",
  RISK_PROFILE: "Risk Management",
};

import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import {
  fetchMapData,
  isMapTypeMobile,
  mapTypeChange,
} from "../../action/actionMap";
import { useDispatch, useSelector } from "react-redux";

import { APP_CONSTANTS } from "../../constants/app";
import { buildGhgUrl } from "../../utils/Url.utils";
import { get } from "lodash";
import s from "../Map/map.module.css";
import sMobile from "../Map/mapResponsive.module.css";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Breadcrumb } from "antd";
import { fetchGhgData } from "../../action/actionSustainability";

export default function GhgActionsComponent() {
  const history = useHistory();

  const mapLevel = useSelector((state) =>
    get(state, "sustainability.mapLevel", APP_CONSTANTS.DEFAULT_LEVEL)
  );
  const currentLocationId = useSelector((state) =>
    get(state, "sustainability.currentLocationId", APP_CONSTANTS.DEFAULT_LOCATION_ID)
  );
  const selectedPointId = useSelector((state) =>
    get(state, 'sustainability.selectedPointId', {})
  );
  const { isMapTypeVisible } = useSelector((state: any) => state.map);
  const { isOpenSideBar } = useSelector((state: any) => state.map);

  const navLocations = useSelector((state)=> get(state, "sustainability.mapData.result", null));
  
  const locationList = function(){
    if(navLocations){
      return [...navLocations.parentLevel, {locationId: navLocations.locationId, locationName: navLocations.locationDesc}];
    } 
      return [];
  }()

  const dispatch = useDispatch();

  useEffect(() => {
    // build ghg url
    history.push(buildGhgUrl(currentLocationId, mapLevel));
  }, [currentLocationId, selectedPointId]);

  function setSideBar() {
    return isOpenSideBar
      ? sMobile.closeMapTypeBySideBar
      : sMobile.mapToggleMode;
  }

  function setSideBarTablet() {
    return isOpenSideBar
      ? sMobile.closeMapTypeBySideBar
      : sMobile.mapToggleModeTablet;
  }

  const handleCloseMapType = (isOpen: boolean) => {
    dispatch(isMapTypeMobile(isOpen));
  };

  function breadcrumbItemClassName(location) {
    if(location.locationId === currentLocationId){
      return `${s.mapBreadcrumbItem} ${s.currentLocationBcItem}`;
    } else {
      return s.mapBreadcrumbItem
    }
  }

  function handleClickBreadcrumb(locationId){
    dispatch(fetchGhgData(locationId));
  }

  return (
    <>
      <Desktop>
        <div className='page-action-left'>
          {locationList.length > 0 && (
            <div className={s.mapBreadcrumbContainer}>
              <Breadcrumb
                className={s.mapBreadcrumb}
                separator={
                  <img
                    className={s.breadcrumbSeparator}
                    alt='separator-icon'
                    src='/img/icon/arrow-right.svg'
                  />
                }
              >
                {locationList.map((l) => {
                  return (
                    <Breadcrumb.Item
                      className={breadcrumbItemClassName(l)}
                      key={l.locationId}
                      onClick={() => handleClickBreadcrumb(l.locationId)}
                    >
                      {l.locationName}
                    </Breadcrumb.Item>
                  );
                })}
              </Breadcrumb>
            </div>
          )}
        </div>
      </Desktop>
      <Mobile>
        <div className={isMapTypeVisible ? setSideBar() : sMobile.closeMaptype}>
          <div className={sMobile.mapToggleTxt}>Map Type</div>
          <div></div>

          <div
            onClick={() => handleCloseMapType(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCloseMapType(false);
              }
            }}
            tabIndex={0}
          >
            <img src='\img\icon\ic_close.svg' alt='' />
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div
          className={`${
            isMapTypeVisible ? setSideBarTablet() : sMobile.closeMaptype
          }`}
        >
          <div className={sMobile.mapToggleTxt}>Map Type</div>
          <div></div>
          <div
            onClick={() => handleCloseMapType(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCloseMapType(false);
              }
            }}
            tabIndex={0}
          >
            <img src='\img\icon\ic_close.svg' alt='' />
          </div>
        </div>
      </Tablet>
    </>
  );
};

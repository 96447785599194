import * as types from "../action/actionType";

import { APP_CONSTANTS } from "../constants/app";
import { Action } from "redux";
import { getTabDefault } from "../utils/Url.utils";
import queryString from "query-string";

const params = queryString.parse(window.location.search);

export const INITIAL_STATE = {
  currentLocationId:
    params && params.location ? Number(params.location) : APP_CONSTANTS.DEFAULT_LOCATION_ID,
  selectedPointId: params && params.id ? params.id : null,
  mapLevel:
    params && params.level ? Number(params.level) : APP_CONSTANTS.DEFAULT_LEVEL,
  mapData: {},
  isLoading: true,
  activeTab: getTabDefault(),
  listModule: {}
};
interface SustainabilityAction extends Action {
  data: string | any;
  payload: any;
}

export function sustainabilityReducer(state = INITIAL_STATE, action: SustainabilityAction) {
  switch (action.type) {
    case types.GHG_DATA_FETCH_REQUESTED: {
      return {
        ...state,
        currentLocationId: action.data,
        selectedPointId: action.data,
        isLoading: true,
      };
    }
    case types.GHG_DATA_FETCH_SUCCEEDED: {
      return {
        ...state,
        mapData: action?.payload,
        isLoading: false,
      };
    }
    case types.GHG_DATA_FETCH_FAILED: {
      return {
        ...state,
        mapData: [],
        isLoading: false,
      };
    }
    case types.GHG_MAP_LEVEL_CHANGE: {
      return {
        ...state,
        mapLevel: action.data,
      };
    }
    case types.SUSTAINABILITY_SUMMARY_UPDATE_REQUESTED: {
      return {
        ...state
      };
    }
    case types.GET_DRILLDOWN_PFLNG_SUCCESS: {
      return {
        ...state,
        listModule: action.payload
      }
    }
    case types.GET_DRILLDOWN_PFLNG_FAIL: {
      return {
        ...state,
        listModule: null
      }
    }
    case types.GET_DRILLDOWN_RGTP_SUCCESS: {
      return {
        ...state,
        listModule: action.payload
      }
    }
    case types.GET_DRILLDOWN_RGTP_FAIL: {
      return {
        ...state,
        listModule: null
      }
    }
    case types.GET_DRILLDOWN_RGTSU_SUCCESS: {
      return {
        ...state,
        listModule: action.payload
      }
    }
    case types.GET_DRILLDOWN_RGTSU_FAIL: {
      return {
        ...state,
        listModule: null
      }
    }
    case types.GET_DRILLDOWN_GT_SUCCESS: {
      return {
        ...state,
        GTModule: action.payload
      }
    }
    case types.GET_DRILLDOWN_GT_FAIL: {
      return {
        ...state,
        GTModule: null
      }
    }
  }
  return state;
}

import Modal from 'antd/lib/modal/Modal';
import { useState } from 'react';

import s from './ModalConfirm.module.css';
import { CONFIRM_MODAL } from '../../constants/content-release.constants';

const ModalConfirm = (props: any) => {
  const [isVisible, setVisible] = useState(true);
  
  const handleCancel = () => {
    setVisible(false);
    setTimeout(() => {
      props.onClose();
    }, 500);
  };

  function _renderMessage() {
    return <p className={s.confirmPrompt}>{props.message}</p>;
  }

  const confirmBtnColor = () => {
    if (props.type === CONFIRM_MODAL.DELETE.KEY) {
      return '#EC7362';
    }
    return '#ffffff';
  };

  function _renderActions() {
    if(props.noActions) {
      return null;
    }
    return (
      <div className={s.groupBtn}>
        <button onClick={handleCancel} className={s.cancelButton}>
          <span>CANCEL</span>
        </button>
        <button
          onClick={() => {
            handleCancel();
            props.onConfirm();
          }}
          className={s.okButton}
          style={{ color: confirmBtnColor() }}
        >
          <span>{props.type || 'OK'}</span>
        </button>
      </div>
    );
  }

  return (
    <>
      <Modal
        className='confirm-modal'
        visible={isVisible}
        footer={null}
        onCancel={handleCancel}
        width={220}
        zIndex={1000}
      >
        <div className={s.confirmContent}>
          {_renderMessage()}
          {_renderActions()}
        </div>
      </Modal>
    </>
  );
};
export default ModalConfirm;

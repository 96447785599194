import React, { useEffect, useState } from 'react'
import { MONTH_SHORT_TEXT } from '../../constants/score-card.constants';
import s from './PeriodDropdown.module.css'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DatePicker, Space } from 'antd';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

interface IDate {
  year: number;
  quarter: string;
}

interface IProp {
  currentQuarter: IDate;
  onDateChange: (date: IDate) => void;
}

const makeText = (m: IDate) => {
  if (m && m.year && m.quarter)
    return m.quarter + " - " + m.year;
  return "?";
};

const PeriodDropdown = (prop: IProp) => {
  const [showPeriodFilter, setShowPeriodFilter] = useState(false);
  const { onDateChange, currentQuarter } = prop;

  const availablePeriods = useSelector((state: any) => get(state, 'risk.availablePeriod.result.availablePeriods', []));

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      const year = date.year();
      const quarter = `Q${date.quarter()}`;
      onDateChange({ year, quarter });
      setShowPeriodFilter(false);
    }
  };

  const disabledDate = (current) => {
		if (!current) {
			return false;
		}
	
		const year = current.year().toString();
		const quarter = `Q${current.quarter()}`;
	
		const isAvailable = availablePeriods.some(
			(period) => period.year === year && period.period === quarter
		);
	
		return !isAvailable;
	};

  return (
    <div className={s.filterBlocks}>
      <div className={s.filterBlock}>
        <span>PERIOD</span>
        <div
          className={`${s.filterBlockDropdown}`}
          onBlur={() => { setTimeout(() => { setShowPeriodFilter(false) }, 500); }}
        >
          <button type='button' style={{ width: '140px', justifyContent: 'center' }}
            onClick={() => setShowPeriodFilter(!showPeriodFilter)}
          >
            {makeText(currentQuarter)}
            <i className='mdi mdi-menu-down'></i>
          </button>
          <div className={`risk-quarter-picker`}>
            <Space direction='vertical'>
              <DatePicker
                picker="quarter"
                value={moment(`${currentQuarter.year}-${currentQuarter.quarter}`, 'YYYY-Q[Q]')}
                onChange={handleDateChange}
                disabledDate={disabledDate}
                open={showPeriodFilter}
                format="[Q]Q-YYYY"
              />
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeriodDropdown;
function transformInitData(result) {
  result.selectedUnit = 0;
  result.selectedKey = result.primaryCards[0]?.key;
  return result;
}

export function transformMftCard(results) {
  let resultData = transformInitData(results);
  return resultData;
}

import { get, isEmpty, isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  clearWaterfallChartLevel3,
  fetchScoreCardBreakdownCardLvl3,
  fetchScoreCardPrimaryLvl3,
  fetchScoreCardRevenueChartLvl3,
  fetchScoreCardWaterfallChartLvl3,
  setScoreCardBreakdownActiveLv3,
  updateLevelAndKeyScoreCard,
  updateScoreCardBreakdownCardLvl3,
} from "../../../action/actionScoreCard";
import { DISABLED_CHART_BY_OPU, TAB_SCORE_CARD } from "../../../constants/score-card.constants";
import { Desktop, Mobile, Tablet } from "../../../responsive/responesive";
import { renderSectionBySectionId } from "../../../utils/common.utils";
import ModalWaterfallChart from "../../ModalWaterfallChart/ModalWaterfallChart";
import ExecutiveSummaryComponent from "../ExecutiveSummary/ExecutiveSummaryComponent";
import sco from "../scorecards.module.css";
import CardBreakdownCardComponent from "./CardBreakdownCardComponent";
import CardSecondaryComponent from "./CardSecondaryComponent";
import s from "./ChartComponent.module.css";
import sMobile from "./ChartResponsiveMobile.module.css";
import ModalBreakdownCardComponent from "./ModalBreakdownCardComponent";
import TrendChartLevel2 from "./TrendChartLevel2";
import WaterfallChartLevel2 from "./WaterfallChartLevel2";

interface Props {
  tab: string;
}

const ChartComponent = ({ tab }: Props) => {
  const scoreCard = useSelector((state: any) => state.scoreCard);
  const [unitTabs, setUnitTabs] = useState([]);
  const [unitSelected, setUnitSelected] = useState("");
  const [activeCard, setActiveCard] = useState("");
  const breakDownCardInfor = useSelector(
    (state: any) => state.scoreCard.breakDownCardInfor
  );
  const opu = useSelector((state) =>
    get(state, "privateroute.userConfig.claims.persona.opu", "")
  );
  const isDisabledWaterfallChart =
    DISABLED_CHART_BY_OPU?.[opu]?.[tab]?.waterfall || false;
  const { sectionIdActive, primaryCardInforDetail } = scoreCard;
  const [isModalVisible, SetIsModalVisible] = useState(false);
  const waterfallChart = useSelector((state) =>
    get(state, "scoreCard.waterfallChart", {})
  );
  const dispatch = useDispatch();
  const handleOpenModal = () => {
    SetIsModalVisible(true);
  };
  const handleCloseModal = () => {
    SetIsModalVisible(false);
    setActiveCard("");
    dispatch(clearWaterfallChartLevel3());
  };

  const updateUnitTab = (units: any, unitSelectedParam: string) => {
    if (
      !isEqual(unitSelectedParam, unitSelected) &&
      !units.includes(unitSelected)
    ) {
      setUnitTabs(units);
      setUnitSelected(unitSelectedParam);
    }
  };

  useEffect(() => {
    if (!isEmpty(scoreCard.units) && scoreCard.units.length > 1) {
      const { units } = scoreCard;
      updateUnitTab(units, scoreCard.unitSelected);
    }
    const { keyScoreCard } = scoreCard;
    const sectionName = renderSectionBySectionId(sectionIdActive) as any;
    const selectedCard = scoreCard[sectionName]?.parameters?.filter(
      (v) => v.key === keyScoreCard
    );
    if (
      !isEmpty(selectedCard) &&
      isEmpty(scoreCard.units) &&
      !isEmpty(selectedCard[0]?.units) &&
      selectedCard[0].units.length > 1
    ) {
      updateUnitTab(selectedCard?.[0]?.units, selectedCard?.[0]?.units[0]);
    } else if (
      selectedCard &&
      selectedCard[0]?.units &&
      selectedCard[0].units.length === 1
    ) {
      updateUnitTab([], "");
    }
  }, [scoreCard]);

  const handleOnclickBreakdownLv2 = (key: string) => {
    const sectionName = renderSectionBySectionId(sectionIdActive) as any;
    const param = {
      paramKey: key,
      year: scoreCard[sectionName]?.year,
      month: scoreCard[sectionName]?.month,
      frequency: scoreCard[sectionName]?.frequency,
      unit: scoreCard.unitSelected,
    };
    const cardActiveBreeakdownLv3 = breakDownCardInfor.filter(
      (card: any) => card.key === key
    )[0] as any;
    setActiveCard(key);
    setTimeout(() => {
      handleOpenModal();
    }, 300);
    if (sectionIdActive !== TAB_SCORE_CARD.FINANCIAL_EXCELLENCE) {
      dispatch(
        fetchScoreCardBreakdownCardLvl3({
          params: param,
          tabActive: sectionIdActive,
        })
      );
    }
    if (sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE) {
      dispatch(updateScoreCardBreakdownCardLvl3([]));
    }
    dispatch(setScoreCardBreakdownActiveLv3(cardActiveBreeakdownLv3));
    dispatch(
      fetchScoreCardRevenueChartLvl3({
        params: param,
        tabActive: sectionIdActive,
      })
    );
    dispatch(
      fetchScoreCardPrimaryLvl3({ params: param, tabActive: sectionIdActive })
    );
    if (sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE) {
      dispatch(
        fetchScoreCardWaterfallChartLvl3({
          params: param,
          tabActive: sectionIdActive,
        })
      );
    }
  };

  const handleOnclickBreakdownLv3 = (key: string) => {
    const sectionName = renderSectionBySectionId(sectionIdActive) as any;
    const param = {
      paramKey: key,
      year: scoreCard[sectionName]?.year,
      month: scoreCard[sectionName]?.month,
      frequency: scoreCard[sectionName]?.frequency,
      unit: scoreCard.unitSelected,
    };
    setActiveCard(key);
    dispatch(
      fetchScoreCardRevenueChartLvl3({
        params: param,
        tabActive: sectionIdActive,
      })
    );
    dispatch(
      fetchScoreCardPrimaryLvl3({ params: param, tabActive: sectionIdActive })
    );
    if (sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE) {
      dispatch(
        fetchScoreCardWaterfallChartLvl3({
          params: param,
          tabActive: sectionIdActive,
        })
      );
    }
  };

  function CardThirdListComponent(breakDownInfo, classModule) {
    return (
      <div className={classModule.cardThirdValueList}>
        {breakDownInfo &&
          breakDownInfo.map((card: any) => {
            return (
              <CardBreakdownCardComponent
                onclickBreakdown={handleOnclickBreakdownLv2}
                card={card}
                key={card.key}
              />
            );
          })}
      </div>
    );
  }

  function PrimaryCardInfoComponent(primaryCardInfo) {
    return (
      primaryCardInfo?.secondaryValue &&
      primaryCardInfo?.secondaryValue?.map((card: any, i: number) => {
        return (
          <CardSecondaryComponent
            key={i}
            card={card}
            primaryCardInforDetail={primaryCardInfo}
          />
        );
      })
    );
  }

  const handleChangeUnitTab = (v) => {
    const { keyScoreCard, levelScoreCard } = scoreCard;
    setUnitSelected(v);
    dispatch(
      updateLevelAndKeyScoreCard({
        level: levelScoreCard,
        key: keyScoreCard,
        tab: scoreCard.sectionIdActive,
        unitSelected: v,
        units: unitTabs,
      })
    );
  };

  const _renderUnitTab = () => {
    return (
      unitTabs &&
      unitTabs.map((v) => (
        <li
          className={v === unitSelected ? sco.active : ""}
          onClick={() => handleChangeUnitTab(v)}
        >
          {v}
        </li>
      ))
    );
  };

  const _renderMobileTablet = () => (
    <>
      <div className={s.chartTilte}>{primaryCardInforDetail?.title}</div>
      <div className={s.revenueChartOverview}>
        {/* Excutive summary */}
        {primaryCardInforDetail?.executiveSummary && (
          <div className={sMobile.summaryChartBackground}>
            <div className={s.summaryChartTitle}>Executive Summary</div>
            <div className={s.summaryChartText}>
              {primaryCardInforDetail && (
                <ExecutiveSummaryComponent data={primaryCardInforDetail} />
              )}
            </div>
          </div>
        )}
        {/* card secondary */}
        <div className={sMobile.secondaryValueList}>
          {PrimaryCardInfoComponent(primaryCardInforDetail)}
        </div>
        {(waterfallChart?.data?.length === 0 || isEmpty(waterfallChart)) &&
          CardThirdListComponent(breakDownCardInfor, s)}
      </div>
      {/* Revenue chart */}
      <TrendChartLevel2
        isWaterFallChart={
          !isDisabledWaterfallChart && waterfallChart?.data?.length > 0
        }
      />
      {waterfallChart?.data && !isDisabledWaterfallChart && (
        <div className={sMobile.waterfallChartOverview}>
          <WaterfallChartLevel2 waterfallChart={waterfallChart} />

          {/* Break down card */}
          {CardThirdListComponent(breakDownCardInfor, sMobile)}
        </div>
      )}
      <ModalBreakdownCardComponent
        isModalVisible={isModalVisible}
        activeCard={activeCard}
        setActiveCard={setActiveCard}
        onCloseModal={handleCloseModal}
        onclickBreakdown={handleOnclickBreakdownLv3}
      />
    </>
  );
  return (
    <>
      <Desktop>
        <div className={s.chartTilte}>{primaryCardInforDetail?.title}</div>
        <div className={sco.scoreCardsRightTab}>
          <ul>{_renderUnitTab()}</ul>
        </div>
        <div className={s.chartLayout}>
          <div className={s.revenueChartOverview}>
            <div className={s.inforChart}>
              {/* Excutive summary */}
              {primaryCardInforDetail?.executiveSummary && (
                <div className={s.summaryChartBackground}>
                  <div className={s.summaryChartTitle}>Executive Summary</div>
                  <div className={s.summaryChartText}>
                    {primaryCardInforDetail && (
                      <ExecutiveSummaryComponent
                        data={primaryCardInforDetail}
                      />
                    )}
                  </div>
                </div>
              )}
              {/* card secondary */}
              <div className={s.secondaryValueList}>
                {PrimaryCardInfoComponent(primaryCardInforDetail)}
              </div>
              {(waterfallChart?.data?.length === 0 ||
                isEmpty(waterfallChart)) &&
                CardThirdListComponent(breakDownCardInfor, s)}
            </div>
            {/* Revenue chart */}
            <TrendChartLevel2
              isWaterFallChart={
                !isDisabledWaterfallChart && waterfallChart?.data?.length > 0
              }
            />
          </div>
          {!isDisabledWaterfallChart && waterfallChart?.data?.length > 0 && (
            <div className={s.waterfallChartOverview}>
              <WaterfallChartLevel2 waterfallChart={waterfallChart} />
              {/* Break down card */}
              {CardThirdListComponent(breakDownCardInfor, s)}
            </div>
          )}
        </div>
        <ModalBreakdownCardComponent
          isModalVisible={isModalVisible}
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          onCloseModal={handleCloseModal}
          onclickBreakdown={handleOnclickBreakdownLv3}
        />
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>

      <ModalWaterfallChart
        data={waterfallChart}
      />

    </>
  );
};

export default ChartComponent;

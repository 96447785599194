import { FC, memo } from "react";
import { useDrag, useDrop } from "react-dnd";

import s from "./ListDnDComponent.module.css";

export interface CardProps {
  idx: number;
  id: string;
  text: string;
  icon: string;
  moveCard: (id: string, to: number) => void;
  findCard: (id: string) => { index: number };
}

interface Item {
  id: string;
  originalIndex: number;
}

const ListDnDComponent: FC<CardProps> = memo(function Card({
  idx,
  id,
  text,
  icon,
  moveCard,
  findCard,
}) {
  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "card",
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "card",
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard]
  );

  const opacity = isDragging ? 0 : 1;
  return (
    <div className={s.listDndOuterCard} key={idx}>
      <div>{idx + 1}</div>
      <div className={s.listDndBox}>
        <div
          ref={(node) => drag(drop(node))}
          className={s.listDndCard}
          style={{ opacity: opacity }}
        >
          <div className={s.scoreCardsItemIcon}>
            <img
              src={`data:image/svg+xml;charset=utf-8;base64,${icon}`}
              alt={"icon"}
            />
          </div>
          <div>{text}</div>
        </div>
      </div>
    </div>
  );
});
export default ListDnDComponent;

import React from 'react';

import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import s from './cargo.module.css';
import CargoDonutChart from './CargoDonutChart';

export default function CargoInformationSection() {
  const { callOutData } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.calloutData,
    };
  });
  const cargoInfo =
    callOutData?.secondarySection?.sectionLabel === 'CARGO INFORMATION' &&
    callOutData?.secondarySection?.sectionValue;
  const findData = (keyword) =>
    cargoInfo?.find?.((d: any) => d.primaryLabel === keyword) || {};
  const contractAgreementData: any = findData('Contract Agreement');
  const totalCargoData: any = findData('Total Cargo');
  const incotermData: any = findData('Incoterm');

  function _renderContractAgreement() {
    return (
      <div className={s.contractAgreementSection}>
        <Row align='middle' justify='space-between'>
          <Col flex={10}>
            <div className={s.chartLegends}>
              {contractAgreementData?.chartValues?.map((v) => {
                return (
                  <div className={s.chartLegendItem}>
                    <span className={`${s.legendBox} ${s[v?.label]}`} />
                    {/* <span className={s.unitName}>{v?.label}</span> */}
                    <span className={s.unitName}>{v?.label.charAt(0).toUpperCase() + v?.label.slice(1).toLowerCase()}</span>
                    <span className={s.value}>{v?.value}</span>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col flex={14}>
            <div className={s.donutChart}>
              <CargoDonutChart data={contractAgreementData} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  function _renderIncoterms() {
    return (
      <div className={s.incotermsSection}>
        <Row align='middle' justify='space-around'>
          <Col flex={14}>
            <div className={s.donutChart}>
              <CargoDonutChart data={incotermData} />
            </div>
          </Col>
          <Col flex={10}>
            <div className={s.chartLegends}>
              {incotermData?.chartValues?.map((v) => {
                return (
                  <div className={s.chartLegendItem}>
                    <span className={`${s.legendBox} ${s[v?.label]}`} />
                    <span className={s.unitName}>{v?.label}</span>
                    <span className={s.value}>{v?.value}</span>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  function _renderTotalCargo() {
    return (
      <div className={s.totalCargoSection}>
        <p>{totalCargoData?.primaryLabel}</p>
        <span>{totalCargoData?.chartValues?.[0]?.value}</span>
      </div>
    );
  }

  if (cargoInfo) {
    return (
      <div className={`${s.highlightComponent} ${s.cargoInfo}`}>
        <div className={s.highlightTitle}>
          <span>{callOutData?.secondarySection?.sectionLabel}</span>
          <span />
        </div>
        <div className={s.cargoInformationSection}>
          <Row align='middle' justify='space-between'>
            <Col flex={2}>{_renderContractAgreement()}</Col>
            <Col flex={1}>{_renderTotalCargo()}</Col>
            <Col flex={2}>{_renderIncoterms()}</Col>
          </Row>
        </div>
      </div>
    );
  }
  return null;
}

import { Action } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../action/actionType';
import { getAvatarUser, getUserProperties, getOPUsUserAccess, getAdminsUserAccess, sendUserAccess } from '../services/user.service';
const USER_API = 'https://jsonplaceholder.typicode.com/users/1';
interface UserAction extends Action {
  payload: string | any;
}

function* fetchUser(action: Action) {
  try {
    const response = yield call(fetch, USER_API);
    const data = yield response.json(); // same like async/await
    yield put({ type: types.USER_FETCH_SUCCEEDED, payload: data });
  } catch (error: any) {
    yield put({ type: types.USER_FETCH_FAILED, message: error.message });
  }
}

function* userFetchImage(action: UserAction) {
  try {
    const response = yield call(getAvatarUser, action.payload);
    yield put({ type: types.USER_FETCH_IMAGE_SUCCEEED, payload: response });
  } catch (error: any) {
    yield put({ type: types.USER_FETCH_IMAGE_FAILED, message: error.message });
  }
}

function* userFetchProperties(action: UserAction) {
  try {
    const response = yield call(getUserProperties, action.payload);
    yield put({ type: types.USER_FETCH_PROPERTIES_SUCCEEDED, payload: response });
  } catch (error: any) {
    yield put({ type: types.USER_FETCH_PROPERTIES_FAILED, message: error.message });
  }
}

function* fetchOPUsUserAccess() {
  try {
    const response = yield call(getOPUsUserAccess);
    yield put({ type: types.USER_ACCESS_OPUS_LOADED, payload: response.result });
  } catch (error: any) {}
}

function* fetchAdminsUserAccess() {
  try {
    const response = yield call(getAdminsUserAccess);
    yield put({ type: types.USER_ACCESS_ADMINS_LOADED, payload: response.result });
  } catch (error: any) {}
}

function* sendUserAccessRequest(action: UserAction) {
  try {
    yield put({ type: types.USER_ACCESS_SENDING });
    const response = yield call(sendUserAccess, action.payload);
    yield put({ type: types.USER_ACCESS_SENT, payload: {isRequestSent: true,...response} });
  } catch (error: any) {
    yield put({ type: types.USER_ACCESS_SENT, payload: error});
  }
}

export function* workerUserData() {
  yield all([
    takeLatest(types.USER_FETCH_IMAGE, userFetchImage),
    takeLatest(types.USER_FETCH_PROPERTIES, userFetchProperties),
    takeLatest(types.USER_FETCH_REQUESTED, fetchUser),
    takeLatest(types.USER_ACCESS_GET_OPUS, fetchOPUsUserAccess),
    takeLatest(types.USER_ACCESS_GET_ADMINS, fetchAdminsUserAccess),
    takeLatest(types.USER_ACCESS_SUBMIT, sendUserAccessRequest)
  ])
}



//Plain Header for Access Request Form
//New non-functional Header component

import React from "react";
import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import s from "../Header/header.module.css";


function PlainHeaderComponent() {

  return (
    <>
      <Desktop>
        <div className={s.headerRoot}>
          <div className={s.headerContainer}>
              <div className={s.logo}>
                <img src="/img/icon/Logo.svg" height="40" alt="React" />
              </div>
              <span className={s.brandTxt}>GO Dashboard</span>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className={s.plainHeaderRootMobile}>
          <div className={s.headerLayoutMobile}>
            <div className={s.logo}>
                <img src="/img/icon/Logo.svg" height="100" alt="React" />
              </div>
              <span className={s.brandTxt}>GO Dashboard</span>
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div className={s.plainHeaderRootMobile}>
          <div className={s.headerLayoutMobile}>
            <div className={s.logo}>
                <img src="/img/icon/Logo.svg" height="40" alt="React" />
              </div>
              <span className={s.brandTxt}>GO Dashboard</span>
          </div>
        </div>
      </Tablet>
    </>
  );
}

export default PlainHeaderComponent;
import "antd/dist/antd.css";

import React, { useEffect } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";

import { clearOnUnmount, fetchMftPeriodList } from "../../action/actionMft";
import MftExecSummaryComponent from "../../components/Mft/MftExecSummary/MftExecSummaryComponent";
import MftScorecardsComponent from "../../components/Mft/MftScorecards/MftScorecardsComponent";
import { OPU_UPCS } from "../../constants/opu.constants";
import s from "./MftComponent.module.css";
import { updateActiveTab } from "../../action/actionMap";
import { URL_SIDE_BAR } from "../../constants/map.constants";

const MftComponent = () => {
  const dispatch = useDispatch();
  const selectedPersona = useSelector((state: any) => state.user.selectedPersona);

  useEffect(() => {
    dispatch(updateActiveTab(URL_SIDE_BAR.MFT));
    return () => {
      dispatch(clearOnUnmount())
    };
  }, []);

  useEffect(() => {
    dispatch(fetchMftPeriodList());
  }, [selectedPersona])


  const backgroundImgUrl = () => {
    switch (selectedPersona) {
      case OPU_UPCS.PFLNG:
        return "/img/pflng_scorecards_bg.jpg";
      case OPU_UPCS.MLNG:
        return "/img/mlng_scorecards_bg.jpg";
      case OPU_UPCS.LNGA:
        return "/img/lnga_scorecards_bg.jpg";
      default:
        return "/img/scorecards_bg1.jpg";
    }
  };

  return (
    <div className={`mft-page fullscreen-pt-0 ${s.mftRoot}`}>
      <ScrollContainer
        className={`page-container bg-map ${s.mftContainer}`}
        style={{ backgroundImage: `url(${backgroundImgUrl()})` }}
      >
        <div className="page-content">
          <div className={s.mftContent}>
            <MftExecSummaryComponent />
            <MftScorecardsComponent />
          </div>
        </div>
      </ScrollContainer>
    </div>
  );
};
export default MftComponent;

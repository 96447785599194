import { Col, Row } from 'antd';
import { get, isNull } from 'lodash';
import { useSelector } from 'react-redux';
import { LMT_OPERATIONAL_SALES_UNITS, LMT_OPERATIONAL_SALES_UNITS_ENUMS } from '../../../../../constants/score-card.constants';
import { formatScoreCardTargetValue } from '../../../../../utils/Number.utils';

import s from '../../SecondaryCard.module.css';

export interface LmtSalesVolumeCargoDetailsProps {
    unitIndex: number;
  }

function LmtSalesVolumeCargoDetails({unitIndex} : LmtSalesVolumeCargoDetailsProps) {
    const { secondaryCardData } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            secondaryCardData: scorecard.secondaryCardDetail
        };
    });

    const lmtSalesVolume = secondaryCardData.lMTScorecardSalesVolumes;

    function formatValueNumber(value) {
        return LMT_OPERATIONAL_SALES_UNITS[unitIndex] === LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE 
            ? formatScoreCardTargetValue(value, 0) :
            formatScoreCardTargetValue(value, 2)
    }

    return (
        <div className={s.detailsContent}>
            {lmtSalesVolume?.lMTScorecardSalesVolumeDetailResponse &&
                lmtSalesVolume.lMTScorecardSalesVolumeDetailResponse.map((card) => {
                    return (
                        <div className={`${s.detailsItem} ${s.detailsItemFlex}`}>
                            <div className={s.parameterData}>
                                <div className={s.parameterName}>
                                    {card.entity}
                                </div>

                            </div>
                            <div className={s.parameterData}>
                                <Row>
                                    <Col span={2}>
                                        <span
                                            className={`${s.colorDot} ${s[card.values[unitIndex].ytdColor]}`}
                                        />
                                    </Col>
                                    <Col span={22}>
                                        <div className={s.label}>YTD</div>
                                        <div className={s.actual}>
                                            <span className={s.value}>
                                                {formatValueNumber(card.values[unitIndex].ytd)}
                                            </span>
                                            <span className={s.unit}>{card.values[unitIndex].unit}</span>
                                        </div>
                                        <div className={s.secondary}>
                                            <span className={s.type}>Plan</span>
                                            <span className={s.value}>
                                                {formatValueNumber(card.values[unitIndex].ytdPlan)}
                                            </span>
                                            <span className={s.unit}>{card.values[unitIndex].unit}</span>
                                        </div>
                                        <div className={s.secondary}>
                                            <span className={s.type}>Var</span>
                                            <span className={`${s.value} ${s[card.values[unitIndex].ytdColor]}`}>
                                                {formatValueNumber(card.values[unitIndex].ytdVar)}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className={s.parameterData}>
                                <Row>
                                    <Col span={2}>
                                        <span
                                            className={`${s.colorDot} ${s[card.values[unitIndex].yepColor]}`}
                                        />
                                    </Col>
                                    <Col span={22}>
                                        <div className={s.label}>YEP</div>
                                        <div className={s.actual}>
                                            <span className={s.value}>
                                                {formatValueNumber(card.values[unitIndex].yep)}
                                            </span>
                                            <span className={s.unit}>{card.values[unitIndex].unit}</span>
                                        </div>
                                        <div className={s.secondary}>
                                            <span className={s.type}>YET</span>
                                            <span className={s.value}>
                                                {formatValueNumber(card.values[unitIndex].yet)}
                                            </span>
                                            <span className={s.unit}>{card.values[unitIndex].unit}</span>
                                        </div>
                                        <div className={s.secondary}>
                                            <span className={s.type}>Var</span>
                                            <span className={`${s.value} ${s[card.values[unitIndex].yepColor]}`}>
                                                {formatValueNumber(card.values[unitIndex].yepVar)}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    );
                })}
        </div>

    );
}

export default LmtSalesVolumeCargoDetails;



import React from "react";

import LimitVCTableComponent from "../../../components/LimitConfiguration/LimitVCTable/LimitVCTableComponent";
import { Tabs } from "antd";
import WorldMapTableComponent from "../../../components/LimitConfiguration/WorldMapTable/WorldMapTableComponent";
import s from "./limitconfiguration.module.css";

const LimitConfigurationComponent = (props: any) => {
  const { TabPane } = Tabs;
  function _tabsComponent() {
    return (
      <Tabs className="limitconfig-tabs" defaultActiveKey="1">
        <TabPane tab="World Map" key="1">
          <WorldMapTableComponent />
        </TabPane>
        <TabPane tab="Value Chain" key="2">
          <LimitVCTableComponent />
        </TabPane>
      </Tabs>
    );
  }
  return (
    <div className="limitconfig">
      <div className={`fullscreen-pt-0 ${s.limitConfigRoot}`}>
        <div className={`page-container bg-map ${s.limitConfigContainer}`}>
          <div className={s.limitConfigPanel}>
            <div className={s.limitConfigPanelContainer}>
              <h2>LIMIT CONFIGURATION</h2>
              <div className={s.limitConfigDiv} />
              {_tabsComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LimitConfigurationComponent;

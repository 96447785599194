import { Action } from "redux";

import * as types from "../action/actionType";

export const initialData = {
  summaryList: [],
  mftCard: {},
  isChartLoading: false,
  cardSectionData: {},
  isCardSectionLoading: false,
  periodsList: [],
  currentSection: 1,
  currentSubSection: 0,
  currentDate: {
    month: NaN,
    year: NaN,
  },
  dynamicTab: undefined,
  currentTabKey: undefined,
  selectedPrimaryCard: {},
  unitIndex: 0,
};

interface MftAction extends Action {
  payload: string | any;
  data: string | any;
}

export function mftReducer(state = initialData, action: MftAction) {
  switch (action.type) {
    case types.MFT_EXECSUMMARY_SUCCEEDED: {
      return {
        ...state,
        summaryList: action.payload,
      };
    }
    case types.MFT_COMMON_REQUESTED: {
      return {
        ...state,
        dynamicTab: undefined,
      };
    }
    case types.MFT_COMMON_SUCCEEDED: {
      return {
        ...state,
        dynamicTab: action.payload,
      };
    }
    case types.MFT_CARD_SUCCEEDED: {
      return {
        ...state,
        mftCard: action.payload,
      };
    }
    case types.MFT_CARD_SECTION_REQUESTED: {
      return {
        ...state,
        isCardSectionLoading: true,
        cardSectionData: {},
      };
    }
    case types.MFT_CARD_SECTION_SUCCEEDED: {
      return {
        ...state,
        cardSectionData: action.payload,
        isCardSectionLoading: false,
      };
    }
    case types.MFT_CARD_SECTION_FAILED: {
      return {
        ...state,
        isCardSectionLoading: false,
        cardSectionData: {},
      };
    }
    case types.MFT_UPDATE_LINE_CHART_REQUESTED: {
      return {
        ...state,
        isChartLoading: true,
      };
    }
    case types.MFT_UPDATE_LINE_CHART_SUCCEEDED: {
      return {
        ...state,
        isChartLoading: false,
        cardSectionData: { ...state.cardSectionData, ...action.payload },
      };
    }
    case types.MFT_UPDATE_LINE_CHART_FAILED: {
      return {
        ...state,
        isChartLoading: false,
      };
    }
    case types.MFT_PERIODLIST_SUCCEEDED: {
      return {
        ...state,
        periodsList: action.payload,
      };
    }
    case types.SET_MFT_CURRENTDATE: {
      return {
        ...state,
        currentDate: action.data,
      };
    }
    case types.SET_MFT_CURRENTSECTION: {
      return {
        ...state,
        currentSection: action.data,
      };
    }
    case types.SET_MFT_CURRENTSUBSECTION: {
      return {
        ...state,
        currentSubSection: action.data,
      };
    }
    case types.SET_MFT_CURRENTTABKEY: {
      return {
        ...state,
        currentTabKey: action.data,
      };
    }
    case types.MFT_RESET_DYNAMIC_TABS: {
      return {
        ...state,
        dynamicTab: undefined,
      };
    }
    case types.MFT_SELECT_PRIMARY_CARD: {
      return {
        ...state,
        selectedPrimaryCard: action.data,
      };
    }
    case types.MFT_SET_UNIT_INDEX: {
      return {
        ...state,
        unitIndex: action.data,
      };
    }
    case types.MFT_CLEAR_ON_UNMOUNT: {
      return {
        ...state,
        cardSectionData: {},
        selectedPrimaryCard: {},
        currentTabKey: undefined,
        dynamicTab: undefined,
        currentSubSection: 0,
        currentSection: 1,
        currentDate: {},
      };
    }
  }
  return state;
}

import React, { useLayoutEffect, useRef } from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { formatHisTrendChartData } from '../../../utils/chart.utils';
import s from './historical-trend.module.css';

am4core.useTheme(am4themes_animated);

const seriesColors = {
  'Total Supply': 'rgba(252, 147, 13, 1)',
  'Non-Power: Others': ['rgba(187, 159, 204, 1)', 'rgba(187, 159, 204, 0.5)'],
  'Non-Power: PIC': ['rgba(139, 92, 167, 1)', 'rgba(139, 92, 167, 0.5)'],
  'Non-Power: GMES': ['rgba(70, 27, 106, 1)', 'rgba(139, 92, 167, 0.5)'],
  Export: ['rgba(203, 219, 107, 1)', 'rgba(203, 219, 107, 0.5)'],
  Power: ['rgba(122, 200, 197, 1)', 'rgba(122, 200, 197, 0.5)'],
};

const labels = [
  'Power',
  'Export',
  'Non-Power: GMES',
  'Non-Power: PIC',
  'Non-Power: Others',
  'Total Supply',
];

const toolTipsLabels = [
  'Total Supply',
  'Total Demand',
  'Non-Power: Others',
  'Non-Power: PIC',
  'Non-Power: GMES',
  'Export',
  'Power',
];

export default function HisTrendChart() {
  const { data } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      data: callout?.hisTrendData?.data,
    };
  });
  const chart = useRef<am4charts.XYChart>();
  const populatedData = formatHisTrendChartData(data);
  // Add data
  let tooltipItems = '';
  for (const t of toolTipsLabels) {
    tooltipItems += `<tr>
      <td style="width: 50%">${t}</td>
      <td style="width: 50%; text-align: right">
        <span style="font-weight: 700">{${t}}</span>
        &nbsp;
        <span>MMscf/d</span>
      </td>
    </tr>`;
  }
  let ToolTipView =
    `<div
      style='
      background: rgba(5, 18, 18, 0.01);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      color: rgba(255, 255, 255, 0.7);
      text-align: left;
      padding: 5px 7px;
      font-weight: 300;
      '
    >
      <div style="border-bottom: 1px solid rgba(217, 217, 217, 0.1); margin-bottom: 10px">
        <p style="margin-bottom: 5px">{categoryX}:00, ${moment(
          data?.[0]?.chartData?.[0]?.date
        ).format('DD MMM YYYY')}</p>
      </div><table>
      ` +
    tooltipItems +
    `
    </table>
    </div>
    `;

  function createSeries(label, x: am4charts.XYChart) {
    // Create series
    let series = x.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = label;
    // series.dataFields.valueYShow = 'totalPercent';
    series.dataFields.categoryX = 'hour';
    series.name = label;
    series.smoothing = 'monotoneX';
    series.tooltipHTML = ToolTipView;
    let seriesToolTip: any = series.tooltip;
    seriesToolTip.getFillFromObject = false;
    seriesToolTip.defaultState.transitionDuration = 0;
    seriesToolTip.hiddenState.transitionDuration = 0;
    seriesToolTip.background.strokeWidth = 0;

    seriesToolTip.background.fill = am4core.color('rgba(5, 18, 18, 0.95)');
    series.fillOpacity = 0.85;
    let gradient = new am4core.LinearGradient();
    gradient.addColor(am4core.color(seriesColors[label][0]));
    gradient.addColor(am4core.color(seriesColors[label][1]));
    series.fill = gradient;
    gradient.rotation = 180;
    series.stacked = true;
    series.strokeWidth = 0;

    if (label === 'Total Supply') {
      series.strokeWidth = 3;
      series.stroke = am4core.color(seriesColors[label]);
      series.fillOpacity = 0;
      series.stacked = false;
    }

    // static
    series.legendSettings.labelText = label;
  }

  useLayoutEffect(() => {
    if (populatedData?.length) {
      let x = am4core.create('historical-trend-chart', am4charts.XYChart);
      // x.colors.step = 2;
      x.data = populatedData;

      // Create axes
      let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis());
      let categoryAxisTooltip: any = categoryAxis.tooltip;
      categoryAxisTooltip.disabled = true;
      categoryAxis.dataFields.category = 'hour';

      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = 20;
      // categoryAxis.renderer.labels.template.html = `<p>{valueX}</p> <span>am<span/>`
      categoryAxis.renderer.opacity = 0.6;

      categoryAxis.startLocation = 0.5;
      categoryAxis.endLocation = 0.5;

      let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
      let valueAxisTooltip: any = valueAxis.tooltip;
      valueAxisTooltip.disabled = true;
      valueAxis.calculateTotals = true;
      valueAxis.min = 0;
      // valueAxis.max = 100;
      // valueAxis.strictMinMax = true;
      valueAxis.renderer.opacity = 0.6;
      for (const s of labels) {
        createSeries(s, x);
      }

      // Add cursor
      x.cursor = new am4charts.XYCursor();
      x.cursor.maxTooltipDistance = -1;
      x.cursor.lineY.disabled = true;
      // x.cursor.xAxis = categoryAxis;
      // x.cursor.fullWidthLineX = true;
      x.cursor.lineX.strokeWidth = 2;
      x.cursor.lineX.fillOpacity = 0.5;
      x.cursor.lineX.fill = am4core.color('#892498');
      x.cursor.lineX.width = 50;
      // add legend
      x.legend = new am4charts.Legend();
      x.legend.data = [
        { label: 'Total Supply', fill: '#FC930D', height: 3, width: 18 },
        { label: 'Non-Power: Others', fill: '#BB9FCC', height: 18, width: 18 },
        { label: 'Non-Power: PIC', fill: '#8B5CA7', height: 18, width: 18 },
        { label: 'Non-Power: GMES', fill: '#461B6A', height: 18, width: 18 },
        { label: 'Export', fill: '#D2E36E', height: 18, width: 18 },
        { label: 'Power', fill: '#7AC8C5', height: 18, width: 18 },
      ];
      x.legend.dataFields.name = 'label';
      x.legend.labels.template.text = '{name}';
      x.legend.markers.template.propertyFields.fill = 'fill';
      x.legend.markers.template.propertyFields.height = 'height';
      x.legend.markers.template.propertyFields.width = 'width';

      x.legend.position = 'right';
      x.legend.align = 'left';
      x.legend.valign = 'bottom';
      x.legend.paddingBottom = 30;
      x.legend.width = am4core.percent(25);
      x.legend.itemContainers.template.clickable = false;
      x.legend.valueLabels.template.disabled = true;
      x.legend.itemContainers.template.focusable = false;
      x.legend.itemContainers.template.cursorOverStyle =
        am4core.MouseCursorStyle.default;
      return () => {
        x.dispose();
      };
    }
  }, [populatedData]);
  if (data?.length > 0) {
    return (
      <>
        <span>MMscf/d</span>
        <div
          id='historical-trend-chart'
          style={{ width: '100%', height: '100%' }}
        ></div>
      </>
    );
  }
  return <h1>Chart not available</h1>;
}

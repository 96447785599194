import { Action } from 'redux';

import * as types from '../action/actionType';

export const initialData = {
  isVisibleAnnouncement: false,
  announcementData: [],
  rcTableData: [],
  isRCTableLoading: false,
  isOpenForm: false,
  isFormLoading: false,
  currentRecord: {},
  actionFailedMessage: ""
};

interface ContentReleaseAction extends Action {
  payload: string | any;
  data: string | any;
}

export function contentReleaseReducer(
  state = initialData,
  action: ContentReleaseAction
) {
  switch (action.type) {
    case types.CONTENT_RELEASE_ANNOUNCEMENT_SUCCEEDED: {
      return {
        ...state,
        announcementData: action.payload,
      };
    }
    case types.CONTENT_RELEASE_ANNOUNCEMENT_FAILED: {
      return {
        ...state,
        isVisibleAnnouncement: false,
      };
    }
    case types.OPEN_MODAL_CONTENT_RELEASE: {
      return {
        ...state,
        isVisibleAnnouncement: true,
      };
    }
    case types.CLOSE_MODAL_CONTENT_RELEASE: {
      return {
        ...state,
        isVisibleAnnouncement: false,
      };
    }
    case types.RC_ANNOUNCEMENT_REQUESTED: {
      return {
        ...state,
        isRCTableLoading: true,
        isFormLoading: false,
        isOpenForm: false,
        currentRecord: {},
      };
    }
    case types.RC_ANNOUNCEMENT_SUCCEEDED: {
      return {
        ...state,
        rcTableData: action.payload,
        isRCTableLoading: false,
      };
    }
    case types.RC_ANNOUNCEMENT_FAILED: {
      return {
        ...state,
        rcTableData: [],
        isRCTableLoading: false,
      };
    }
    case types.RC_ANNOUNCEMENT_FORM_TOGGLE: {
      return {
        ...state,
        isOpenForm: action.payload,
      };
    }
    case types.RC_ANNOUNCEMENT_SET_CURRENT_RECORD: {
      return {
        ...state,
        currentRecord: action.payload,
      };
    }
    case types.RC_ANNOUNCEMENT_SET_FORM_LOADING: {
      return {
        ...state,
        isFormLoading: action.payload,
      };
    }
    case types.RC_ANNOUNCEMENT_ACTION_FAILED: {
      return {
        ...state,
        actionFailedMessage: action.payload,
      };
    }
    case types.RC_ANNOUNCEMENT_CLEAR_FAILED_ACTION: {
      return {
        ...state,
        actionFailedMessage: "",
      };
    }
    case types.SUSTAINABILITY_SUMMARY_UPDATE_SUCCEEDED: {
      return {
        ...state,
        isFormLoading: false,
      };
    }
  }
  return state;
}

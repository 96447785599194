import { Tooltip } from "antd";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, Fragment } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";


import { setActiveButton } from "../../action/actionUserManagement";

import { fetchMapData, isOpenSideBarMobile, updateActiveTab } from "../../action/actionMap";
import { setShowFP } from "../../action/actionForwardPrice";
import { URL_SIDE_BAR } from "../../constants/map.constants";
import { URL_SIDE_BAR_CONFIG } from "../../constants/sidebar.constants";
import { signOut } from "../../AuthService";
import { isUserAdmin } from "../../utils/user.utils";
import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import { checkActiveTab } from "../../utils/Url.utils";
import { getDefaultMap, getDefaultView, getSustainabilityMap, isSuperAdmin, userRoutes } from "../../utils/user.utils";
import NavigationComponent from "../Navigation/NavigationComponent";
import s from "../SideBar/sidebar.module.css";
import { fetchGhgData } from "../../action/actionSustainability";

const SideBarComponent = (props: any) => {
  let adminIcon = {
    [userRoutes()[4]?.path]: "/img/icon/Map.svg",
    [userRoutes()[5]?.path]: "/img/icon/ValueChain.svg",
    [userRoutes()[6]?.path]: "/img/icon/Scorecards.svg",
  };
  const history = useHistory();
  const pathName = window.location.pathname;
  const { isOpenSideBar } = useSelector((state: any) => state.map);

  const activeButton = useSelector((state: any) => state.usermanagement.activeButton); //to track whether button is clicked or not

  const dispatch = useDispatch();
  function handleActiveTabe(tab: string) {
    dispatch(updateActiveTab(tab));
  }

  const handleCloseSideBar = (isOpen: boolean) => {
    dispatch(isOpenSideBarMobile(isOpen));
  };

  const selectedPersona = useSelector((state) =>
    get(state, "user.selectedPersona")
  );

  const personaSelection = useSelector((state) =>
    get(state, "user.personaSelection")
  );

  function _renderLink(config: any) {

    function isActiveTab(pathName: string, currentTab: string) {
      return pathName.includes(currentTab) ? true : false;
    }
    const isActive = isActiveTab(
      pathName === '/' && config.path === URL_SIDE_BAR.MAP
        ? URL_SIDE_BAR.MAP
        : pathName,
      config.path
    );

    return (
      <div
        className={
          `page-action` +
          checkActiveTab(
            pathName === "/" && config.path === URL_SIDE_BAR.MAP
              ? URL_SIDE_BAR.MAP
              : pathName,
            config.path
          )
        }
        onClick={() => {
          handleActiveTabe(config.path);
          handleCloseSideBar(false);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleActiveTabe(config.path);
            handleCloseSideBar(false);
          }
        }}
        tabIndex={0}
      >
        <div className="page-action-icon">
          <img src={isActive ? config.iconActive : config.icon} alt={config.alt} />
        </div>
        <div className="page-action-text">{config.text}</div>
      </div>
    );
  }

  function _renderNonAccessLink(config: any) {
    return (
      <Tooltip
        overlayClassName="tooltipLink"
        placement="bottomLeft"
        title={`No access to ${config.text}`}
      >
        <div className={`page-action ${s.personaAccess}`}>
          <div className="page-action-icon">
            <img src={config.icon} alt={config.alt} />
          </div>
          <div className="page-action-text">{config.text}</div>
        </div>
      </Tooltip>
    );
  }

  function getDefaultMapLink(defaultMap) {
    const { locationId, isDefault, level } = defaultMap;
    return `/map?location=${locationId}&satellite=${isDefault}&level=${level}`
  }

  function getDefaultSustainabilityLink(defaultMap) {
    const { locationId, level } = defaultMap;
    return `/sustainability?location=${locationId}&level=${level}`
  }

  function handleClickSidebar(config: any) {
    sessionStorage.setItem("preUrl", pathName);
    if (config.path.indexOf("map") > 0) {
      const defaultMap = getDefaultMap();
      const defaultMapLink = getDefaultMapLink(defaultMap);
      history.push(defaultMapLink)
      dispatch(fetchMapData(defaultMap.locationId));
    }
    else if (config.path.indexOf("sustainability") > 0) {
      const defaultMap = getSustainabilityMap();
      const defaultMapLink = getDefaultSustainabilityLink(defaultMap);
      history.push(defaultMapLink)
      dispatch(fetchGhgData(defaultMap.locationId));
    }
    else {
      const defaultLink =
        getDefaultView(config.path, config.viewType) || config.path;
      history.push(defaultLink);
      dispatch(setActiveButton(config.path)); //update the state of the active button
    }
  }

  function setActiveSidebar(link) {
    //determine if its clicked from dropdown menu or from the sidebar
    if (activeButton !== pathName)
      return link === pathName ? s.activeSidebar : "";
    else
      return link === activeButton ? s.activeSidebar : "";
  }
  function _renderAdminSidebar() {
    let menuList = isSuperAdmin() ? userRoutes() : userRoutes()?.filter?.(menu => !menu.isSuperAdmin)
    return (
      <div className={s.adminSidebar}>
        {menuList.map((config, idx) => {
          let icon = adminIcon[config.path]
            ? adminIcon[config.path]
            : config.icon;
          return (
            <div key={idx} className={setActiveSidebar(config.path)}>
              <div>
                <div
                  className={s.adminSidebarButton}
                  onClick={() => handleClickSidebar(config)}
                >
                  <img src={icon} alt={config.alt} />
                  <span>{config.text}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  function _renderRiskSidebar() {
    return (
      <div className={s.adminSidebar}>
        {/* <div>
          <div>
            <div className={s.adminSidebarButton}>
              <img src='/img/icon/admin-module.svg' />
              <span>ASSURANCE</span>
            </div>
          </div>
        </div> */}
        <div>
          <div className={s.activeSidebar}>
            <div className={s.adminSidebarButton}>
              <img src='/img/icon/riskProfile-active.svg' />
              <span>RISK PROFILE</span>
            </div>
          </div>
        </div>
        {/* <div>
          <div>
            <div className={s.adminSidebarButton}>
              <img src='/img/icon/scorecardorderings.svg' />
              <span>INTEGRITY AND PLANNING</span>
            </div>
          </div>
        </div> */}
      </div>
    );
  }

  function isPersonaAccessible(path: string) {
    const pathArr = path.split("/");
    const getPath = pathArr[pathArr.length - 1];
    let returnIsAccess = false;
    personaSelection.forEach((item) => {
      if (item.opuName === selectedPersona) {
        returnIsAccess = item.viewUrl[getPath] !== "";
      }
    });
    return returnIsAccess;
  }

  function _renderSidebarLink(config, idx) {
    const isPersonaAccess = isPersonaAccessible(config.path);
    return (
      <>
        {isPersonaAccess ? (
          <Link
            key={idx}
            to={getDefaultView(config.path, config.viewType) || config.path}
            onClick={() => handleClickSidebar(config)}
          >
            {_renderLink(config)}
          </Link>
        ) : (
          <>{_renderNonAccessLink(config)}</>
        )}
      </>
    );
  }

  function _renderSidebar() {
    return null;
  }

  const _renderDesktopSideBar = () => {
    return (
      <>
        {pathName.indexOf("admin") > 0
          ? _renderAdminSidebar()
          : pathName.indexOf("riskprofile") > 0
          ? _renderRiskSidebar()
          : _renderSidebar()}
      </>
    );
  };

  function _renderGroupButton() {
    return (
      <div className={s.groupButton}>
        {userRoutes().map((config, idx) => {
          if (config.base) {
            return _renderSidebarLink(config, idx);
          }
          return "";
        })}
      </div>
    );
  }

  const [showAdminButton, setAdminButton] = useState(true);
  function _renderAdminButton() {

    const toggleAdminButton = () => {
      setAdminButton(!showAdminButton);
    };

    return (      
      <div
        className='page-action'
        onClick={toggleAdminButton}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            toggleAdminButton();
          }
        }}
        tabIndex={0}
      >
        <div className='page-action-icon'>
          <img src={!showAdminButton? '/img/icon/Admin-green.svg' : '/img/icon/Admin-grey.svg'} alt='Admin' />
        </div>
        <div className='page-action-text'>Admin</div>
        <div className='page-action-arrow'>
          <img src={!showAdminButton? '/img/icon/Chevron Up.svg' : '/img/icon/Chevron Down.svg'} alt='Arrow' />
        </div>
        {!showAdminButton && _renderUsermangementContent()}
        {/* {!showAdminButton &&
          <div className='page-action-dropdown'>
            <div className='page-action-icon'>
            <img src='/img/icon/Admin-grey.svg' alt='User Management'></img>
            </div>
            <div className='page-action-text'>User Management</div>
          </div>
        } */}
      </div>
    );
  }

  const [isPopUser, setIsPopUser] = useState(false);
  function adminFunc(link) {
    setIsPopUser(false);
    dispatch(updateActiveTab(URL_SIDE_BAR.USERMANAGEMENT));
    history.push(link);
  }

  function _renderUsermangementContent() {
    return (
      <div className='page-action-dropdown-container'>
        <div
          className='page-action-dropdown'
          onClick={() => adminFunc(URL_SIDE_BAR.USERMANAGEMENT)} 
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              adminFunc(URL_SIDE_BAR.USERMANAGEMENT);
            }
          }}
          tabIndex={0}
        >
          <div className='page-action-icon'> <img src="/img/icon/usermanagements.svg" alt="usermanagement" /></div>
          <div className='page-action-text'>User Management</div>
        </div>
        <div
          className='page-action-dropdown'
          onClick={() => adminFunc(URL_SIDE_BAR.LIMITCONFIGURATION)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              adminFunc(URL_SIDE_BAR.LIMITCONFIGURATION);
            }
          }}
          tabIndex={0}
        >
          <div className='page-action-icon'> <img src="/img/icon/limitconfigs.svg" alt="configuration" /></div>
          <div className='page-action-text'>Limit Configuration</div>
        </div>
        <div
          className='page-action-dropdown'
          onClick={() => adminFunc(URL_SIDE_BAR.SCORECARDORDERING)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              adminFunc(URL_SIDE_BAR.SCORECARDORDERING);
            }
          }}
          tabIndex={0}
        >
          <div className='page-action-icon'> <img src="/img/icon/scorecardorderings.svg" alt="scorecardordering" /></div>
          <div className='page-action-text'>Scorecard Ordering</div>
        </div>
        <div
          className='page-action-dropdown'
          onClick={() => adminFunc(URL_SIDE_BAR.RELEASE_CONTENT)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              adminFunc(URL_SIDE_BAR.RELEASE_CONTENT);
            }
          }}
          tabIndex={0}
        >
          <div className='page-action-icon'> <img src="/img/icon/release-content-grey.svg" alt="releasecontent" style={{ opacity: '0.6', width: '24px', height:'24px', fill: '#ffffff'}}/></div>
          <div className='page-action-text'>Release Content</div>
        </div>
        <div
          className='page-action-dropdown'
          onClick={() => adminFunc(URL_SIDE_BAR.DATA_HEALTH_DASHBOARD)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              adminFunc(URL_SIDE_BAR.DATA_HEALTH_DASHBOARD);
            }
          }}
          tabIndex={0}
        >
          <div className='page-action-icon'> <img src="/img/icon/datahealths.svg" alt="datahealth" /></div>
          <div className='page-action-text'>Data Health Dashboard</div>
        </div>
        <div
          className='page-action-dropdown'
          onClick={() => adminFunc(URL_SIDE_BAR.SCORECARD_MANAGEMENT)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              adminFunc(URL_SIDE_BAR.SCORECARD_MANAGEMENT);
            }
          }}
          tabIndex={0}
        >
          <div className='page-action-icon'> <img src="/img/icon/ScorecardManagement_grey.svg" alt="scoremanagement" style={{ opacity: '0.6', width: '18px', marginLeft: '5px' }} /></div>
          <div className='page-action-text'>Scorecard Management</div>
        </div>
      </div>
    );
  }

  function _renderProfileButton() {
    const [showProfileButton, setProfileButton] = useState(true);

    const toggleProfileButton = () => {
      setProfileButton(!showProfileButton);
    };

    const isActive = true;

    return (      
      <div
        className={`page-action ${!showAdminButton ? 'page-action-shift' : ''}`}
        onClick={toggleProfileButton}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            toggleProfileButton();
          }
        }}
        tabIndex={0}
      >
        <div className='page-action-icon'>
          <img src={!showProfileButton? '/img/icon/Profile-active.svg' : '/img/icon/Profile.svg'} alt='Profile' />
        </div>
        <div className='page-action-text'>Profile</div>
        <div className='page-action-arrow'>
          <img src={!showProfileButton? '/img/icon/Chevron Up.svg' : '/img/icon/Chevron Down.svg'} alt='Arrow' />
        </div>
        {!showProfileButton &&
        <div className='page-action-dropdown-container'>
          <div
            className='page-action-dropdown'
            onClick={() => signOut()}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                signOut();
              }
            }}
            tabIndex={0}
          >
            <div className='page-action-icon'>
            <img src='/img/icon/Logout.svg' alt='Logout'></img>
            </div>
            <div className='page-action-text'>Logout</div>
          </div>
        </div>
        }
      </div>
    );
  }

  return (
    <>
      <Desktop>{_renderDesktopSideBar()}</Desktop>
      <Mobile>
        <div
          className={
            isOpenSideBar ? s.backGroundSideBarMobile : s.isCloseSideBarMobile
          }
        >
          <div className={s.headerSideBar}>
            <div className={s.logoBrand}>
              <div className={s.logo}>
                <img src="/img/icon/Logo Petronas.svg" height="40" alt="React" />
              </div>
              <div className={s.brandTxt}>GO DASHBOARD</div>
            </div>
            <div
              className={s.iconClose}
              onClick={() => handleCloseSideBar(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCloseSideBar(false);
                }
              }}
              tabIndex={0}
            >
              <img src="\img\icon\ic_close.svg" alt="" />
            </div>
          </div>
          <div className={s.naviBackground}>
            <NavigationComponent />
            {_renderGroupButton()}
            <div
              className={'page-action'}
              onClick={() => dispatch(setShowFP(true))}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  dispatch(setShowFP(true));
                }
              }}
              tabIndex={0}
            >
            <img
              className={'page-action-icon'}
              src='/img/icon/Farword curve.svg'
              alt='Notifications'
            />
            <span className={'page-action-text'}>Forward Price</span>
          </div>

          <a
            target='_blank'
            rel='noreferrer'
            href='https://confluence.petronas.com/display/GoDashboard/GO+Dashboard+Home'
          >
            <div className={'page-action'}>
              <img
                className={'page-action-icon'}
                src='/img/icon/confluence.svg'
                alt='confluence'
              />
              <span className={'page-action-text'}>Reference</span>
            </div>
          </a>

          <a
            target='_blank'
            rel='noreferrer'
            href='https://forms.office.com/r/5z454TynY3'
          >
            <div className={'page-action'}>
              <img
                className={'page-action-icon'}
                src='/img/icon/feedback.svg'
                alt='feedback'
              />
              <span className={'page-action-text'}>Feedback</span>
            </div>
          </a>

          {isUserAdmin() && _renderAdminButton()}
          {_renderProfileButton()}
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div
          className={
            isOpenSideBar ? s.backGroundSideBarMobile : s.isCloseSideBarMobile
          }
        >
          <div className={s.headerSideBar}>
            <div className={s.logoBrand}>
              <div className={s.logo}>
                <img src="/img/icon/Logo.svg" height="40" alt="React" />
              </div>
              <div className={s.brandTxt}>GO DASHBOARD</div>
            </div>
            <div
              className={s.iconClose}
              onClick={() => handleCloseSideBar(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCloseSideBar(false);
                }
              }}
              tabIndex={0}
            >
              <img src="\img\icon\ic_close.svg" alt="" />
            </div>
          </div>
          <div className={s.naviBackground}>
            <NavigationComponent />
            {_renderGroupButton()}
            <div
              className={'page-action'}
              onClick={() => dispatch(setShowFP(true))}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  dispatch(setShowFP(true));
                }
              }}
              tabIndex={0}
            >
            <img
              className={'page-action-icon'}
              src='/img/icon/Farword curve.svg'
              alt='Notifications'
            />
            <span className={'page-action-text'}>Forward Price</span>
          </div>

          <a
            target='_blank'
            rel='noreferrer'
            href='https://confluence.petronas.com/display/GoDashboard/GO+Dashboard+Home'
          >
            <div className={'page-action'}>
              <img
                className={'page-action-icon'}
                src='/img/icon/confluence.svg'
                alt='confluence'
              />
              <span className={'page-action-text'}>Reference</span>
            </div>
          </a>

          <a
            target='_blank'
            rel='noreferrer'
            href='https://forms.office.com/r/5z454TynY3'
          >
            <div className={'page-action'}>
              <img
                className={'page-action-icon'}
                src='/img/icon/feedback.svg'
                alt='feedback'
              />
              <span className={'page-action-text'}>Feedback</span>
            </div>
          </a>

          {isUserAdmin() && _renderAdminButton()}
          {_renderProfileButton()}
          </div>
        </div>
      </Tablet>
    </>
  );
};
export default SideBarComponent;

import { Col, Row } from 'antd';
import { get, isNull } from 'lodash';
import { useSelector } from 'react-redux';
import { formatScoreCardTargetValue } from '../../../../../utils/Number.utils';

import s from '../../SecondaryCard.module.css';
import cardStyle from './LmtHssePerformanceSecondary.module.css';
import { MONTH_SHORT_TEXT } from '../../../../../constants/score-card.constants';

function LmtHsseFatality() {
    const { secondaryCardData, selectedDate } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            secondaryCardData: scorecard.secondaryCardDetail,
            selectedDate: scorecard.currentDate,
        };
    });

    const fatalityData = secondaryCardData.fatalities;

    return (
        <div className={s.detailsContent}>
            {fatalityData?.length > 0 &&
                fatalityData.map((card) => {
                    return (
                        <div className={s.detailsItem}>
                            <Row>
                                <Col span={6}>
                                    <div className={cardStyle.sectionInfo}>
                                        <div className={cardStyle.sectionDate}>
                                            {`${card.date != null ? card.date : ''} ${MONTH_SHORT_TEXT[selectedDate.month-1]} ${secondaryCardData.year}`}
                                        </div>
                                        <div className={cardStyle.sectionEntity}>
                                            {card.entity}
                                        </div>
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <div className={cardStyle.sectionDetail}>
                                        {card.details}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
        </div>
    );
}

export default LmtHsseFatality;



import axiosClient from "./axiosClient.service";
import { ENDPOINTS } from "../constants/endpoints";
import { AxiosPromise } from "axios";
export function getAvatarUser(data: any) {
  return axiosClient.get(`${ENDPOINTS.MICROSOFT_GRAPH_BASE_URL}/beta/users/${data}/photo/$value`, { responseType: 'blob' })
}

export function getUserProperties(data: any) {
  return axiosClient.get(`${ENDPOINTS.MICROSOFT_GRAPH_BASE_URL}/v1.0/me/?$select=displayName, mail`, { responseType: 'json' })
}

export function getOPUsUserAccess() {
  return axiosClient.get(`${ENDPOINTS.GET_OPUS_USER_ACCESS}`, { responseType: 'json' })
}

export function getAdminsUserAccess() {
  return axiosClient.get(`${ENDPOINTS.GET_ADMIN_USER_ACCESS}`, { responseType: 'json' })
}

export function sendUserAccess(data: any) {
  return axiosClient.post(`${ENDPOINTS.SEND_USER_ACCESS}`, data)
}

export function getRequestStatus(): AxiosPromise<{ result: { isRequested: boolean } }> {
  return axiosClient.get(`${ENDPOINTS.GET_REQUEST_STATUS}`)
}



import { Modal, notification, Popover, Switch } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCreateUser, fetchDeleteUser, fetchUpdateUser, fetchUsersManagement, reInitUserTableStatus } from '../../../action/actionUserManagement';
import { STATUS_STR } from '../../../constants/endpoints';
import { MODALDELHEADERFIRST, MODALDELHEADERSECOND, MODALDELSUBTXT, MODALTYPE } from '../../../constants/usermanagement.constanst';
import { IUserPersona } from '../../../model/user.model';
import { getUserPersona, isSuperAdmin } from '../../../utils/user.utils';
import s from './AddUserModalComponent.module.css';

const intiForm: any = {
    userId: "",
    name: "",
    email: "",
    opuId: "",
    opuName: "",
    personaId: "",
    personaName: "",
    accessId: "",
    accessRole: "",
    accessReason: "",
    enabled: false,
};
const AddUserModalPageComponent = (props: any) => {
    const dispatch = useDispatch();
    const { isVisible, opus, personas, closeFunc, modalType, record, roles,statusFilter,sortType } = props;
    const [modalForm, setModalForm] = useState(intiForm);
    const [isDelModalShow, setIsDelModalShow] = useState(false);
    const [isShowOPUDropdown, setIsShowOPUDropdown] = useState(false);
    const [isShowPersonaDropdown, setIsShowPersonaDropdown] = useState(false);
    const isOPUAdmin = !isSuperAdmin();
    const userPersona: IUserPersona = getUserPersona();
    const userTableStatus = useSelector((state) =>
        get(state, "usermanagement.userTableStatus", 0)
    );
    const [selectedOpu, setSelectedOpu] = useState(intiForm.opuName);
    const [selectedPersona, setSelectedPersona] = useState(intiForm.personaName);

    const accessId = "accessId";
    const accessRole = "accessRole";

    useEffect(() => {
        if (isOPUAdmin) {
            setModalForm({
                ...modalForm,
                opuId: userPersona.opuId,
                opuName: userPersona.opu,
            });
        }
        return () => {
            dispatch(reInitUserTableStatus());
        }
    }, []);

    useEffect(() => {
        if (modalType === MODALTYPE.EDIT) {
            setModalForm({
                userId: record.userId,
                name: record.name,
                email: record.email,
                opuId: record.opuId,
                opuName: record.opuName,
                personaId: record.personaId,
                personaName: record.personaName,
                accessId: record.accessId,
                accessRole: record.accessRole,
                accessReason: record.accessReason,
                enabled: record.enabled
            });
            setSelectedOpu(record.opuName);
            setSelectedPersona(record.personaName);
        }
    }, [modalType])


    function successNotification() {
        notification.open({
            message: 'Successfully Saved',
            description: (<>The user <b>"{modalForm.name}"</b> has been updated successfully.</>),
            duration: 3,
            className: 'gotoast',
            icon: <img src="/img/icon/ToastTick.svg" alt="close" />,
        });
        setModalForm(intiForm);
    }
    useEffect(() => {
        switch (userTableStatus) {
            case STATUS_STR.CREATED:
            dispatch(fetchUsersManagement({ status: statusFilter, sortType: sortType }));

                successNotification();
                break;
            case STATUS_STR.UPDATE:
                dispatch(fetchUsersManagement({ status: statusFilter, sortType: sortType }));
                successNotification();
                break;
            case STATUS_STR.DELETE:
                notification.open({
                    message: 'Successfully deleted',
                    description: (<>The user <b>"{modalForm.name}"</b> has been deleted successfully.</>),
                    duration: 3,
                    className: 'gotoast',
                    icon: <img src="/img/icon/ToastTick.svg" alt="close" />,
                });
                setModalForm(intiForm);
                dispatch(fetchUsersManagement({ status: statusFilter, sortType: sortType }));
                break;
            case STATUS_STR.ERROR:
                notification.open({
                    message: 'Saving failed',
                    description: `The changes couldn't be saved.`,
                    duration: 3,
                    className: 'gotoastfailed',
                    icon: <img src="/img/icon/ToastFail.svg" alt="close" />,
                });
                setModalForm(intiForm);
                dispatch(fetchUsersManagement({ status: statusFilter, sortType: sortType }));
                break;
        }
    }, [userTableStatus])

    var superAdminAccess = roles.find(r => r.name === "Super Admin");
    var opuAdminAccess = roles.find(r => r.name === "OPU Admin");

    useEffect(() => {
        if (selectedPersona === "Admin" || selectedPersona === "Developer") {
            setModalForm({ ...modalForm, [accessId]: superAdminAccess.id, [accessRole]: superAdminAccess.name })
        }
        else {
            setModalForm({ ...modalForm, [accessId]: 0, [accessRole]: "Normal User" })
        }
    }, [selectedOpu, selectedPersona])


    function _renderPopOpus() {
        return (
            <div className={s.popoverContent}>
                {opus.map((values, idx) => (
                    <span
                        key={idx}
                        tabIndex={0}
                        onClick={() => {
                            setIsShowOPUDropdown(false);
                            setDropdown("opuId", "opuName", values.id, values.name);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setIsShowOPUDropdown(false);
                              setDropdown("opuId", "opuName", values.id, values.name);
                            }
                        }}
                        >
                        {values.name}
                    </span>
                ))}
            </div>
        );
    }
    
    function _renderPopPersonas() {
        let personaList = personas[modalForm.opuId]
        ? personas[modalForm.opuId]
        : [];
        return (
            <div className={s.popoverContent}>
                {personaList.map((values, idx) => (
                    <span
                        key={idx}
                        tabIndex={0}
                        onClick={() =>
                            setDropdown("personaId", "personaName", values.id, values.name)
                            }
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setDropdown("personaId", "personaName", values.id, values.name);
                            }
                        }}
                    >
                        {values.name}
                    </span>
                ))}
            </div>
        );
    }

    function _renderPopRole() {
        return (
            <div className={s.popoverContent}>
                {roles.map((values, idx) => (
                    <span
                        key={idx}
                        onClick={() =>
                            setDropdown("accessId", "accessRole", values.id, values.name)
                        }
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setDropdown("accessId", "accessRole", values.id, values.name);
                            }
                        }}
                        tabIndex={0}
                    >
                        {values.name}
                    </span>
                ))}
            </div>
        );
    }

    function setDropdown(attrId, attrName, id, name) {
        if (attrId === "opuId") {
            setSelectedOpu(name);
            setSelectedPersona("");
            setModalForm({
                ...modalForm,
                [attrId]: id,
                [attrName]: name,
                personaId: "",
                personaName: "",
            });
        }
        else if (attrId === "personaId") {
            setSelectedPersona(name);
            setModalForm({ ...modalForm, [attrId]: id, [attrName]: name });
        }
        else {
            setModalForm({ ...modalForm, [attrId]: id, [attrName]: name });
        }
    }

    function setText(e, attr) {
        setModalForm({ ...modalForm, [attr]: e.target.value });
    }

    function closeModal() {
        setModalForm(intiForm);
        closeFunc();
    }

    function renderModalHeader() {
        const headerText = modalType === MODALTYPE.CREATE ? 'Add User' : 'Edit User'
        return (
            <div className={s.modalHeader}>
                <p>{headerText}</p>
                <img
                    onClick={() => closeModal()}
                    src="/img/icon/CrossPopup.svg"
                    alt="close"
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            closeModal();
                        }
                    }}
                />
            </div>
        )
    }
    function getInitials(name) {
        const hasTokens = name.indexOf(' ') !== -1
        return name.substring(0, hasTokens ? 1 : 2) + (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
    }

    function renderModalInput() {
        return (
            <>
                {modalType === MODALTYPE.EDIT ?
                    <div className={s.modalEditHeader}>
                        <p className={s.headerInitial}>{getInitials(modalForm.name)}</p>
                        <div className={s.headerUsersDetail}>
                            <span>{modalForm.name}</span>
                            <span>{modalForm.email}</span>
                        </div>
                    </div>
                    : <>
                        <div>
                            <p>User name</p>
                            <input
                                type="text"
                                placeholder='Input User name'
                                onChange={(e) => setText(e, "name")}
                                value={modalForm.name}
                            />
                        </div>
                        <div>
                            <p>Email</p>
                            <input
                                type="text"
                                placeholder='Input User email'
                                onChange={(e) => setText(e, "email")}
                                value={modalForm.email}
                            />
                        </div>
                    </>

                }
            </>
        )
    }

    function setActiveUser(e) {
        setModalForm({ ...modalForm, enabled: e });
    }

    const createUser = () => {
        dispatch(fetchCreateUser(modalForm));
        closeFunc();
    }
    const editUser = () => {
        dispatch(fetchUpdateUser(modalForm));
        closeFunc();
    }
    function renderModalFooter() {
        const buttonText = modalType === MODALTYPE.CREATE ? 'Add User' : 'Save';
        return (
            <div className={s.modalFooter}>
                <div className='addUserManagementSwitch'>
                    <span>Status</span>
                    <Switch onChange={(e) => setActiveUser(e)} checkedChildren="Enable" unCheckedChildren="Disable" checked={modalForm.enabled} />
                </div>
                <div>
                    {modalType === MODALTYPE.EDIT && <button className={s.modalFooterDelButton} onClick={() => setIsDelModalShow(true)}>Delete</button>}
                    <button className={s.modalFooterButton} onClick={() => modalType === MODALTYPE.CREATE ? createUser() : editUser()}>{buttonText}</button>
                </div>
            </div>
        )
    }

    const deleteUser = () => {
        dispatch(fetchDeleteUser(modalForm));
        setIsDelModalShow(false);
        closeFunc();
    }

    const getRootContainer = (elementClassName): HTMLElement => {
        const elements = document.getElementsByClassName(elementClassName);
        if (elements.length > 0) {
            return elements[0] as HTMLElement;
        }
        return document.body;
    };

    function renderDeletePrompt() {
        return (
            <Modal
                className="delUserConfirmModalComponent"
                closable={false}
                visible={isDelModalShow}
                footer={null}
                width={420}
                style={{ top: 300 }}
            >
                <div className={s.modalDelContent}>
                    <div className={s.modalDelIcon}>
                        <img src="/img/icon/Info.svg" alt="arrow" />
                    </div>
                    <div className={s.modalDelHeader}>
                        <span>{MODALDELHEADERFIRST}</span>
                        <span>{`${MODALDELHEADERSECOND}`}</span>
                        <span>{`'${modalForm.name}'?`}</span>
                    </div>
                    <div className={s.modalDelSubtxt}>
                        <span>{`${MODALDELSUBTXT}`}</span>
                    </div>
                    <div className={s.modalDelButton}>
                        <button className={s.modalDelFooterCancelButton} onClick={() => setIsDelModalShow(false)}>Cancel</button>
                        <button className={s.modalDelFooterDelButton} onClick={() => deleteUser()}>Delete</button>
                    </div>

                </div>
            </Modal>
        )
    }
    return (
        <>
            <Modal
                className="addUserModalComponent"
                closable={false}
                visible={isVisible}
                footer={null}
                width={600}
                /*style={{ top: 225 }}*/
                centered={true}
            >
                {renderModalHeader()}
                <div className={s.divider} />
                <div className={s.modalContent}>
                    {renderModalInput()}
                    <div className={s.modalDropdownContainer}>
                        <div className={s.opuPopover}>
                            <p>OPU</p>
                            <Popover
                                overlayClassName="tbodyDropdown"
                                placement="bottom"
                                content={_renderPopOpus()}
                                trigger="focus"
                                getPopupContainer={() => getRootContainer(s.opuPopover)}
                                visible={isShowOPUDropdown}
                            >
                                <div className={s.tbodyText}>
                                    <input
                                        type="text"
                                        value={modalForm.opuName}
                                        disabled={isOPUAdmin}
                                        onClick={() => setIsShowOPUDropdown(true)}
                                        onBlur={() => {
                                            setTimeout(() => {
                                                setIsShowOPUDropdown(false)
                                            }, 300);
                                        }}
                                    />
                                    <img
                                        onClick={() => setIsShowOPUDropdown(false)}
                                        src="/img/icon/DownArrow.svg"
                                        alt="arrow"
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                setIsShowOPUDropdown(false);
                                            }
                                        }}
                                    />
                                </div>
                            </Popover>
                        </div>
                        <div className={s.personaPopover}>
                            <p>PERSONA</p>
                            <Popover
                                overlayClassName="tbodyDropdown"
                                placement="bottom"
                                content={_renderPopPersonas()}
                                trigger="focus"
                                getPopupContainer={() => getRootContainer(s.personaPopover)}
                                visible={isShowPersonaDropdown}
                            >
                                <div className={s.tbodyText}>
                                    <input type="text" value={modalForm.personaName}
                                        onClick={() => setIsShowPersonaDropdown(true)}
                                        onBlur={() => {
                                            setTimeout(() => {
                                                setIsShowPersonaDropdown(false)
                                            }, 300);
                                        }} />
                                    <img
                                        onClick={() => setIsShowPersonaDropdown(false)}
                                        src="/img/icon/DownArrow.svg"
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                setIsShowPersonaDropdown(false);
                                            }
                                        }}
                                    />
                                </div>
                            </Popover>
                        </div>
                        {/* <div className={s.rolePopover}>
                            <p>Role</p>
                            <Popover
                                overlayClassName="tbodyDropdown"
                                placement="bottom"
                                content={_renderPopRole()}
                                trigger="focus"
                                getPopupContainer={()=>getRootContainer(s.rolePopover)}
                            >
                                <div className={s.tbodyText}>
                                    <input type="text" value={modalForm.accessRole} />
                                    <img
                                        onClick={() => closeFunc()}
                                        src="/img/icon/DownArrow.svg"
                                        alt="arrow"
                                    />
                                </div>
                            </Popover>
                        </div> */}
                    </div>
                    <div>
                        <p>REASON</p>
                        <textarea
                            disabled={modalType === MODALTYPE.EDIT}
                            placeholder='Input your reason'
                            onChange={(e) => setText(e, "accessReason")}
                            value={modalForm.accessReason}
                        />
                    </div>
                    {renderModalFooter()}
                </div>
            </Modal>
            {renderDeletePrompt()}
        </>
    );
};
export default AddUserModalPageComponent;

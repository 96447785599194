import axios from "axios";
import queryString from "query-string";

import { finishARequest, sendARequest } from "../action/actionLoading";
import { fetchAuth } from "../action/actionPrivateRoute";
import { getAzureADToken } from "../AuthService";
import { ENDPOINTS, IGNORE_URL_COUNT_REQUEST } from "../constants/endpoints";
import { SESSION_STORAGE_KEY } from "../constants/user.constants";
import { store } from "../store/store";
import { ignoreUrlWhenCountRequest } from "../utils/Url.utils";

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request config` for the full list of configs
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem(getApiTokenKey()),
    "Auth-Persona": sessionStorage.getItem(SESSION_STORAGE_KEY.PERSONA_TOKEN),
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

export const axiosClientFormData = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "multipart/form-data",
    Authorization: "Bearer " + sessionStorage.getItem(getApiTokenKey()),
    "Auth-Persona": sessionStorage.getItem(SESSION_STORAGE_KEY.PERSONA_TOKEN),
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

export const axiosClientFile = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "blob",
    Authorization: "Bearer " + sessionStorage.getItem(getApiTokenKey()),
    "Auth-Persona": sessionStorage.getItem(SESSION_STORAGE_KEY.PERSONA_TOKEN),
  },
  paramsSerializer: (params) => queryString.stringify(params),
  responseType: "arraybuffer"
});

export const axiosClientFileAnonymous = axios.create({
  headers: {
    "content-type": "application/octet-stream",
  },
});

export const axiosClientDownloadFileAnonymous = axios.create({
  headers: {
    "response-type": "arraybuffer",
  },
});


function getApiTokenKey() {
  return `${SESSION_STORAGE_KEY.API_ACCESS_TOKEN}-${process.env.REACT_APP_STAGE}`;
}

function getGraphApiTokenKey() {
  return `${SESSION_STORAGE_KEY.GRAPH_ACCESS_TOKEN}-${process.env.REACT_APP_STAGE}`;
}

axiosClient.interceptors.request.use(
  async (config) => {
    if (config.url?.startsWith(ENDPOINTS.MICROSOFT_GRAPH_BASE_URL)) {
      config.headers = {
        Authorization:
          "Bearer " + sessionStorage.getItem(getGraphApiTokenKey()),
      };
    } else {
      const azureADJWT = await getAzureADToken();

      config.headers = {
        Authorization: "Bearer " + azureADJWT,
        "Auth-Persona": sessionStorage.getItem(
          SESSION_STORAGE_KEY.PERSONA_TOKEN
        ),
      };
    }
    // Handle token here ...
    if (
      config.url &&
      ignoreUrlWhenCountRequest(config?.url)
    ) {
      store.dispatch(sendARequest());
    }
    return config;
  },
  (error) => {
    store.dispatch(finishARequest());
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    if (
      response.config.url &&
      ignoreUrlWhenCountRequest(response.config.url)
    ) {
      store.dispatch(finishARequest());
    }
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  async (error) => {
    if (
      error.response.status === 403 &&
      error.response.data.indexOf("Token expired") > -1
    ) {
      store.dispatch(fetchAuth());
    }
    // Handle errors
    store.dispatch(finishARequest());
    throw error;
  }
);

export default axiosClient;

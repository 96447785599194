import { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import s from "./GHGMultiointComponent.module.css";
import { toFixedSustainability } from "../../../utils/Number.utils";
import { Col, Row } from "antd";
import { sanitizeNumber } from '../../../utils/common.utils';

const GHGPointComponent = (props: any) => {
    let location = props.data;

    const selectedPoint = useSelector((state) =>
        get(state, 'sustainability.selectedPoint', null)
    );

    function _renderPointGroup() {
        const pointGroupClassName = (location.locationId === selectedPoint?.locationId && location.locationName === selectedPoint?.locationName) ? `${s.pointGroupDot} ${s.selectedDot}` : s.pointGroupDot;
        return (
            <div className={pointGroupClassName}
                onClick={() => handleViewDetail()} 
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {handleViewDetail();}
                }}
                tabIndex={0}
            >
                <span ></span>
            </div>
        )
    }

    // Click point
    function handleViewDetail() {
        if (location?.locationId && !location?.isCummulative) {

        }
    }

    function _renderDataPoint() {
        return _renderPointGroup();
    }

    function locationName() {
        return location.locationName;
    }

    function convertToZero(str: any) {
    if (str === '0.000' || str === '0' || str === '0.00' || str === '0.0') {
        return 0;
    }
    return str; 
}

    const convertedParam = {
        ytd: location.parameters.find((item) => item.displayLabel === "YTD"),
        ytdPlaned: location.parameters.find((item) => item.displayLabel === "Planned YTD"),
        yet: location.parameters.find((item) => item.displayLabel === "YET"),
        yep: location.parameters.find((item) => item.displayLabel === "YEP"),
    }
    const varYTD = (convertedParam.ytdPlaned.value !== '' && convertedParam.ytdPlaned.value !== '0.000') ?
    (((Math.round((convertedParam.ytdPlaned.value !== null ? convertedParam.ytdPlaned.value : 0) * 1000) / 1000) -
        (Math.round((convertedParam.ytd.value !== null ? convertedParam.ytd.value : 0) * 1000) / 1000)) /
        (Math.round(convertedParam.ytdPlaned.value * 1000) / 1000) * 100) : '-';
    const varYEP = convertedParam.yet.value !== '' ? (((Math.round((convertedParam.yet.value!==null ? convertedParam.yet.value : 0) * 1000) / 1000) - (Math.round((convertedParam.yep.value !== null ? convertedParam.yep.value : 0) * 1000) / 1000)) / (Math.round(convertedParam.yet.value * 1000) / 1000) * 100) : '-';

    let parametersFake = [
        {
            displayLabel: "YTD",
            value: convertedParam.ytd.value,
            unit: "Mil",
            data: [{
                displayLabel: 'Plan',
                value: convertedParam.ytdPlaned.value,
            }, {
                displayLabel: 'Var',
                value: varYTD == '-' ? '' : varYTD,
                valueColorGP: varYTD == '-' ? 'E75552' : varYTD < 0 ? '#E75552' : varYTD <= 0.2 ? '#FDB924' : '#00FF5D',
                valueColorLNG: varYTD == '-' ? 'E75552' : varYTD < 0 ? '#E75552' : varYTD <= 0.2 ? '#FDB924' : '#00FF5D',
                unit: "%",  
            }]
        },
        {
            displayLabel: "YEP",
            value: convertedParam.yep.value,
            unit: "Mil",
            data: [{
                displayLabel: 'YET',
                value: convertedParam.yet.value,
            }, {
                displayLabel: 'Var',
                value: varYEP == '-' ? '' : varYEP,
                valueColorGP: varYEP == '-' ? 'E75552' : varYEP < 0 ? '#E75552' : varYEP <= 0.2 ? '#FDB924' : '#00FF5D',
                valueColorLNG: varYEP == '-' ? 'E75552' : varYEP < 0 ? '#E75552' : varYEP <= 0.2 ? '#FDB924' : '#00FF5D',
                unit: "%",
            }]
    }]

    const selectedLocationName = location?.locationName;

    return (
        selectedLocationName === 'LNG Assets' || 'Gas & Power' ? null :
        <div className={`${s.pointGroupWrap} ${location?.position ? s[location?.position] : s.Left} ${s.green}`}
            style={{ top: (sanitizeNumber(location.Y) + "px"), left: sanitizeNumber(location.X) + "px" }}>
            <div className={s.pointGroup}>
                <div className={s.pointGroupInfo}>
                    <div className={s.pointGroupInfoTop}>
                        <span>
                            {locationName() === 'Gas & Power' ? 'Gas Processing & Utilities' : locationName()}
                            <span className={s.subText}> (M tCO<sub>2</sub>e) </span>
                        </span>
                        <div className={s.pointGroupContent}>
                            {
                                // location?.parameters.map((param: any, i: any) => {
                                parametersFake.map((param: any, idx: any) => {
                                    return (
                                        <div key={'y' + idx}>
                                            <span className={s.label}>{param?.displayLabel}</span>
                                            <div className={s.pointGroupInfoBodyText} key={'x' + idx}>
                                                <b>{param?.value !== '' ? toFixedSustainability(param?.value, 3) : '-'}</b>
                                            </div>
                                            <div className={s.pointGroupInfoBodyFooterText}>
                                                {param.data.map((item) => {
                                                    return <Row>
                                                        <Col span={8}>
                                                            <span style={{marginRight: 14}}>{item.displayLabel}</span>
                                                        </Col>
                                                        <Col span={16}>
                                                            <div>
                                                                <b style={{ color: location?.locationName === 'LNG Assets' ? item.valueColorLNG : item.valueColorGP, marginLeft: 4 }}>{item.value !== '' ? toFixedSustainability(item.value, 3) : '-'}</b>
                                                                {item.value && <span className={s.unit} style={{ color: location?.locationName === 'LNG Assets' ? item.valueColorLNG : item.valueColorGP }}>{item.unit}</span>}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                })}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default GHGPointComponent;
import React from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import ReleaseContentTableComponent from '../../../components/ReleaseContent/RCTable/ReleaseContentTableComponent';
import s from './ReleaseContent.module.css';

const ReleaseContentComponent = (props: any) => {
  return (
    <div className='usermanagement'>
      <div className={`fullscreen-pt-0 ${s.userManagementRoot}`}>
        <ScrollContainer
          className={`page-container bg-map ${s.userManagementContainer}`}
        >
          <ReleaseContentTableComponent />
        </ScrollContainer>
      </div>
    </div>
  );
};
export default ReleaseContentComponent;

import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import {
  closeScorecardSecondaryCardModal, 
} from '../../../action/actionScoreCard';

import s from './SecondaryCard.module.css';
import { Modal } from 'antd';
import { FINANCIAL_EXCELLENCE_LABEL, HSSE_PERFORMANCE_LABEL, PLANT_PERFORMANCE_LABEL, PRODUCTION_GENERATION_LABEL
} 
from '../../../constants/score-card.constants';

import BaseLmtOperationalPerformanceSecondary from './LMT/OperationalPerformance/BaseLmtOperationalPerformanceSecondary';
import BaseLmtHssePerformanceSecondary from './LMT/HssePerformance/BaseLmtHssePerformanceSecondary';
import BaseLmtFinancialExcellenceSecondary from './LMT/FinancialExcellence/LmtBaseFinancialExcellenceSecondary';
import GneLngLoadable from './GNE/LNGLoadable/GneLngLoadable';

import { renderScorecardNameBySectionLabel } from "../../../utils/common.utils";
import { OPU_UPCS } from '../../../constants/opu.constants';
import BaseMLNGHssePerformanceSecondary from './LMT/HssePerformance/BaseMLNGHssePerformanceSecondary';
import MlngBaseFinancialExcellenceSecondary from './LMT/FinancialExcellence/MlngBaseFinancialExcellenceSecondary';
import BaseMlngPlantPerformanceSecondary from './LMT/PlantPerformance/BaseMlngPlantPerformanceSecondary';

export interface SecondaryCardProps {
  sectionName: string;
  selectedPersona: string;
  isShowCardNameAsHeader?: boolean;
}

export default function SecondaryCard({ sectionName, selectedPersona, isShowCardNameAsHeader }: SecondaryCardProps) {
  const dispatch = useDispatch();

  const { isVisibleModal, scorecardNameLNGLoadable } = useSelector((state) => {
    const scorecard = get(state, 'scoreCard', null);
    return {
      isVisibleModal: scorecard.isOpenScorecardSecondaryCardModel,
      scorecardNameLNGLoadable: scorecard.selectedScorecard?.name
    };
  });

  const CloseButton = () => <img alt='close-icon' src='/img/icon/close-btn.svg' />;

  const handleCancel = () => {
    dispatch(closeScorecardSecondaryCardModal());
  };

  function renderSecondaryCardContent() {
    if (selectedPersona === OPU_UPCS.GNE) {
      return <GneLngLoadable/>
    }
    else if (sectionName === PRODUCTION_GENERATION_LABEL) {
      return <BaseLmtOperationalPerformanceSecondary/>
    }
    else if (sectionName === HSSE_PERFORMANCE_LABEL) {
      if(selectedPersona === OPU_UPCS.MLNG){
        return <BaseMLNGHssePerformanceSecondary/>
      }
      return <BaseLmtHssePerformanceSecondary/>
    }
    else if (sectionName === FINANCIAL_EXCELLENCE_LABEL) {
      if(selectedPersona === OPU_UPCS.MLNG){
        return <MlngBaseFinancialExcellenceSecondary/>
      }
      return <BaseLmtFinancialExcellenceSecondary/>
    }
    else if (sectionName === PLANT_PERFORMANCE_LABEL) {
      if(selectedPersona === OPU_UPCS.MLNG){
        return <BaseMlngPlantPerformanceSecondary/>
      }
    }
  }

  return (
    <>
      <Modal
        className='scorecardSecondaryModal'
        visible={isVisibleModal}
        footer={null}
        onCancel={handleCancel}
        width='60%'
        zIndex={1000}
        closeIcon={<CloseButton />}
        maskClosable={false}
        transitionName=""
      >
        {selectedPersona === OPU_UPCS.LMT ? (
            <div className={s.modalHeader}>
              <p>{renderScorecardNameBySectionLabel(sectionName, selectedPersona)}</p>
            </div>
          ) : selectedPersona === OPU_UPCS.GNE ? (
            <div className={s.modalHeader}>
              <p>{scorecardNameLNGLoadable}</p>
            </div>
          ) : isShowCardNameAsHeader ? (
            <div className={s.modalHeader}>
                <p>{scorecardNameLNGLoadable}</p>
              </div> 
          ):null
        }

        {renderSecondaryCardContent()}
      </Modal>
    </>
  );
};

import React from 'react';

import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import s from './callout-footer.module.css';

import {
  fetchMapData,
  mapPointCloseViewDetail,
  openHisTrendChartModal,
  mapModalChange,
  fetchDeliverData
} from '../../../action/actionMap';

import { CALLOUT_BERTH } from '../../../constants/location.constants';
import { NEW_CALLOUT } from '../../../constants/map.constants';

export default function _renderFooterComponent() {
  const allowedCallouts = [21, 23, 24, 26, 31, 32, 33];
  const dispatch = useDispatch();

  const selectedPoint = useSelector((state) =>
    get(state, 'map.selectedPoint', null)
  );
  const { callOutData, isLoading } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.calloutData,
      isLoading: callout.isCalloutDataLoading,
    };
  });
  const isShowDrilldown = selectedPoint?.drilldown;
  const totalBtn =
    callOutData?.drillDowns?.length + callOutData?.appLinks?.length + 1;
  const colSpan = 24 / totalBtn;
  const isActiveHisTrend = selectedPoint.locationId === 103;
  const historicalTrendClassName = (function () {
    if (isActiveHisTrend) {
      return `${s.calloutActionBtn}`;
    }
    return `${s.calloutActionBtn} ${s.disabled}`;
  })();

  const isBerth = CALLOUT_BERTH.includes(selectedPoint?.locationId);

  function handleGoToDrillDown(drillDownId: number) {
    dispatch(mapPointCloseViewDetail());
    dispatch(fetchMapData(drillDownId));
  }

  function onOpenHisTrendChartModal() {
    if (isActiveHisTrend) {
      dispatch(openHisTrendChartModal());
    }
  }

  function onOpenCargoDetailsModal() {
    if (selectedPoint.calloutTypeId === 9) {
      const totalCargo = callOutData?.secondarySection?.sectionValue?.find(
        (s) => s.primaryLabel === 'Total Cargo'
      )?.chartValues?.[0]?.value ?? '';

      if ([14, 19, 67].includes(selectedPoint.locationId) || isBerth) {
        dispatch(
          fetchDeliverData(selectedPoint.locationId, '', totalCargo, 'false')
        );
      }
    }

    dispatch(mapModalChange(true));
  }

  return (
    <div className={s.callOutFooter}>
      <div className={s.callOutGroupActionBtnContainer}>
        {!isLoading && (
          <Row gutter={16}>
            {isShowDrilldown &&
              callOutData?.drillDowns?.map((d) => {
                return (
                  <Col span={colSpan} key={d.drillDownId}>
                    <span
                      className={s.calloutActionBtn}
                      tabIndex={0}
                      onClick={() => handleGoToDrillDown(d.drillDownId)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleGoToDrillDown(d.drillDownId);
                        }
                      }}
                    >
                      <img alt='Drilldown' src='/img/icon/ic-locate.svg' />
                      <span>Go to {d.drillDownName}</span>
                    </span>
                  </Col>
                );
              })}
              {allowedCallouts.includes(callOutData?.locationId) && ( 
                <Col span={allowedCallouts.includes(callOutData?.locationId) ? (callOutData?.appLinks?.length === 1 ? 8 : 6) : colSpan}>
                    <span
                      className={s.calloutActionBtn}
                      tabIndex={0}
                      onClick={() => handleGoToDrillDown(callOutData?.locationId)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleGoToDrillDown(callOutData?.locationId);
                        }
                      }}
                    >
                      <img alt='Drilldown' src='/img/icon/ic-locate.svg' />
                      {callOutData?.locationId === 21 || callOutData?.locationId === 23 || callOutData?.locationId === 24 || callOutData?.locationId === 26 ? (
                        <span>Go to {callOutData?.locationName ? callOutData.locationName.split(' ').map(word => word.charAt(0)).join('') : ''}</span>
                      ) : (
                        <span>Go to {callOutData?.locationName}</span>
                      )}
                    </span>
                  </Col>)}
            {callOutData?.appLinks?.map((a) => (
              <Col span={allowedCallouts.includes(callOutData?.locationId) ? (callOutData?.appLinks?.length === 1 ? 8 : 6) : (totalBtn === 2 ? 8 : colSpan)} key={a.label}> 
                <span className={s.calloutActionBtn}>
                  <img alt='applink' src='/img/icon/ic-app-link.svg' />
                  <span>
                    <a target='_blank' rel='noreferrer' href={a.appUrl}>
                      Link to {a.label}
                    </a>
                  </span>
                </span>
              </Col>
            ))}

            {selectedPoint.calloutTypeId === 9 ? (
              <Col span={allowedCallouts.includes(callOutData?.locationId) ? 6 : colSpan}>
                <span
                  onClick={onOpenCargoDetailsModal}
                  className={s.calloutActionBtn}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onOpenCargoDetailsModal();
                    }
                  }}
                  tabIndex={0}
                >
                  <img alt='chart-link' src='/img/icon/berthing-ship.svg' />
                  <span>Cargo Details</span>
                </span>
              </Col>
            ) : (
              <Col  span={allowedCallouts.includes(callOutData?.locationId) ? (callOutData?.appLinks?.length === 1 ? 8 : 6) : (totalBtn === 2 ? 8 : colSpan)}>
                <span
                  onClick={onOpenHisTrendChartModal}
                  className={historicalTrendClassName}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onOpenHisTrendChartModal();
                    }
                  }}
                  tabIndex={0}
                >
                  <img alt='chart-link' src='/img/icon/ic-chart.svg' />
                  <span>Historical Trend</span>
                </span>
              </Col>
            )}
          </Row>
        )}
      </div>
    </div>
  );
}

import { Action } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

import * as types from "../action/actionType";
import { MFT_SECTION, MFT_SUSTAIN_SECTION, MFT_SUSTAIN_STATIC_KEY } from "../constants/mft.constants";
import { transformMftCard } from "../factory/mftCard.factory";
import { transformMftChartData } from "../factory/mftChart.factory";
import { transformMftWaveChartData } from "../factory/mftWaveChart.factory";
import {
  getMftCardGne,
  getMftCardGrowthGne,
  getMftCommon,
  getMftEmissionCardsGne,
  getMftEmissionChartsGne,
  getMftExecSummary,
  getMftIntensityCardsGne,
  getMftIntensityChartsGne,
  getMftLineGne,
  getMftLineGrowthGne,
  getMftPeriodList,
  getMftReductionCardsGne,
  getMftSustainCards,
  getMftSustainLineChart,
  getMftSustainWaveChart,
} from "../services/mft.service";

interface MFTAction extends Action {
  payload: string | any;
  data: any;
}

const isSubTabWaveChart = {
  0: false,
  1: true,
  2: true,
  3: false,
};

function* fetchExecSummary(action: MFTAction) {
  try {
    const { year, month, sectionId } = action.data;
    const params = `?year=${year}&month=${month}&section=${sectionId}`;
    const response = yield call(getMftExecSummary, params);
    yield put({
      type: types.MFT_EXECSUMMARY_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.MFT_EXECSUMMARY_FAILED,
      message: error.message,
    });
  }
}

function* fetchCardSection(action: MFTAction) {
  try {
    const { year, month, sectionId, subTabId, categoryId, isDynamicTab, unit } = action.data;
    let params = `?year=${year}&month=${month}` + (categoryId ? `&key=${categoryId}` : "") + (unit ? `&unit=${unit}` : "");
    let sectionChartAPI: any;
    let sectionCardAPI: any;

    switch (sectionId) {
      case MFT_SECTION.VALUE:
        sectionChartAPI = getMftLineGne;
        sectionCardAPI = getMftCardGne;
        break;
      case MFT_SECTION.GROWTH:
        sectionChartAPI = getMftLineGrowthGne;
        sectionCardAPI = getMftCardGrowthGne;
        break;
      case MFT_SECTION.SUSTAINIBILITY:
        if (isDynamicTab) {
          sectionCardAPI = getMftSustainCards
          if (MFT_SUSTAIN_STATIC_KEY.includes(categoryId)) {
            sectionChartAPI = getMftSustainWaveChart
          } else {
            sectionChartAPI = getMftSustainLineChart
          }
        } else {
          const tabIdApiCard = {
            [MFT_SUSTAIN_SECTION.EMISSION]: getMftEmissionCardsGne,
            [MFT_SUSTAIN_SECTION.REDUCTION]: getMftReductionCardsGne,
            [MFT_SUSTAIN_SECTION.INTENSITY]: getMftIntensityCardsGne,
          };
          const tabIdApiChart = {
            [MFT_SUSTAIN_SECTION.EMISSION]: getMftEmissionChartsGne,
            [MFT_SUSTAIN_SECTION.REDUCTION]: getMftEmissionChartsGne,
            [MFT_SUSTAIN_SECTION.INTENSITY]: getMftIntensityChartsGne,
          };
          sectionChartAPI = tabIdApiChart[subTabId];
          sectionCardAPI = tabIdApiCard[subTabId];
        }
        break;
    }

    const responseCard = yield call(sectionCardAPI, params);

    let responseChart = {
      result: [],
    }

    if (!(isDynamicTab && [MFT_SECTION.VALUE, MFT_SECTION.GROWTH].includes(sectionId))) {
      params = params + (unit ? `&unit=${unit}` : `&unit=${responseCard.result.primaryCards[0].units[0]}`)
      responseChart = yield call(sectionChartAPI, params);
    }

    let transformData = [];
    let transformCard = [];

    if (responseChart?.result?.length > 0) {
      if (isDynamicTab && MFT_SECTION.SUSTAINIBILITY === sectionId) {
        if (MFT_SUSTAIN_STATIC_KEY.includes(categoryId)) {
          transformData = transformMftWaveChartData(responseChart.result, year);
        } else {
          transformData = transformMftChartData(responseChart.result, year);
        }
      } else {
        transformData = isSubTabWaveChart[subTabId]
          ? transformMftWaveChartData(responseChart.result, year)
          : transformMftChartData(responseChart.result, year);
      }
    }

    if (responseCard.result?.primaryCards?.length > 0) {
      yield put({
        type: types.MFT_SELECT_PRIMARY_CARD,
        data: responseCard.result.primaryCards[0],
      });
      transformCard = transformMftCard(responseCard.result);
    }
    yield put({
      type: types.MFT_CARD_SECTION_SUCCEEDED,
      payload: {
        cards: transformCard,
        charts: responseChart.result,
        data: transformData,
      },
    });
  } catch (error: any) {
    yield put({
      type: types.MFT_CARD_SECTION_FAILED,
      message: error.message,
    });
  }
}

function* updateDynamicLineChart(action: MFTAction) {
  try {
    const { year, month, sectionId, categoryId, unit } = action.data;
    const transformUnit = unit === "%" ? "%25" : unit;
    let params = `?year=${year}&month=${month}&unit=${transformUnit}` + (categoryId ? `&key=${categoryId}` : "");
    let url: any;
    switch (sectionId) {
      case MFT_SECTION.VALUE:
        url = getMftLineGne;
        break;
      case MFT_SECTION.GROWTH:
        url = getMftLineGrowthGne;
        break;
    }
    const responseChart = yield call(url, params);
    let transformData = [];
    if (responseChart.result.length > 0) {
      transformData = transformMftChartData(responseChart.result, year);
    }

    yield put({
      type: types.MFT_UPDATE_LINE_CHART_SUCCEEDED,
      payload: {
        charts: responseChart.result,
        data: transformData,
      },
    });
  } catch (error: any) {
    yield put({
      type: types.MFT_UPDATE_LINE_CHART_FAILED,
      message: error.message,
    });
  }
}


function* fetchPeriodlist(action: MFTAction) {
  try {
    const response = yield call(getMftPeriodList);
    yield put({
      type: types.MFT_PERIODLIST_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.MFT_PERIODLIST_FAILED,
      message: error.message,
    });
  }
}

function* fetchCommon(action: MFTAction) {
  try {
    const { year, month, sectionId } = action.data;
    const params = `?year=${year}&month=${month}&section=${sectionId}`;
    const response = yield call(getMftCommon, params);
    yield put({
      type: types.MFT_COMMON_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.MFT_COMMON_FAILED,
      message: error.message,
    });
  }
}

export function* workerMFTData() {
  yield takeLatest(types.MFT_EXECSUMMARY_REQUESTED, fetchExecSummary);
  yield takeLatest(types.MFT_COMMON_REQUESTED, fetchCommon);
  yield takeLatest(types.MFT_CARD_SECTION_REQUESTED, fetchCardSection);
  yield takeLatest(types.MFT_PERIODLIST_REQUESTED, fetchPeriodlist);
  yield takeLatest(types.MFT_UPDATE_LINE_CHART_REQUESTED, updateDynamicLineChart);
}

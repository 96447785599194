import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useDispatch } from 'react-redux';

import { fetchScoreCardOrderList, fetchScoreCardOrderOpu } from '../../../action/actionScorecardorder';
import ListOrderingComponent from '../../../components/ScorecardsOrdering/ListOrdering/ListOrderingComponent';
import {
  FINANCIAL_EXCELLENCE_LABEL,
  HSSE_PERFORMANCE_LABEL,
  PLANT_PERFORMANCE_LABEL,
  PRODUCTION_GENERATION_LABEL,
  TAB_SCORE_CARD,
} from '../../../constants/score-card.constants';
import s from './ScorecardsOrderingComponent.module.css';

const ScorecardsOrderingComponent = (props: any) => {
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  useEffect(() => {
    const data = {
      sectionId: 1,
      userOpuId: "",
    };
    dispatch(fetchScoreCardOrderList(data));
    dispatch(fetchScoreCardOrderOpu());
  }, []);
  function setTabChange(sectionId) {
    const data = {
      sectionId: sectionId,
      userOpuId: "",
    };
    dispatch(fetchScoreCardOrderList(data));
  }
  function _tabsComponent() {
    return (
      <Tabs
        onChange={(e) => {
          setTabChange(e);
        }}
        className="scorecardorder-tabs"
        defaultActiveKey="1"
      >
        <TabPane
          tab={HSSE_PERFORMANCE_LABEL}
          key={TAB_SCORE_CARD.HSSE_PERFORMANCE}
        >
          <ListOrderingComponent sectionId={TAB_SCORE_CARD.HSSE_PERFORMANCE} />
        </TabPane>
        <TabPane
          tab={PRODUCTION_GENERATION_LABEL}
          key={TAB_SCORE_CARD.PRODUCTION_GENERATION}
        >
          <ListOrderingComponent
            sectionId={TAB_SCORE_CARD.PRODUCTION_GENERATION}
          />
        </TabPane>
        <TabPane
          tab={PLANT_PERFORMANCE_LABEL}
          key={TAB_SCORE_CARD.PLANT_PERFORMANCE}
        >
          <ListOrderingComponent sectionId={TAB_SCORE_CARD.PLANT_PERFORMANCE} />
        </TabPane>
        <TabPane
          tab={FINANCIAL_EXCELLENCE_LABEL}
          key={TAB_SCORE_CARD.FINANCIAL_EXCELLENCE}
        >
          <ListOrderingComponent
            sectionId={TAB_SCORE_CARD.FINANCIAL_EXCELLENCE}
          />
        </TabPane>
      </Tabs>
    );
  }
  return (
    <div className="scorecardorder">
      <div className={`fullscreen-pt-0 ${s.scorecardorderRoot}`}>
        <ScrollContainer
          className={`page-container bg-map ${s.scorecardorderContainer}`}
        >
          <div className={s.scorecardorderPanel}>
            <div className={s.scorecardorderPanelContainer}>
              <h2>SCORECARD ORDERING</h2>
              {_tabsComponent()}
            </div>
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
};
export default ScorecardsOrderingComponent;

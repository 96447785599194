export   function transformToPixels(lat: number, lon: number, imageNorthLat: number, imageSouthLat: number, imageWestLong: number, imageEastLong: number,
    oWidth: number, oHeight: number, currentImageSizeWidth: number) {

    const pixelsPerLat = oHeight / (imageNorthLat - imageSouthLat);
    const pixelsPerLong = oWidth / (imageEastLong - imageWestLong);

    const x = (lon - imageWestLong) * pixelsPerLong;
    const y = Math.abs(lat - imageNorthLat) * pixelsPerLat;

    const ratioX = (currentImageSizeWidth / oWidth);
    const ratioY = (currentImageSizeWidth / oWidth);
    return { X: x * ratioX, Y: y * ratioY };
  }
import React from 'react';

import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import s from './callout-footer.module.css';

import {
  mapPointCloseViewDetail
} from '../../../action/actionMap';
import { fetchDisableCallOutSustainability, fetchGhgData } from '../../../action/actionSustainability';

export default function _renderFooterComponent() {
  const dispatch = useDispatch();

  const selectedPoint = useSelector((state) =>
    get(state, 'map.selectedPoint', null)
  );
  const { callOutData, isLoading } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.sustainabilityCalloutData,
      isLoading: callout.isCalloutDataLoading,
    };
  });
  
  // GT, PLC, PFLNG1, PFLNG2  
  const showDrilldownIDs = [2, 3, 4, 5, 6, 7];
  const isShowDrilldown = callOutData && showDrilldownIDs.includes(callOutData.locationId);
  const totalBtn = callOutData && showDrilldownIDs.includes(callOutData.locationId) ? 2 : 1;
  const colSpan = 24 / totalBtn;

  function handleGoToDrillDown(drillDownId: number) {
    dispatch(mapPointCloseViewDetail());
    dispatch(fetchGhgData(drillDownId));
    dispatch(fetchDisableCallOutSustainability())
  }

  return (
    <div className={s.callOutFooter}>
      <div className={s.callOutGroupActionBtnContainer}>
        {!isLoading && (
          <Row gutter={16}>
            {callOutData && isShowDrilldown &&
              (
                <Col span={colSpan} key={callOutData.locationId}>
                  <span
                    className={s.sustainabilityCalloutActionBtn}
                    onClick={() => handleGoToDrillDown(callOutData.locationId)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleGoToDrillDown(callOutData.locationId);
                      }
                    }}
                    tabIndex={0}
                  >
                    <img alt='Drilldown' src='/img/icon/ic-locate.svg' />
                    <span>Go to {callOutData.locationName}</span>
                  </span>
                </Col>
              )
            }

            <Col span={colSpan}>
              <span className={`${s.sustainabilityCalloutActionBtn} ${s.disabled}`}>
                <img alt='applink' src='/img/icon/ic-app-link.svg' />
                <span>
                  <a target='_blank' rel='noreferrer' href="#" className={s.disabledButton}>
                    Generate Report
                  </a>
                </span>
              </span>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
}

import { get } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchScoreCardLmtFinancialSecondaryCardDetail,
    fetchScoreCardLmtOperationalExecSummary,
    openScorecardSecondaryCardTabModal
}
from "../../../../../action/actionScoreCard";

import {
    LMT_PAGE_TYPE, LMT_TAB_SCORE_CARD, MONTH_SHORT_TEXT, LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS,
     LMT_SCORECARD_FINANCIAL_TYPE_CONFIG, LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS, LMT_FINANCIAL_SCORECARD_TYPE
}
from "../../../../../constants/score-card.constants";

import ScorecardExecutiveSummary from "../ExecutiveSummary/ScorecardExecutiveSummary";

import s from '../../SecondaryCard.module.css';
import LmtCapex from "./LmtCapex";

export default function BaseLmtFinancialExcellenceSecondary() {
    const dispatch = useDispatch();

    const { isVisibleModal, currentDate, selectedScorecard, selectedTabIndex } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            isVisibleModal: scorecard.isOpenScorecardSecondaryCardModel,
            currentDate: scorecard.currentDate,
            selectedScorecard: scorecard.selectedScorecard,
            selectedTabIndex: scorecard.selectedTabIndex
        };
    });

    function requestDetail() {
        const params = `?year=${currentDate.year}&month=${MONTH_SHORT_TEXT[currentDate.month - 1]}`;
        dispatch(fetchScoreCardLmtFinancialSecondaryCardDetail(params));
    }

    function requestExecSummary() {
        const params = `?section=${LMT_TAB_SCORE_CARD.FINANCIAL_PERFORMANCE}&page=${LMT_PAGE_TYPE.SCORECARD}` +
            `&year=${currentDate.year}&month=${MONTH_SHORT_TEXT[currentDate.month - 1]}`;
        dispatch(fetchScoreCardLmtOperationalExecSummary(params));
    }

    const scorecardTypeId = selectedScorecard ? selectedScorecard.scorecardTypeId : 0

    useEffect(() => {
        if (isVisibleModal && selectedScorecard) {
            switch (scorecardTypeId) {
            case LMT_FINANCIAL_SCORECARD_TYPE.CAPEX:
                requestDetail();
                dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS.CAPEX));            
                break;
            default:
                requestExecSummary();
                dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS.EXECUTIVE_SUMMARY));
                break;
            }
        }
    }, [selectedScorecard]);

    // Tab change
    const financialTabs = LMT_SCORECARD_FINANCIAL_TYPE_CONFIG;

    function onTabChange(i) {
        switch (i) {
            case LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS.EXECUTIVE_SUMMARY:
                requestExecSummary();
                break;
            case LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS.CAPEX:
                requestDetail();
                break;
            default:
                break;
        }
        dispatch(openScorecardSecondaryCardTabModal(i));
    }

    function tabClassName(i) {
        if (i === selectedTabIndex) {
            return s.active;
        }
        return;
    }

    function renderTab(i) {
        if (i === LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS.EXECUTIVE_SUMMARY) {
            return <ScorecardExecutiveSummary />
        }
        if (i === LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS.CAPEX) {
            return <LmtCapex />
        }
    }

    return (
        <div className={s.modalBody}>
            <div className={s.detailsTab}>
                <ul>
                    {financialTabs.map((tab) => {
                        return (
                            <li
                                key={tab.label}
                                className={tabClassName(tab.value)}
                                onClick={() => onTabChange(tab.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {onTabChange(tab.value);}
                                  }}
                                tabIndex={0}
                            >
                                {tab.label}
                            </li>
                        );
                    })}
                </ul>
            </div>

            {renderTab(selectedTabIndex)}
        </div>
    )
}
import moment from 'moment';
import { populateLineChartData } from '../utils/chart.utils';

function transformInitData(datas, frequency) {
    const frequencyFormat = {
        "": "m",
        "1hour": "m",
        "8hour": "h",
        "daily": "h",
        "weekly": "DD MMM YYYY",
        "biweekly": "DD MMM YYYY"
    }

    const isText = {
        "": true,
        "1hour": true,
        "8hour": true,
        "daily": true,
        "weekly": false,
        "biweekly": false,
    }

    const dateText = {
        "": "minutes ago",
        "1hour": "minutes ago",
        "8hour": "hour ago",
        "daily": "hour ago"
    }

    const dateDuration = {
        "": "minutes",
        "1hour": "minutes",
        "8hour": "hours",
        "daily": "hours"
    }


    const dataFields = datas?.tabsInfo?.secondaryInfo?.dataFields;
    let returnData = populateLineChartData(datas);
    for (let didx = 0; didx < datas?.tabsInfo?.secondaryInfo?.dataFields?.length; didx++) {
        let dataObject = dataFields[didx];
        const seriesNo = didx + 1;
        for (let cidx = 0; cidx < dataObject?.chartData.length; cidx++) {
            const chartObject = dataObject?.chartData[cidx];
            const dateFormat = moment(chartObject?.date).format(frequencyFormat[frequency])
            const dateDiff = moment().diff(chartObject?.date, dateDuration[frequency]);
            returnData[cidx].tooltipLabel = isText[frequency] ? `${dateDiff} ${dateText[frequency]}` : dateFormat
            returnData[cidx].date = moment(chartObject?.date).toDate();
            returnData[cidx][`series${seriesNo}`] = chartObject?.value ? chartObject?.value : 0;
            returnData[cidx][`unit${seriesNo}`] = chartObject?.tooltipUnit;
        }
    }

    return returnData
}

export function transformMapChartData(results, frequency) {
    let resultData = transformInitData(results, frequency);
    return resultData
}
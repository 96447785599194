import { Action } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as types from '../action/actionType';
import { STATUS } from '../constants/endpoints';
import { transformOpuList } from '../factory/opuList.factory';
import { transformScorecardOrder } from '../factory/scorecardOrder.factory';
import { IUserPersona } from '../model/user.model';
import { getScorecardOPU, getScorecardOrder, postScorecardOrder } from '../services/scorecardorder.service';
import { getUserPersona, isSuperAdmin } from '../utils/user.utils';

interface ScorecardOrderAction extends Action {
  payload: string | any;
  data: any;
}

function* fetchScorecardOrdering(action: ScorecardOrderAction) {
  try {
    const userPersona: IUserPersona = getUserPersona();
    const sectionId = `/${action.data?.sectionId}`;
    const userOpuId =
      action.data?.userOpuId === ""
        ? `/${userPersona?.opuId}`
        : `/${action.data?.userOpuId}`;

    const response = yield call(getScorecardOrder, sectionId, userOpuId);
    const transFormdata = transformScorecardOrder(response.result);
    yield put({
      type: types.SCORECARDORDERING_LIST_SUCCEEDED,
      payload: transFormdata,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARDORDERING_LIST_FAILED,
      message: error.message,
    });
  }
}

function* fetchScorecardOrderOpu(action: ScorecardOrderAction) {
  try {
    if (isSuperAdmin()) {
      const response = yield call(getScorecardOPU);
      const transFormdata = transformOpuList(response.result);
      yield put({
        type: types.SCORECARDORDERING_OPU_SUCCEEDED,
        payload: transFormdata,
      });
    }
  } catch (error: any) {
    yield put({
      type: types.SCORECARDORDERING_OPU_FAILED,
      message: error.message,
    });
  }
}

function* fetchUpdateScorecard(action: ScorecardOrderAction) {
  try {
    const sectionId = `/${action.data?.sectionId}`;
    const userOpuId = `/${action.data?.userOpuId}`;

    const response = yield call(
      postScorecardOrder,
      sectionId,
      userOpuId,
      action.data?.dataBody
    );

    if (response.status === STATUS.UPDATE) {
      const data = {
        sectionId: action.data?.sectionId,
        userOpuId: action.data?.userOpuId,
      };
      yield put({
        type: types.SCORECARDORDERING_LIST_REQUESTED,
        data: data,
      });
    }
  } catch (error: any) {
    yield put({
      type: types.SCORECARDORDERING_UPDATE_FAILED,
      message: error.message,
    });
  }
}

export function* workerScorecardOrderingData() {
  yield all([
    takeLatest(types.SCORECARDORDERING_LIST_REQUESTED, fetchScorecardOrdering),
    takeLatest(types.SCORECARDORDERING_OPU_REQUESTED, fetchScorecardOrderOpu),
    takeLatest(types.SCORECARDORDERING_UPDATE_REQUESTED, fetchUpdateScorecard),
  ]);
}

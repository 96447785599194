import { useDispatch, useSelector } from "react-redux";

import s from "./BaseLmtSalesSummaryChart.module.css";
import { useState } from "react";
import { setLmtCustomerSelection } from "../../../../action/actionScoreCard";
import { get } from "lodash";

export interface LmtChartCustomerSelectionComponentProps {
  customerData: any[]
  allowMultiple?: boolean;
}

export default function LmtChartCustomerSelectionComponent({ customerData }: LmtChartCustomerSelectionComponentProps) {
  const dispatch = useDispatch();
  const { selectedLmtCustomer, selectedLmtCountry, countriesInfo } = useSelector((state) => {
    const scorecard = get(state, 'scoreCard', null);
    return {
      selectedLmtCustomer: scorecard.selectedLmtCustomer,
      selectedLmtCountry: scorecard.selectedLmtCountry,
      countriesInfo: scorecard.lmtCountryData
    };
  });

  let tempCustomerSelection: any[] = [];
  let filteredCustomers: any[] = []

  // Filter base on country selection
  if (selectedLmtCountry && selectedLmtCountry.length > 0) {
    for (let idx = 0; idx < selectedLmtCountry.length; idx++) {
      let countryInfo = countriesInfo.find(c => { return c.countryName === selectedLmtCountry[idx] });
      if (countryInfo) {
        // countryInfo.listCustomerName.map(c => {
        //   filteredCustomers.push({ customer: c, selected: true });
        // })
        countryInfo.listCustomerName.map(c => {
        filteredCustomers.push({customer: c.customerName, selected: true})
        })
      }
    }
  }
  else {
    filteredCustomers = customerData;
  }

  if (selectedLmtCustomer && selectedLmtCustomer.length > 0) {
    // Add All first
    tempCustomerSelection.push({ customer: "All", selected: false })
    // Starts from the next country
    for (let idx = 0; idx < filteredCustomers.length; idx++) {
      if (selectedLmtCustomer.indexOf(filteredCustomers[idx].customer) >= 0) {
        filteredCustomers[idx].selected = true;
      }
      else {
        filteredCustomers[idx].selected = false;
      }
      tempCustomerSelection.push(filteredCustomers[idx])
    }
  }
  else {
    // Add All first
    tempCustomerSelection.push({ customer: "All", selected: true })
    tempCustomerSelection.push(...filteredCustomers);
  }
  tempCustomerSelection = tempCustomerSelection.filter((value, index) => {
    const _value = JSON.stringify(value);
    return index === tempCustomerSelection.findIndex(obj => {
      return JSON.stringify(obj) === _value;
    });
  });
  const [customerSelection, setCustomerSelection] = useState(tempCustomerSelection);

  function handleCustomerSelect(customerName: string, selectionStatus: boolean) {
    let selectedCustomers: string[] = [];
    if (customerName === "All" && selectionStatus) {
      // Change all customers to selected
      for (let idx = 0; idx < customerSelection.length; idx++) {
        customerSelection[idx].selected = true;
      }
    }
    else if (customerName === "All" && !selectionStatus) {
      for (let idx = 0; idx < customerSelection.length; idx++) {
        customerSelection[idx].selected = false;
      }
    }
    else {
      const selectedCustomer = customerSelection.find(c => c.customer === customerName);
      const customerIdx = customerSelection.indexOf(selectedCustomer);
      if (customerIdx >= 0) {
        customerSelection[customerIdx].selected = selectionStatus;
      }
      selectedCustomers = customerSelection.filter(c => c.selected).map(c => c.customer);
    }

    setCustomerSelection([...customerSelection]);
    dispatch(setLmtCustomerSelection(selectedCustomers.filter(s => s !== "All")));
  }

  return (
    <ul>
      {customerSelection && customerSelection.map((customer) => {
        return (
          <div className={s.countrySelection}>
            <li key={customer.customer}>
              <button
                type="button"
                onClick={() => handleCustomerSelect(customer.customer, !customer.selected)}>
                <img alt="checkbox" src={customer.selected
                  ? "/img/icon/Checkbox-checked.svg"
                  : "/img/icon/Checkbox-uncheck.svg"} />
              </button>
              <p>{customer.customer}</p>
            </li>
          </div>);
      })}
    </ul>);
}
import { Col, Row } from "antd";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import s from '../../SecondaryCard.module.css';
import cardStyle from './GneLngLoadableSecondary.module.css';
import { useEffect } from "react";
import { fetchCargoDelivery } from "../../../../../action/actionScoreCard";

export default function GneLngLoadable() {
    const dispatch = useDispatch();

const scoreCard = useSelector((state: any) => state.scoreCard);

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
}

const { cargoDeliveryData } = scoreCard;

useEffect(() => {
    dispatch(fetchCargoDelivery())
},[])

return (
    <div className={cardStyle.sectionContainer}>
        <div className={s.detailsItem} style={{position: 'relative'}}>
            <div className={cardStyle.sectionInfo}>
                <div className={cardStyle.sectionTitle}>
                    <span>{cargoDeliveryData?.label}</span>
                </div>
                <div className={cardStyle.cargoValue}>
                    <span>{cargoDeliveryData?.value?.toLocaleString('en-US')}</span>
                    <span>&nbsp;{cargoDeliveryData?.unit}</span>
                </div>
                <div style={{borderTop: "1px solid grey", position: 'absolute', left: 0, right: 0, margin: "8px 13px"}}></div>
                <span className={cardStyle.timeStamp}><img src='/img/icon/ic-time.svg' alt='time'/>&nbsp;As at {formatDate(cargoDeliveryData?.updatedDate)}</span>
            </div>
            
        </div>
    </div>
);
}
import { Action } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../action/actionType';
import { getDataPipeline } from '../services/datapipelines.service';
interface DataPipelineAction extends Action {
    data: string | any;
}

function* fetchDataPipelines(action: DataPipelineAction) {
    try {
        const data = yield call(getDataPipeline);
        yield put({ type: types.DATA_PIPELINE_SUCCEEDED, payload: data.result });
        
    } catch (error: any) {
        yield put({ type: types.DATA_PIPELINE_FAILED, message: error.message });
    }
}

export function* workerDataPipeline() {
    yield all([
        takeLatest(types.DATA_PIPELINE_REQUESTED, fetchDataPipelines),
    ])
}
import { ENDPOINTS } from "../constants/endpoints";
import axiosClient from "./axiosClient.service";

export function getGhgData(data: any) {
    return axiosClient.get(ENDPOINTS.GET_GHG_DATA + data)
}

export function updateCalloutSummary(data) {
    return axiosClient.post(ENDPOINTS.POST_SUSTAINABILITY_SUMMARY, data);
}

export function getSustainabilityDrilldownPFLNG(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_MFT_SUSTAINABILITY_DRILLDOWN_FPLNG+ (payload === undefined ? '' : payload))
}

export function getSustainabilityDrilldownRGTP(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_MFT_SUSTAINABILITY_DRILLDOWN_RGTP+ (payload === undefined ? '' : payload))
}

export function getSustainabilityDrilldownRGTSU(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_MFT_SUSTAINABILITY_DRILLDOWN_RGTSU+ (payload === undefined ? '' : payload))
}

export function getSustainabilityDrilldownGT(payload?: any) {
    return axiosClient.get(ENDPOINTS.GET_MFT_SUSTAINABILITY_DRILLDOWN_GT+ (payload === undefined ? '' : payload))
}
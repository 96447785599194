import React from 'react';

import { useSelector } from 'react-redux';
import { get } from 'lodash';

import {
    Desktop,
    Mobile,
    Tablet,
    TabletSec,
  } from '../../../responsive/responesive';
import s from './demand-value.module.css'
import DemandValueChart from './DemandValueChart';

const DemandValue = () => {
  const { callOutData } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.calloutData,
    };
  });
  const demandValues =
    callOutData?.secondarySection?.sectionLabel === 'DEMAND BY INDUSTRIAL' &&
    callOutData?.secondarySection?.sectionValue;

    function _renderDemandValueChart(demandValues) {
        return (
          <>
            <Desktop>
              <DemandValueChart
                demandValueData={demandValues}
                totalDemandData={
                  callOutData?.primaryTags?.find(
                    (p) => p.tagName === 'Total Demand'
                  )?.primaryValue
                }
                legendHeight={70}
              />
            </Desktop>
            <TabletSec>
              <DemandValueChart
                demandValueData={demandValues}
                totalDemandData={
                  callOutData?.primaryTags?.find(
                    (p) => p.tagName === 'Total Demand'
                  )?.primaryValue
                }
                legendHeight={40}
              />
            </TabletSec>
            <Tablet>
              <DemandValueChart
                demandValueData={demandValues}
                totalDemandData={
                  callOutData?.primaryTags?.find(
                    (p) => p.tagName === 'Total Demand'
                  )?.primaryValue
                }
                legendHeight={40}
              />
            </Tablet>
            <Mobile>
              <DemandValueChart
                demandValueData={demandValues}
                totalDemandData={
                  callOutData?.primaryTags?.find(
                    (p) => p.tagName === 'Total Demand'
                  )?.primaryValue
                }
                legendHeight={50}
              />
            </Mobile>
          </>
        );
      }

  if (demandValues) {
    return (
      <div className={s.highlightComponent}>
        <div className={s.highlightTitle}>
          <span>DEMAND BY INDUSTRIAL</span>
          <span />
        </div>
        <div className={s.mapRightPanelDemandValue}>
          {_renderDemandValueChart(demandValues)}
        </div>
      </div>
    );
  }
  return null;
};

export default DemandValue;

import { isNull } from 'lodash';
import moment from 'moment';

export function isTabs(data) {
  if (data?.tabs?.length > 0) {
    if (typeof data?.tabs[0].label !== 'string') {
      return false;
    }
    return true;
  }
  return false;
}

export function formatDate(timeStamp: string) {
  if (timeStamp) {
    const date = moment(timeStamp).format('DD MMM YYYY hh:mm A');
    return date;
  }
  return 'N/A';
}

export function numberWithCommas(x) {
  //return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 'N/A';
  if (isNull(x)){
    return 'N/A';
  }
  return x.toLocaleString("en-US");
}

import { Table, Tooltip, Popover, } from "antd";
import { CaretUpFilled, CaretDownFilled, CheckOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from 'antd';
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    fetchOpus,
    fetchPersonas,
    fetchRoles,
    fetchUsersManagement,
    fetchUsersDownload,
    fetchUsersStatus
} from "../../../action/actionUserManagement";
import { INDICATOR, MODALTYPE } from "../../../constants/usermanagement.constanst";
import { IOpus, IRoles } from "../../../model/userManagement.model";
import AddUserModalPageComponent from "../AddUserModal/AddUserModalPageComponent";
import s from "./UserTableComponent.module.css";
import { isSuperAdmin } from '../../../utils/user.utils';

const UserTablePageComponent = () => {
    const dispatch = useDispatch();
    const [isModalVisible, setisModalVisible] = useState(false);
    const [modalType, setModalType] = useState('');
    const [userRecord, setUserRecord] = useState();
    const [statusFilter, setStatusFilter] = useState(0);
    // Sort type for "Most Frequent Access" value is 2
    const [sortType, setSortType] = useState(2);
    const userTableData = useSelector((state) =>
        get(state, "usermanagement.userTableData", [])
    );
    const opus: IOpus[] = useSelector((state) =>
        get(state, "usermanagement.opus", [])
    );
    const roles: IRoles[] = useSelector((state) =>
        get(state, "usermanagement.roles", [])
    );
    const personas = useSelector((state) =>
        get(state, "usermanagement.personas", [])
    );
    const isUserTableLoading = useSelector((state) =>
        get(state, "usermanagement.isUserTableLoading", false)
    );
    const userStatus = useSelector((state) =>
        get(state, "usermanagement.userStatus", [])
    );

    const [filterTable, setFilterTable] = useState(null as any);

    useEffect(() => {
        dispatch(fetchOpus());
        dispatch(fetchRoles());
        dispatch(fetchPersonas());
        dispatch(fetchUsersStatus());
    }, []);

    useEffect(() => {
        dispatch(fetchUsersManagement({ status: statusFilter, sortType: sortType }));
    }, [statusFilter, sortType]);

    useEffect(() => {
        setFilterTable(userTableData);
    }, [JSON.stringify(userTableData)]);

    function closeModalFunc() {
        setisModalVisible(false);
        setModalType('');
    }

    function downloadUsers() {
        dispatch(fetchUsersDownload())
    }

    const onSearch = (value: any) => {
        const filteredTable = userTableData.filter((o: any) =>
            Object.keys(o).some((k) => {
                return `${String(o[k])}`
                    .toLowerCase()
                    .includes(value.toLowerCase());
            })
        );
        const modalData = value === "" ? null : filteredTable;
        setFilterTable(modalData);
    };

    function tableCellUserText(text, subText) {
        return (
            <div className={s.tableTextUserWrap}>
                <Tooltip title={text} overlayInnerStyle={{ wordWrap: 'normal' }} placement="topLeft">
                    <span className={s.tableText}>{text}</span>
                </Tooltip>
                <span className={s.tableSubText}>{subText}</span>
            </div >
        )
    }

    function tableCellText(text, subText) {
        return (
            <div className={s.tableTextWrap}>
                <span className={s.tableText}>{text}</span>
                <span className={s.tableSubText}>{subText}</span>
            </div>
        )
    }

    function tableCellLastActive(date, loginCount) {
        const lastLoginTime = date ? moment(date).format('DD MMM YYYY hh:mmA') : '';
        const subText = loginCount ? <><b>{`${loginCount}`}</b>{` times in last 3 months`} </> : <><b>0</b>{` times in last 3 months`} </>;
        return (
            <div className={s.tableTextWrap}>
                <span className={s.tableText}>{lastLoginTime}</span>
                <span className={s.tableSubText}>{date && subText}</span>
            </div>
        )
    }

    function tableCellReason(text) {
        return (
            <div className={s.tableTextWrap}>
                <Tooltip title={text} placement="bottomRight" color={"#182034"} key={"#182034"}>
                    <span className={s.tableTextReason}>{text}</span>
                </Tooltip>
            </div>

        )
    }

    const StatusSelect = () => {
        const Content = () => {
            return (
                <div className={s.popoverContent}>
                    {userStatus.map((s, i) => (
                        <div key={s}>
                            <span
                                onClick={() => setStatusFilter(s.value)}
                                key={s.value}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        setStatusFilter(s.value);
                                    }
                                  }}
                                tabIndex={0}
                            >
                                {statusFilter === s.value ? (
                                    <span style={{ fontWeight: 700 }}>
                                        <text>{s.text}</text>
                                        <CheckOutlined className={s.tickIcon} style={{ position: 'relative', float: 'right', top: '3px' }} />
                                    </span>
                                ) : (
                                    s.text
                                )}
                            </span>
                        </div>
                    ))}
                </div>
            );
        };

        const getRootContainer = (): HTMLElement => {
            const elements = document.getElementsByClassName(s.tbodyText);
            if (elements.length > 0) {
                return elements[0] as HTMLElement;
            }
            return document.body;
        };
        return (
            <Popover
                overlayClassName="tbodyDropdown"
                placement='bottom'
                content={<Content />}
                trigger='click'
                getPopupContainer={getRootContainer}
            >
                <div className={s.tbodyText}>
                    <p>
                        {userStatus?.find?.((s) => s.value === statusFilter)?.text ||
                            null}
                    </p>
                    <img
                        className={s.arrow}
                        src='/img/icon/DownArrow.svg'
                        alt='arrow'
                    />
                </div>
            </Popover>
        );
    };

    const openAddNewUserModal = () => {
        setModalType(MODALTYPE.CREATE)
        setisModalVisible(true)
    }

    const openEditUserModal = (record) => {
        setUserRecord(record);
        setModalType(MODALTYPE.EDIT)
        setisModalVisible(true)
    }
    function tableHeader() {
        return (
            <div className={s.tableHeader}>
                <div className={s.searchAndFilter}>
                    <input placeholder="Search" onChange={(e) => onSearch(e.target.value)} className={s.tableHeaderInput} style={{ backgroundImage: 'url(/img/icon/Search.svg)' }} />
                    <StatusSelect />
                </div>
                <div>
                    {isSuperAdmin() && <button className={s.exportBtn} onClick={downloadUsers} >Export</button>}
                    <button className={s.tableHeaderAddUser} onClick={openAddNewUserModal}>Add User</button>
                </div>
            </div>
        )
    }

    function tableIndicator(status) {
        let classIndicator;
        switch (status) {
            case INDICATOR.ACTIVE:
                classIndicator = s.indicatorGreenGlow
                break;
            case INDICATOR.NOTLOGINUSER:
                classIndicator = s.indicatorGreenDim
                break;
            case INDICATOR.INACTIVE:
                classIndicator = s.indicatorGrey
                break;
        }
        return <div className={classIndicator} />
    }

    function onClickLastActive(item) {
        setSortType(item.key);
    }
    const menu = (
        <Menu
            selectable
            onClick={onClickLastActive}
            defaultSelectedKeys={['2']}
        >
            <Menu.Item key="1">
                Most Recent Login
            </Menu.Item>
            <Menu.Item key="2">
                Most Frequent Access
            </Menu.Item>
        </Menu>
    );

    function renderTableColumn() {
        return [
            {
                title: "",
                dataIndex: "status",
                width: 20,
                ellipsis: true,
                render: (text: any) => tableIndicator(text),
            },
            {
                title: "User",
                dataIndex: "name",
                width: 200,
                ellipsis: true,
                render: (_text, record) => tableCellUserText(record?.name, record?.email),
                sorter: (a: any, b: any) => `${a?.name}`.localeCompare(`${b?.name}`),
            },
            {
                title: "OPU/Sector",
                dataIndex: "opuName",
                width: 90,
                ellipsis: true,
                render: (_text, record) => tableCellText(record?.opuName, record?.personaName),
                sorter: (a: any, b: any) => `${a?.opuName}`.localeCompare(`${b?.opuName}`),
            },
            {
                title: <>Last Active
                    <Dropdown overlay={menu}>
                        <span className={s.lastActiveArrow}>
                            <CaretUpFilled style={{ color: "#ccc" }} />
                            <CaretDownFilled style={{ color: "#ccc", marginTop: "-0.3em" }} />
                        </span>
                    </Dropdown>
                </>,
                dataIndex: "lastLoginTime",
                width: 110,
                ellipsis: true,
                render: (_text, record) => tableCellLastActive(record.lastLoginTime, record?.loginCount),
            },
            {
                title: "Admin Approval",
                dataIndex: "grantedUserName",
                ellipsis: true,
                width: 200,
                render: (_text, record) => tableCellText(record?.grantedUserName, record?.grantedUserEmail),
                sorter: (a: any, b: any) => `${a?.grantedUserName}`.localeCompare(`${b?.grantedUserName}`),
            },
            {
                title: "Reason for access",
                dataIndex: "accessReason",
                ellipsis: true,
                width: 200,
                render: (_text, record) => tableCellReason(record?.accessReason),
                sorter: (a: any, b: any) => `${a?.accessReason}`.localeCompare(`${b?.accessReason}`),
            },
            {
                title: "",
                ellipsis: true,
                width: 20,
                render: (_text, record) => (
                  <div
                    className={s.editButton}
                    onClick={() => openEditUserModal(record)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        openEditUserModal(record);
                      }
                    }}
                    tabIndex={0}
                  >
                    <img src="/img/icon/Pencil.svg" alt="edit" />
                  </div>
                ),
              }
              ,

        ]
    }

    return (
        <>
            <Table
                className="user-table-page"
                rowClassName={(_record, index) => index % 2 === 0 ? '' : s.tableStripe}
                rowKey={(record) => record.uid}
                columns={renderTableColumn()}
                dataSource={filterTable === null ? userTableData : filterTable}
                pagination={{
                    pageSize: 10,
                    position: ["bottomLeft"],
                    total: filterTable !== null ? filterTable.length : userTableData.length,
                    showTotal: (total, range) => <div className={s.totalPagination}><b>{range[0]}-{range[1]}</b>{` of ${total} items`}</div>,
                    showSizeChanger: false,
                }}
                showSorterTooltip={false}
                title={() => tableHeader()}
                scroll={{ x: 1132 }}
                loading={isUserTableLoading}
            />
            <AddUserModalPageComponent
                modalType={modalType}
                isVisible={isModalVisible}
                opus={opus}
                personas={personas}
                roles={roles}
                closeFunc={() => closeModalFunc()}
                record={userRecord}
                sortType={sortType}
                statusFilter={statusFilter}
            />
        </>
    );
};
export default UserTablePageComponent;

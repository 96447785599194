import { LABEL_HEADER_MOBILE, URL_SIDE_BAR } from '../constants/map.constants';
export function getHeaderLabel (activeTab: string) {
    switch (activeTab) {
        case URL_SIDE_BAR.MAP:
            return LABEL_HEADER_MOBILE.MALAYSIA_MAP;
        case URL_SIDE_BAR.VALUE_CHAIN:
            return LABEL_HEADER_MOBILE.VALUE_CHAIN;
        case URL_SIDE_BAR.SCORECARDS:
            return LABEL_HEADER_MOBILE.SCORECARDS;
        case URL_SIDE_BAR.SUSTAINABILITY:
            return LABEL_HEADER_MOBILE.SUSTAINABILITY;
        default:
            break;
    }
}
import { useDispatch, useSelector } from 'react-redux';
import { get, set } from "lodash";
import { Col, Modal, Row } from 'antd';
import { useState, useEffect } from 'react';
import { fetchRiskKRICallout, fetchRiskMitigationCallout} from "../../action/actionRisk";
import s from './RiskInfoCallout.module.css';

export interface RiskInfoCalloutProps {
  risk: {
    ranking: number;
    entity: string;
    statementAndOutlook: string;
    gasTopRisk: string;
    kriStatus: number[];
    mitigation: number[];
  };
  ModalOpen: boolean;
  onClose: () => void;
  year: number;
  quarter: string;
}

export default function RiskCard({ risk, ModalOpen: initialModalOpen, onClose, year, quarter }: RiskInfoCalloutProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRiskKRICallout(`?year=${year}&quater=${quarter}&rank=${risk.ranking}`));
    dispatch(fetchRiskMitigationCallout(`?year=${year}&quater=${quarter}&rank=${risk.ranking}`));
  }, [dispatch]);

  const kri = useSelector((state) =>
    get(state, 'risk.kriCallout.result', [])
  );

  const mitigation = useSelector((state) =>
    get(state, 'risk.mitigationCallout.result', [])
  );

  const [ModalOpen, setModalOpen] = useState(initialModalOpen);
  const [activeTab, setActiveTab] = useState<'KRI Status' | 'Mitigation'>('KRI Status');

  useEffect(() => {
    setModalOpen(initialModalOpen);
  }, [initialModalOpen]);

  const CloseButton = () => <img alt='close-icon' src='/img/icon/close-btn.svg' onClick={handleCancel} />;

  const handleCancel = () => {
    setModalOpen(false);
    onClose();
  };

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = (dueDate) => {
    if (dueDate.length > 10) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const getMitigationStatus = (status: string) => {
    switch (status) {
      case 'Completed':
        return s.completed;
      case 'On Track':
        return s.onTrack;
      case 'Delayed':
        return s.delayed;
      case 'Not Started':
        return s.notStarted;
      default:
        return '';
    }
  };

  const getMitigationCircle = (status: string) => {
    switch (status) {
      case 'Completed':
        return s.completedCircle;
      case 'On Track':
        return s.onTrackCircle;
      case 'Delayed':
        return s.delayedCircle;
      case 'Not Started':
        return s.notStartedCircle;
      default:
        return '';
    }
  };

  const getKRIStatus = (status: string) => {
    switch (status) {
      case 'Base':
        return s.base;
      case 'Warning':
        return s.warning;
      case 'Critical':
        return s.critical;
      case 'Not Started':
        return s.notStarted;
      default:
        return '';
    }
  };

  const getKRICircle = (status: string) => {
    switch (status) {
      case 'Base':
        return s.baseCircle;
      case 'Warning':
        return s.warningCircle;
      case 'Critical':
        return s.criticalCircle;
      case 'Not Started':
        return s.notStartedCircle;
      default:
        return '';
    }
  };

  function getSaturationClass(indicatorType, status) {
    return indicatorType === status ? s.enabled : s.disabled;
  }
  

  function renderKRIContent() {
    
    return kri.length > 0 ? (
      <div className={s.mainContainer}>
        <h3>KRI Status</h3>
    
        {kri.map((KRIObject, kriIndex) => {
            
          const matchingIndicators = KRIObject.indicators.filter(
            (indicator) => indicator.indicatorType === KRIObject.status
          );

          const otherIndicators = KRIObject.indicators.filter(
            (indicator) => indicator.indicatorType !== KRIObject.status
          );

          return (
            <div key={kriIndex} className={s.mitigationItem}>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div className={s.indicatorsContainer}>
                  {KRIObject.status.toLowerCase() === 'not started' ? (
                    <div className={`${s.mitigationStat} ${getKRIStatus("Not Started")} ${getSaturationClass('not started', KRIObject.status.toLowerCase())}`}>
                      <div className={s.indicatorItem}>
                        <span className={`${s.circle} ${getKRICircle("Not Started")}`}></span>
                        <span style={{ color: '#3D3D3D' }}>{"Not Started"}</span>
                      </div>
                    </div>
                  ) : (
                    matchingIndicators.map((indicator, indicatorIndex) => (
                      <div key={indicatorIndex} className={`${s.mitigationStat} ${getKRIStatus(indicator.indicatorType)} ${getSaturationClass(indicator.indicatorType, KRIObject.status)}`}>
                        <div className={s.indicatorItem}>
                          <span className={`${s.circle} ${getKRICircle(indicator.indicatorType)}`}></span>
                          <span style={{ color: 'white' }}>{indicator.indicatorValue}</span>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                {otherIndicators.length > 0 && (
                  <div className={s.infoIconContainer}>
                    <span className={s.infoIcon}>
                      <img alt='info-icon' src='/img/icon/Info.svg' />
                    </span>
                    <div className={s.KRItooltip}>
                        {/* {KRIObject.indicators.map((indicator, indicatorIndex) => (
                          <div key={indicatorIndex} className={s.indicatorItem} style={{marginTop: '4px'}}>
                            <span className={`${s.circle} ${getKRICircle(indicator.indicatorType)}`}></span>
                            <span className={s.indicatorType}>{indicator.indicatorType}</span>
                            <span className={s.indicatorValue}>{indicator.indicatorValue}</span>

                            {indicator.indicatorType !== "Not Started" ? (
                              <span className={s.indicatorValue}>{indicator.indicatorValue}</span>
                            ) : null}
                          </div>
                        ))} */}
                        {KRIObject.indicators.map((indicator, indicatorIndex) => {
                          return (
                            <div key={indicatorIndex} className={s.indicatorItem} style={{marginTop: '4px'}}>
                              <span className={`${s.circle} ${getKRICircle(indicator.indicatorType)}`}></span>
                              <span className={s.indicatorType}>{indicator.indicatorType}</span>
                              <span className={s.indicatorValue}>{indicator.indicatorValue}</span>
                            </div>
                          );
                        })}

                        <div className={s.indicatorItem} style={{marginTop: '4px'}}>
                          <span className={`${s.circle} ${getKRIStatus("Not Started")}`}></span>
                          <span className={s.indicatorType}>{"Not Started"}</span>
                        </div>
                    </div>
                  </div>
                )}
              </div>

              <span className={s.mitigationNew}>{KRIObject.kriName}</span>
              <span className={s.mitigationDesc}>{KRIObject.description}</span>

            </div>
          );
        })}
      </div>
    
    ) : (
      <div className={s.empty}>No KRI data available.</div>
    );
  }

  function renderMitigationContent() {
    return (
      mitigation.length > 0 ? (
        <div className={s.mainContainer}>
          <h3>Mitigations</h3>
          {mitigation.map((item, index) => (
            <div key={index} className={s.mitigationItem}>
              <div className={`${s.mitigationStat} ${getMitigationStatus(item.status)}`}>
                <span className={`${s.circle} ${getMitigationCircle(item.status)}`}></span>
                <span
                  style={{
                    color:
                      item.status === 'Not Started'
                        ? 'black'
                        : item.status === 'Delayed'
                        ? '#E29E20'
                        : '',
                  }}
                >{item.status}</span>
              </div>
              
              <Row className={s.mitigationHeadercontainer}>
                <Col span={16} className={s.ownerContainer}>
                  <span className={s.ownerLabel}>Owner:</span>
                  <div className={s.ownerList}>
                    {item.mitigationOwner.split('\n').map((line, index, array) => (
                      <span key={index} className={array.length > 1 ? s.ownerLine : ''}>{line}</span>
                    ))}
                  </div>
                </Col>
                <Col span={8} className={s.dueDateContainer}>
                  <span className={s.ownerLabel}>Due Date:</span>
                  <div
                    className={`${s.dueDate} ${item.dueDate.length > 10 ? s.pointer : ''}`}
                    onMouseEnter={() => handleMouseEnter(item.dueDate)}
                    onMouseLeave={handleMouseLeave}
                  >
                    {item.dueDate.length > 10 ? `${item.dueDate.substring(0, 10)}...` : item.dueDate}
                    {showTooltip && (
                      <span className={s.tooltip}>{item.dueDate}</span>
                    )}
                  </div>
                </Col>
              </Row>

              <div className={s.mitigationNew}>
                {item.newMitigation.split('\n').map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
              <div className={s.mitigationDesc}>{item.description}</div>
            </div>
          ))}
        </div>
      ) : <div className={s.empty}>No Mitigation data available.</div>
    );
  }
  

  const renderRiskCalloutContent = () => (
    <div className={s.riskModalContent}>
      <div className={s.header}>
        <div className={s.title}>Risk Details</div>
        <div className={s.closeButtonContainer}>
          <CloseButton />
        </div>
      </div>
      <div className={s.separatorLine}></div>
      <div className={s.section}>
        <h3>{risk.entity}</h3>
        <div className={s.sectionContainer}>
          <div className={s.sectionTitle}>Risk Statement</div>
          <span>{risk.gasTopRisk}.</span>
        </div>
        <div className={s.sectionContainer}>
          <div className={s.sectionTitle}>Outlook</div>
          <span>{risk.statementAndOutlook}</span>
        </div>
      </div>

      <div className={s.tabs} style={{marginTop: '10px'}}>
        <div
          className={`${s.tab} ${activeTab === 'KRI Status' ? s.active : ''}`} onClick={() => setActiveTab('KRI Status')}>KRI Status
        </div>
        <div
          className={`${s.tab} ${activeTab === 'Mitigation' ? s.active : ''}`} onClick={() => setActiveTab('Mitigation')}>Mitigations
        </div>
      </div>

      <div className={s.tabContent}>
        <div {...(activeTab === 'KRI Status' ? {} : { inert: 'true' })}>
          {activeTab === 'KRI Status' && renderKRIContent()}
        </div>
        <div {...(activeTab === 'Mitigation' ? {} : { inert: 'true' })}>
          {activeTab === 'Mitigation' && renderMitigationContent()}
        </div>
      </div>

    </div>
  );

  return (
    <Modal
      className={s.riskModal}
      visible={ModalOpen}
      footer={null}
      onCancel={handleCancel}
      width='60%'
      style={{ right: 0, height: '90%', position: 'absolute' }}
      closable={false}
      maskClosable={true}
      transitionName=''
    >
      {renderRiskCalloutContent()}
    </Modal>
  );
}

import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected"
import { Spin } from "antd";
import { get, isEmpty } from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDataPipeline } from "../../../action/actionDataPipelines";
import s from "./DataPipelinesComponent.module.css";

const DataPipelinesComponent = () => {
    const chartPipeline = useRef<am4plugins_forceDirected.ForceDirectedTree>();
    const dispatch = useDispatch();
    const datapipelineData = useSelector((state) =>
        get(state, "dataPipeline.datapipelineData", [])
    );
    const isLoading = useSelector((state) =>
        get(state, "dataPipeline.isLoading", false)
    );
    useEffect(() => {
        dispatch(fetchDataPipeline());
    }, []);

    useEffect(() => {
        if (datapipelineData?.node) {
            const chartDivPipeline = am4core.create("dataPipelinesChart", am4plugins_forceDirected.ForceDirectedTree);
            chartPipeline.current = chartDivPipeline;
            const chartCurrent = chartPipeline.current;
            const networkSeries = chartPipeline.current.series.push(new am4plugins_forceDirected.ForceDirectedSeries())
            chartCurrent.data = [datapipelineData.node];
            chartCurrent.zoomable = true;

            networkSeries.dataFields.value = "value";
            networkSeries.dataFields.name = "name";
            networkSeries.dataFields.children = "children";
            networkSeries.dataFields.fixed = "fixed";
            networkSeries.nodes.template.propertyFields.x = "x";
            networkSeries.nodes.template.propertyFields.y = "y";
            networkSeries.links.template.distance = 2;
            networkSeries.manyBodyStrength = -100;
            networkSeries.links.template.strength = 1
            networkSeries.maxRadius = 70;
            // networkSeries.maxLevels = 3;
            // label
            networkSeries.nodes.template.label.text = "{description}";
            networkSeries.nodes.template.label.valign = "middle";
            networkSeries.nodes.template.label.dy = 15;
            networkSeries.nodes.template.label.fill = am4core.color("#FFFFFF");
            networkSeries.fontSize = 10;


            networkSeries.nodes.template.fillOpacity = 1;


            networkSeries.links.template.strokeWidth = 1;
            networkSeries.showOnInit = false;

            const hoverState = networkSeries.links.template.states.create("hover");
            hoverState.properties.strokeWidth = 3;
            hoverState.properties.strokeOpacity = 1;

            networkSeries.nodes.template.events.on("over", function (event) {
                event.target.dataItem.childLinks.each(function (link) {
                    link.isHover = true;
                })
                if (event.target.dataItem.parentLink) {
                    event.target.dataItem.parentLink.isHover = true;
                }

            })

            networkSeries.nodes.template.events.on("out", function (event) {
                event.target.dataItem.childLinks.each(function (link) {
                    link.isHover = false;
                })
                if (event.target.dataItem.parentLink) {
                    event.target.dataItem.parentLink.isHover = false;
                }
            })

            //set tooltip
            networkSeries.nodes.template.adapter.add("tooltipText", (value, target: any) => {
                const { tooltipText, name } = target.dataItem.dataContext;
                if (tooltipText !== null) {
                    return tooltipText
                }
                return name;
            })

            //set color
            const nodeColor = {
                red: "#F64D4B",
                yellow: "#F0AB00",
                green: "#00A19C",
            }
            networkSeries.nodes.template.adapter.add("fill", (value, target: any) => {
                const { color } = target.dataItem.dataContext;
                return nodeColor[color];
            })
            networkSeries.nodes.template.adapter.add("stroke", (value, target: any) => {
                const { color } = target.dataItem.dataContext;
                return nodeColor[color];
            })

            const icon = networkSeries.nodes.template.createChild(am4core.Image);
            icon.propertyFields.href = "image";
            icon.horizontalCenter = "middle";
            icon.verticalCenter = "middle";
            icon.width = 60;
            icon.height = 60;
        }

        // Add data
        return () => {
            chartPipeline.current?.dispose();
        };
    }, [JSON.stringify(datapipelineData)]);

    function dataPipelineLegend() {
        if(isEmpty(datapipelineData?.colorSummaries)){
            return
        }
        const cssColor = {
            green: s.legendGreen,
            yellow: s.legendYellow,
            red: s.legendRed
        }
        return (
            <div className={s.dataPipelineLegend}>
                {
                    datapipelineData?.colorSummaries.map((value, key) => (
                        <div key={key}>
                            <div className={cssColor[value?.color]} />
                            <span>{value?.total}</span>
                        </div>)
                    )
                }
            </div>
        )
    }
    return (
        <>
            {
                isLoading ? (
                    <Spin size='large' className='chartSpin' />
                ) :
                    <div className={s.chartBlock}>
                        <div className={s.chartContainer}>
                            {dataPipelineLegend()}
                            <div id="dataPipelinesChart" className={s.scrollbar} />
                        </div>
                    </div>
            }
        </>
    );
};
export default DataPipelinesComponent;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { isMapTypeMobile, isOpenSideBarMobile, updateActiveTab } from "../../action/actionMap";
import { LABEL_HEADER_MOBILE, URL_SIDE_BAR } from "../../constants/map.constants";
import { ROUTES } from "../../constants/routes";
import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import { getHeaderLabel } from "../../utils/sidebar.utils";
import s from "../Header/header.module.css";
import Navigation from "../Navigation/NavigationComponent";
import { fetchDisableCallOutSustainability } from "../../action/actionSustainability";

function HeaderComponent() {
  const dispatch = useDispatch();
  const { isOpenSideBar, activeTab } = useSelector((state: any) => state.map);

  const handleGoToHomePage = () => {
    dispatch(updateActiveTab(URL_SIDE_BAR.MAP));
    dispatch(fetchDisableCallOutSustainability())
  };
  const handleOpenMapType = (isopen: boolean) => {
    dispatch(isMapTypeMobile(isopen));
  };

  const handleOpenSideBarMoblie = (isOpen: boolean) => {
    dispatch(isOpenSideBarMobile(isOpen));
    dispatch(fetchDisableCallOutSustainability())
  };

  function isSideBar() {
    if (isOpenSideBar) {
      return getHeaderLabel(activeTab) === LABEL_HEADER_MOBILE.SCORECARDS
        ? s.closeHeaderScoreCard
        : s.closeHeader;
    } else {
      return getHeaderLabel(activeTab) === LABEL_HEADER_MOBILE.SCORECARDS
        ? s.headerRootMobileScoreCard
        : s.headerRootMobile;
    }
  }

  return (
    <>
      <Desktop>
        <div className={s.headerRoot}>
          <div className={s.headerContainer}>
            <Navigation />
            <Link
              className={s.brand}
              to={ROUTES.HOMEPAGE}
              onClick={() => handleGoToHomePage()}
            >
              <div className={s.logo}>
                <img src="/img/icon/Logo.svg" height="40" alt="React" />
              </div>
              <span className={s.brandTxt}>GO Dashboard</span>
            </Link>
          </div>
        </div>
      </Desktop>
      <Mobile>
        <div className={isSideBar()}>
          <div className={s.headerLayoutMobile}>
            <div
              onClick={() => handleOpenSideBarMoblie(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleOpenSideBarMoblie(true);
                }
              }}
              tabIndex={0}
              style={{position:'absolute', left:'0', marginLeft: '20px'}}
            >
              <img src="/responsive-icon/Menu-white.svg" alt="React" />
            </div>
            {/* <div className={s.headerTxtMobile}>{getHeaderLabel(activeTab)}</div> */}
            <div className={s.headerInfo}>
              <img src="/img/icon/Logo Petronas.svg" height="26" width ="26" alt="React" />
              <div className={s.headerTxtMobile}>GO DASHBOARD</div>
            </div>
            
            {/* {getHeaderLabel(activeTab) === LABEL_HEADER_MOBILE.MALAYSIA_MAP ? (
              <div onClick={() => handleOpenMapType(true)}>
                <img src="/responsive-icon/Icon_3_dots.svg" alt="React" />
              </div>
            ) : (
              <div onClick={() => handleOpenMapType(true)}>
                <img src="/responsive-icon/Icon-More.svg" alt="React" />
              </div>
            )} */}
          </div>
        </div>
      </Mobile>
      <Tablet>
        <div className={isSideBar()}>
          <div className={s.headerLayoutMobile}>
            <div
              onClick={() => handleOpenSideBarMoblie(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleOpenSideBarMoblie(true);
                }
              }}
              tabIndex={0}
              style={{position:'absolute', left:'0', marginLeft:'20px'}}
            >
              <img src="/responsive-icon/Menu-white.svg" alt="React" />
            </div>
            {/* <div className={s.headerTxtMobile}>{getHeaderLabel(activeTab)}</div> */}
            <img src="/img/icon/Logo Petronas.svg" height="26"  width= "26" alt="React" />
            <div className={s.headerTxtMobile}>GO DASHBOARD</div>
            {/* {getHeaderLabel(activeTab) === LABEL_HEADER_MOBILE.MALAYSIA_MAP ? (
              <div onClick={() => handleOpenMapType(true)}>
                <img src="/responsive-icon/Icon_3_dots.svg" alt="React" />
              </div>
            ) : (
              <div onClick={() => handleOpenMapType(true)}>
                <img src="/responsive-icon/Icon-More.svg" alt="React" />
              </div>
            )} */}
          </div>
        </div>
      </Tablet>
    </>
  );
}

export default HeaderComponent;

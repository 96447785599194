import React, { useLayoutEffect, useRef, memo } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

interface RiskKRIChartComponentProps {
  data: number[];
  id: string;
}

interface ExpandedDataItem {
  category: string;
  value: number;
  color: am4core.Color;
}

const RiskKRIChartComponent: React.FC<RiskKRIChartComponentProps> = ({ data, id }) => {
  const chartRef = useRef<am4charts.PieChart | null>(null);

  useLayoutEffect(() => {
    let chart = am4core.create(id, am4charts.PieChart);

    if(!!chartRef){
      chartRef.current = chart;
    }

    // Green, Yellow, Red, Grey
    const colors = [am4core.color("#0B882E"), am4core.color("#FDB924"), am4core.color("#DA2228"), am4core.color("#C2C2C2")];
    const categories = ["Base", "Warning", "Critical", "Not Started"];

    let expandedData: ExpandedDataItem[] = [];
    data.forEach((value, index) => {
      for (let i = 0; i < value; i++) {
        expandedData.push({
          category: categories[index],
          value: 1,
          color: colors[index]
        });
      }
    });

    chart.data = expandedData;

    chart.logo.disabled = true;

    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";
  
    pieSeries.innerRadius = am4core.percent(70);
  
    // Divide the border
    pieSeries.slices.template.stroke = am4core.color("#ffffff");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
  
    pieSeries.slices.template.tooltipText = "";
    pieSeries.ticks.template.disabled = true; // Disable ticks
  
    // Centered Total
    let label = chart.seriesContainer.createChild(am4core.Label);
    label.text = `${data.reduce((acc, val) => acc + val, 0)}`;
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 12;
  
    return () => {
      chart.dispose();
    };
  }, [chartRef, JSON.stringify(data), id]);

  return <div id={id} style={{ width: "70px", height: "70px" }}></div>;
};
export default RiskKRIChartComponent;

import { call, put, takeLatest } from "redux-saga/effects";

import * as types from "../action/actionType";
import { SESSION_STORAGE_KEY } from "../constants/user.constants";
import { transformViewPermission } from "../factory/normalizeViewPermission.factory";
import { getValidateUser, postLoginCount } from "../services/private-route.service";
import { userDefaultMap } from "../utils/user.utils";
import { getRequestStatus } from "../services/user.service";

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

declare global {
  interface Window {
  dataLayer: any[]; // Declare the dataLayer property
  }
}

function* fetchAuthData() {
  try {
    const userConfig = yield call(getValidateUser);
    const userResult = userConfig.result;
    const token = sessionStorage.getItem(`${SESSION_STORAGE_KEY.GRAPH_ACCESS_TOKEN}-${process.env.REACT_APP_STAGE}`);
    const parsetoken = parseJwt(token);
    const uid = parsetoken.oid;

    // Pass the 'OID' to the dataLayer
    if (uid) {
      window.dataLayer.push({
        'event': 'Login',
        'uid': uid
      });
    }
    
    sessionStorage.setItem(
      SESSION_STORAGE_KEY.PERSONA_TOKEN,
      userResult.personaToken
    );
    sessionStorage.setItem(
      SESSION_STORAGE_KEY.USER_ROLE,
      JSON.stringify(userResult?.claims?.roles)
    );
    sessionStorage.setItem(
      SESSION_STORAGE_KEY.USER_MAP,
      JSON.stringify(userResult?.claims?.mapLocations)
    );
    sessionStorage.setItem(
      SESSION_STORAGE_KEY.USER_PERSONA,
      JSON.stringify(userResult?.claims?.persona)
    );
    sessionStorage.setItem(
      SESSION_STORAGE_KEY.VIEW_PERMISSION,
      JSON.stringify(userResult?.claims?.viewPermissions)
    );

    const transformData = transformViewPermission(userResult);
    yield put({
      type: types.USER_SET_PERSONA_SELECTION,
      payload: transformData,
    });

    let defaultMap = userDefaultMap(userResult?.claims?.mapLocations);
    sessionStorage.setItem(
      SESSION_STORAGE_KEY.DEFAULTMAP,
      JSON.stringify(defaultMap.locationId)
    );
    sessionStorage.setItem(
      SESSION_STORAGE_KEY.DEFAULTLEVEL,
      JSON.stringify(defaultMap.level)
    );

    yield put({
      type: types.PRIVATE_ROUTE_AUTH_SUCCEEDED,
      data: "success",
      userConfig: userResult,
    });

  } catch (error: any) {
    yield fethRequestStatus();
    yield put({
      type: types.PRIVATE_ROUTE_AUTH_FAILED,
      message: error.response?.data?.title || "error",
    });
  }
}

function* fethRequestStatus() {
  try {
    const isRequest = yield call(getRequestStatus);
    yield put({ type: types.USER_ACCESS_SENT, payload: { isRequestSent: isRequest.result.isRequested } });
  }
  catch (error: any) {
    yield put({ type: types.USER_ACCESS_SENT, payload: { isRequestSent: false } });
  }
}

function* fetchLoginCount() {
  try {
    yield call(postLoginCount);
  } catch (error: any) {
    yield put({
      type: types.LOGINCOUNT_FAILED,
      message: "error",
    });
  }
}

export function* workerFetchPrivateRouteData() {
  yield takeLatest(types.PRIVATE_ROUTE_AUTH_REQUESTED, fetchAuthData);
  yield takeLatest(types.LOGINCOUNT_REQUESTED, fetchLoginCount);
}

export const colorKey = {
  LNGA: "#00A19C",
  LMT: "#009DDC",
  "G&P": "#E75552",
  NE: "#50A4B9",
  GNE: "#847FE0",
};

export const cardsKeyType = {
  LNGA: "breakdowns",
  LMT: "breakdowns",
  "G&P": "breakdowns",
  NE: "breakdowns",
  GNE: "primaryCards",
};

export const SUSTAIN_COLOR = {
  Emissions: "#847FE0",
  Reduction: "#50A4B9",
};

export const MFT_SECTION = {
  VALUE: 1,
  GROWTH: 2,
  SUSTAINIBILITY: 3,
};

export const MFT_SUSTAIN_SECTION = {
  EMISSION: 1,
  REDUCTION: 2,
  INTENSITY: 3,
};

export const MFT_SUSTAIN_STATIC_KEY = ["sustainability-ghg-emission", "sustainability-ghg-emissions", "sustainability-ghg-reduction", "sustainability-ghgemission", "sustainability-ghgreduction"];

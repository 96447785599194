import "react-responsive-carousel/lib/styles/carousel.min.css";

import React, { useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import { TAB_SCORE_CARD } from '../../../constants/score-card.constants';
import { isNull } from 'lodash';
import s from '../scorecards.module.css';
import { toFixedNumberAbsolute } from '../../../utils/Number.utils';

const BigCardSubInforComponent = (props: any) => {
    const card = props.data;
    const tabActive = props.tabActive;
    const [currentExpandInfor, setcurrentExpandInfor] = useState({level: 2, index: 0});
    const [currentCarouselData, setCurrentCarouselData] = useState([{title: '', value: '', description: ''}]);
    const [selectedItem, setSelectedItem] = useState(0);

    useEffect(() => {
      if(tabActive === TAB_SCORE_CARD.HSSE_PERFORMANCE) {
        setCurrentCarouselData(card?.projectionValues);
      }
    }, [card])

    function handleCurrentExpandInfor(sub, index) {
        
      if (currentExpandInfor.level !== 2 || sub.thirdValues === null) return;
      // === 2
      setcurrentExpandInfor({level: 3, index});
      setCurrentCarouselData(card?.projectionValues[index]?.thirdValues);
      setSelectedItem(0);
    }

    function handleBackEvent () {
      setSelectedItem(currentExpandInfor.index);
      setcurrentExpandInfor({level: 2, index: currentExpandInfor.index});
      setCurrentCarouselData(card?.projectionValues);
    }

    if(card?.description){
       return (
        <div className={s.scoreCardsItemMore}>
           <p>{card.description}</p>
        </div>
       )
    }

    return <div className={s.scoreCardsItemMore}>
        {/* Slider */}
        {
            tabActive === TAB_SCORE_CARD.HSSE_PERFORMANCE && currentCarouselData && currentCarouselData.length > 0 && 
            <Carousel key={new Date().getTime()} className="subvalue-slide" showArrows={false} showStatus={false} showIndicators={true} showThumbs={false} selectedItem={selectedItem}>
                {
                    currentCarouselData?.map((sub, indexx) => {
                        return <div key={indexx} className={s.scoreCardsItemMoreSlide} >
                            <div className={s.scoreCardsItemMoreLeft} >
                                <div className={s.scoreCardsItemTitleIcon}>
                                  {currentExpandInfor.level === 3 && 
                                  <div className={`bg_image-back-white ${s.iconBackWhite}`}  onClick={() => handleBackEvent()}></div>}
                                  <span>{sub?.title}</span>
                                </div>
                                <b onClick={() => handleCurrentExpandInfor(sub, indexx)} >{sub?.value}</b>
                            </div>
                            <div className={s.scoreCardsItemMoreRight}>
                                {sub?.description}
                            </div>
                        </div>
                    })
                }
            </Carousel>
        }

        {/* Normal Info */}
        {
           ( tabActive !== TAB_SCORE_CARD.HSSE_PERFORMANCE  && card?.projectionValues && card?.projectionValues.length > 0) &&
            <div>
                {
                    card?.projectionValues?.map((sub, indexx) => {
                        return <div key={indexx} className={s.subValueNormal}>
                            {sub?.type}
                            {/* {sub?.currency && <span>&nbsp;{sub?.currency}</span>} */}
                            <b>&nbsp;{isNull(sub?.value) ? "-" : toFixedNumberAbsolute(sub?.value, 2)}</b>
                            {/* {sub?.unit && <span>&nbsp;{sub?.unit}</span>} */}
                        </div>
                    })
                }

            </div>
        }
    </div>
}
export default BigCardSubInforComponent;

import { get } from 'lodash';
import { useSelector } from 'react-redux';
import s from '../../SecondaryCard.module.css';
import summaryTitle from './ScorecardExecutiveSummary.module.css';
import { Col, Row } from 'antd';
import moment from 'moment';

interface ScorecardExecutiveSummaryProps{
    isDetailBreakdown?: boolean;
    sectionName?: String | null;
}

function ScorecardExecutiveSummary({isDetailBreakdown, sectionName}: ScorecardExecutiveSummaryProps) {
    const { hsseExecutiveSummary, financialExecutiveSummary, plantPerformanceSummary } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            hsseExecutiveSummary: isDetailBreakdown ? scorecard.executiveSummary.summaries : [scorecard.executiveSummary],
            financialExecutiveSummary: scorecard.executiveSummary.summaries,
            plantPerformanceSummary: scorecard.executiveSummary.summaryGroup
        };
    });

    return (
        <div className={s.detailsContent}>
            <div className={s.executiveSummary}>
                {!isDetailBreakdown ?
                    (hsseExecutiveSummary[0]?.summary):
                    (<div className={`${s.detailsContent} ${summaryTitle.detailBreakdown}`}>
                        {hsseExecutiveSummary?.length===0 && (
                            <div className={`${s.detailsItem} ${summaryTitle.sectionTitle}`}>
                                No additional information
                            </div>
                        )}
                        {sectionName === "HSSE Performance" ? (
                            hsseExecutiveSummary?.map((summaryData, index) => <div className={`${s.detailsItem} ${summaryTitle.sectionTitle}`} key={summaryData.summary+'_'+index}>
                                <Row>
                                    <Col span={5}>
                                        <span>{summaryData.summaryDate}</span>
                                        <h3>{summaryData.location}</h3>
                                    </Col>
                                    <Col span={19} style={{ paddingLeft: '10px' }}>
                                        {summaryData.summary}
                                    </Col>
                                </Row>
                            </div>)
                        ) : sectionName === "Plant Performance" ? (
                            plantPerformanceSummary?.map((group, groupIndex) => (
                                <div className={s.detailsItem} style={{ display: 'block' }} key={`group_${groupIndex}`}>
                                    <h3>{group.downtime ? group.downtime : 'N/A'}</h3>
                                    {group.summaries.map((summary, summaryIndex) => (
                                        <Row className={summaryTitle.execSummaryRow} key={`summary_${summaryIndex}`}>
                                            <Col span={5} className={summaryTitle.summaryDate}>
                                                <span>{summary.summaryDate ? summary.summaryDate : 'N/A'}</span>
                                            </Col>
                                            <Col span={18} className={summaryTitle.execSummaryContent}>
                                                <span>{summary.desciption}</span>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            ))
                        ) : sectionName === "Financial Excellence" ? (
                            financialExecutiveSummary?.map((summaryData, index) =>
                            <div className={s.listItem} key={summaryData.summary+'_'+index}>
                                <ul>
                                    <li>{summaryData.summary}</li>
                                </ul>
                            </div>)
                        ) : null}
                    </div>)
                }
            </div>
        </div>

    )
}

export default ScorecardExecutiveSummary;
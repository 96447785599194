import React from 'react';

import { useSelector } from 'react-redux';
import { get } from 'lodash';

import s from './highlight.module.css';
import { formatDate } from '../../../utils/callout.utils';

const Highlight = () => {
  const { callOutData } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.calloutData,
    };
  });

  function getHighlightsSectionHeading() {        
    let sectionHeading = 'HIGHLIGHTS';

    if(callOutData && callOutData?.secondarySection?.sectionLabel)
    {
        sectionHeading = callOutData?.secondarySection?.sectionLabel;
    }
    return sectionHeading;
  }

  const highlights =
    callOutData?.secondarySection?.sectionValue.highlightData?.length &&
    callOutData?.secondarySection?.sectionValue;

  if (highlights?.highlightData?.length) {
    return (
      <div className={s.highlightComponent}>
        <div className={s.highlightTitle}>
          <span>{getHighlightsSectionHeading()}</span>
        </div>
        <div className={s.mapRightPanelHighlights}>
          <span className={s.highlightDate}>
            <img src='/img/icon/ic-time.svg' alt='time' />
            &nbsp; {formatDate(highlights?.dateCreated)}
          </span>
          {highlights?.highlightData?.map((h) => {
            const isMultipleHighlights = highlights?.highlightData?.length > 1;
            return (
              <div className={s.highlightDesc} key={h.category}>
                {isMultipleHighlights && (
                  <p className={s.highlightItemTitle}>{h.category}</p>
                )}
                <ul>
                  {h.highlighText.map((t) => {
                    const mainParts = t.split('•').filter(part => part.trim() !== '');
            
                    return mainParts.map((mainPart, mainIndex) => {
                      const subParts = mainPart.split('◦').filter(subPart => subPart.trim() !== '');
            
                      return (
                        <li key={mainIndex}>
                          <span className={s.highlightItemDescription}>
                            {subParts[0].trim()}
                            {subParts.length > 1 && (
                              <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                                {subParts.slice(1).map((subPart, subIndex) => (
                                  <li key={subIndex}>{subPart.trim()}</li>
                                ))}
                              </ul>
                            )}
                          </span>
                        </li>
                      );
                    });
                  })}
                </ul>
              </div>
            );
            
          })}
        </div>
      </div>
    );
  }
  return null;
};

export default Highlight;

import { Col, Row } from 'antd';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { formatScoreCardTargetValue, toFixedNumberAbsolute } from '../../../../../utils/Number.utils';

import s from '../../SecondaryCard.module.css';
import cardStyle from './LmtHssePerformanceSecondary.module.css';

function LmtHsseSustainability() {
    const { secondaryCardData } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            secondaryCardData: scorecard.secondaryCardDetail
        };
    });

    const sustainability = secondaryCardData.sustainability;

    return (
        <div className={s.detailsContent}>
            {sustainability?.length > 0 &&
                sustainability.map((card) => {
                    return (
                        <div className={s.detailsItem}>
                            <Row>
                                <Col span={16}>
                                    <div className={cardStyle.sustainabilityInfo}>
                                        <div className={cardStyle.sustainabilityEffort}>
                                            {card.effort}
                                        </div>
                                        <div className={cardStyle.sustainabilityDetail}>
                                            {card.details}
                                        </div>
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <div className={cardStyle.sustainabilityValue}>
                                        <div className={cardStyle.title}>
                                            YTD
                                        </div>
                                        <div className={cardStyle.actualValue}>
                                            {toFixedNumberAbsolute(card.ytd, 0)}
                                        </div>
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <div className={cardStyle.sustainabilityValue}>
                                        <div className={cardStyle.title}>
                                            YEP
                                        </div>
                                        <div className={cardStyle.projectionValue}>
                                            {toFixedNumberAbsolute(card.yep, 0)}
                                        </div>
                                        <div className={cardStyle.title}>
                                            YET
                                        </div>
                                        <div className={cardStyle.projectionValue}>
                                            {toFixedNumberAbsolute(card.yet, 0)}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
        </div>
    );
}

export default LmtHsseSustainability;
import moment from "moment";
import { Action } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";

import * as types from "../action/actionType";
import { transformFPChartData } from "../factory/forwardPriceChart.factory";
import { transformFPChartV2Data } from "../factory/forwardPriceChartV2.factory";
import { getFowardPrice } from "../services/forwardPrice.service";

interface ForwardPriceAction extends Action {
    payload: string | any;
    data: any;
}

function* fetchFPChart(action: ForwardPriceAction) {
    try {
        const response = yield call(getFowardPrice);
        const transformData = transformFPChartV2Data(response.result);
        yield put({
            type: types.FP_GET_CHART_SUCCEEDED,
            payload: { chartData: transformData },
        });
    } catch (error: any) {
        yield put({
            type: types.FP_GET_CHART_FAILED,
            message: error.message,
        });
    }
}

export function* workerForwardPriceData() {
    yield all([
        takeLatest(types.FP_GET_CHART_REQUESTED, fetchFPChart),
    ]);
}

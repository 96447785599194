import { ENDPOINTS } from '../constants/endpoints';
import axiosClient from './axiosClient.service';

export function getLimitWMTable(personaId) {
  return axiosClient.get(ENDPOINTS.GET_LIMITCOFIG_WM_TABLE + personaId);
}

export function getLimitVCTable(userOpuId) {
  return axiosClient.get(ENDPOINTS.GET_LIMITCONFIG_VC_TABLE + userOpuId);
}

export function postLimitWMTable(data) {
  return axiosClient.post(
    ENDPOINTS.POST_LIMITCONFIG_RULES + `/${data.tagId}`,
    data.updatedRule
  );
}

import moment from "moment";

function getChartKey(datas) {
  let returnLabel = {};
  for (let index = 0; index < datas.length; index++) {
    returnLabel[`key${index}`] = `${datas[index].key}`;
  }
  return returnLabel;
}

function getChartLabel(datas, year) {
  let returnLabel = {};
  for (let index = 0; index < datas.length; index++) {
    returnLabel[`label${index}`] = ``;
  }
  return returnLabel;
}

function getSeriesDataYear(chartData, nextYear) {
  let returSeriesData = 0;
  chartData.forEach((item, value) => {
    if (item.year === nextYear) {
      returSeriesData = item.values.p4r || 0;
    }
  });
  return returSeriesData;
}

function getTooltipDataYear(chartData, nextYear) {
  let returSeriesData = 0;
  chartData.forEach((item, value) => {
    if (item.year === nextYear) {
      returSeriesData = item.values.p4r;
    }
  });
  return returSeriesData;
}

function getChartSeries(datas, nextYear) {
  let returnSeries = {};
  for (let index = 0; index < datas.length; index++) {
    const chartData = datas[index].chartData;
    returnSeries[`series${index}`] = getSeriesDataYear(chartData, nextYear);
    returnSeries[`tooltip${index}`] = getTooltipDataYear(chartData, nextYear);
  }
  return returnSeries;
}

function getChartUnit(datas) {
  let returnUnit = {};
  for (let index = 0; index < datas.length; index++) {
    returnUnit[`unit${index}`] = datas[index].currency;
  }
  return returnUnit;
}

function getChartSubUnit(datas) {
  let returnSubUnit = {};
  for (let index = 0; index < datas.length; index++) {
    returnSubUnit[`subUnit${index}`] = datas[index].unit;
  }
  return returnSubUnit;
}
function isZero(index, chartDataIdx) {
  return index === 0 && chartDataIdx === 0;
}

function getChartShowTooltip(datas, chartDataIdx) {
  let returnSeries = {};
  for (let index = 0; index < datas.length; index++) {
    returnSeries[`showTooltip${index}`] = isZero(index, chartDataIdx)
      ? "always"
      : "hover";
  }
  return returnSeries;
}

function populateData(datas, chartDataLength, year) {
  let returnChartData: any = [];
  const chartKey = getChartKey(datas);
  const chartUnit = getChartUnit(datas);
  const chartSubUnit = getChartSubUnit(datas);

  let currentYear = moment().year(year).subtract(1, "years");
  let prevYear = year - 1;
  for (let chartDataIdx = 0; chartDataIdx < chartDataLength; chartDataIdx++) {
    let nextYear = ++prevYear;
    const chartYear = currentYear.add(1, "years");
    const chartlabels = getChartLabel(datas, chartYear.format("yyyy"));
    const chartSeries = getChartSeries(datas, nextYear);
    const chartShowTooltip = getChartShowTooltip(datas, chartDataIdx);
    returnChartData.push({
      date: chartYear.toDate(),
      ...chartKey,
      ...chartlabels,
      ...chartSeries,
      ...chartUnit,
      ...chartSubUnit,
      ...chartShowTooltip,
    });
  }
  return returnChartData;
}

function transformInitData(datas, year) {
  const chardDataLength = 5;
  const returnTransformData = populateData(datas, chardDataLength, year);
  return returnTransformData;
}

export function transformMftChartData(results, year) {
  const resultData = transformInitData(results, year);
  return resultData;
}

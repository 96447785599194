import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import React, { useEffect, useState } from "react";
import { Spin, Select } from "antd";
import {
  fetchChartData,
  fetchMapData,
  fetchPrimaryInfoData,
  mapPointCloseViewDetail,
} from "../../action/actionMap";
import { useDispatch, useSelector } from "react-redux";

import MapChartComponent from "./MapChartComponent";
import MapModalComponent from "./MapModalComponent";
import MapScrollComponent from "./MapScrollComponent";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { formatNumber } from "../../utils/Number.utils";
import { formatUnit } from "../../utils/unit.utils";
import { get } from "lodash";
import { isAllowLocation } from "../../utils/map.utils";
import { isTabs } from "../../utils/callout.utils";
import s from "../Map/map.module.css";
import sMobile from "./mapResponsive.module.css";

const { Option } = Select;
const legendInit = {
  series1: true,
  series2: true,
  series3: true,
  series4: true,
};
const isShowShipType = { 1: false, 2: false, 3: true, 4: true, 5: true };
const isShowLegendType = { 1: true, 2: true, 3: false, 4: false, 5: false };
const MapDetailComponent = (props: any) => {
  const selectedPoint = useSelector((state) =>
    get(state, "map.selectedPoint", null)
  );
  const { callOutData, isCalloutDataLoading } = useSelector((state) => {
    const callout = get(state, "callout", null);
    return {
      callOutData: callout.calloutData,
      isCalloutDataLoading: callout.isCalloutDataLoading,
    };
  });

  const [tabActive, setTabActive] = useState(0);
  const [selected, setSelected] = useState("");
  const [frequency, setFrequency] = useState("");
  const [legendUnActive, setLegendUnactive] = useState(legendInit);

  const dispatch = useDispatch();
  const dataFields = callOutData?.tabsInfo?.secondaryInfo?.dataFields || [];

  useEffect(() => {
    if (frequency === "") {
      if (callOutData?.tabsInfo?.secondaryInfo?.frequency) {
        setFrequency(callOutData?.tabsInfo?.secondaryInfo?.frequency[0]?.key);
      }
    }
  }, [callOutData]);

  useEffect(() => {
    if (frequency !== "") {
      initializeState();
      setLegendUnactive(legendInit);
    }
  }, [selectedPoint]);

  function initializeState() {
    setSelected("0");
    setFrequency("");
    setTabActive(0);
  }

  function close() {
    initializeState();
    dispatch(mapPointCloseViewDetail());
    setLegendUnactive(legendInit);
  }

  function onTabChange(index) {
    const tabId = callOutData?.tabs[index].key;
    setTabActive(Number(index));
    setSelected(index);
    dispatch(fetchPrimaryInfoData(callOutData.locationId, tabId));
    dispatch(fetchChartData(callOutData.locationId, tabId, frequency));
    setLegendUnactive(legendInit);
  }

  function onFrequencyChange(value) {
    setFrequency(value);
    dispatch(
      fetchChartData(
        callOutData.locationId,
        callOutData.tabs[tabActive].key,
        value
      )
    );
    setLegendUnactive(legendInit);
  }

  function handleZoomInDetail() {
    dispatch(mapPointCloseViewDetail());
    dispatch(fetchMapData(selectedPoint?.locationId));
  }

  function onLegendClick(chartIndex) {
    let chartNo = chartIndex + 1;
    let legend = {
      ...legendUnActive,
      [`series${chartNo}`]: !legendUnActive[`series${chartNo}`],
    };
    setLegendUnactive(legend);
  }

  function TabComponent() {
    return isTabs(callOutData) ? (
      <ScrollMenu
        hideArrows={true}
        hideSingleArrow={true}
        transition={0.2}
        data={callOutData?.tabs.map((values, i) => {
          return (
            <div
              key={i}
              className={
                tabActive === i ? s.mapRightPanelTabActive : s.mapRightPanelTab
              }
            >
              {values.label}
            </div>
          );
        })}
        arrowClass={s.tabArrow}
        selected={selected}
        onSelect={(key) => onTabChange(key)}
        alignCenter={false}
        scrollToSelected={true}
        arrowLeft={
          <img
            className={s.arrowLeft}
            src="/img/icon/ic_chevron_right.svg"
            alt="right"
          />
        }
        arrowRight={
          <img
            className={s.arrowRight}
            src="/img/icon/ic_chevron_right.svg"
            alt="left"
          />
        }
      />
    ) : (
      ""
    );
  }

  function PrimaryComponent() {
    return (
      <div className={s.mapRightPanelPrimary}>
        {callOutData?.tabsInfo?.primaryInfo?.parameters.map((values, i) => {
          return (
            <div key={i}>
              <span>{values.label}</span>
              <span>
                {formatNumber(values.value) === "null"
                  ? "-"
                  : formatNumber(values.value)}
              </span>
              {formatUnit(values.unit)}
            </div>
          );
        })}
      </div>
    );
  }

  function BannerComponent() {
    return (
      <div className={s.mapRightPanelBanner}>
        <img
          src={
            callOutData?.tabsInfo?.primaryInfo?.imageUrl === ""
              ? "/callout-images/vessels/Default.png"
              : callOutData?.tabsInfo?.primaryInfo?.imageUrl
          }
          alt="vessel"
        />
      </div>
    );
  }

  function FrequencyComponent() {
    return (
      <Select
        value={frequency}
        className={s.mapRightPanelSelect}
        onChange={(value) => onFrequencyChange(value)}
      >
        {callOutData?.tabsInfo?.secondaryInfo?.frequency?.map((values, i) => (
          <Option key={i} value={values.key}>
            {values.label}
          </Option>
        ))}
      </Select>
    );
  }

  function setLegendClass(value, i) {
    const seriesidx = i + 1;
    const chartColor = legendUnActive[`series${seriesidx}`]
      ? s[`color-${value.chartColor}`]
      : `${s.colorGrey}`;
    const legendChartClass = s[`legend${value.chartType}`];
    return `${legendChartClass} ${chartColor}`;
  }

  function setLegendSeries(i) {
    const seriesidx = i + 1;
    const legendColor = legendUnActive[`series${seriesidx}`]
      ? ""
      : `${s.colorGrey}`;
    return `${s.legendText} ${legendColor}`;
  }

  function LegendComponent() {
    return (
      <div className={s.mapRightPanelLegend}>
        {dataFields.map((value, i) => (
          <React.Fragment key={i}>
            {value.legendInfo?.showLegend && (
              <div
                className={s.legendItemContainer}
                onClick={() => onLegendClick(i)}
              >
                <div className={`${setLegendClass(value, i)}`}></div>
                <div className={`${setLegendSeries(i)}`}>
                  {value.legendInfo.label}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }

  function ShipTemplateComponent() {
    return (
      isShowShipType[callOutData?.typeId] && (
        <div className={s.shipRow}>
          <img src="/img/icon/Icon Ships.svg" alt="ship" />
          <div className={s.shipRowText}>
            <div className={s.shipTextOrigin}>
              <b>{callOutData?.tabsInfo?.secondaryInfo?.title1}</b>
              <span>{callOutData?.tabsInfo?.secondaryInfo?.subtitle1}</span>
            </div>
            <div className={s.shipTextDestination}>
              <b>{callOutData?.tabsInfo?.secondaryInfo?.title2}</b>
              <span>{callOutData?.tabsInfo?.secondaryInfo?.subtitle2}</span>
            </div>
          </div>
          <div className={s.border} />
        </div>
      )
    );
  }

  function AppLinkComponent() {
    return (
      <div className={s.mapRightPanelButtons}>
        <div className={s.mapRightPanelLink}>
          {callOutData?.appLinks?.map((value, i) => (
            <span>
              <img src="/img/icon/Icon Link.svg" alt="Link to others app" />
              <a key={i} target="_blank" href={value.appUrl} rel="noreferrer">
                {value.label}
              </a>
            </span>
          ))}
        </div>
      </div>
    );
  }

  function TitleComponent() {
    let isShowDrilldown =
      selectedPoint?.drilldown /*&& isAllowLocation(selectedPoint.locationId)*/;
    return (
      <>
        <div className={s.pointTitle}>
          <span>{callOutData?.locationName}</span>
        </div>
        {isShowDrilldown && (
          <div className={s.drillDown} onClick={() => handleZoomInDetail()}>
            <img alt="Drilldown" src="/img/icon/Icon Drill down.svg" />
            <span>MORE</span>
          </div>
        )}
      </>
    );
  }

  function DrilldownComponent() {
    return (
      <button
        type="button"
        className={s.mapRightPanelClose}
        onClick={() => close()}
      >
        <img alt="Close" src="/img/icon/ic_close.svg" />
      </button>
    );
  }

  function _renderMobileTablet(isTable = false) {
    return (
      <div
        className={
          sMobile.mapRightPanelBody +
          ` ${isTable ? sMobile.mapRightPanelBodyTablet : ""}`
        }
      >
        <div className={sMobile.mapRightPaneTitle}>{TitleComponent()}</div>
        {DrilldownComponent()}

        <div className={s.mapRightPanelContent}>
          {" "}
          {isCalloutDataLoading ? (
            <div className={s.calloutLoadingIconContainer}>
              <Spin size="large" className="chartSpin" />
            </div>
          ) : (
            <div className={s.mapRightPanelTabs}>
              {TabComponent()}
              {PrimaryComponent()}
              {BannerComponent()}

              <div className={sMobile.mapRightPanelTabContent}>
                {isShowLegendType[callOutData?.typeId] && (
                  <>
                    <div className={s.mapRightPanelTopChart}>
                      {LegendComponent()}
                      {FrequencyComponent()}
                    </div>
                    <MapChartComponent
                      frequency={frequency}
                      legendUnActive={legendUnActive}
                    />
                  </>
                )}
                {ShipTemplateComponent()}

                <MapScrollComponent />
                <MapModalComponent />
              </div>
              <div className={s.mapRightPanelBorder} />
              {AppLinkComponent()}
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <>
      <Desktop>
        <div className={s.mapRightPanelBody}>
          <div className={s.mapRightPaneTitle}>{TitleComponent()}</div>
          {DrilldownComponent()}

          <div className={s.mapRightPanelContent}>
            {isCalloutDataLoading ? (
              <div className={s.calloutLoadingIconContainer}>
                <Spin size="large" className="chartSpin" />
              </div>
            ) : (
              <div className={s.mapRightPanelTabs}>
                {TabComponent()}
                {PrimaryComponent()}
                {BannerComponent()}

                <div className={s.mapRightPanelTabContent}>
                  {isShowLegendType[callOutData?.typeId] && (
                    <>
                      <div className={s.mapRightPanelTopChart}>
                        {LegendComponent()}
                        {FrequencyComponent()}
                      </div>
                      <MapChartComponent
                        frequency={frequency}
                        legendUnActive={legendUnActive}
                      />
                    </>
                  )}
                  {ShipTemplateComponent()}

                  <MapScrollComponent />
                  <MapModalComponent />
                </div>
                <div className={s.mapRightPanelBorder} />
                {AppLinkComponent()}
              </div>
            )}
          </div>
        </div>
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet(true)}</Tablet>
    </>
  );
};
export default MapDetailComponent;

import * as types from "../action/actionType";

import { APP_CONSTANTS } from "../constants/app";
import { Action } from "redux";
import { getTabDefault } from "../utils/Url.utils";
import queryString from "query-string";

const params = queryString.parse(window.location.search);

export const INITIAL_STATE = {
  currentLocationId:
    params && params.location
      ? Number(params.location)
      : APP_CONSTANTS.DEFAULT_LOCATION_ID,
  selectedPointId: params && params.id ? params.id : null,
  selectedPoint: null,
  mapLevel:
    params && params.level ? Number(params.level) : APP_CONSTANTS.DEFAULT_LEVEL,
  isSatelliteMap:
    params && params.satellite ? params.satellite === "true" : true,
  mapData: {},
  isLoading: true,
  isModalVisible: false,
  activeTab: getTabDefault(),
  isMapTypeVisible: true,
  isOpenSideBar: false,
};
interface MapAction extends Action {
  data: string | any;
  payload: any;
}

export function mapReducer(state = INITIAL_STATE, action: MapAction) {
  switch (action.type) {
    case types.MAP_DATA_FETCH_REQUESTED: {
      return {
        ...state,
        currentLocationId: action.data,
        isLoading: true,
      };
    }
    case types.MAP_DATA_FETCH_SUCCEEDED: {
      return {
        ...state,
        mapData: action?.payload,
        isLoading: false,
      };
    }
    case types.MAP_DATA_FETCH_FAILED: {
      return {
        ...state,
        mapData: [],
        isLoading: false,
      };
    }
    case types.MAP_TYPE_CHANGE: {
      return {
        ...state,
        isSatelliteMap: action.data,
      };
    }
    case types.MAP_LEVEL_CHANGE: {
      return {
        ...state,
        mapLevel: action.data,
      };
    }
    case types.MAP_POINT_VIEW_DETAIL: {
      if (action.data?.locationId) {
        return {
          ...state,
          selectedPoint: action.data,
          selectedPointId: action.data?.locationId,
        };
      }
      return {
        ...state,
        selectedPoint: action.data,
      };
    }
    case types.MAP_POINT_CLOSE_VIEW_DETAIL: {
      return {
        ...state,
        selectedPoint: null,
        selectedPointId: null,
      };
    }
    case types.SCORE_CARD_SIDE_BAR_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: action?.payload,
      };
    }
    case types.MAP_IS_OPEN_MAPTYPE_MOBILE: {
      return {
        ...state,
        isMapTypeVisible: action.payload,
      };
    }
    case types.MAP_IS_OPEN_SIDE_BAR_MOBILE: {
      return {
        ...state,
        isOpenSideBar: action.payload,
      };
    }
  }
  return state;
}

import { get } from 'lodash';
import { ReactElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Desktop, Mobile } from '../../responsive/responesive';

export default function LoadingComponent(): ReactElement {
  const totalRequestStart = useSelector((state) => get(state, "loading.totalRequestStart", 0));
  const totalRequestFinish = useSelector((state) => get(state, "loading.totalRequestFinish", 0));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(totalRequestStart > totalRequestFinish);
  }, [totalRequestStart, totalRequestFinish])
  return (
    <>
      <Desktop>
        <div className={`page-loading ${loading ? 'show' : ''}`}>
          <img src='/img/spinner/spinner.gif' className="plus" alt="loading..." style={{ width: 70 }} />
        </div>
      </Desktop>
      <Mobile>
        <div className={`page-loading-moblie ${loading ? 'show' : ''}`}>
          <img src='/img/spinner/spinner.gif' className="plus" alt="loading..." style={{ width: 70 }} />
        </div>
      </Mobile>
    </>
  )
}

import { isArray } from "lodash";
import moment from "moment";
import { formatScoreCardNumber } from "../utils/Number.utils";

function getChartData(chartData, chartDate) {
    let dataObj: any = Object.values(chartDate);
    if (isArray(chartData)) {
        chartData.forEach((item, idx) => {
            item.values.forEach((itemValue, idxValue) => {
                dataObj[idxValue][`values${idx}`] = formatScoreCardNumber(itemValue['forward-price'] || 0, 2);
                if (idx === 0) {
                    dataObj[idxValue]['timestamp'] = `${itemValue['monthname']} ${itemValue['year']}`;
                }
            });           
        });
    }
    return dataObj;
}

function getData(results, chartDate) {
    const lastUpdated = moment(results[0]?.charts[0].updateddate).format('hh:mm a, DD MMM YYYY');
    const dateOne = JSON.stringify(chartDate);
    const chartOne = getChartData(results[0]?.charts, JSON.parse(dateOne))
    const dateTwo = JSON.stringify(chartDate);
    const chartTwo = getChartData(results[1]?.charts, JSON.parse(dateTwo))
    return { lngData: chartOne, crudeChart: chartTwo, lastUpdated: lastUpdated }

}

function getLengthAndEarliestDate(results) {
    let earliestDate = '';
    const chartOne = results[0]?.charts?.map((item, idx) => {
        if (idx === 0) {
            earliestDate = moment.utc(`${item?.values[0]?.monthname}-01-${item?.values[0]?.year}`).toString();
        }
        return item?.values?.length
    })
    const chartTwo = results[1]?.charts?.map((item) => {
        if (!earliestDate) {
            earliestDate = moment.utc(`${item?.values[0]?.monthname}-01-${item?.values[0]?.year}`).toString();
        }
        return item?.values?.length
    })
    return { dateLength: Math.max(...chartOne, ...chartTwo), earliestDate: earliestDate };
}
function getDate(results) {
    const dateProps = getLengthAndEarliestDate(results)
    let dateArray: any = [];
    dateArray.push({ date: moment(dateProps.earliestDate).toDate() })
    for (let index = 1; index < dateProps.dateLength; index++) {
        dateArray.push({ date: moment(dateProps.earliestDate).add(index, 'months').toDate() })
    }
    return dateArray;
}
function populateData(results) {
    const chartDate = getDate(results)
    const chartData = getData(results, chartDate);
    return chartData;
}
function transformInitData(results) {
    return populateData(results);
}

export function transformFPChartV2Data(results) {
    return transformInitData(results)
}

import { get } from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import { Desktop, Mobile, Tablet } from "../../responsive/responesive";
import BigCardInforComponent from "./BigCard/BigCardInforComponent";
import BigCardInforComponentLevel2List from "./BigCardLv2/BigCardInforComponentLevel2List";
import ChartComponent from "./Chart/ChartComponent";
import s from "./scorecards.module.css";
import sMobile from "./scorecardsResponsiveMobile.module.css";

const ProductionAndGenerationComponent = (props: any) => {
  const productionAndGeneratioData = useSelector((state) =>
    get(state, "scoreCard.productionGenerationData", [])
  );
  const levelScoreCard = useSelector((state) =>
    get(state, "scoreCard.levelScoreCard", 1)
  );

  function _renderBigCard0() {
    return (<div className={s.scoreCardsCol}>
      {productionAndGeneratioData?.parameters?.map((card, idx) => {
        return idx % 3 === 0 ? (
          <BigCardInforComponent key={idx} data={card} />
        ) : (
          ""
        );
      })}
    </div>)
  }

  function _renderBigCard1() {
    return (<div className={s.scoreCardsCol}>
      {productionAndGeneratioData?.parameters?.map((card, idx) => {
        return idx % 3 === 1 ? (
          <BigCardInforComponent key={idx} data={card} />
        ) : (
          ""
        );
      })}
    </div>)
  }

  function _renderBigCard2() {
    return (<div className={s.scoreCardsCol}>
      {productionAndGeneratioData?.parameters?.map((card, idx) => {
        return idx % 3 === 2 ? (
          <BigCardInforComponent key={idx} data={card} />
        ) : (
          ""
        );
      })}
    </div>)
  }
  const _renderMobileTablet = () => (
    levelScoreCard === 1 ? (
      <div className={sMobile.scoreCardsItems}>
        {_renderBigCard0()}
        {_renderBigCard1()}
        {_renderBigCard2()}
      </div>
    ) : (
      <div>
        <BigCardInforComponentLevel2List
          listBigCard={productionAndGeneratioData?.parameters}
        />
        <div className={sMobile.backGroundChart}>
          <ChartComponent tab="production" />
        </div>
      </div>
    ))

  return (
    <>
      <Desktop>
        {levelScoreCard === 1 ? (
          <div className={s.scoreCardsItems}>
            {_renderBigCard0()}
            {_renderBigCard1()}
            {_renderBigCard2()}
          </div>
        ) : (
          <div>
            <BigCardInforComponentLevel2List
              listBigCard={productionAndGeneratioData?.parameters}
            />
            <div className={s.backGroundChart}>
              <ChartComponent tab="production" />
            </div>
          </div>
        )}
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>
    </>
  );
};
export default ProductionAndGenerationComponent;

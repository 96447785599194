import { Tabs } from 'antd';
import { useEffect, useState } from "react";
import s from "./BaseLmtSalesSummaryChart.module.css";
import { Modal } from "antd";
import GeneralComponent from "./General/GeneralComponent";
import CountryDetailComponent from "./CountryDetail/CountryDetailComponent";
import CustomerDetailComponent from "./CustomerDetail/CustomerDetailComponent";
import { LMT_SALES_VOLUME_TAB } from "../../../../constants/value-chain.constants";
interface Iprops {
  isVisibleModal: boolean;
  handleCancelModal: () => void;
  selectedTab: string;
  name: string;
  country: string;
  setSelectedTab?: (tab: string) => void;
  setSelectedCountry?: (country: string) => void;
  isMultiTab?: boolean
}

const { TabPane } = Tabs;
export default function BaseLmtSalesSummaryChart(props: Iprops) {
  const { isVisibleModal, handleCancelModal, selectedTab, name, country, setSelectedTab, setSelectedCountry, isMultiTab = false } = props
  const [showCountryFilter, setShowCountryFilter] = useState(false);
  const [showCustomerFilter, setShowCustomerFilter] = useState(false);
  const [showSourceFilter, setShowSourceFilter] = useState(false);
  const [selectCustomer, setSelectCustomer] = useState('');
  const currentYear = new Date().getFullYear();
  const [fromYearFilterValue, setFromYearFilterValue] = useState(currentYear);
  const [toYearFilterValue, setToYearFilterValue] = useState(currentYear);
  const [unitIndex, setUnitIndex] = useState(0);

  const CloseButton = () => <img alt='close-icon' src='/img/icon/close-btn.svg' />;
  const handleChangeCustomerTab = (customer: string, country: string) => {
    setSelectCustomer(customer);
    setSelectedTab && setSelectedTab(LMT_SALES_VOLUME_TAB.CUSTOM_DETAIL)
    setSelectedCountry && setSelectedCountry(country);
  }
  
  useEffect(() => {
    setShowCountryFilter(false)
    setShowCustomerFilter(false)
  },[isVisibleModal])

  const TabComponent = () => {
    return <Tabs activeKey={selectedTab} onChange={(tab) => { setSelectedTab && setSelectedTab(tab) }}>
      <TabPane tab="General" key={LMT_SALES_VOLUME_TAB.GENERAL}>
        <GeneralComponent selectedTab={selectedTab} isVisibleModal={isVisibleModal} showCountryFilter={showCountryFilter} setShowCountryFilter={setShowCountryFilter} showCustomerFilter={showCustomerFilter} setShowCustomerFilter={setShowCustomerFilter} showSourceFilter={showSourceFilter} setShowSourceFilter={setShowSourceFilter}  />
      </TabPane>
      <TabPane tab="Country Detail" key={LMT_SALES_VOLUME_TAB.COUNTRY_DETAIL} >
        <CountryDetailComponent
          selectedCountry={country}
          setSelectedCountry={setSelectedCountry}
          isVisibleModal={isVisibleModal}
          handleChangeCustomerTab={handleChangeCustomerTab}
          fromYearFilterValue={fromYearFilterValue}
          setFromYearFilterValue={setFromYearFilterValue}
          toYearFilterValue={toYearFilterValue}
          setToYearFilterValue={setToYearFilterValue}
          unitIndex={unitIndex}
          setUnitIndex={setUnitIndex}
          selectedTab={selectedTab}
          
        />
      </TabPane>
      {<TabPane tab="Customer Detail" key={LMT_SALES_VOLUME_TAB.CUSTOM_DETAIL} >
        <CustomerDetailComponent
          customer={selectCustomer}
          country={country}
          fromYearFilterValue={fromYearFilterValue}
          setFromYearFilterValue={setFromYearFilterValue}
          toYearFilterValue={toYearFilterValue}
          setToYearFilterValue={setToYearFilterValue}
          unitIndex={unitIndex}
          setUnitIndex={setUnitIndex}
          selectedTab={selectedTab}
        />
      </TabPane>}
    </Tabs>
  }

  return (
    <Modal
      className='scorecardLmtChartModal'
      visible={isVisibleModal}
      footer={null}
      onCancel={handleCancelModal}
      width='95%'
      zIndex={1000}
      closeIcon={<CloseButton />}
      maskClosable={false}
      transitionName=""
      style={{ top: 20 }}
    >
      <div className={s.modalHeader}>
        <p>{name}</p>
      </div>
      <div className="tabsContainer">
        {
          isMultiTab
            ?
            <GeneralComponent selectedTab={selectedTab} isVisibleModal={isVisibleModal} showCountryFilter={showCountryFilter} setShowCountryFilter={setShowCountryFilter} showCustomerFilter={showCustomerFilter} setShowCustomerFilter={setShowCustomerFilter} showSourceFilter={showSourceFilter} setShowSourceFilter={setShowSourceFilter} />
            :
            TabComponent()
        }
      </div>
    </Modal >
  )
}


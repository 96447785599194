import React from 'react'
import GasBusinessValueChainComponent from './GasBusinessValueChainComponent/GasBusinessValueChainComponent'
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import LMTValueChainComponent from './LMTValueChainComponent/LMTValueChainComponent';

const ValueChainComponent = () => {
    const selectedPersona = useSelector((state) =>
        get(state, "user.selectedPersona")
    );

    if (selectedPersona === 'LMT') {
        return <LMTValueChainComponent />
    }

    return (
        <GasBusinessValueChainComponent />
    )
}

export default ValueChainComponent
function transformInitData(result) {
  let newResult: any = [];
  for (let index = 0; index < result.length; index++) {
    const object = result[index];
    newResult.push({
      id: object.opuId,
      text: object.opuName,
    });
  }

  return newResult;
}

export function transformOpuList(results) {
  return transformInitData(results);
}

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Col, Row, Tabs } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createAnnouncement } from '../../../action/actionContentRelease';
import {
  CONFIRM_MODAL,
  SLIDE_LIMIT,
} from '../../../constants/content-release.constants';
import { ISlide } from '../../../model/contentRelease.model';
import ModalConfirm from '../../ModalConfirm/ModalConfirm';
import s from './CreateUpdateForm.module.css';

function CreateUpdateForm(props: any) {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const isFormLoading = useSelector((state) =>
    get(state, 'contentRelease.isFormLoading', false)
  );
  const [slides, setSlides] = useState<ISlide[]>([
    { slideTitle: '', content: '', slideOrder: 0, imageFile: '' },
  ]);
  const [currentSlideKey, setCurrentSlideKey] = useState(0);
  const [confirmKey, setConfirmKey] = useState<any>(null);
  const [titleContent, setTitleContent] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [isFormValidated, setFormValidate] = useState(false);

  useEffect(() => {
    validateForm();
  }, [titleContent, expiryDate, slides]);

  function validateForm() {
    const isValidated =
      slides.every((slide) => slide.imageFile || slide.imageUrl) &&
      slides.every(
        (slide) => slide.content.length > 0 && slide.content.length <= 300
      ) &&
      titleContent.length > 0 &&
      titleContent.length <= 100;
    setFormValidate(isValidated);
  }

  function _renderGroupBtn() {
    return (
      <div className={s.groupBtn}>
        <button
          className={btnClassName('secondaryBtn', isFormLoading)}
          disabled={isFormLoading}
          onClick={() => {
            props.onClose();
          }}
          type='button'
        >
          CANCEL
        </button>
        <button
          disabled={!isFormValidated || isFormLoading}
          className={btnClassName(
            'primaryBtn',
            !isFormValidated || isFormLoading
          )}
          onClick={() => {
            setConfirmKey(CONFIRM_MODAL.SAVE.KEY);
          }}
          type='button'
        >
          SAVE
        </button>
      </div>
    );
  }

  function btnClassName(type, isDisabled) {
    return `${s[type]}` + (isDisabled ? ` ${s.disabled}` : '');
  }

  function _renderConfirmModal() {
    if (confirmKey) {
      switch (confirmKey) {
        case CONFIRM_MODAL.SAVE.KEY: {
          return (
            <ModalConfirm
              message={CONFIRM_MODAL.SAVE.MESSAGE}
              type={CONFIRM_MODAL.SAVE.KEY}
              onConfirm={() => {
                createNewAnnouncement();
              }}
              onClose={closeModal}
            />
          );
        }
        case CONFIRM_MODAL.PUBLISH.KEY: {
          return;
        }
        default: {
          return;
        }
      }
    }
  }

  function createNewAnnouncement() {
    const data = {
      status: 0,
      expiryDate,
      titleContent,
      slides,
    };
    dispatch(createAnnouncement(data));
  }

  function closeModal() {
    setConfirmKey(null);
  }

  function _renderForm() {
    return (
      <div className={s.formContent}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className={s.formItem}>
              <label>Title content</label>
              <input
                value={titleContent}
                type='text'
                name='titleContent'
                placeholder='Enter your title'
                onChange={(e) => {
                  setTitleContent(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col span={12}>
            <div className={s.formItem}>
              <label>Schedule Until</label>
              <input
                value={
                  expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : ''
                }
                type='date'
                name='expiryDate'
                min={moment().format('YYYY-MM-DD')}
                onChange={(e) => {
                  const date = moment(
                    `${e.target.value} 23:59`,
                    'YYYY-MM-DD HH:mm'
                  ).toISOString();
                  setExpiryDate(date);
                }}
              />
            </div>
          </Col>
          <Col span={24}>{_renderSlides()}</Col>
        </Row>
      </div>
    );
  }

  function _renderSlides() {
    return (
      <div className={s.slideTabs}>
        <Tabs
          activeKey={`${currentSlideKey}`}
          defaultActiveKey={`${0}`}
          onChange={(value) => {
            setCurrentSlideKey(Number(value));
          }}
        >
          {slides.map((slide) => {
            return (
              <TabPane
                tab={`SLIDE ${slide.slideOrder + 1}`}
                key={slide.slideOrder}
              >
                {_renderSlideDetail()}
              </TabPane>
            );
          })}
        </Tabs>
        {_renderGroupSlideBtn()}
      </div>
    );
  }


  function _renderSlideDetail() {
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <div className={s.formItem}>
              <label>Title Slide</label>
              <input
                value={slides[currentSlideKey].slideTitle}
                onChange={(e) => {
                  let newSlides = [...slides];
                  newSlides[currentSlideKey].slideTitle = e.target.value;
                  setSlides(newSlides);
                }}
                type='text'
                name='titleSlide'
                placeholder='Enter your title'
              />
            </div>
          </Col>
          <Col span={12}>
            <div className={s.formItem}>
              <label>Upload</label>
              <label
                htmlFor={`img-upload-slide${currentSlideKey}`}
                className={s.imgUploader}
              >
                <span>
                  {slides[currentSlideKey]?.imageFile?.name
                    ? slides[currentSlideKey]?.imageFile?.name
                    : 'Upload Jpg, PNG..(below 10mb'}
                </span>
                <span>BROWSE</span>
              </label>
              <input
                accept='image/png, image/jpeg, image/jpg'
                id={`img-upload-slide${currentSlideKey}`}
                type='file'
                name='imageFile'
                placeholder='Upload jpg, png...(below 10mb)'
                onChange={(e) => {
                  let newSlides = [...slides];
                  if (e.target.files) {
                    newSlides[currentSlideKey].imageFile =
                      e.target.files[0] || '';
                  }
                  setSlides(newSlides);
                }}
              />
            </div>
          </Col>
          <Col span={24}>
            <div className={s.formItem}>
              <label>Content Slide</label>
              <div id='content-editor'>
                <CKEditor
                  config={{
                    placeholder: 'Enter your content',
                    wordCount: { displayWords: false },
                    containerClass: 'editor'
                  }}
                  editor={ClassicEditor}
                  onChange={(event, editor) => {
                    let newSlides = [...slides];
                    newSlides[currentSlideKey].content = editor.getData();
                    setSlides(newSlides);
                  }}
                  className={s.editor}
                />
                <p className={s.charCount}>
                  Character limits:{' '}
                  {
                    slides[currentSlideKey].content.length
                    // slides[currentSlideKey].content.replace(/<[^>]*>/g, '')
                    //   .length
                  }
                  /300
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  function _renderGroupSlideBtn() {
    return (
      <div className={s.groupSlideBtn}>
        <button
          className={btnClassName(
            'secondaryBtn',
            slides.length <= SLIDE_LIMIT.MIN
          )}
          disabled={slides.length <= SLIDE_LIMIT.MIN}
          onClick={() => {
            const remainSlides = slides.filter(
              (item) => item.slideOrder !== currentSlideKey
            );
            remainSlides.forEach((item, index) => {
              item.slideOrder = index;
            });
            setSlides(remainSlides);
            setCurrentSlideKey(0);
          }}
          type='button'
        >
          REMOVE SLIDE
        </button>

        <button
          className={btnClassName(
            'secondaryBtn',
            slides.length >= SLIDE_LIMIT.MAX
          )}
          disabled={slides.length >= SLIDE_LIMIT.MAX}
          onClick={() => {
            const lastSlide = slides[slides.length - 1];
            const newSlide = {
              slideTitle: '',
              content: '',
              slideOrder: lastSlide.slideOrder + 1,
              imageFile: '',
            };
            setSlides([...slides, newSlide]);
            setCurrentSlideKey(lastSlide.slideOrder + 1);
          }}
          type='button'
        >
          NEW SLIDE
        </button>
      </div>
    );
  }

  return (
    <>
      <div className={s.contentDetailPanel}>
        <div className={s.contentDetailContainer}>
          <h2>CONTENT DETAIL</h2>
          {_renderGroupBtn()}
          {_renderForm()}
        </div>
      </div>
      {_renderConfirmModal()}
    </>
  );
}

export default CreateUpdateForm;

import * as types from './actionType';

import { APP_CONSTANTS } from '../constants/app';

export const fetchMapData = (locationId = APP_CONSTANTS.DEFAULT_LOCATION_ID) => ({
  type: types.MAP_DATA_FETCH_REQUESTED,
  data: locationId
})
export const mapTypeChange = (isSatelliteMap: boolean) => ({
  type: types.MAP_TYPE_CHANGE,
  data: isSatelliteMap
})
export const mapLevelChange = (level: number) => ({
  type: types.MAP_LEVEL_CHANGE,
  data: level
})
export const mapPointViewDetail = (point: any) => ({
  type: types.MAP_POINT_VIEW_DETAIL,
  data: point
})
export const mapPointCloseViewDetail = () => ({
  type: types.MAP_POINT_CLOSE_VIEW_DETAIL
})
export const mapPointZoomIn = (point: any) => ({
  type: types.MAP_POINT_ZOOM_IN,
  data: point
})
export const mapPointZoomOut = (point: any) => ({
  type: types.MAP_POINT_ZOOM_OUT,
  data: point
})
export const mapModalChange = (isModalVisible: boolean) => ({
  type: types.MAP_MODAL_CHANGE,
  data: isModalVisible
})
export const fetchCalloutData = (callout = Number) => ({
  type: types.MAP_CALLOUT_FETCH_REQEUSTED,
  data: callout
})
export const fetchCalloutDataSec = (callout: Number) => ({
  type: types.MAP_CALLOUT_FETCH_REQUESTED_SEC,
  data: callout
})
export const fetchHighlightData = (locationId = Number) => ({
  type: types.MAP_HIGHLIGHT_FETCH_REQEUSTED,
  locationId: locationId
})
export const fetchSustainabilityCalloutData = (callout = Number, isDaily: boolean) => ({
  type: types.SUSTAINABILITY_CALLOUT_FETCH_REQUESTED,
  data: callout,
  isDaily
})
export const fetchDeliverData = (
  locationId: string,
  beforeDateTime: string,
  limit: string,
  latestByBerth: string
) => ({
  type: types.MAP_DELIVER_FETCH_REQEUSTED,
  locationId,
  beforeDateTime,
  limit,
  latestByBerth,
});
export const fetchPrimaryInfoData = (locationId, tabId) => ({
  type: types.MAP_PRIMARY_FETCH_REQUESTED,
  locationId: locationId,
  tabId: tabId
})
export const fetchChartData = (locationId, tabId, frequency) => ({
  type: types.MAP_CHART_FETCH_REQUESTED,
  locationId: locationId,
  tabId: tabId,
  frequency: frequency
})
export const onTabPrimaryChange = (tabId) => ({
  type: types.MAP_PRIMARY_TAB_CHANGE,
  tabId: tabId
})

export const updateActiveTab = (tabActive) => ({
  type: types.SCORE_CARD_SIDE_BAR_ACTIVE_TAB,
  payload: tabActive
})

export const isMapTypeMobile = (isOpen: boolean) => ({
  type: types.MAP_IS_OPEN_MAPTYPE_MOBILE,
  payload: isOpen
})

export const isOpenSideBarMobile = (isOpen: boolean) => ({
  type: types.MAP_IS_OPEN_SIDE_BAR_MOBILE,
  payload: isOpen
})

export const openHisTrendChartModal = () => ({
  type: types.OPEN_MODAL_HIS_TREND,
});

export const closeHisTrendChartModal = () => ({
  type: types.CLOSE_MODAL_HIS_TREND,
});

export const fetchHisTrendChartData = (
  locationId: string,
  frequency: string
) => ({
  type: types.HIS_TREND_CHART_DATA_REQUESTED,
  locationId,
  frequency,
});

export const fetchCargoDetails = (
  locationId: string,
  beforeDateTime: string,
  limit: string,
  latestByBerth: string
) => ({
  type: types.CALLOUT_CARGO_DETAILS_REQUESTED,
  locationId,
  beforeDateTime,
  limit,
  latestByBerth,
});
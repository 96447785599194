import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import UploadFileTable from '../../../components/ScorecardManagement/UploadFileTable/UploadFileTable';
import ScrollContainer from 'react-indiana-drag-scroll'
import DateDropdown from '../../../components/DateDropdown/DateDropdown';
import { fetchListHistoryUpload, fetchScoreCardMenuList, fetchScoreCardPeriodsList, removeUploadFileMessage, resetUploadFileSuccessStatus, uploadScorecardFile } from '../../../action/actionScoreCard';
import { MONTH_SHORT_TEXT } from '../../../constants/score-card.constants';
import s from './ScorecardManagement.module.css'
import moment from 'moment';
import ScoreCardFolderList from '../../../components/ScorecardManagement/ScoreCardFolderList/ScoreCardFolderList';
import ScorecardManagementUploadModal from '../../../components/ScorecardManagement/ScorecardManagementUploadModal/ScorecardManagementUploadModal';
import { SorterResult } from 'antd/lib/table/interface';

export interface DataType {
    fileName: string;
    location: string;
    uploadedBy: string;
    status: string;
}

const ScorecardManagement = () => {
    const [currentDate, setCurrentDate] = useState({ month: 0, year: 1999 })
    const [sortedInfo, setSortedInfo] = useState<SorterResult<DataType>>({});
    const [folder, setFolder] = useState('BPP LMT DFC');
    const [isShowModal, setIsShowModal] = useState(false);
    const scoreCard = useSelector((state: any) => state.scoreCard);
    const dispatch = useDispatch();

    const {
        periodsList,
        folderList,
        historyUploadList,
        isDeleteFileUpload
    } = scoreCard;
    function disabledDate(current: moment.Moment) {
        const currentMonthYear = moment().format("MM-YYYY");
        if (periodsList && periodsList.length > 0) {
            return (
                current <
                moment(`${periodsList[0].month}-${periodsList[0].year}`, "MM-YYYY") ||
                current > moment(`${currentMonthYear}`, "MM-YYYY")
            );
        } else {
            return false;
        }
    }

    const onDateChange = (date) => {
        const convertDate = { month: date.month, year: date.year }
        setCurrentDate(convertDate)
        getScoreCardMenuList(convertDate)
        getSListHistoryUpload(convertDate)
    }

    const onOpenModal = (folderName: string) => {
        setFolder(folderName)
        setIsShowModal(true)
    }

    const getScoreCardMenuList = (date) => {
        dispatch(fetchScoreCardMenuList({
            params: `?period=${MONTH_SHORT_TEXT[date.month - 1]}%20${date.year}`
        }))
    }

    const getSListHistoryUpload = (date) => {
        dispatch(fetchListHistoryUpload({
            params: `?period=${MONTH_SHORT_TEXT[date.month - 1]}%20${date.year}`
        }))
    }

    const onSuccessUpload = () => {
        const date = { month: currentDate.month, year: currentDate.year };
        getScoreCardMenuList(date);
        getSListHistoryUpload(date);
        //clear sorter after uploading 
        setSortedInfo({})
    }

    useEffect(() => {
        dispatch(fetchScoreCardPeriodsList());
    }, []);

    // get periods list 
    useEffect(() => {
        if (periodsList && periodsList.length > 0) {
            const dateFilter = periodsList?.filter(
                (item: any) => item.defaultSelection === true
            )[0];
            const payload = {
                year: dateFilter?.year,
                month: dateFilter?.month,
            };
            getSListHistoryUpload(payload)
            getScoreCardMenuList(payload)
            setCurrentDate(payload);
        }
    }, [periodsList]);

    useEffect(() => {
        const interval = setInterval(() => {
        //   if (currentDate && currentDate.month && currentDate.year) {
            getScoreCardMenuList(currentDate);
            getSListHistoryUpload(currentDate);
        //   }
        }, 5000); 
        return () => clearInterval(interval); 
    }, [currentDate]);

    useEffect(() => {
        const date = { month: currentDate.month, year: currentDate.year };
        getScoreCardMenuList(date);
        getSListHistoryUpload(date);
    }, [isDeleteFileUpload])

    return (
        <div className="usermanagement">
            <div className={`fullscreen-pt-0 ${s.userManagementRoot}`}>
                <ScrollContainer
                    className={`page-container bg-map ${s.userManagementContainer}`}
                >
                    <div className={s.userManagementPanel}>
                        <div className={s.userManagementPanelContainer}>
                            <div className={s.userManagementPanelInnerContainer}>
                                <h2>SCORECARD MANAGEMENT</h2>
                                <div className={s.userManagementDiv} />
                                <div className={s.periodTab}>
                                    <DateDropdown disabledDate={disabledDate} periodsList={[]} currentDate={currentDate} onDateChange={onDateChange} />
                                    <button type='button' className={s.uploadButton} onClick={() => onOpenModal('BPP LMT DFC')} >Upload File</button>
                                </div >
                                <ScoreCardFolderList onOpenModal={onOpenModal} folderList={folderList} currentDate={currentDate} />
                                <UploadFileTable data={historyUploadList} sortedInfo={sortedInfo} setSortedInfo={setSortedInfo} />
                                <ScorecardManagementUploadModal folderOptions={folderList?.map(fl => ({name: fl.location}))} isShowModal={isShowModal} folder={folder} setFolder={setFolder} setIsShowModal={setIsShowModal} currentDate={currentDate} onSuccessUpload={onSuccessUpload} />
                            </div>
                        </div>
                    </div>
                </ScrollContainer>
            </div>
        </div>
    )
}

export default ScorecardManagement
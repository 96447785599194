import React, { useLayoutEffect, useRef, memo } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

interface RiskMitigationChartComponentProps {
  data: number[];
  id: string;
}

interface ExpandedDataItem {
  category: string;
  value: number;
  color: am4core.Color;
}

const RiskMitigationChartComponent: React.FC<RiskMitigationChartComponentProps> = ({ data, id }) => {
  const chartRef = useRef<am4charts.PieChart | null>(null);

  useLayoutEffect(() => {
    let chart = am4core.create(id, am4charts.PieChart);

    if(!!chartRef){
        chartRef.current = chart;
    }

    const colors = [am4core.color("#00A19C"), am4core.color("#005CB9"), am4core.color("#FDB924"), am4core.color("#D6D6D6")];
    const categories = ["Completed", "On Track", "Delayed", "Not Started"];
    const total = data.reduce((acc, val) => acc + val, 0);

    let chartData: ExpandedDataItem[];

    // No Mitigation info
    if (total === 0) {
        chartData = [{
            category: "No Data",
            // Creating grey circle
            value: 1, 
            color: am4core.color("#D6D6D6")
        }];
    } else {
        let expandedData: ExpandedDataItem[] = [];
        data.forEach((value, index) => {
            for (let i = 0; i < value; i++) {
                expandedData.push({
                    category: categories[index],
                    value: 1,
                    color: colors[index]
                });
            }
        });
        chartData = expandedData;
    }

    chart.data = chartData;

    chart.logo.disabled = true;

    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "category";
    pieSeries.slices.template.propertyFields.fill = "color";

    pieSeries.innerRadius = am4core.percent(70);

    pieSeries.slices.template.stroke = am4core.color("#ffffff");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;

    pieSeries.slices.template.tooltipText = "";
    pieSeries.ticks.template.disabled = true; // Disable ticks

    let label = chart.seriesContainer.createChild(am4core.Label);
    label.text = total > 0 ? `${total}` : "0";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fontSize = 12;

    return () => {
        chart.dispose();
    };
}, [chartRef, JSON.stringify(data), id]);

return <div id={id} style={{ width: "70px", height: "70px" }}></div>;
};
export default RiskMitigationChartComponent;
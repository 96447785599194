import { Action } from 'redux';
import * as types from '../action/actionType';

export const INITIAL_STATE = {
    valueChainData: {},
    isLoading: true,
    valueChainOverviewData: []
};
interface ValueChainAction extends Action {
    data: string | any;
    payload: any;
}

export function valueChainReducer(state = INITIAL_STATE, action: ValueChainAction) {
    switch (action.type) {
        case types.VALUE_CHAIN_DATA_REQUESTED: {
            return {
                ...state,
                isLoading: true
            }
        }
        case types.VALUE_CHAIN_DATA_FETCH_SUCCEEDED: {
            return {
                ...state,
                valueChainData: action?.payload,
                isLoading: false
            }
        }
        case types.VALUE_CHAIN_DATA_FETCH_FAILED: {
            return {
                ...state,
                valueChainData: [],
                isLoading: false
            }
        }
        // Value chain overview
        case types.VALUE_CHAIN_OVERVIEW_REQUESTED: {
            return {
                ...state,
            }
        }
        case types.VALUE_CHAIN_OVERVIEW_FETCH_SUCCEEDED: {
            return {
                ...state,
                valueChainOverviewData: action?.payload,
            }
        }
        case types.VALUE_CHAIN_OVERVIEW_FETCH_FAILED: {
            return {
                ...state,
                valueChainOverviewData: [],
            }
        }
    }
    return state;
}



import React from 'react';
import 'antd/dist/antd.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import GoDashboardComponent from './container/GoDashboardComponent';

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_dark);
function App() {
  window.dataLayer = window.dataLayer || [];
  return (
   <GoDashboardComponent />
  ); 
}

export default App;

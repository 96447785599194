import * as types from "../action/actionType";

import { Action } from "redux";

export const initialData = {
  isWMTableLoading: false,
  isVCTableLoading: false,
  limitWmTableData: [],
  limitVcTableData: [],
};

interface LimitConfigAction extends Action {
  payload: string | any;
  data: string | any;
}

export function limitConfigReducer(
  state = initialData,
  action: LimitConfigAction
) {
  switch (action.type) {
    case types.LIMITCONFIG_GET_WM_TABLE_REQUESTED: {
      return {
        ...state,
        isWMTableLoading: true,
      };
    }
    case types.LIMITCONFIG_GET_WM_TABLE_SUCCEEDED: {
      return {
        ...state,
        limitWmTableData: action.payload,
        isWMTableLoading: false,
      };
    }
    case types.LIMITCONFIG_GET_WM_TABLE_FAILED: {
      return {
        ...state,
        isWMTableLoading: false,
      };
    }
    case types.LIMITCONFIG_GET_VC_TABLE_REQUESTED: {
      return {
        ...state,
        isVCTableLoading: true,
      };
    }
    case types.LIMITCONFIG_GET_VC_TABLE_SUCCEEDED: {
      return {
        ...state,
        limitVcTableData: action.payload,
        isVCTableLoading: false,
      };
    }
    case types.LIMITCONFIG_GET_VC_TABLE_FAILED: {
      return {
        ...state,
        isVCTableLoading: false,
      };
    }
    case types.LIMITCONFIG_UPDATE_RULES_REQUESTED: {
      return {
        ...state,
        isWMTableLoading: true,
        isVCTableLoading: true,
      };
    }
    case types.LIMITCONFIG_UPDATE_RULES_SUCCEEDED: {
      return {
        ...state,
        isWMTableLoading: false,
        isVCTableLoading: false,
      };
    }
    case types.LIMITCONFIG_UPDATE_RULES_FAILED: {
      return {
        ...state,
        isWMTableLoading: false,
        isVCTableLoading: false,
      };
    }
  }
  return state;
}

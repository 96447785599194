import { ENDPOINTS } from "../constants/endpoints";
import axiosClient from "./axiosClient.service";

export function getIsUserAuthorized() {
  return axiosClient.get(ENDPOINTS.GET_PRIVATE_ROUTE_IS_AUTHORIZED);
}

export function getValidateUser() {
  return axiosClient.get(ENDPOINTS.GET_VALIDATE_USER);
}

export function postLoginCount() {
  return axiosClient.post(ENDPOINTS.POST_LOGIN_HISTORY);
}

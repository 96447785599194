import PersonaTableComponent from "../../../components/Usermanagement/PersonaTable/PersonaTableComponent";
import ScrollContainer from "react-indiana-drag-scroll";
import { Tabs } from "antd";
import s from "./Usersmanagement.module.css";
import UserTablePageComponent from "../../../components/Usermanagement/UserTable/UserTablePageComponent";

const UserManagementComponent = () => {
  const { TabPane } = Tabs;

  function _tabsComponent() {
    return (
      <Tabs className="usermanagement-tabs" defaultActiveKey="1">
        <TabPane tab="User" key="1">
          <UserTablePageComponent />
        </TabPane>
        {/* <TabPane tab="Persona" key="2">
          <PersonaTableComponent />
        </TabPane> */}
      </Tabs>
    );
  }
  return (
    <div className="usermanagement">
      <div className={`fullscreen-pt-0 ${s.userManagementRoot}`}>
        <ScrollContainer
          className={`page-container bg-map ${s.userManagementContainer}`}
        >
          <div className={s.userManagementPanel}>
            <div className={s.userManagementPanelContainer}>
              <div className={s.userManagementPanelInnerContainer}>
                <h2>User Management</h2>
                <div className={s.userManagementDiv} />
                {_tabsComponent()}
              </div>
            </div>
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
};
export default UserManagementComponent;

import { ENDPOINTS } from '../constants/endpoints';
import axiosClient, { axiosClientFormData } from './axiosClient.service';

export function getContentReleaseAnnouncement() {
  return axiosClient.get(ENDPOINTS.GET_CONTENT_RELEASE_ANNOUNCEMENT);
}

export function getRCTable(data) {
  const { pageSize, pageNumber } = data;
  const params = `?pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return axiosClient.get(ENDPOINTS.GET_RC_TABLE_DATA + params);
}

export function togglePublishStatus(data) {
  const { id, isPublish } = data;
  const params = `/${id}?isPublish=${isPublish}`;
  return axiosClient.post(ENDPOINTS.RC_TOGGLE_PUBLISH + params);
}

export function deleteRecord(data) {
  const { id } = data;
  const params = `/${id}`;
  return axiosClient.delete(ENDPOINTS.GET_RC_TABLE_DATA + params);
}

interface Slide {
  slideTitle: string;
  content: string;
  slideOrder: number;
  imageManner?: number;
  imageUrl?: any;
  imageIndex?: number;
}

function formatAnnouncementData(data, files) {
  return {
    ...data,
    slides: data.slides.map((s) => {
      let slide: Slide = {
        content: s.content,
        slideTitle: s.slideTitle,
        slideOrder: s.slideOrder,
      };
      if (s.imageFile) {
        const imageIndex = files.indexOf(s.imageFile);
        if (imageIndex !== -1) {
          slide.imageIndex = imageIndex;
          slide.imageManner = 2;
        } else {
          slide.imageUrl = s.imageUrl;
          slide.imageManner = 1;
        }
      } else {
        if(s.imageUrl){
          slide.imageUrl = s.imageUrl;
          slide.imageManner = 1;
        }else{
          slide.imageManner = 0;
        }
      }
      return slide;
    }),
  };
}

function formatFormData(data){
  const formData = new FormData();
  const files: File[] = [];
  for (const s of data.slides) {
    if (s.imageFile && typeof s.imageFile !== 'string') {
      files.push(s.imageFile);
    }
  }
  if (files.length > 0) {
    for (const f of files) {
      formData.append('files', f);
    }
  }
  const announcement = formatAnnouncementData(data, files);
  formData.append('stringifyAnnouncement', JSON.stringify(announcement));
  return formData
}

export function createRecord(data) {
  const formData = formatFormData(data)
  return axiosClientFormData.post(ENDPOINTS.GET_RC_TABLE_DATA, formData);
}

export function updateRecord(data) {
  const formData = formatFormData(data)
  return axiosClientFormData.put(ENDPOINTS.GET_RC_TABLE_DATA, formData);
}

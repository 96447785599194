import s from "./GHGListEquipmentComponent.module.css";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { useState, useEffect } from "react";
import { fetchSustainabilityDrilldownRGTP, fetchGhgData } from "../../../action/actionSustainability";
import { toFixedSustainabilityNumber } from "../../../utils/Number.utils";
import { Tooltip } from "antd";
import queryString from "query-string";
import PiTagLegend from "../GHGTimestamp/GHGTimestampComponent";

const GHGListEquipmentRGTPComponent = (props) => {
  const {  location } = props;
  const params = queryString.parse(window.location.search);

  const dispatch = useDispatch()

  const listModule = useSelector((state) =>
    get(state, 'sustainability.listModule.result', {})
  );
  
  const lastUpdated = useSelector((state) =>
    get(state, 'sustainability.listModule', {})
  );

  function handleQuickJump(locationId) {
    dispatch(fetchGhgData(locationId));
  }

  useEffect(() => {
    const params = `?location=${location}`
    dispatch(fetchSustainabilityDrilldownRGTP(params))
  },[]) 

  function toggleJump(){
    return (
      <div className={s.jumpPFLNGContainer} onClick={() => handleQuickJump(2)}>
        <div className={s.jumpPFLNG}>
          
          <div style={{marginRight: '5px'}}>RGT SG UDANG</div>
          <span style={{paddingBottom: '3px'}}><img alt='right-arrow' src='/img/icon/forward.svg'/></span>
        </div>
      </div>
    );
  }

  return <>
    {toggleJump()}
      <div className={s.mapBreadcrumbRight}>
        {listModule.listModule && listModule.listModule.filter(module => module.moduleName !== 'Indirect Emission - Purchased Energy').slice(0, -3).map((module, index) => {
          const total = { value: 0 };

          function calculateTotal(num: number | string){
            const number = typeof num === 'string' ? parseFloat(num) : num;
            total.value += number;
            return total.value;
          }

          return (
            <div className={s.equipmentItemContainer} key={index}>
              <div className={s.listEquipmentItem}> 
                <table>
                  <h3 style={{height: module.moduleName === "Indirect Emission - Purchased Energy" ? '42px' : 'auto'}}>{module.moduleName}</h3>
                  <td className={s.valueEquipment} style={{fontWeight: 300,fontSize: '12px'}}><span>(tCO<sub>2</sub>e/h)</span></td>
                  {module.listEquipment
                    .sort((a, b) => Number(b.value) - Number(a.value))
                    .map((equipment, eqIndex) => {
                      calculateTotal(equipment.value);
                      return (
                        <tr key={eqIndex}>
                          <td>{equipment.equipmentName}</td>
                          <td className={s.valueEquipment}>
                            {toFixedSustainabilityNumber(equipment.value, 3)}
                          </td>
                        </tr>
                      );
                    })
                  }
                  <tr className={s.totalWord}>Total
                    <td className={s.totalEquipment}>{toFixedSustainabilityNumber(total.value,3)}</td>
                  </tr>
                </table>
              </div>
            </div>
          );
        })}
      </div>
      <div className={s.mapBreadcrumbLeft}>
        {listModule.listModule && listModule.listModule.filter(module => module.moduleName !== 'Indirect Emission - Purchased Energy').slice(-3).map((module, index) => {
          const total = { value: 0 };

          function calculateTotal(num: number | string){
            const number = typeof num === 'string' ? parseFloat(num) : num;
            total.value += number;
            return total.value;
          }

          return (
            <div className={s.equipmentItemContainer} key={index}>
              <div className={s.listEquipmentItem}> 
                <table>
                  <h3 style={{height: module.moduleName === "Indirect Emission - Purchased Energy" ? '42px' : 'auto'}}>{module.moduleName}</h3>
                  <td className={s.valueEquipment} style={{fontWeight: 300,fontSize: '12px'}}><span>(tCO<sub>2</sub>e/h)</span></td>
                  {module.listEquipment.sort((a, b) => Number(b.value) - Number(a.value)).map((equipment, eqIndex) => {
                    calculateTotal(equipment.value);
                    return (
                      <tr key={eqIndex}>
                        <td>{equipment.equipmentName}</td>
                        <td className={s.valueEquipment}>
                          {toFixedSustainabilityNumber(equipment.value, 3)}
                        </td>
                      </tr>
                    );
                  })}
                  <tr className={s.totalWord}>Total
                    <td className={s.totalEquipment}>{toFixedSustainabilityNumber(total.value,3)}</td>
                  </tr>
                </table>
              </div>
            </div>
          );
            })}
          </div>
          <PiTagLegend param={params} ghgData={lastUpdated} />
  </>
};

export default GHGListEquipmentRGTPComponent;

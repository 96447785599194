import moment from "moment";

function getChartSubKey(chartData, returnLabel, dataKey) {
  for (let index = 0; index < chartData.length; index++) {
    returnLabel[`key${dataKey}${index}`] = `${chartData[index].key}`;
  }
}

function getChartKey(datas) {
  let returnLabel = {};
  for (let index = 0; index < datas.length; index++) {
    const dataKey = datas[index].key;
    returnLabel[`key${index}`] = dataKey;
    if (datas[index].chartData.length > 0) {
      getChartSubKey(datas[index].chartData, returnLabel, dataKey);
    }
  }
  return returnLabel;
}
function getChartLabel(datas, year) {
  let returnLabel = {};
  for (let index = 0; index < datas.length; index++) {
    returnLabel[`label${index}`] = ``;
  }
  return returnLabel;
}

function getSeriesDataYear(dataSet, nextYear) {
  let returSeriesData = 0;
  dataSet.forEach((item, value) => {
    if (item.year === nextYear) {
      returSeriesData = item.values.p4r || 0;
    }
  });
  return returSeriesData;
}

function getTooltipDataYear(dataSet, nextYear) {
  let returSeriesData = 0;
  dataSet.forEach((item, value) => {
    if (item.year === nextYear) {
      returSeriesData = item.values.p4r;
    }
  });
  return returSeriesData;
}

function getChartSubSeries(
  chartData,
  parentIdx,
  returnLabel,
  nextYear,
  parentKey
) {
  let sumSeries = 0;

  for (let index = 0; index < chartData.length; index++) {
    const dataSet = chartData[index].dataSet;
    const dataSetKey = chartData[index].key;
    const seriesData = getSeriesDataYear(dataSet, nextYear);
    const tooltipData = getTooltipDataYear(dataSet, nextYear);
    sumSeries += seriesData;
    returnLabel[`series${parentKey}${dataSetKey}`] = seriesData;
    returnLabel[`tooltip${parentKey}${dataSetKey}`] = tooltipData;
  }
  return sumSeries;
}
function getChartSeries(datas, nextYear) {
  let returnSeries = {};
  for (let index = 0; index < datas.length; index++) {
    const chartData = datas[index].chartData;
    const parentKey = datas[index].key;
    if (chartData.length > 0) {
      getChartSubSeries(chartData, index, returnSeries, nextYear, parentKey);
    }
  }
  return returnSeries;
}

function getChartUnit(datas) {
  let returnUnit = {};
  for (let index = 0; index < datas.length; index++) {
    returnUnit[`unit${index}`] = datas[index].currency;
  }
  return returnUnit;
}

function getChartSubUnit(datas) {
  let returnSubUnit = {};
  for (let index = 0; index < datas.length; index++) {
    returnSubUnit[`subUnit${index}`] = datas[index].unit;
  }
  return returnSubUnit;
}
function isZero(index, chartDataIdx) {
  return (
    (index === 0 && chartDataIdx === 0) || (index === 0 && chartDataIdx === 3) || (index === 1 && chartDataIdx === 3)
  );
}

function getChartShowTooltip(datas, chartDataIdx) {
  let returnSeries = {};
  for (let index = 0; index < datas.length; index++) {
    returnSeries[`showTooltip${index}`] = isZero(index, chartDataIdx)
      ? "always"
      : "hover";
  }
  return returnSeries;
}

function populateData(datas, chartDataLength, year) {
  let returnChartData: any = [];
  const chartKey = getChartKey(datas);
  const chartUnit = getChartUnit(datas);
  const chartSubUnit = getChartSubUnit(datas);

  let currentYear = moment().year(year).subtract(1, "years");
  let prevYear = year - 1;
  for (let chartDataIdx = 0; chartDataIdx < chartDataLength; chartDataIdx++) {
    let nextYear = ++prevYear;
    const chartYear = currentYear.add(1, "years");
    const chartlabels = getChartLabel(datas, chartYear.format("yyyy"));
    const chartSeries = getChartSeries(datas, nextYear);
    const chartShowTooltip = getChartShowTooltip(datas, chartDataIdx);
    returnChartData.push({
      date: chartYear.toDate(),
      ...chartKey,
      ...chartlabels,
      ...chartSeries,
      ...chartUnit,
      ...chartSubUnit,
      ...chartShowTooltip,
    });
  }
  return returnChartData;
}

function transformInitData(datas, year) {
  const chardDataLength = 5;
  return populateData(datas, chardDataLength, year);
}

export function transformMftWaveChartData(results, year) {
  return transformInitData(results, year);
}

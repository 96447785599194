import { Menu, Radio } from "antd";
import { get, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateLevelAndKeyScoreCard } from "../../../action/actionScoreCard";
import { TAB_SCORE_CARD } from "../../../constants/score-card.constants";
import { formatScoreCardValue, formatScoreCardValueAbsolute, toFixedNumber } from "../../../utils/Number.utils";
import s from "../scorecards.module.css";
import BigCardSubInforComponent from "./BigCardSubInforComponent";

const BigCardInforComponent = (props: any) => {
  const card = props.data;
  const [cardValue, setCardValue] = useState(card?.values?.[0] || card);
  const [selectedKeys, setSelectedKeys] = useState(card?.units?.[0] || "");
  useEffect(() => {
    setCardValue(props.data?.values?.[0] || props.data);
  }, [props.data]);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const levelScoreCard = useSelector((state) =>
    get(state, "scoreCard.levelScoreCard", 1)
  );
  const sectionIdActive = useSelector(
    (state: any) => state.scoreCard.sectionIdActive
  );
  const selectedPersona = useSelector((state: any) => state.user.selectedPersona)
  // Expand Card
  function handleExpandCard() {
    if (
      cardValue?.projectionValues?.length === 0 || cardValue?.description === ""
    ) {
      return;
    }
    setIsExpanded(!isExpanded);
  }

  // Check card has sub value
  function isDisableExpand() {
    return (
      cardValue?.projectionValues?.length === 0 || cardValue?.description === ""
    );
  }

  function handleClickBigCard() {
    if (
      levelScoreCard === 1 &&
      sectionIdActive !== TAB_SCORE_CARD.HSSE_PERFORMANCE
    ) {
      const payload = {
        level: 2,
        key: card.key,
        tab: sectionIdActive,
        units: card?.units.length > 1 ? card?.units : [],
        unitSelected: card?.units.length > 1 ? selectedKeys : "",
      };
      dispatch(updateLevelAndKeyScoreCard(payload));
    }
  }

  function _renderDeltaValue(value: number) {
    const formatValue = toFixedNumber(value < 0 ? Math.abs(value) : value, 2);
    return formatValue;
  }

  const handleChangeUnit: any = (unit) => {
    const value = props.data.values.filter((v) => v.unit === unit);
    setCardValue(value[0]);
    setSelectedKeys(unit);
  };

  const _renderMenu = () => {
    return (
      <Menu selectedKeys={selectedKeys}>
        {card.units.map((v) => (
          <Menu.Item key={v} onClick={() => handleChangeUnit(v)}>
            {v}
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  function _renderRadioGroup() {
    return (
      <Radio.Group size="small" className={s.radioGroup} value={selectedKeys} onChange={(e) => handleChangeUnit(e.target.value)}>
        {card.units.map((v) => (
          <Radio.Button className={`${s.radioButton} ${cardValue?.color ? s[cardValue?.color] : ""} ${selectedKeys === v ? s["checked"] : ""}`} key={v} value={v}>
            {v}
          </Radio.Button>
        ))}
      </Radio.Group>
    );
  }

  function _renderScoreCardsItemValue() {
    return (
      <div className={s.scoreCardsItemValue}>
        {displayActualValue() ? cardValue?.actualValue?.value : formatScoreCardValue(
          cardValue?.actualValue?.value,
          cardValue?.actualValue?.currency,
          isCardUnitIndex
        )}
        {cardValue?.actualValue?.currency && (
          <b>
            <span style={{ opacity: 1 }}>
              {cardValue?.actualValue?.currency}
            </span>
          </b>
        )}
        {cardValue?.actualValue?.unit && (
          <span>{cardValue?.actualValue?.unit}</span>
        )}
      </div>
    )
  }

  function _renderScoreCardsItemPlan() {
    return (cardValue?.secondaryValues && cardValue?.secondaryValues?.length > 0) && (
      <div className={s.scoreCardsItemPlanTarget}>
        {cardValue?.secondaryValues?.map((v, idx) => {
          return (
            <>
              <span>
                {v.type}
              </span>
              :&nbsp;
              <span>
                {isNull(v.value) ? "-" : v.value}&nbsp;{v.unit}
              </span>
              {idx === 0 && <>&nbsp;&nbsp;<span style={{ opacity: "0.3" }}>|</span>&nbsp;</>}
            </>
          )
        })}
      </div>
    )
  }

  function _renderCardSecondaryValue() {
    return cardValue?.secondaryValue && (
      <div className={s.scoreCardsItemPlanTarget}>
        <div className={s.scoreCardItemValue}>
          {cardValue?.secondaryValue?.type}:{" "}
          {/* {cardValue?.secondaryValue?.currency
            ? cardValue?.secondaryValue?.currency
            : ""} */}
          {cardValue?.secondaryValue?.value && (
            <b>
              &nbsp;
              {displayActualValue() ? cardValue?.secondaryValue?.value : formatScoreCardValueAbsolute(
                cardValue?.secondaryValue?.value,
                null,
                1
              )}
            </b>
          )}
          {/* {cardValue?.secondaryValue?.unit && (
            <span>&nbsp;{cardValue?.secondaryValue?.unit}</span>
          )} */}
        </div>

        <div className={s.scoreCardItemDeltaValue}>
          {
            <b>
              {isNull(cardValue?.deltaValue?.value)
                ? "-"
                : _renderDeltaValue(cardValue?.deltaValue?.value)}
            </b>
          }
          {cardValue?.deltaValue?.unit && (
            <span>&nbsp;{cardValue?.deltaValue?.unit}</span>
          )}
          {cardValue?.deltaValue?.arrowDirection && (
            <i
              className={`mdi ${cardValue?.deltaValue?.arrowDirection === "up"
                ? "mdi-menu-up"
                : "mdi-menu-down"
                }`}
              style={{ fontSize: 24 }}
            ></i>
          )}
        </div>
      </div>
    )
  }

  function _renderCardSPYLValue() {
    if((sectionIdActive !== TAB_SCORE_CARD.HSSE_PERFORMANCE && isExpanded) || sectionIdActive === TAB_SCORE_CARD.HSSE_PERFORMANCE ) {
      return cardValue?.splyValue && (
        <div className={s.scoreCardsItemPlanTarget}>
          <div className={s.scoreCardItemValue}>

            {cardValue?.splyValue?.type}:{" "}
            {/* {cardValue?.splyValue?.currency
              ? cardValue?.splyValue?.currency
              : ""} */}
            {cardValue?.splyValue?.value && (
              <b>
                &nbsp;
                {displayActualValue() ? cardValue?.splyValue?.value : formatScoreCardValueAbsolute(
                  cardValue?.splyValue?.value,
                  null,
                  1
                )}
              </b>
            )}
            {/* {cardValue?.splyValue?.unit && (
              <span>&nbsp;{cardValue?.splyValue?.unit}</span>
            )} */}
          </div>

          <div className={s.scoreCardItemDeltaValue}>

            {
              <b>
                {isNull(cardValue?.splyDeltaValue?.value)
                  ? "-"
                  : _renderDeltaValue(cardValue?.splyDeltaValue?.value)}
              </b>
            }
            {cardValue?.splyDeltaValue?.unit && (
              <span>&nbsp;{cardValue?.splyDeltaValue?.unit}</span>
            )}
            {cardValue?.splyDeltaValue?.arrowDirection && (
              <i
                className={`mdi ${cardValue?.splyDeltaValue?.arrowDirection === "up"
                  ? "mdi-menu-up"
                  : "mdi-menu-down"
                  }`}
                style={{ fontSize: 24 }}
              ></i>
            )}
          </div>
        </div>
      )
    }  
  }

  // Check if PLC UMC, PFLNG1 UMC and PFLNG2 UMC on LNGA financial excellence display actual value otherwise display fix number
  const displayActualValue = () => {
    return selectedPersona === "LNGA" && (card?.name === "PLC UMC" || card?.name === "PFLNG1 UMC" || card?.name === "PFLNG2 UMC")
  }
  const isCardUnitIndex = cardValue?.actualValue?.unit === "index" ? 2 : 1
  const isClickBigcardClass = levelScoreCard === 1 && sectionIdActive !== TAB_SCORE_CARD.HSSE_PERFORMANCE ? s.canClickBigCard : "";
  return (
    <div
      className={`${s.scoreCardsItem} ${cardValue?.color ? s[cardValue?.color] : s.DEFAULTCOLOR} ${isExpanded ? s.expanded : ""} ${isDisableExpand() ? s.disabledExpand : ""}`} >
      {/* Card Identified */}
      <div className={s.scoreCardItemTopLeft}>
        {
          // ICON_CARD.ICON_IDENTIFIED
          cardValue?.identified && (
            <img
              className={s.scoreCardsItemIdentified}
              src={cardValue?.identified ? cardValue?.identified : ``}
              alt="Identified"
            />
          )
        }
        {card?.units && card?.units?.length > 1 && _renderRadioGroup()}
      </div>
      {/* Card Body */}
      <div
        className={`${s.scoreCardsItemBody} ${isClickBigcardClass}`}
        onClick={() => handleClickBigCard()}
      >
        <div className={s.scoreCardsItemIcon}>
          <img
            src={`data:image/svg+xml;charset=utf-8;base64,${card?.icon}`}
            alt={card?.name}
          />
        </div>
        <div className={s.scoreCardsItemInfo}>
          <div className={s.scoreCardsItemTitle}>{card?.name}</div>
          {_renderScoreCardsItemValue()}
        </div>
        {_renderCardSecondaryValue()}
        {_renderCardSPYLValue()}
        {_renderScoreCardsItemPlan()}
      </div>

      {/* Card More */}
      <BigCardSubInforComponent data={cardValue} tabActive={sectionIdActive} />

      {/* Card Footer */}
      <div
        className={s.scoreCardsItemFooter}
        onClick={() => handleExpandCard()}
      >
        <span>
          <i className="mdi mdi-menu-down"></i>
        </span>
      </div>
    </div>
  );
};

export default BigCardInforComponent;

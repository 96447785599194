import { get } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchScoreCardMlngPlantExecSummary, openScorecardSecondaryCardTabModal } from "../../../../../action/actionScoreCard";
import { MONTH_SHORT_TEXT, LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS } from "../../../../../constants/score-card.constants";
import ScorecardExecutiveSummary from "../ExecutiveSummary/ScorecardExecutiveSummary";
import s from '../../SecondaryCard.module.css';

export default function BaseMlngPlantPerformanceSecondary() {
    const dispatch = useDispatch();

    const { isVisibleModal, currentDate, selectedScorecard } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            isVisibleModal: scorecard.isOpenScorecardSecondaryCardModel,
            currentDate: scorecard.currentDate,
            selectedScorecard: scorecard.selectedScorecard
        };
    });

    const plantPerformanceData = useSelector((state) =>
        get(state, 'scoreCard.plantPerformanceData', [])
    );
    const sectionName = plantPerformanceData.sectionName
    
    function requestExecSummary() {
        const params = `?year=${currentDate.year}&month=${MONTH_SHORT_TEXT[currentDate.month - 1]}&key=${selectedScorecard?.key}`;
        dispatch(fetchScoreCardMlngPlantExecSummary(params));
    }

    const scorecardTypeId = selectedScorecard ? selectedScorecard.scorecardTypeId : 0

    useEffect(() => {
        if (isVisibleModal && selectedScorecard) {
            switch (scorecardTypeId) {
                // case LMT_HSSE_SCORECARD_TYPE.GROUP_FATALITY:
                //     requestDetail(scorecardTypeId);
                //     dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.GROUP_FATALITY));
                //     break;
                // case LMT_HSSE_SCORECARD_TYPE.MHSEV:
                //     requestDetail(scorecardTypeId);
                //     dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.MHSEV));
                //     break;
                // case LMT_HSSE_SCORECARD_TYPE.SUSTAINABILITY:
                //     requestDetail(scorecardTypeId);
                //     dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.SUSTAINABILITY));
                //     break;
                default:
                    requestExecSummary();
                    dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.EXECUTIVE_SUMMARY));
                    break;
            }
        }
    }, [selectedScorecard]);


    function renderTab(i) {
        if (i === LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.EXECUTIVE_SUMMARY) {
            return <ScorecardExecutiveSummary isDetailBreakdown={true} sectionName={sectionName}/>
        }
    }

    return (
        <div className={s.modalBody}>
            {renderTab(0)}
        </div>
    )
}
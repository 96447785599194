import { Desktop, Mobile, Tablet } from '../../responsive/responesive';

import BigCardInforComponent from './BigCard/BigCardInforComponent';
import { HSSE_PERFORMANCE_LABEL } from '../../constants/score-card.constants';
import React from 'react';
import { get } from 'lodash';
import s from './scorecards.module.css';
import sMobile from './scorecardsResponsiveMobile.module.css';
import { useSelector } from 'react-redux';

const HSSEPerformanceComponent = (props: any) => {
    const hssePerformanceData = useSelector(state => get(state, 'scoreCard.hssePeromanceData', []))
    function ScoreCardColComponent() {
        return <> <div className={s.scoreCardsCol}>
            {
                hssePerformanceData?.parameters?.map((card, idx) => {
                    return idx % 3 === 0 ? <BigCardInforComponent key={idx} data={card} tabActive={HSSE_PERFORMANCE_LABEL} /> : ''
                })
            }
        </div>
            <div className={s.scoreCardsCol}>
                {
                    hssePerformanceData?.parameters?.map((card, idx) => {
                        return idx % 3 === 1 ? <BigCardInforComponent key={idx} data={card} tabActive={HSSE_PERFORMANCE_LABEL} /> : ''
                    })
                }
            </div>
            <div className={s.scoreCardsCol}>
                {
                    hssePerformanceData?.parameters?.map((card, idx) => {
                        return idx % 3 === 2 ? <BigCardInforComponent key={idx} data={card} tabActive={HSSE_PERFORMANCE_LABEL} /> : ''
                    })
                }
            </div></>
    }
    return (
        <>
            <Desktop>
                <div className={s.scoreCardsItems}>
                    {ScoreCardColComponent()}
                </div>
            </Desktop>
            <Mobile>
                <div className={sMobile.scoreCardsItems}>
                    {ScoreCardColComponent()}
                </div>
            </Mobile>
            <Tablet>
                <div className={sMobile.scoreCardsItems}>
                    {ScoreCardColComponent()}
                </div>
            </Tablet>
        </>
    )
}
export default HSSEPerformanceComponent;

import { Popover } from "antd";
import React from "react";

import s from "./PopoverComponent.module.css";

interface Props {
  value?: string;
  arr: IArr[];
  selectFunc?: any;
}

interface IArr {
  text: any;
  key: any;
}

const PopoverComponent = ({ value, arr, selectFunc }: Props) => {
  function setFunc(index) {
    selectFunc(index);
  }
  function _renderContent() {
    return (
      <div className={s.popoverContent}>
        {arr.map((item, index) => (
          <span onClick={() => setFunc(index)} key={index}>
            {item.text}
          </span>
        ))}
      </div>
    );
  }
  return (
    <Popover
      overlayClassName="tbodyDropdown"
      placement="bottom"
      trigger="click"
      content={_renderContent()}
    >
      <div className={s.tbodyText}>
        <span>{value}</span>
        <img className={s.arrow} src="/img/icon/DownArrow.svg" alt="arrow" />
      </div>
    </Popover>
  );
};

export default PopoverComponent;

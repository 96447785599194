export const ASCENDING = "asc";
export const DESCENDING = "desc";
export const EVEN = "even";
export const VIEWSCONFIGID = {
  SCORECARDS: 1,
  VALUECHAIN: 2,
  MFT: 3
};

export const MODALTYPE = {
  CREATE: 'create',
  EDIT: 'edit',
};

export const MODALDELHEADERFIRST = 'Are you sure you want to'
export const MODALDELHEADERSECOND = 'delete user'
export const MODALDELSUBTXT = 'You can\'t undo this action.'

export const INDICATOR = {
  ACTIVE: 'GREEN',
  NOTLOGINUSER: 'GREEN_HOLLOW',
  INACTIVE: 'GREY'
}
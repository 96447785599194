import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux';
import * as types from '../action/actionType';
import {
  getContentReleaseAnnouncement,
  getRCTable,
  togglePublishStatus,
  deleteRecord,
  createRecord,
  updateRecord,
} from '../services/content-release.service';

interface ContentReleaseAction extends Action {
  payload: string | any;
  data: any;
}

function* fetchCRData() {
  try {
    const response = yield call(getContentReleaseAnnouncement);
    yield put({
      type: types.CONTENT_RELEASE_ANNOUNCEMENT_SUCCEEDED,
      payload: response.result,
    });
    if (response?.result?.slides?.length > 0) {
      yield put({
        type: types.OPEN_MODAL_CONTENT_RELEASE,
      });
    }
  } catch (error: any) {
    yield put({
      type: types.CONTENT_RELEASE_ANNOUNCEMENT_FAILED,
      message: error.message,
    });
  }
}

function* fetchRCTableData(action: ContentReleaseAction) {
  try {
    const response = yield call(getRCTable, action.payload);
    yield put({
      type: types.RC_ANNOUNCEMENT_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.RC_ANNOUNCEMENT_FAILED,
      message: error.message,
    });
  }
}

function* togglePublish(action: ContentReleaseAction) {
  try {
    yield put({
      type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
      payload: true,
    });
    yield call(togglePublishStatus, action.payload);
    yield put({
      type: types.RC_ANNOUNCEMENT_REQUESTED,
      payload: { pageSize: 10000, pageNumber: 1 },
    });
  } catch (error: any) {
    yield put({
      type: types.RC_ANNOUNCEMENT_ACTION_FAILED,
      payload: error.message,
    });
    yield put({
      type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
      payload: false,
    });
  }
}

function* deleteAnnouncement(action: ContentReleaseAction) {
  try {
    yield put({
      type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
      payload: true,
    });
    yield call(deleteRecord, action.payload);
    yield put({
      type: types.RC_ANNOUNCEMENT_REQUESTED,
      payload: { pageSize: 10000, pageNumber: 1 },
    });
  } catch (error: any) {
    yield put({
      type: types.RC_ANNOUNCEMENT_ACTION_FAILED,
      payload: error.message,
    });
    yield put({
      type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
      payload: false,
    });
  }
}

function* createAnnouncement(action: ContentReleaseAction) {
  try {
    yield put({
      type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
      payload: true,
    });
    yield call(createRecord, action.payload);
    yield put({
      type: types.RC_ANNOUNCEMENT_REQUESTED,
      payload: { pageSize: 10000, pageNumber: 1 },
    });
  } catch (error: any) {
    yield put({
      type: types.RC_ANNOUNCEMENT_ACTION_FAILED,
      payload: error?.response?.data?.message || error.message,
    });
    yield put({
      type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
      payload: false,
    });
  }
}

function* updateAnnouncement(action: ContentReleaseAction) {
  try {
    yield put({
      type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
      payload: true,
    });
    yield call(updateRecord, action.payload);
    yield put({
      type: types.RC_ANNOUNCEMENT_REQUESTED,
      payload: { pageSize: 10000, pageNumber: 1 },
    });
  } catch (error: any) {
    yield put({
      type: types.RC_ANNOUNCEMENT_ACTION_FAILED,
      payload: error?.response?.data?.message || error.message,
    });
    yield put({
      type: types.RC_ANNOUNCEMENT_SET_FORM_LOADING,
      payload: false,
    });
  }
}

export function* workerContentReleaseData() {
  yield all([
    takeLatest(types.CONTENT_RELEASE_ANNOUNCEMENT_REQUESTED, fetchCRData),
    takeLatest(types.RC_ANNOUNCEMENT_REQUESTED, fetchRCTableData),
    takeLatest(types.RC_ANNOUNCEMENT_PUBLISH_STATUS_TOGGLE, togglePublish),
    takeLatest(types.RC_ANNOUNCEMENT_DELETE, deleteAnnouncement),
    takeLatest(types.RC_ANNOUNCEMENT_CREATE, createAnnouncement),
    takeLatest(types.RC_ANNOUNCEMENT_UPDATE, updateAnnouncement),
  ]);
}

import "react-responsive-carousel/lib/styles/carousel.min.css";

import { chunk, cloneDeep } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";

import { setKeyListMiniCardDropdownLv2, updateLevelAndKeyScoreCard } from "../../../action/actionScoreCard";
import { Desktop, Mobile, Tablet } from "../../../responsive/responesive";
import { toFixedNumber } from "../../../utils/Number.utils";
import sMobile from "../../ScoreCards/scorecardsResponsiveMobile.module.css";
import s from "../scorecards.module.css";

interface IsExpanded {
  key: string,
  value: boolean
}

const MiniCardInforComponent = (props: any) => {
  const card = props.data;
  const fixedWidthPriceForex = card?.categoryName === "Price & Forex" ? true : false
  const [isExpandedArray, setIsExpandedArray] = useState([] as Array<IsExpanded>);
  const dispatch = useDispatch();
  const sectionIdActive = useSelector((state: any) => state.scoreCard.sectionIdActive)

  const infoRows = chunk(card?.parameters, card?.categoryMaxSize)

  const getIndexFromKey = (key) => {
    return isExpandedArray.findIndex(v => v.key === key);
  }

  const getValueFromKey = (key) => {
    const data = isExpandedArray.filter(v => v.key === key);
    return data.length > 0 ? data[0].value : false;
  }

  const handleExpandMiniCard = (key) => {
    const isExpandedArrayClone = cloneDeep(isExpandedArray);
    const index = getIndexFromKey(key);
    if (index > -1) {
      isExpandedArrayClone[index].value = !isExpandedArrayClone[index].value
    } else {
      isExpandedArrayClone.push({ key, value: true });
    }
    setIsExpandedArray(isExpandedArrayClone);
  }

  function handleOnClickMiniCard(key) {
    const payload = {
      level: 2,
      key: key,
      tab: sectionIdActive
    }
    dispatch(updateLevelAndKeyScoreCard(payload));
  }

  const handleOnclickMiniCardMobile = (key: any) => {
    const payload = {
      level: 2,
      key: key
    }
    dispatch(setKeyListMiniCardDropdownLv2(payload))
  }

  function miniCardInfoClass(col) {
    return `${s.miniCardItemInfo} ${getValueFromKey(col?.key) ? s.expandedMiniCard : ''}`;
  }

  function _renderMobileMiniCardWrap(row, idx) {
    return <div className={sMobile.miniCardsWrap} key={idx} style={{ textAlign: 'left' }}>
      {row.map((col: any, indexx) =>
        <div>
          <div key={indexx} className={miniCardInfoClass(col)} onClick={() => { handleOnClickMiniCard(col?.key); handleOnclickMiniCardMobile(card?.categoryKey) }}>
            <div className={s.miniCardItemTitle}>{col?.name}</div>
            <div className={s.miniCardItemUnit}>{col?.actualValue?.unit}</div>
            <div className={s.miniCardItemValue + " " + s['miniCardItemValueColor' + col?.color]} >{col?.actualValue?.value?.toFixed(2)}</div>
            <div className={getValueFromKey(col?.key) ? s.miniCardItemMoreExpanded : s.miniCardItemMore}>{col?.secondaryValue?.type}: {toFixedNumber(col?.secondaryValue?.value, 2)}</div>
          </div>
          <div className={getValueFromKey(col?.key) ? s.miniCardItemFooterArrowUp : s.miniCardItemFooter} onClick={() => handleExpandMiniCard(col?.key)}><span><i className="mdi mdi-menu-down"></i></span></div>
        </div>
      )}
    </div>
  }

  const MiniCardInfo = infoRows.map((row, idx) => (
    <>
      <Desktop>
        <div className={s.miniCardsWrap} key={idx} style={{ textAlign: 'left' }}>
          {row.map((col: any, indexx) =>
            <div key={indexx}>
              <div className={miniCardInfoClass(col)} onClick={() => handleOnClickMiniCard(col?.key)}>
                <div className={s.miniCardItemTitle}>{col?.name}</div>
                <div className={s.miniCardItemUnit}>{col?.actualValue?.unit}</div>
                <div className={s.miniCardItemValue + " " + s['miniCardItemValueColor' + col?.color]} >{col?.actualValue?.value?.toFixed(2)}</div>
                <div className={getValueFromKey(col?.key) ? s.miniCardItemMoreExpanded : s.miniCardItemMore}>{col?.secondaryValue?.type}: {toFixedNumber(col?.secondaryValue?.value, 2)}</div>
              </div>
              <div className={getValueFromKey(col?.key) ? s.miniCardItemFooterArrowUp : s.miniCardItemFooter} onClick={() => handleExpandMiniCard(col?.key)}><span><i className="mdi mdi-menu-down"></i></span></div>
            </div>
          )}
        </div>
      </Desktop>
      <Mobile>
        {_renderMobileMiniCardWrap(row, idx)}
      </Mobile>
      <Tablet>
        {_renderMobileMiniCardWrap(row, idx)}
      </Tablet>
    </>
  )
  );

  const _renderMobileTablet = () => (
    <div className={`${sMobile.miniCardItem} ${card?.parameters?.length / card?.categoryMaxSize <= 1 ? 'hide-pagination' : ''}`} style={fixedWidthPriceForex ? { "width": "700px" } : undefined}>
      {/* Card Header */}
      <div className={sMobile.miniCardItemHeader}>
        {card?.categoryName}
      </div>
      {/* Card Body */}
      <div className={sMobile.miniCardItemBody}>
        {/* Slider */}
        {
          card?.parameters && card?.parameters?.length > 0 && <Carousel className="subvalue-slide minicard-slide" showArrows={false} showStatus={false} showIndicators={true} showThumbs={false} autoPlay={false} interval={900000}>
            {
              MiniCardInfo
            }
          </Carousel>
        }
      </div>
    </div>
  )

  return (
    <>
      <Desktop>
        <div className={`${s.miniCardItem} ${card?.parameters?.length / card?.categoryMaxSize <= 1 ? 'hide-pagination' : ''}`} style={fixedWidthPriceForex ? { "width": "700px" } : undefined}>
          {/* Card Header */}
          <div className={s.miniCardItemHeader}>
            {card?.categoryName}
          </div>
          {/* Card Body */}
          <div className={s.miniCardItemBody}>
            {/* Slider */}
            {
              card?.parameters && card?.parameters?.length > 0 && <Carousel className="subvalue-slide minicard-slide" showArrows={false} showStatus={false} showIndicators={true} showThumbs={false}>
                {
                  MiniCardInfo
                }
              </Carousel>
            }
          </div>
        </div>
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>
    </>
  )
}

export default MiniCardInforComponent;
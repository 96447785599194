import * as types from "./actionType";

export const fetchMftExecSummary = (data) => ({
  type: types.MFT_EXECSUMMARY_REQUESTED,
  data: data,
});

export const fetchMftCommon = (data) => ({
  type: types.MFT_COMMON_REQUESTED,
  data: data,
});

export const fetchMftCardSection = (data) => ({
  type: types.MFT_CARD_SECTION_REQUESTED,
  data: data,
});

export const fetchMftPeriodList = () => ({
  type: types.MFT_PERIODLIST_REQUESTED,
});

export const setMftCurrentDate = (data) => ({
  type: types.SET_MFT_CURRENTDATE,
  data: data,
});

export const setMftCurrentSection = (data) => ({
  type: types.SET_MFT_CURRENTSECTION,
  data: data,
});

export const setMftCurrentSubSection = (data: any) => ({
  type: types.SET_MFT_CURRENTSUBSECTION,
  data: data,
});

export const setMftCurrentTabKey = (data: any) => ({
  type: types.SET_MFT_CURRENTTABKEY,
  data: data,
});

export const updateDynamicLineChart = (data: any) => ({
  type: types.MFT_UPDATE_LINE_CHART_REQUESTED,
  data: data,
});

export const resetDynamicTabs = () => ({
  type: types.MFT_RESET_DYNAMIC_TABS,
});

export const selectPrimaryCard = (data:any) => ({
  type: types.MFT_SELECT_PRIMARY_CARD,
  data: data,
});

export const setUnitIndex = (data:any) => ({
  type: types.MFT_SET_UNIT_INDEX,
  data: data,
});

export const clearOnUnmount = () => ({
  type: types.MFT_CLEAR_ON_UNMOUNT,
});

import s from "./GHGListEquipmentComponent.module.css";
import { useDispatch, useSelector } from "react-redux";
import { get } from "lodash";
import { useEffect } from "react";
import { fetchSustainabilityDrilldownPFLNG, fetchGhgData } from "../../../action/actionSustainability";
import { toFixedSustainabilityNumber } from "../../../utils/Number.utils";
import { Tooltip } from "antd";
import queryString from "query-string";
import PiTagLegend from "../GHGTimestamp/GHGTimestampComponent";

const GHGListEquipmentDUAComponent = (props) => {
  const {  location } = props;
  const params = queryString.parse(window.location.search);

  const dispatch = useDispatch()

  const listModule = useSelector((state) =>
    get(state, 'sustainability.listModule.result', {})
  );

  const lastUpdated = useSelector((state) =>
    get(state, 'sustainability.listModule', {})
  );

  useEffect(() => {
    const params = `?location=${location}`
    dispatch(fetchSustainabilityDrilldownPFLNG(params))
  },[])  

  function handleQuickJump(locationId) {
    dispatch(fetchGhgData(locationId));
  }

  function toggleJump(){
    return (
      <div className={s.jumpPFLNGContainer} onClick={() => handleQuickJump(6)}>
        <div className={s.jumpPFLNG}>
          <span style={{paddingBottom: '0px', transform: 'rotate(180deg)'}}><img alt='left-arrow' src='/img/icon/forward.svg'/></span>
          <div style={{marginLeft: '5px'}}>PFLNG SATU</div>
        </div>
      </div>
    );
  }
  
    return <>
    {toggleJump()}
    {listModule?.listModule && (
    <div className={s.mapBreadcrumb}>
    {listModule.listModule.map((module, moduleIndex) => {
      const total = { value: 0 };
  
      function calculateTotal(num: number | string){
        const number = typeof num === 'string' ? parseFloat(num) : num;
        total.value += number;
        return total.value;
      }
  
      return (
        <div key={moduleIndex} className={s.equipmentItemContainer}>
          <div className={s.listEquipmentContainer}>
            <div className={s.listEquipmentItem}>
              <table>
                <h3>{`${module.moduleName}`}</h3>
                <td className={s.valueEquipment} style={{fontWeight: 300,fontSize: '12px'}}><span>(tCO<sub>2</sub>e/h)</span></td>
                {module.listEquipment
                  .sort((a, b) => Number(b.value) - Number(a.value))
                  .map((equipment, equipmentIndex) => {
                    calculateTotal(equipment.value);
                    return (
                      <tr key={equipmentIndex}>
                        <td>{equipment.equipmentName}</td>
                        <td className={s.valueEquipment}>{toFixedSustainabilityNumber(equipment.value,3)}</td>
                      </tr>
                    );
                  })
                }
                <tr className={s.pflngTotalWord}>
                  <td>Total</td>
                  <td className={s.totalEquipment}>{toFixedSustainabilityNumber(total.value,3)}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      );
    })}
  </div>
  )}
  <PiTagLegend param={params} ghgData={lastUpdated} />
  </>
};

export default GHGListEquipmentDUAComponent;

import moment from "moment";

function populateInitData() {
    let chartData: { date: any, label1: string, label2: string, label3: string, label4: string, series1: number, series2: number, series3: number, series4: number, unit1: string, subUnit1: string, unit2: string, subUnit2: string, unit3: string, subUnit3: string, unit4: string, subUnit4: string }[] = [];
    for (let idx = 0; idx < 12; idx++) {
        chartData.push({ date: null, label1: '', label2: '', label3: '', label4: '', series1: 0, series2: 0, series3: 0, series4: 0, unit1: '', subUnit1: '', unit2: '', subUnit2: '', unit3: '', subUnit3: '', unit4: '', subUnit4: '' })
    }
    return chartData;
}

function getCurrentMonthSeries(chartData, date: Date) {
    let returnSeries = 0
    chartData.forEach(item => {
        const currentMonth = item.month.includes('-') ? item.month.split('-')[0] : item.month
        if (currentMonth === moment(date).format("MMM")) {
            returnSeries = item.value
        }
    });
    return returnSeries
}

function transformInitData(results) {
    let transformData = populateInitData();
    for (let seriesidx = 0; seriesidx < results.charts.length; seriesidx++) {
        const resultData = results.charts[seriesidx];
        let seriesNo = seriesidx + 1
        for (let idx = 0; idx < 12; idx++) {
            let date = new Date();
            date.setMonth(idx);
            transformData[idx][`label${seriesNo}`] = resultData.chartTitle;
            transformData[idx][`unit${seriesNo}`] = results.currency;
            transformData[idx][`subUnit${seriesNo}`] = results.unit;
            transformData[idx].date = date;
            transformData[idx][`series${seriesNo}`] = getCurrentMonthSeries(resultData.chartData, date);
        }
    }

    return transformData;
}

export function transformTrendChartlevel3Data(results) {
    let resultData = transformInitData(results);
    resultData = { ...results, data: resultData }
    return resultData
}
import { Desktop, Mobile, Tablet } from '../../../responsive/responesive'

import React from 'react'
import { TAB_SCORE_CARD } from '../../../constants/score-card.constants'
import s from './ChartComponent.module.css'
import sMobile from './ChartResponsiveMobile.module.css';

interface Props {
  executiveSummary: string
  sectionIdActive: any
}
const SummaryComponent = ({ executiveSummary, sectionIdActive }: Props) => {
  function setSummaryClass(cssModule) {
    const isFinanceTab = sectionIdActive === TAB_SCORE_CARD.FINANCIAL_EXCELLENCE ? cssModule.sumaryChartBackgroundModalWithFinancial : cssModule.sumaryChartBackgroundModalWithProduction;
    const isPlantTab = sectionIdActive === TAB_SCORE_CARD.PLANT_PERFORMANCE ? cssModule.sumaryChartBackgroundModalWithPlant : isFinanceTab;
    return `${isPlantTab}`
  }

  function _renderMobileTablet() {
    return ( 
    <div className={`${setSummaryClass(sMobile)}`}>
    <div className={s.sumaryChartTitle}>
      Executive Summary
    </div>
    <div className={s.summaryChartText}>
      {
        executiveSummary
      }
    </div>
  </div>
  )}
  return (
    <>
      {executiveSummary && (<>
        <Desktop>
          <div className={`${setSummaryClass(s)}`}>
            <div className={s.sumaryChartTitle}>
              Executive Summary
            </div>
            <div className={s.summaryChartText}>
              {
                executiveSummary
              }
            </div>
          </div>
        </Desktop>
        <Mobile>{_renderMobileTablet()}</Mobile>
        <Tablet>{_renderMobileTablet()}</Tablet>
      </>)}</>
  )
}

export default SummaryComponent
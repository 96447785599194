import { get } from "lodash";
import React, { useEffect } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ScrollContainer from "react-indiana-drag-scroll";
import { useDispatch, useSelector } from "react-redux";

import { updateActiveTab } from "../../../action/actionMap";
import { fetchValueChainData } from "../../../action/actionValueChain";
import IntegratedItemComponent from "../../../components/ValueChain/IntegratedItemComponent";
import { URL_SIDE_BAR } from "../../../constants/map.constants";
import { GAS_BUSINESS_VALUE_CHAIN_STATIC_PROCESS } from "../../../constants/value-chain.constants";
import { Desktop, Mobile, Tablet } from "../../../responsive/responesive";
import { formatNumber } from "../../../utils/Number.utils";
import s from "./GasBusinessValueChain.module.css";
import sRes from "./GasBusinessValueChainResponsive.module.css";
import OPUSelectionComponent from "../OPUselectionComponent/OPUselectionComponent";

const GasBusinessValueChainComponent = (props: any) => {
  const dispatch = useDispatch();
  // Define state
  const handle = useFullScreenHandle();

  const valueChainData = useSelector((state) =>
    get(state, "valuechain.valueChainData", {})
  );

  useEffect(() => {
    const oneMinutes = 60000;
    const intervalId = setInterval(() => {
      dispatch(fetchValueChainData());
    }, oneMinutes);
    dispatch(fetchValueChainData());
    dispatch(updateActiveTab(URL_SIDE_BAR.VALUE_CHAIN));
    return () => clearInterval(intervalId)
  }, []);

  const isGasContained = valueChainData?.result?.gas || false;

  const _renderStaticProcess = (config, idx) => {
    return (
      <div key={idx} className={s.progressItem}>
        <div className={s.progressItemIcon}>
          <img src={config?.icon} alt="Static Process" />
        </div>
        <div className={s.progressItemText}>{config?.text}</div>
        {config.text !== "Delivered" && (
          <div className={s.progressItemSeparator}></div>
        )}
      </div>
    );
  };

  const _renderIntegratedGas = (title: string, property: string) => {
    return (
      <>
        <div className={sRes.integrateLNG}>
          <div className={sRes.integrateLNGItem}>
            <h2>{title}</h2>
            {valueChainData?.result?.gas?.value && (
              <IntegratedItemComponent
                hideBody={true}
                data={valueChainData?.result?.gas}
                integratedLNGProcess={GAS_BUSINESS_VALUE_CHAIN_STATIC_PROCESS[0]}
              />
            )}
            {valueChainData?.result?.gas[property] &&
              valueChainData?.result?.gas[property].map(
                (item: any, index: any) => {
                  return (
                    <IntegratedItemComponent
                      key={index}
                      data={item}
                      integratedLNGProcess={
                        GAS_BUSINESS_VALUE_CHAIN_STATIC_PROCESS[index + 1]
                      }
                    />
                  );
                }
              )}
          </div>
        </div>
      </>
    );
  };


  const _renderMobileTablet = () => (
    <ScrollContainer
      className={`page-container bg-map ${sRes.valueChainContainer}`}
    >
      <div className="page-content">
        {
          <>
            {/* Integrate LNG*/}
            <div className={sRes.integrateLNGContainer}>
              <h2>Integrated LNG</h2>
              <div className={sRes.integrateLNG}>
                <div className={sRes.integrateLNGItem}>
                  {valueChainData?.result?.lng &&
                    valueChainData?.result?.lng.map(
                      (item: any, index: any) => {
                        return (
                          <IntegratedItemComponent
                            key={index}
                            data={item}
                            integratedLNGProcess={
                              GAS_BUSINESS_VALUE_CHAIN_STATIC_PROCESS[index]
                            }
                          />
                        );
                      }
                    )}
                </div>
              </div>
            </div>

            {/* Integrate GAS*/}
            {isGasContained &&
              <div className={sRes.integrateGASContainer}>
                <h2>Integrated GAS</h2>
                {_renderIntegratedGas("C1", "c1")}
                {_renderIntegratedGas("C2", "c2")}
                {_renderIntegratedGas("C3", "c3")}
                {_renderIntegratedGas("C4", "c4")}
              </div>
            }

          </>
        }
      </div>
    </ScrollContainer>
  );

  function _renderIntegratedItem(value) {
    return value.map(
      (item: any, index: any) => {
        return (
          <IntegratedItemComponent
            key={index}
            data={item}
            isLastItem={value.length === index + 1}
          />
        );
      }
    )
  }
  return (
    <>
      <Desktop>
        <div className={`fullscreen-pt-0 ${s.valueChainRoot}`}>
          <FullScreen handle={handle}>
            <div className="page-action-right" style={{ top: 136 }}>
              <div
                className={`full-screen ${handle.active ? "full-screen-exit" : ""
                  }`}
                onClick={!handle.active ? handle.enter : handle.exit}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    !handle.active ? handle.enter() : handle.exit();
                  }
                }}
                tabIndex={0}
              >
                <img alt="full_screen" src="/img/icon/full_screen_icon.svg" />
              </div>
            </div>
            <ScrollContainer
              className={`page-container bg-map ${s.valueChainContainer}`}
            >
              <div className="page-content">
                {/* Progress */}
                <div className={s.OPUSelectionContainer}>
                  <OPUSelectionComponent />
                </div>
                <div className={s.progressTop}>
                  {GAS_BUSINESS_VALUE_CHAIN_STATIC_PROCESS.map((config, idx) =>
                    _renderStaticProcess(config, idx)
                  )}
                </div>

                {
                  <>
                    {/* Integrate LNG*/}
                    <div className={s.integrateLNG}>
                      <h2>Integrated LNG</h2>
                      <div className={s.integrateLNGContainer}>
                        {valueChainData?.result?.lng && _renderIntegratedItem(valueChainData?.result?.lng)}
                      </div>
                    </div>

                    {/* Integrate GAS*/}
                    {isGasContained &&
                      <div className={s.integrateGAS}>
                        <h2>Integrated GAS</h2>
                        <div className={s.integrateGASContainer}>
                          <div className={s.integrateGASLeft}>
                            <b
                              className={`text-${valueChainData?.result?.gas?.color}`}
                            >
                              {formatNumber(valueChainData?.result?.gas?.value)}
                            </b>
                            <small>{valueChainData?.result?.gas?.unit}</small>
                          </div>
                          <div className={s.integrateGASLines}>
                            <div className={s.integrateGASLine}>
                              <div className={s.integrateGASTitle}>C1</div>
                              <div className={s.integrateGASItems}>
                                {valueChainData?.result?.gas?.c1 && _renderIntegratedItem(valueChainData?.result?.gas?.c1)}
                              </div>
                            </div>

                            <div className={s.integrateGASLine}>
                              <div className={s.integrateGASTitle}>C2</div>
                              <div className={s.integrateGASItems}>
                                {valueChainData?.result?.gas?.c2 && _renderIntegratedItem(valueChainData?.result?.gas?.c2)}
                              </div>
                            </div>

                            <div className={s.integrateGASLine}>
                              <div className={s.integrateGASTitle}>C3</div>
                              <div className={s.integrateGASItems}>
                                {valueChainData?.result?.gas?.c3 && _renderIntegratedItem(valueChainData?.result?.gas?.c3)}
                              </div>
                            </div>

                            <div className={s.integrateGASLine}>
                              <div className={s.integrateGASTitle}>C4</div>
                              <div className={s.integrateGASItems}>
                                {valueChainData?.result?.gas?.c4 && _renderIntegratedItem(valueChainData?.result?.gas?.c4)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </>
                }
              </div>
            </ScrollContainer>
          </FullScreen>
        </div>
      </Desktop>
      <Mobile>{_renderMobileTablet()}</Mobile>
      <Tablet>{_renderMobileTablet()}</Tablet>
    </>
  );
};
export default GasBusinessValueChainComponent;

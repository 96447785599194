import React, { useEffect, useState } from 'react'
import s from './CountryDetailComponent.module.css'
import { LMT_OPERATIONAL_SALES_UNITS, LMT_OPERATIONAL_SALES_UNITS_ENUMS } from '../../../../../constants/score-card.constants'
import { DatePicker, Space, Row, Col } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { fetchLmtSalesCountryData, fetchScoreCardLmtChartDetail, fetchLmtContractDetail, fetchLmtSaleVolume, fetchScoreCardLmtCountryChartDetail } from '../../../../../action/actionScoreCard';
import LmtSalesStackedChartComponent from '../LmtSalesStackedChartComponent';
import LmtSalesListComponent from '../LmtSalesListComponent';
import LmtSalesTopCustomerComponent from '../LmtSalesTopCustomerComponent';
import { COUNTRY_LIST } from '../../../../../constants/value-chain.constants';

interface IProp {
    selectedCountry: string,
    isVisibleModal: boolean,
    setSelectedCountry?: (country: string) => void,
    handleChangeCustomerTab: (customer: string, country: string) => void,
    fromYearFilterValue: number,
    setFromYearFilterValue: (fromYearFilterValue: number) => void,
    toYearFilterValue: number,
    setToYearFilterValue: (toYearFilterValue: number) => void,
    unitIndex: number,
    setUnitIndex: (unitIndex: number) => void,
    selectedTab: string
}
const CountryDetailComponent = (props: IProp) => {
    const { 
        setSelectedCountry,
        selectedCountry,
        isVisibleModal,
        handleChangeCustomerTab,
        fromYearFilterValue,
        setFromYearFilterValue,
        toYearFilterValue,
        setToYearFilterValue,
        unitIndex,
        setUnitIndex,
        
        selectedTab } = props
    const dispatch = useDispatch();

    const { chartData, countryData, contractData, saleVolumeData } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            isVisibleModal: scorecard.isOpenScorecardLmtChartModal,
            chartData: scorecard.lmtCountryChartData,
            countryData: scorecard.lmtCountryData,
            contractData: scorecard.lmtContractData,
            saleVolumeData: scorecard.saleVolumeData
        };
    });
    //show
    const [showCountryFilter, setShowCountryFilter] = useState(false);
    const [showFromYearFilter, setShowFromYearFilter] = useState(false);
    const [showToYearFilter, setShowToYearFilter] = useState(false);
    const [showUnitSelection, setShowUnitSelection] = useState(false);
    // const [selectedCountry, setSelectedCountry] = useState(countryData[0].countryName);

    useEffect(() => {

        let chartRequest = {
            from: fromYearFilterValue,
            to: toYearFilterValue,
            unit: unitIndex,
            listCountry: [selectedCountry],
            listCustomer: [],
            year: 2022,
            month: 'Feb'
        };

        let contractRequest = {
            country: selectedCountry
        }

        const saleVolumeRequest = {
            from: fromYearFilterValue,
            to: toYearFilterValue,
            unit: unitIndex,
            country: selectedCountry
        }
        const params = `?from=${fromYearFilterValue}&to=${toYearFilterValue}`;
        dispatch(fetchLmtSalesCountryData(params));
        dispatch(fetchScoreCardLmtCountryChartDetail(chartRequest));
        dispatch(fetchLmtContractDetail(contractRequest));
        dispatch(fetchLmtSaleVolume(saleVolumeRequest));
    }, [fromYearFilterValue, toYearFilterValue, unitIndex, selectedCountry]);

    useEffect(() => {
        const params = `?from=${fromYearFilterValue}&to=${toYearFilterValue}`;
        dispatch(fetchLmtSalesCountryData(params));
    }, [])

    useEffect(() => {
        setShowCountryFilter(false)
        setShowFromYearFilter(false)
        setShowToYearFilter(false)
        setShowUnitSelection(false)
    }, [isVisibleModal, selectedTab])

    const getRootContainer = (elementClassName): HTMLElement => {
        const elements = document.getElementsByClassName(elementClassName);
        if (elements.length > 0) {
            return elements[0] as HTMLElement;
        }
        return document.body;
      };

    // Change unit dataset
    const units = LMT_OPERATIONAL_SALES_UNITS;
    let keyIdx = 0;

    function handleChangeUnit(unit: string) {
        let selectedUnitIndex = units.findIndex(v => v == unit);
        setUnitIndex(selectedUnitIndex);
        setShowUnitSelection(false);
    };

    function handleChangeCountry(country: string) {
        setSelectedCountry && setSelectedCountry(country);
        setShowCountryFilter(false);
    };

    function handleFromYearSelect($event) {
        setFromYearFilterValue($event.year());
        setShowFromYearFilter(false);
    }

    function handleToYearSelect($event) {
        setToYearFilterValue($event.year());
        setShowToYearFilter(false);
    }

    function displaySelectedYear(selectedYear: any) {
        if (selectedYear)
            return selectedYear;
        return "?";
    }

    function handleCountrySelection(isShow: boolean) {
        if (isShow == false) {
            // open dropdown
            setShowCountryFilter(!isShow)
        }
        else {
            setSelectedCountry && setSelectedCountry(selectedCountry)
            // Close dropdown
            setShowCountryFilter(!isShow);
        }
    }

    const convertedCountryList = countryData.map((item) => {return item.countryName} )
    
    return (
        <>
            <div className={s.filterBlocks}>
                <div className={s.filterCountryCustomerBlock}>
                    <div className={s.filterBlock}>
                        <span>COUNTRY</span>
                        <div
                            className={`${s.filterBlockDropdown} ${showCountryFilter ? s.show : ''}`}
                        >
                            <button
                                type="button"
                                style={{ width: "155px", justifyContent: 'center' }}
                                onClick={() => handleCountrySelection(showCountryFilter)}
                                onBlur={() => {
                                    setTimeout(() => {
                                        setShowCountryFilter(false)
                                    }, 200);
                                }}
                            >
                                {selectedCountry}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className={s.filterBlockDropdownCountryMenu}>
                                <ul>
                                    {convertedCountryList.map((country, id) => {
                                        return (<li key={id}>
                                            <button
                                                type="button"
                                                onClick={() => handleChangeCountry(country)}
                                                className={selectedCountry?.toUpperCase() === country?.toUpperCase() ? s.selected : ''}
                                            >
                                                {country}
                                            </button>
                                        </li>)
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.filterPeriodUnitBlock}>
                    <div className={s.filterBlock}>
                        <span>FROM</span>
                        <div className={s.datepickerSelector}></div>
                        <div className={`${s.filterBlockDropdown}`}
                            onBlur={() => setShowFromYearFilter(false)}>
                            <button
                                type="button"
                                style={{ width: "110px", justifyContent: 'center' }}
                                onClick={() =>
                                    setShowFromYearFilter(!showFromYearFilter)
                                }
                            >
                                {displaySelectedYear(fromYearFilterValue)}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className="scorecards-year-picker">
                                <Space direction="vertical">
                                    <DatePicker
                                        value={moment(
                                            `${fromYearFilterValue}`,
                                            "YYYY"
                                        )}
                                        onChange={handleFromYearSelect}
                                        open={showFromYearFilter}
                                        picker="year"
                                        getPopupContainer={() => getRootContainer(s.datepickerSelector)}
                                    />
                                </Space>
                            </div>
                        </div>
                    </div>
                    <div className={s.filterBlock}>
                        <span>TO</span>
                        <div className={s.datepickerSelector}></div>
                        <div className={`${s.filterBlockDropdown}`}
                            onBlur={() => setShowToYearFilter(false)}>
                            <button
                                type="button"
                                style={{ width: "110px", justifyContent: 'center' }}
                                onClick={() =>
                                    setShowToYearFilter(!showToYearFilter)
                                }
                            >
                                {displaySelectedYear(toYearFilterValue)}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className="scorecards-year-picker">
                                <Space direction="vertical">
                                    <DatePicker
                                        value={moment(
                                            `${toYearFilterValue}`,
                                            "YYYY"
                                        )}
                                        onChange={handleToYearSelect}
                                        open={showToYearFilter}
                                        picker="year"
                                        getPopupContainer={() => getRootContainer(s.datepickerSelector)}
                                    />
                                </Space>
                            </div>
                        </div>
                    </div>
                    <div className={s.filterBlock}>
                        <span>UNIT</span>
                        <div className={`${s.filterBlockDropdown} ${showUnitSelection ? s.show : ''}`}>
                            <button
                                type="button"
                                style={{ width: "110px", justifyContent: 'center' }}
                                onClick={() =>
                                    setShowUnitSelection(!showUnitSelection)
                                }
                            >
                                {units[unitIndex]}
                                <i className="mdi mdi-menu-down"></i>
                            </button>
                            <div className={s.filterBlockDropdownMenu}>
                                <ul>
                                    <li>
                                        <button
                                            type="button"
                                            onClick={() => handleChangeUnit(LMT_OPERATIONAL_SALES_UNITS_ENUMS.MMT)}
                                            className={units[unitIndex] === LMT_OPERATIONAL_SALES_UNITS_ENUMS.MMT ? s.selected : ''}
                                        >
                                            {LMT_OPERATIONAL_SALES_UNITS_ENUMS.MMT}
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            type="button"
                                            onClick={() => handleChangeUnit(LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE)}
                                            className={units[unitIndex] === LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE ? s.selected : ''}
                                        >
                                            {LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className={s.chartBody}>
                {/* Render Chart components */}
                {chartData && (
                    <Row gutter={[10, 10]}>
                        <Col span={24} key={"chart" + keyIdx++}>
                            <div className={s.saleVolumeContainer}>
                                <div className={`${s.saleVolumeTitle} ${s.font20}`}>Sales Volume
                                    <div className={s.saleVolumeData}>
                                        <div className={s.saleVolumeTotalContainer}>
                                            <div className={s.saleVolumeTotalWord}>Total</div>
                                            <div className={s.saleVolumeTotalNumber}>{saleVolumeData?.saleVolumeTotal}</div>
                                        </div >
                                        <div className={s.saleVolumeGraphContainer}>
                                            <LmtSalesTopCustomerComponent chartData={saleVolumeData?.saleVolumeData} country={selectedCountry} handleChangeCustomerTab={handleChangeCustomerTab} />
                                        </div>
                                    </div >
                                </div >
                            </div >
                        </Col >
                        {/* LmtSalesStackedCustomerChartComponent */}
                        <Col span={8} key={"chart" + keyIdx++} >
                            <LmtSalesStackedChartComponent chartData={chartData.saleByTermSpot} chartName="Sales by Term/Spot" />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesStackedChartComponent chartData={chartData.saleBySeason} chartName="Sales by Season" />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesStackedChartComponent chartData={chartData.saleByIncoterms} chartName="Sales by Incoterms" />
                        </Col>
                        <Col span={8} key={"chart" + keyIdx++}>
                            <LmtSalesListComponent chartData={chartData.saleByPorts} chartName="Sales by Ports" />
                        </Col>
                        <Col span={16} key={"chart" + keyIdx++}>
                            <LmtSalesListComponent chartData={contractData?.results} chartName="Contract Details" />
                        </Col>
                    </Row >
                )}
            </div >

        </>
    )
}

export default CountryDetailComponent
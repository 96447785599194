import React, { useState } from 'react'
import { MONTH_SHORT_TEXT } from '../../constants/score-card.constants';
import s from './DateDropdown.module.css'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DatePicker, Space } from 'antd';

const { MonthPicker } = DatePicker;

interface IDate { month: number, year: number }

interface IProp {
    currentDate: IDate;
    periodsList: { defaultSelection: boolean, month: number, year: number }[];
    disabledDate?: ((date: moment.Moment) => boolean) | undefined;
    onDateChange: (date: IDate) => void
}

const DateDropdown = (prop: IProp) => {
    const [showPeriodFilter, setShowPeriodFilter] = useState(false);
    const { disabledDate, periodsList, onDateChange, currentDate } = prop
    
    function handleDateSelect($event) {
        onDateChange({ month: $event.month() + 1, year: $event.year() })
        setShowPeriodFilter(false);
    }

    const makeText = (m) => {
        if (m && m.year && m.month)
            return MONTH_SHORT_TEXT[m.month - 1] + " " + m.year;
        return "?";
    };

    const checkDisabledPreviousButton = () => {
        if(periodsList.length === 0) return false
        const checkDate = moment(`${currentDate?.month}-${currentDate?.year}`, "MM-YYYY").isSame(
            moment(`${periodsList[0]?.month}-${periodsList[0]?.year}`, "MM-YYYY"))
        return checkDate
    }

    const checkDisabledNextButton = () => {
        const currentMonthYear = moment().format("MM-YYYY");
        if(periodsList.length === 0) return  moment(`${currentDate?.month}-${currentDate?.year}`, "MM-YYYY").isSame(
            moment(`${currentMonthYear}`, "MM-YYYY")
        )
        const checkDate = moment(`${currentDate?.month}-${currentDate?.year}`, "MM-YYYY").isSame(
            moment(`${periodsList[periodsList.length - 1]?.month}-${periodsList[periodsList.length - 1]?.year}`, "MM-YYYY"));
        return checkDate
    }

    function increaseDate() {
        let newMonth = currentDate.month + 1;
        let newYear = currentDate.year
        if (newMonth > 12) {
            newMonth = 1;
            newYear++
        }
        onDateChange({ month: newMonth, year: newYear })
    }

    function decreaseDate() {
        let newMonth = currentDate.month - 1;
        let newYear = currentDate.year
        if (newMonth < 1) {
            newMonth = 12;
            newYear--
        }
        onDateChange({ month: newMonth, year: newYear })
    }

    return (
        <div className={s.filterBlocks}>
            <div className={s.filterBlock}>
                <span>PERIOD</span>
                <div
                    className={`${s.filterBlockDropdown}`}
                    onBlur={() => setShowPeriodFilter(false)}
                >
                    <div className={s.buttonContainer}>
                        <CaretLeftOutlined style={checkDisabledPreviousButton() ? { color: 'gray', pointerEvents: 'none' } : {}} onClick={decreaseDate} />
                        <button
                            type='button'
                            onClick={() =>
                                setShowPeriodFilter(!showPeriodFilter)
                            }
                        >
                            {makeText(currentDate)}
                        </button>
                        <CaretRightOutlined style={checkDisabledNextButton() ? { color: 'gray', pointerEvents: 'none' } : {}} onClick={increaseDate} />
                    </div>
                    <div className='scorecards-month-picker'>
                        <Space direction='vertical'>
                            <MonthPicker
                                disabledDate={disabledDate}
                                value={moment(
                                    `${currentDate.year}-${currentDate.month}`,
                                    'YYYY-MM'
                                )}
                                onChange={handleDateSelect}
                                open={showPeriodFilter}
                            />
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DateDropdown
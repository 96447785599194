export const MONTH_SHORT_TEXT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec','Jan'];

export const TAB_SCORE_CARD = {
  HSSE_PERFORMANCE: 1,
  FINANCIAL_EXCELLENCE: 2,
  PLANT_PERFORMANCE: 3,
  PRODUCTION_GENERATION: 4,
  STRATEGIC_INITIATIVES: 5
}

/* LMT Scorecard Secondary Details */
export const LMT_TAB_SCORE_CARD = {
  HSSE_PERFORMANCE: 1,
  OPERATIONAL_PERFORMANCE: 2,
  FINANCIAL_PERFORMANCE: 3
}

export const LMT_PAGE_TYPE = {
  SCORECARD: 1,
  MFT: 2
}

export const EXECUTIVE_SUMMARY_LABEL = 'Key Highlight';
export const LMT_SALES_VOLUME_LABEL = 'Summary';
export const CARGO_DETAILS_LABEL = 'Cargo Details';
export const COMMERCIAL_EXCELLENCE_LABEL = 'Commercial Excellence';
export const FATALITY_LABEL = 'Fatality';
export const MHSEV_LABEL = 'MHSEV';
export const SUSTAINABILITY_LABEL = 'Sustainability';
export const CAPEX_LABEL = 'CAPEX';

export const LMT_OPERATIONAL_SCORECARD_TYPE = {
  LMT_SALES_VOLUME: 0,
  COMMERCIAL_EXCELLENCE: 1,
}

export const LMT_OPERATIONAL_SALES_UNITS_ENUMS = {
  MMT: 'MMT',
  BCE: 'BCE'
}

export const LMT_OPERATIONAL_SALES_UNITS = [LMT_OPERATIONAL_SALES_UNITS_ENUMS.MMT, LMT_OPERATIONAL_SALES_UNITS_ENUMS.BCE]

export const LMT_HSSE_SCORECARD_TYPE = {
  GROUP_FATALITY: 0,
  MHSEV: 1,
  LTIF: 2,
  SUSTAINABILITY: 3
}

export const LMT_FINANCIAL_SCORECARD_TYPE = {
  CAPEX: 8
}

export const LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS = {
  EXECUTIVE_SUMMARY: 0,
  LMT_SALES_VOLUME: 1, 
  CARGO_DETAILS: 2,
  COMMERCIAL_EXCELLENCE: 3
}

export const LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS = {
  EXECUTIVE_SUMMARY: 0,
  GROUP_FATALITY: 1, 
  MHSEV: 2,
  SUSTAINABILITY: 3
}

export const LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS = {
  EXECUTIVE_SUMMARY: 0,
  CAPEX: 1
}

export const LMT_SCORECARD_OPERATIONAL_TYPE_CONFIG = [
  {
    label: EXECUTIVE_SUMMARY_LABEL,
    value: LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.EXECUTIVE_SUMMARY
  },
  {
    label: LMT_SALES_VOLUME_LABEL,
    value: LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.LMT_SALES_VOLUME
  },
  {
    label: CARGO_DETAILS_LABEL,
    value: LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.CARGO_DETAILS
  },
  {
    label: COMMERCIAL_EXCELLENCE_LABEL,
    value: LMT_SCORECARD_SECONDARY_OPERATIONAL_TAB_ENUMS.COMMERCIAL_EXCELLENCE
  }
]

export const LMT_SCORECARD_HSSE_TYPE_CONFIG = [
  {
    label: EXECUTIVE_SUMMARY_LABEL,
    value: LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.EXECUTIVE_SUMMARY
  },
  {
    label: FATALITY_LABEL,
    value: LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.GROUP_FATALITY
  },
  {
    label: MHSEV_LABEL,
    value: LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.MHSEV
  },
  {
    label: SUSTAINABILITY_LABEL,
    value: LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.SUSTAINABILITY
  }
]

export const LMT_SCORECARD_FINANCIAL_TYPE_CONFIG = [
  {
    label: EXECUTIVE_SUMMARY_LABEL,
    value: LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS.EXECUTIVE_SUMMARY
  },
  {
    label: CAPEX_LABEL,
    value: LMT_SCORECARD_SECONDARY_FINANCIAL_TAB_ENUMS.CAPEX
  }
]

export const SCORECARD_DETAILS = [ TAB_SCORE_CARD.FINANCIAL_EXCELLENCE ]
export const SCORECARD_CHARTS = [ TAB_SCORE_CARD.FINANCIAL_EXCELLENCE ]

export const HSSE_PERFORMANCE_LABEL = 'HSSE Performance';
export const FINANCIAL_EXCELLENCE_LABEL = 'Financial Excellence';
export const PLANT_PERFORMANCE_LABEL = 'Plant Performance';
export const PRODUCTION_GENERATION_LABEL = 'Production & Generation';
export const STRATEGIC_INITIATIVES_LABEL = 'Strategic Initiatives';
export const OPERATIONAL_PERFORMANCE_LABEL = 'Operational Performance';
export const FINANCIAL_PERFORMANCE_LABEL = 'Financial Performance';

export const FINANCIAL_EXCELLENCE_URL_MORE_DETAILS = 'https://app.powerbi.com/Redirect?action=OpenApp&appId=00c56d91-ebf3-4adb-80d3-2addc0c46802&ctid=3b2e8941-7948-4131-978a-b2dfc7295091';
export const PLANT_PERFORMANCE_URL_MORE_DETAILS = 'https://app.powerbi.com/Redirect?action=OpenApp&appId=00c56d91-ebf3-4adb-80d3-2addc0c46802&ctid=3b2e8941-7948-4131-978a-b2dfc7295091';
export const PRODUCTION_GENERATION_URL_MORE_DETAIL = 'https://app.powerbi.com/Redirect?action=OpenApp&appId=00c56d91-ebf3-4adb-80d3-2addc0c46802&ctid=3b2e8941-7948-4131-978a-b2dfc7295091';
export const STRATEGIC_INITIATIVES_URL_MORE_DETAIL = 'https://app.powerbi.com/groups/me/apps/00c56d91-ebf3-4adb-80d3-2addc0c46802/reports/32484213-5f79-4608-9b8e-d09b0ea6ed27/ReportSection';
export const LMT_URL_MORE_DETAILS = 'https://app.powerbi.com/links/iKkak3143l?ctid=3b2e8941-7948-4131-978a-b2dfc7295091&pbi_source=linkShare';
export const GNE_ENDPOINT = "/strategicinitiatives/v1"




export const TAB_SCORE_CARD_CONFIG = [
  {
    label: HSSE_PERFORMANCE_LABEL,
    value: 1,
  },
  {
    label: PRODUCTION_GENERATION_LABEL,
    value: 4,
  },
  {
    label: PLANT_PERFORMANCE_LABEL,
    value: 3,
  },
  {
    label: FINANCIAL_EXCELLENCE_LABEL,
    value: 2,
  },
  {
    label: STRATEGIC_INITIATIVES_LABEL,
    value: 5
  }
]

export const DISABLED_CHART_BY_OPU = {
  PFLNG: {
    plant: {
      waterfall: true
    },
    production: {
      waterfall: true
    },
    financial: {
      waterfall: true
    }
  }
}

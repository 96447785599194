import * as types from "./actionType";

export const opemModalContentRelease = () => ({
    type: types.OPEN_MODAL_CONTENT_RELEASE
})

export const closeModalContentRelease = () => ({
    type: types.CLOSE_MODAL_CONTENT_RELEASE,
})

export const fetchContentReleaseAnnouncement = () => ({
    type: types.CONTENT_RELEASE_ANNOUNCEMENT_REQUESTED
})

export const fetchRCTable = (payload: any) => ({
    type: types.RC_ANNOUNCEMENT_REQUESTED,
    payload
})

export const togglePublishStatus = (payload: any) => ({
    type: types.RC_ANNOUNCEMENT_PUBLISH_STATUS_TOGGLE,
    payload
})

export const deleteAnnouncement = (payload: any) => ({
    type: types.RC_ANNOUNCEMENT_DELETE,
    payload
})

export const createAnnouncement = (payload: any) => ({
    type: types.RC_ANNOUNCEMENT_CREATE,
    payload
})

export const updateAnnouncement = (payload: any) => ({
    type: types.RC_ANNOUNCEMENT_UPDATE,
    payload
})

export const toggleAnnouncementForm = (payload: boolean) => ({
    type: types.RC_ANNOUNCEMENT_FORM_TOGGLE,
    payload
})

export const setCurrentRecord = (payload: any) => ({
    type: types.RC_ANNOUNCEMENT_SET_CURRENT_RECORD,
    payload
})

export const clearFailedAction = () => ({
    type: types.RC_ANNOUNCEMENT_CLEAR_FAILED_ACTION
})


import { Collapse } from 'antd';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { formatScoreCardTargetValue } from '../../../../../utils/Number.utils';

import s from '../../SecondaryCard.module.css';
import cardStyle from './LmtCapex.module.css';

function LmtCapex() {
    const { secondaryCardData } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            secondaryCardData: scorecard.secondaryCardDetail
        };
    });

    const { Panel } = Collapse;

    function customExpandIcon(props) {
        if (props.isActive) {
            return (
              <a
                onClick={(e) => {props.onItemClick(props.panelKey);}}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {props.onItemClick(props.panelKey);}
                }}
                tabIndex={0}
              >
                <CollapseIcon />
              </a>
            );
          } else {
            return (
              <a
                onClick={(e) => {props.onItemClick(props.panelKey);}}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {props.onItemClick(props.panelKey);}
                }}
                tabIndex={0}
              >
                <ExpandIcon />
              </a>
            );
          }
          
    }

    const ExpandIcon = () => <img alt='expand-icon' src='/img/icon/scorecard-secondary-expand.svg' />;
    const CollapseIcon = () => <img alt='collapse-icon' src='/img/icon/scorecard-secondary-collapse.svg' />;
    const expandIconPosition = 'right';

    function renderTitleCard(card) {
        return (
            <div className={cardStyle.customAntHeader}>
                <div className={cardStyle.commercialDataName}>
                    <div className={cardStyle.itemName}>{card.cardName}</div>
                </div>
                <div className={cardStyle.commercialData}>
                    <div className={cardStyle.item}>
                        <span className={cardStyle.value}>
                            {formatScoreCardTargetValue(card.ytd, 2)}
                        </span>
                    </div>
                </div>
                <div className={cardStyle.commercialData}>
                    <div className={cardStyle.item}>
                        <span className={cardStyle.value}>
                            {formatScoreCardTargetValue(card.yep, 2)}
                        </span>
                    </div>
                </div>
                <div className={cardStyle.commercialData}>
                    <div className={cardStyle.item}>
                        <span className={cardStyle.value}>
                            {formatScoreCardTargetValue(card.p4r, 2)}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    function renderExpandContent(data) {
        return <>
            {data.map((detailItem, index) => {
                return (
                    <>
                        <div className={cardStyle.commercialInitiative}>
                            <div className={cardStyle.commercialDataName}>
                                <div className={cardStyle.initiativeName}>{detailItem.detailName}</div>
                            </div>
                            <div className={cardStyle.commercialData}>
                                <div className={cardStyle.initiative}>
                                    <span className={cardStyle.value}>
                                        {formatScoreCardTargetValue(detailItem.ytd, 2)}
                                    </span>
                                </div>
                            </div>
                            <div className={cardStyle.commercialData}>
                                <div className={cardStyle.initiative}>
                                    <span className={cardStyle.value}>
                                        {formatScoreCardTargetValue(detailItem.yep, 2)}
                                    </span>
                                </div>
                            </div>
                            <div className={cardStyle.commercialData}>
                                <div className={cardStyle.initiative}>
                                    <span className={cardStyle.value}>
                                        {formatScoreCardTargetValue(detailItem.p4r, 2)}
                                    </span>
                                </div>
                            </div>
                        </div>
                        {index !== data.length - 1 && <>&nbsp;</>}
                    </>
                );
            })}
        </>
    }

    const capex = secondaryCardData;
    return (
        <div className={s.detailsContent}>
            <div className={cardStyle.commercialHeader}>
                <div className={cardStyle.commercialHeaderItem}>
                    Project (USD Million)
                </div>
                <div className={cardStyle.commercialHeaderValue}>
                    YTD
                </div>
                <div className={cardStyle.commercialHeaderValue}>
                    YEP
                </div>
                <div className={cardStyle.commercialHeaderValue}>
                    P4R
                </div>
            </div>

            <div className={cardStyle.commercialBody}>
                {capex?.capexItems &&
                    capex.capexItems.map((card) => {
                        return (
                            card.cardDetails.length === 0 ?
                                (<div className={s.commercialItem}>
                                    {renderTitleCard(card)}
                                </div>) :
                                (
                                    <div className={cardStyle.commercialCollapseItems}>
                                        <Collapse bordered={false}
                                            expandIcon={(props) => customExpandIcon(props)}
                                            expandIconPosition={expandIconPosition}
                                            className={cardStyle.customAntCollapse}>
                                            <Panel header={renderTitleCard(card)} key={card.items}>
                                                {renderExpandContent(card.cardDetails)}
                                            </Panel>
                                        </Collapse>
                                    </div>
                                )
                        );
                    })}
            </div>

            <div className={cardStyle.commercialTotal}>
                <div className={cardStyle.totalName}>
                    Total
                </div>
                <div className={cardStyle.commercialTotalValue}>
                    {formatScoreCardTargetValue(capex?.totalYtd, 2)}
                </div>
                <div className={cardStyle.commercialTotalValue}>
                    {formatScoreCardTargetValue(capex?.totalYep, 2)}
                </div>
                <div className={cardStyle.commercialTotalValue}>
                    {formatScoreCardTargetValue(capex?.totalP4r, 2)}
                </div>
            </div>
        </div>
    );
}

export default LmtCapex;
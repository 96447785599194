import React from 'react';

import { Row, Col } from 'antd';

import s from './berthing.module.css';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { debug } from 'console';

const BerthingComponent = (props:any) => {
  const selectedPoint = useSelector((state) =>
    get(state, 'map.selectedPoint', null)
  );
  const { data } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      data: callout.berthingData,
    };
  });

  const isBerth = props.isBerth;

  function _renderBerthTitle() {
    if (selectedPoint.locationId === 14 || isBerth) {
      return <span>BERTHING</span>;
    } else if (selectedPoint.locationId === 19) {
      return <span>PFLNG SATU BERTH</span>;
    } else if (selectedPoint.locationId === 67) {
      return <span>PFLNG DUA BERTH</span>;
    }
  }

  function convertLoadSize(loadsize: string) {
    const parts = loadsize.split(' ');
    const numberPart = Number(parts[0]).toLocaleString();
    return `${numberPart} ${parts[1]}`;
  }

  if (selectedPoint.calloutTypeId === 9) {
    return (
      <div className={`${s.highlightComponent} ${s.berthing}`}>
        <div className={s.highlightTitle}>
          {_renderBerthTitle()}
          <span />
        </div>
        <div className={s.cargoInformationSection}>
          <div className={s.berthingContainer}>
            {data?.length > 0 &&
              data.map((d, i) => {
                return (
                  <div className={s.berthItem}>
                    <Row justify='space-around' align='bottom'>
                      <Col flex="20">
                        {d.berth ? (
                          <span className={s.berthName}>{d.berth}</span>
                        ) : null}
                        <div>
                          <img
                            alt='shipment'
                            src='\img\icon\berthing-ship.svg'
                          />
                          <span className={s.shipName}>
                            {d.vessel.label}&nbsp; | &nbsp;&nbsp;
                            {/* {d.loadSize} */}
                            {convertLoadSize(d.loadSize)}
                          </span>
                        </div>
                      </Col>
                      <Col flex="1">
                        <img
                          alt='right-arrow'
                          src='\img\icon\berthing-arrow.svg'
                        />
                      </Col>
                      <Col flex="20">
                        <div className={s.transportInfo}>
                          <span
                            className={`${s.colorDot} ${
                              d.color === 'green' ? s.active : null
                            }`}
                          />
                          <span className={s.transportMethod}>Sailway</span>
                          <span className={s.transportDate}>{d.sailway}</span>
                        </div>
                        <div className={s.transportCompany}>
                          {d.location.imageUrl ? (
                            <img
                              alt='flag'
                              src={d.location.imageUrl}
                              className={s.flag}
                            />
                          ) : (
                            <img
                              alt='flag'
                              src="\img\icon\Blank Flag.svg"
                              className={s.flag}
                            />
                          )}
                          <span className={s.companyName}>
                            {d.location.label || 'N/A'}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default BerthingComponent;

import { IRule } from "../../../model/limitConfig.model";
import { Modal, Popover } from "antd";
import React, { useState } from "react";
import { isEmpty, isNaN, isNumber } from "lodash";

import DotColorsPopComponent from "../../DotColors/DotColorsPopComponent";
import { fetchUpdateRules } from "../../../action/actionLimitConfig";
import s from "./EditRulesModalComponent.module.css";
import { useDispatch } from "react-redux";

interface Props {
  isVisible: boolean;
  editRow?: any;
  closeFunc?: any;
  type: "VC" | "WM";
}
const TITLE_TEXT_1 = {
  VC: "MAIN CATEGORY",
  WM: "MAP",
};

const TITLE_TEXT_2 = {
  VC: "CATEGORY",
  WM: "LOCATION",
};
const TITLE_ATTR_1 = {
  VC: "mainCategoryName",
  WM: "parentLocation",
};

const TITLE_ATTR_2 = {
  VC: "categoryName",
  WM: "locationDescription",
};

const EditRulesModalComponent = ({
  isVisible,
  editRow,
  closeFunc,
  type,
}: Props) => {
  const [modifiedRow, setmodifiedRow] = useState<any>();
  const dispatch = useDispatch();

  function setselectedParam(index) {
    let newModifiedRow = JSON.parse(JSON.stringify(getTableData()));
    newModifiedRow.selectedParam = index;
    setmodifiedRow(newModifiedRow);
  }

  function _contentText(values) {
    return type === "VC"
      ? `${values.displayName} - ${values.parameterName}`
      : values.parameterName;
  }

  function _renderContent(selectedRow) {
    return (
      <div className={s.popoverContent}>
        {selectedRow?.parameters?.map((values, index) => (
          <div key={index}>
            <span onClick={() => setselectedParam(index)} key={index}>
              {_contentText(values)}
            </span>
          </div>
        ))}
      </div>
    );
  }

  function _theadText(text) {
    return (
      <th>
        <div>
          <p>{text}</p>
        </div>
      </th>
    );
  }
  function _thead() {
    return (
      <tr>
        {_theadText("COLORS")}
        {_theadText("LOWER BOUND")}
        {_theadText("UPPER BOUND")}
        <th></th>
      </tr>
    );
  }

  function setColors(value, index) {
    let newModifiedRow: any = JSON.parse(JSON.stringify(getTableData()));
    newModifiedRow.parameters[newModifiedRow.selectedParam].rules[index].color =
      value;
    setmodifiedRow(newModifiedRow);
  }

  function _tbodyDropColors(item: IRule, idx) {
    return (
      <td>
        <div>
          <DotColorsPopComponent
            index={idx}
            color={item.color}
            selectColorFunc={setColors}
          />
        </div>
      </td>
    );
  }

  function setNumber(value, attr, idx) {
    if (isNumber(value) || isNaN(value)) {
      return;
    }
    let newValue =
      value === "" || value === "-" || value === "e" ? 0 : parseFloat(value);
    let newModifiedRow: any = JSON.parse(JSON.stringify(getTableData()));
    newModifiedRow.parameters[newModifiedRow.selectedParam].rules[idx][attr] =
      newValue;
    setmodifiedRow(newModifiedRow);
  }

  function _tbodyNo(item, attr, idx) {
    return (
      <td>
        <div>
          <input
            onChange={(e) => setNumber(e.target.value, attr, idx)}
            value={item[attr]}
          />
        </div>
      </td>
    );
  }

  function deleteRow(idx) {
    let newModifiedRow: any = JSON.parse(JSON.stringify(getTableData()));
    newModifiedRow.parameters[newModifiedRow.selectedParam].rules.splice(
      idx,
      1
    );
    setmodifiedRow(newModifiedRow);
  }

  function _tbodyImgDelete(idx) {
    return (
      <td>
        <div onClick={() => deleteRow(idx)} className={s.tbodyImgDelete}>
          <img src="/img/icon/Delete.svg" alt="delete" />
        </div>
      </td>
    );
  }

  function getTableData() {
    return isEmpty(modifiedRow) ? editRow : modifiedRow;
  }

  function _tbody() {
    let tableRows: any = getTableData();
    let selectedParam = tableRows.parameters[tableRows.selectedParam].rules;
    return (
      <>
        {selectedParam.map((item, idx) => (
          <tr key={idx}>
            {_tbodyDropColors(item, idx)}
            {_tbodyNo(item, "lowerBound", idx)}
            {_tbodyNo(item, "upperBound", idx)}
            {_tbodyImgDelete(idx)}
          </tr>
        ))}
      </>
    );
  }
  function _modalContent() {
    let selectedRow: any = getTableData();
    return (
      <div className={s.modalContentTitle}>
        <div>
          <p className={s.parentText}>{TITLE_TEXT_1[type]}</p>
          <div>
            <p className={s.childText}>{selectedRow[TITLE_ATTR_1[type]]}</p>
          </div>
        </div>
        <div>
          <p className={s.parentText}>{TITLE_TEXT_2[type]}</p>
          <div>
            <p className={s.childText}>{selectedRow[TITLE_ATTR_2[type]]}</p>
          </div>
        </div>
        <Popover
          overlayClassName="tbodyDropdown"
          placement="bottom"
          content={_renderContent(selectedRow)}
          trigger="click"
        >
          <div>
            <p className={s.parentText}>PARAMETER</p>
            <div>
              <p className={s.childText}>
                {_contentText(
                  selectedRow?.parameters[selectedRow.selectedParam]
                )}
              </p>
              <img
                className={s.arrow}
                src="/img/icon/DownArrow.svg"
                alt="arrow"
              />
            </div>
          </div>
        </Popover>
      </div>
    );
  }

  function addRules() {
    let newModifiedRow: any = JSON.parse(JSON.stringify(getTableData()));
    let newRules: IRule = {
      color: "",
      lowerBound: 0,
      upperBound: 0,
    };
    newModifiedRow.parameters[newModifiedRow.selectedParam].rules.push(
      newRules
    );
    setmodifiedRow(newModifiedRow);
  }
  function _addMoreColor() {
    return (
      <div onClick={() => addRules()}>
        <img src="/img/icon/Button Plus.svg" alt="add" />
        <p>Add more color</p>
      </div>
    );
  }

  function setCloseModal() {
    setmodifiedRow(undefined);
    closeFunc();
  }

  function saveRules() {
    let tableRows: any = getTableData();
    let updateRules = {
      tagId: tableRows.parameters[tableRows.selectedParam].tagId,
      updatedRule: tableRows.parameters[tableRows.selectedParam].rules,
      type: type,
    };
    closeFunc();
    dispatch(fetchUpdateRules(updateRules));
  }

  function _modalBody() {
    let selectedRow: any = getTableData();
    return (
      <>
        {selectedRow?.parameters && (
          <div>
            <div className={s.modalHeader}>
              <p>Edit Limit Configuration</p>
              <img
                onClick={() => setCloseModal()}
                src="/img/icon/CrossPopup.svg"
                alt="close"
              />
            </div>
            <div className={s.modalContent}>
              {_modalContent()}
              <div className={s.modalBody}>
                <table className={s.modalTable}>
                  <thead>{_thead()}</thead>
                  <tbody className={s.tbody}>{_tbody()}</tbody>
                </table>
                <div className={s.addMoreColor}>{_addMoreColor()}</div>
              </div>
            </div>
            <div className={s.modalFooter}>
              {!selectedRow?.parameters[selectedRow.selectedParam]
                .disableEdit && (
                <button onClick={() => saveRules()}>SAVE</button>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <Modal
      className="EditRulesModalComponent"
      closable={false}
      footer={null}
      visible={isVisible}
      width={640}
    >
      {_modalBody()}
    </Modal>
  );
};
export default EditRulesModalComponent;

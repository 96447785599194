import { Action } from "redux";
import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from "../action/actionType";
import { transformTrendChartlevel3Data } from "../factory/trendChartLevel3.factory";
import { transformTrendChartlevel2Data } from "../factory/trendChartLevel2.factory";
import { transformWaterfallChartLevel2 } from "../factory/waterfallChartlevel2.factory";
import { transformWaterfallChartLevel3 } from "../factory/waterfallChartLevel3.factory";
import {
  getScoreBreakdownCard,
  getScoreCardExecutiveSummary,
  getScoreCardGNEBigCard,
  getScoreCardGNESmallCard,
  getScoreCardRevenueChart,
  getScorePrimaryCardDetail,
  getScoreCardWaterfallChart,
  getScoreCardPrimaryCardDetailLevel3,
  getScoreCardRevenueChartLvl3,
  getScoreCardPeriodsList,
  getScoreCardWaterfallChartLvl3,
  getScoreCardBreakdownCardsLv3,
  getScoreCardGNEStrategicInit,
  getScorecardLmtOperationalSecondaryCardDetail,
  getScorecardLmtOperationalExecSummary,
  getScorecardLmtHsseSecondaryCardDetail,
  getScorecardLmtFinancialSecondaryCardDetail,
  getScorecardLmtChartData,
  getScorecardLmtSalesCountryData,
  getScorecardLmtContractDetail,
  getScorecardLmtContractDetailTable,
  getScoreMenuList,
  uploadScorecardsFile,
  getListHistoryUpload,
  getScorecardLmtSaleVolume,
  deleteUploadFile,
  previewUploadFile,
  validateScorecardsFile,
  getCargoDelivery,
  multipartUploadFile,
  initMultipartUpload,
  completeMultipartUploadFile,
  getPresignedUrlMUltipart,
  getScorecardMlngHseExecSummary,
  getScorecardMlngFinancialExecSummary,
  getScorecardMlngPlantExecSummary,
  getSecondaryCards,
} from "../services/score-card.service";
interface ScoreCard extends Action {
  payload: {} | any;
}

function* fetchScoreCardExcutiveSummary(action: ScoreCard) {
  try {
    const response = yield call(getScoreCardExecutiveSummary, action.payload);
    yield put({
      type: types.SCORE_CARD_EXECUTIVE_SUMMARY_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_EXECUTIVE_SUMMARY_FAILED,
      message: error.message,
    });
  }
}

function* fetchScoreCardGNEBigCard(action: ScoreCard) {
  try {
    const response = yield call(getScoreCardGNEBigCard, action.payload);
    yield put({
      type: types.SCORE_CARD_GNE_TAB_BIG_CARD_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_GNE_TAB_BIG_CARD_FAILED,
      message: error.message,
    });
  }
}

function* fetchScoreCardGNESmallCard(action: ScoreCard) {
  try {
    const response = yield call(getScoreCardGNESmallCard, action.payload);
    yield put({
      type: types.SCORE_CARD_GNE_TAB_SMALL_CARD_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_GNE_TAB_SMALL_CARD_FAILED,
      message: error.message,
    });
  }
}

// BREAKDOWN CARD & PRIMARY CARD SAGA

export function* fetchScoreCardPrimaryCardSaga(action: ScoreCard) {
  try {
    const response = yield call(getScorePrimaryCardDetail, action.payload);
    yield put({
      type: types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardBreakdownCardSaga(action: ScoreCard) {
  try {
    const response = yield call(getScoreBreakdownCard, action.payload);
    yield put({
      type: types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardRevenueSaga(action: ScoreCard) {
  try {
    const response = yield call(getScoreCardRevenueChart, action.payload);
    const transformData = transformTrendChartlevel2Data(response);
    yield put({
      type: types.SCORE_CARD_REVENUE_CHART_SUCCEEDED,
      payload: transformData,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_REVENUE_CHART_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardWaterfallSaga(action: ScoreCard) {
  try {
    const response = yield call(getScoreCardWaterfallChart, action.payload);
    const transformData = transformWaterfallChartLevel2(
      response?.result?.chartData
    );
    yield put({
      type: types.SCORE_CARD_WATERFALL_CHART_SUCCEEDED,
      payload: {
        ...transformData,
        unit: response.result.unit,
        barNumber: response?.result?.chartData?.length,
      },
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_WATERFALL_CHART_FAILED,
      message: error.message,
    });
  }
}

export function* fetchCargoDelivery() {
  try {
    const response = yield call(getCargoDelivery);
    yield put({
      type: types.GET_CARGO_DELIVERY_SUCCESS,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_CARGO_DELIVERY_FAIL,
      message: error.message,
    });
  }
}
// BREAKDOWNCARD DETAIL LEVEL 3

export function* fetchScoreCardPrimaryCardLvl3Saga(action: ScoreCard) {
  try {
    const { result } = yield call(
      getScoreCardPrimaryCardDetailLevel3,
      action.payload
    );
    yield put({
      type: types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_SUCCEEDED,
      payload: result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_FAILED,
      message: error.message,
    });
  }
}

export function* fecthScoreCardBreakdownCardLvl3(action: ScoreCard) {
  try {
    const { result } = yield call(
      getScoreCardBreakdownCardsLv3,
      action.payload
    );
    yield put({
      type: types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_SUCCEEDED,
      payload: result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardWaterfallLvl3Saga(action: ScoreCard) {
  try {
    const response = yield call(getScoreCardWaterfallChartLvl3, action.payload);
    const transformData = transformWaterfallChartLevel3(
      response?.result?.chartData
    );
    yield put({
      type: types.SCORE_CARD_WATERFALL_CHART_LVL3_SUCCEEDED,
      payload: {
        ...transformData,
        unit: response?.result?.unit,
        barNumber: response?.result?.chartData?.length,
      },
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_WATERFALL_CHART_LVL3_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardPeriodsListSaga() {
  try {
    const response = yield call(getScoreCardPeriodsList);
    yield put({
      type: types.SCORE_CARD_GNE_PERIODS_LIST_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_GNE_PERIODS_LIST_FAILED,
      message: error.message,
    });
  }
}

export function* deleteUploadFileSaga(action: ScoreCard) {
  try {
    const response = yield call(deleteUploadFile, action.payload);
    yield put({
      type: types.SCORE_CARD_GNE_DELETE_UPLOAD_FILE_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_GNE_DELETE_UPLOAD_FILE_FAILED,
      message: error.message,
    });
  }
}

export function* dowloadUploadFileSaga(action: ScoreCard) {
  try {
    const response = yield call(previewUploadFile, action.payload);
    try {
      window.open(response, '_self')
    } catch (error: any) {
      yield put({
        type: types.SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_SUCCEEDED,
        payload: response,
      });
    }
  } catch (error) {
    yield put({
      type: types.SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_SUCCEEDED,
      payload: error,
    });
  }
}

export function* previewUploadFileSaga(action: ScoreCard) {
  try {
    const response = yield call(previewUploadFile, action.payload);
    yield put({
      type: types.SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardMenuSaga(action: ScoreCard) {
  try {
    const response = yield call(getScoreMenuList, action.payload);
    yield put({
      type: types.SCORE_CARD_MENU_LIST_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_MENU_LIST_FAILED,
      message: error.message,
    });
  }
}

export function* fetchListHistoryUploadSaga(action: ScoreCard) {
  try {
    const response = yield call(getListHistoryUpload, action.payload);
    yield put({
      type: types.SCORE_CARD_LIST_HISTORY_UPLOAD_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_LIST_HISTORY_UPLOAD_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardRevenueLvl3Saga(action: ScoreCard) {
  try {
    const response = yield call(getScoreCardRevenueChartLvl3, action.payload);
    const transformData = transformTrendChartlevel3Data(response.result);
    yield put({
      type: types.SCORE_CARD_REVENUE_CHART_LVL3_SUCCEEDED,
      payload: transformData,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_REVENUE_CHART_LVL3_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardGNEStrategicInit(action: ScoreCard) {
  try {
    const response = yield call(getScoreCardGNEStrategicInit, action.payload);
    yield put({
      type: types.SCORECARD_STRATEGIC_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARD_STRATEGIC_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardLmtOperationalSecondaryCardSaga(
  action: ScoreCard
) {
  try {
    const response = yield call(
      getScorecardLmtOperationalSecondaryCardDetail,
      action.payload
    );
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardLmtHsseSecondaryCardSaga(action: ScoreCard) {
  try {
    const response = yield call(
      getScorecardLmtHsseSecondaryCardDetail,
      action.payload
    );
    yield put({
      type: types.SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardLmtOperationalExecSummarySaga(
  action: ScoreCard
) {
  try {
    const response = yield call(
      getScorecardLmtOperationalExecSummary,
      action.payload
    );
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardMlngFinancialExecSummarySaga(
  action: ScoreCard
) {
  try {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED,
      payload: {},
    });
    const response = yield call(
      getScorecardMlngFinancialExecSummary,
      action.payload
    );
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardMlngHseExecSummarySaga(
  action: ScoreCard
) {
  try {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED,
      payload: {},
    });
    const response = yield call(
      getScorecardMlngHseExecSummary,
      action.payload
    );
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardMlngPlantExecSummarySaga(
  action: ScoreCard
) {
  try {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED,
      payload: {},
    });
    const response = yield call(
      getScorecardMlngPlantExecSummary,
      action.payload
    );
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_SUCCEEDED,
      payload: response.result,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardLmtFinancialSecondaryCardSaga(
  action: ScoreCard
) {
  try {
    const response = yield call(
      getScorecardLmtFinancialSecondaryCardDetail,
      action.payload
    );
    yield put({
      type: types.SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_FAILED,
      message: error.message,
    });
  }
}
//customer
export function* fetchScoreCardLmtCustomerChartDataSaga(action: ScoreCard) {
  try {
    const response = yield call(getScorecardLmtChartData, action.payload);
    yield put({
      type: types.SCORECARD_LMT_CUSTOMER_CHART_DATA_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARD_LMT_CUSTOMER_CHART_DATA_FAILED,
      message: error.message,
    });
  }
}
//general
export function* fetchScoreCardLmtChartDataSaga(action: ScoreCard) {
  try {
    const response = yield call(getScorecardLmtChartData, action.payload);
    yield put({
      type: types.SCORECARD_LMT_CHART_DATA_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARD_LMT_CHART_DATA_FAILED,
      message: error.message,
    });
  }
}
//country
export function* fetchScoreCardLmtCountryChartDataSaga(action: ScoreCard) {
  try {
    const response = yield call(getScorecardLmtChartData, action.payload);
    yield put({
      type: types.SCORECARD_LMT_COUNTRY_CHART_DATA_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARD_LMT_COUNTRY_CHART_DATA_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardLmtSalesCountryDataSaga(action?: ScoreCard) {
  try {
    const response = yield call(getScorecardLmtSalesCountryData , action?.payload);
    yield put({
      type: types.SCORECARD_LMT_SALES_COUNTRY_DATA_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARD_LMT_SALES_COUNTRY_DATA_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardLmtContractDetailSaga(action: ScoreCard) {
  try {
    const response = yield call(getScorecardLmtContractDetail, action.payload);
    yield put({
      type: types.SCORECARD_LMT_CONTRACT_DETAIL_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARD_LMT_CONTRACT_DETAIL_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardLmtSaleVolumeSaga(action: ScoreCard) {
  try {
    const response = yield call(getScorecardLmtSaleVolume, action.payload);
    yield put({
      type: types.SCORECARD_LMT_SALE_VOLUME_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARD_LMT_SALE_VOLUME_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScoreCardGnePerformanceSaga(action: ScoreCard) {
  try {
    const response = yield call(getSecondaryCards, action.payload);
    yield put({
      type: types.SCORECARD_GNE_TAB_PERFORMANCE_SECONDARY_CARD_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARD_GNE_TAB_PERFORMANCE_SECONDARY_CARD_FAILED,
      message: error.message,
    });
  }
}

export function* fetchScorecardLmtContractDetailTableSaga(action: ScoreCard) {
  try {
    const response = yield call(
      getScorecardLmtContractDetailTable,
      action.payload
    );
    yield put({
      type: types.SCORECARD_LMT_CONTRACT_DETAIL_TABLE_SUCCEEDED,
      payload: response,
    });
  } catch (error: any) {
    yield put({
      type: types.SCORECARD_LMT_CONTRACT_DETAIL_TABLE_FAILED,
      message: error.message,
    });
  }
}

export function* uploadScoreCardFileSaga(payload: any) {
  try {
    const response = yield call(uploadScorecardsFile, {
      ...payload.data,
      stringFile: '',
    });
    if (!response.resultMessage) {
      yield put({
        type: types.UPLOAD_SCORECARD_FILE_REQUESTED_SUCCEEDED,
        payload: response.resultMessage,
      });
    } else {
      yield put({
        type: types.UPLOAD_SCORECARD_FILE_REQUESTED_FAILED,
        payload: response.resultMessage,
      });
    }
  } catch (error: any) {
    yield put({
      type: types.UPLOAD_SCORECARD_FILE_REQUESTED_FAILED,
      payload: error.message,
    });
  }
}

export function* validateScoreCardFileSaga(payload: any) {
  try {
    const buffer = Buffer.from(payload.data.stringFile, 'base64');
    const fileSize = buffer.length;
    if(fileSize === 0){
      yield put({
        type: types.VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_FAIL,
        payload: 'No file selected for upload.',
      });
      return;
    }

    const initMultipartUploadResponse = yield call(initMultipartUpload, {
      ...payload.data,
      stringFile: '',
    });
    if(initMultipartUploadResponse.result){
      const initMultipartUploadId = initMultipartUploadResponse.resultMessage;
      

      const chunkSize = Math.max(5 * 1024 * 1024, Math.ceil(fileSize / 10000)); // 2MB chunk size
      const numberOfParts = Math.ceil(fileSize / chunkSize);

      for (let i = 0; i < numberOfParts; i++) {
        const start = i * chunkSize;
        let end = Math.min(start + chunkSize, fileSize);

        const chunk = buffer.slice(start, end);

        const presignedUrlMUltipart = yield call(getPresignedUrlMUltipart, {
          ...payload.data,
          stringFile: '',
          byteFile: null,
        }, i+1, initMultipartUploadId);

        yield call(multipartUploadFile, {
          byteFile: chunk,
        }, presignedUrlMUltipart)
      }

      yield call(completeMultipartUploadFile, {
        ...payload.data,
        stringFile: '',
        UploadId: initMultipartUploadId,
        ETags: []
      });
    }
    else {
      yield put({
        type: types.VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_FAIL,
        payload: initMultipartUploadResponse.resultMessage,
      });

      return;
    }
    const response = yield call(validateScorecardsFile, {
      ...payload.data,
      stringFile: '',
    });
      yield put({
        type: types.VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_TRUE,
        payload: response.result,
      });
      yield put({
        type: types.VALIDATE_SCORECARD_FILE_REQUESTED_SUCCEEDED_FAIL,
        payload: response.resultMessage,
      });
  } catch (error: any) {
    yield put({
      type: types.VALIDATE_SCORECARD_FILE_REQUESTED_FAILED,
      payload: error.message,
    });
  }
}

export function* workerScoreCardData() {
  yield all([
    takeLatest(
      types.SCORE_CARD_EXECUTIVE_SUMMARY_REQUESTED,
      fetchScoreCardExcutiveSummary
    ),
    takeLatest(
      types.SCORE_CARD_GNE_TAB_BIG_CARD_REQUESTED,
      fetchScoreCardGNEBigCard
    ),
    takeLatest(
      types.SCORE_CARD_GNE_TAB_SMALL_CARD_REQUESTED,
      fetchScoreCardGNESmallCard
    ),
    takeLatest(
      types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_REQUESTED,
      fetchScoreCardBreakdownCardSaga
    ),
    takeLatest(
      types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_DETAIL_REQUESTED,
      fetchScoreCardPrimaryCardSaga
    ),
    takeLatest(
      types.SCORE_CARD_REVENUE_CHART_REQUESTED,
      fetchScoreCardRevenueSaga
    ),
    takeLatest(
      types.SCORE_CARD_WATERFALL_CHART_REQUESTED,
      fetchScoreCardWaterfallSaga
    ),
    takeLatest(
      types.SCORE_CARD_GNE_TAB_PRIMARY_CARD_LVL3_REQUESTED,
      fetchScoreCardPrimaryCardLvl3Saga
    ),
    takeLatest(
      types.SCORE_CARD_REVENUE_CHART_LVL3_REQUESTED,
      fetchScoreCardRevenueLvl3Saga
    ),
    takeLatest(
      types.SCORE_CARD_GNE_PERIODS_LIST_REQUSETED,
      fetchScoreCardPeriodsListSaga
    ),
    takeLatest(
      types.GET_CARGO_DELIVERY_REQUESTED,
      fetchCargoDelivery
    ),
    takeLatest(
      types.SCORE_CARD_GNE_DELETE_UPLOAD_FILE_REQUESTED,
      deleteUploadFileSaga
    ),
    takeLatest(
      types.SCORE_CARD_GNE_PREVIEW_UPLOAD_FILE_REQUESTED,
      previewUploadFileSaga
    ),
    takeLatest(
      types.SCORE_CARD_GNE_DOWNLOAD_UPLOAD_FILE_REQUESTED,
      dowloadUploadFileSaga
    ),
    takeLatest(types.SCORE_CARD_MENU_LIST_REQUSETED, fetchScoreCardMenuSaga),
    takeLatest(
      types.SCORE_CARD_LIST_HISTORY_UPLOAD_REQUSETED,
      fetchListHistoryUploadSaga
    ),
    takeLatest(
      types.SCORE_CARD_WATERFALL_CHART_LVL3_REQUESTED,
      fetchScoreCardWaterfallLvl3Saga
    ),
    takeLatest(
      types.SCORE_CARD_GNE_TAB_BREAKDOWN_CARD_LVL3_REQUESTED,
      fecthScoreCardBreakdownCardLvl3
    ),
    takeLatest(
      types.SCORECARD_STRATEGIC_REQUESTED,
      fetchScoreCardGNEStrategicInit
    ),
    takeLatest(
      types.SCORE_CARD_LMT_TAB_OPERATIONAL_SECONDARY_CARD_DETAIL_REQUESTED,
      fetchScoreCardLmtOperationalSecondaryCardSaga
    ),
    takeLatest(
      types.SCORE_CARD_LMT_TAB_HSSE_SECONDARY_CARD_DETAIL_REQUESTED,
      fetchScoreCardLmtHsseSecondaryCardSaga
    ),
    takeLatest(
      types.SCORE_CARD_LMT_TAB_OPERATIONAL_EXEC_SUMMARY_REQUESTED,
      fetchScoreCardLmtOperationalExecSummarySaga
    ),
    takeLatest(
      types.SCORE_CARD_MLNG_HSE_EXEC_SUMMARY_REQUESTED,
      fetchScoreCardMlngHseExecSummarySaga
    ),
    takeLatest(
      types.SCORE_CARD_MLNG_FINANCIAL_EXEC_SUMMARY_REQUESTED,
      fetchScoreCardMlngFinancialExecSummarySaga
    ),
    takeLatest(
      types.SCORE_CARD_MLNG_PLANT_EXEC_SUMMARY_REQUESTED,
      fetchScoreCardMlngPlantExecSummarySaga
    ),
    takeLatest(
      types.SCORE_CARD_LMT_TAB_FINANCIAL_SECONDARY_CARD_DETAIL_REQUESTED,
      fetchScoreCardLmtFinancialSecondaryCardSaga
    ),
    takeLatest(
      types.SCORECARD_LMT_COUNTRY_CHART_DATA_REQUESTED,
      fetchScoreCardLmtCountryChartDataSaga
    ),
    takeLatest(
      types.SCORECARD_LMT_CUSTOMER_CHART_DATA_REQUESTED,
      fetchScoreCardLmtCustomerChartDataSaga
    ),
    takeLatest(
      types.SCORECARD_LMT_CHART_DATA_REQUESTED,
      fetchScoreCardLmtChartDataSaga
    ),
    takeLatest(
      types.SCORECARD_LMT_SALES_COUNTRY_DATA_REQUESTED,
      fetchScoreCardLmtSalesCountryDataSaga
    ),
    takeLatest(
      types.SCORECARD_LMT_CONTRACT_DETAIL_TABLE_REQUESTED,
      fetchScorecardLmtContractDetailTableSaga
    ),
    takeLatest(types.UPLOAD_SCORECARD_FILE_REQUESTED, uploadScoreCardFileSaga),
    takeLatest(
      types.SCORECARD_LMT_CONTRACT_DETAIL_REQUESTED,
      fetchScoreCardLmtContractDetailSaga
    ),
    takeLatest(
      types.SCORECARD_LMT_SALE_VOLUME_REQUESTED,
      fetchScoreCardLmtSaleVolumeSaga
    ),
    takeLatest(
      types.VALIDATE_SCORECARD_FILE_REQUESTED,
      validateScoreCardFileSaga
    ),
    takeLatest(
      types.SCORECARD_GNE_TAB_PERFORMANCE_SECONDARY_CARD_REQUESTED,
      fetchScoreCardGnePerformanceSaga
    ),
  ]);
}

import { ENDPOINTS, OPERATIONAL_API_BASE_URL, SCORECARES_API_BASE_URL } from "../constants/endpoints";
import { OPU } from "../constants/opu.constants";
import {
  GNE_ENDPOINT,
  TAB_SCORE_CARD,
} from "../constants/score-card.constants";
import { buildApiEndPoint, getOpuApi } from "../utils/Url.utils";
import axiosClient, { axiosClientDownloadFileAnonymous, axiosClientFile, axiosClientFileAnonymous, axiosClientFormData } from "./axiosClient.service";

export function getScoreCardExecutiveSummary(payload: any) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GET_EXECUTIEVE_SUMMARY,
    ENDPOINTS.GET_EXECUTIEVE_SUMMARY_OPU,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
  );
  return url ? axiosClient.get(url + payload) : undefined;
}

export function getScoreCardGNEBigCard(payload: any) {
  let url = "";
  const opu = getOpuApi("Scorecards");
  switch (payload?.tabActive) {
    case TAB_SCORE_CARD.HSSE_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_BIG_CARD_HSSE,
        ENDPOINTS.GET_GNE_TAB_BIG_CARD_HSSE_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA, OPU.LMT]
      );
      break;
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_BIG_CARD_FINANCIAL,
        `${['MLNG'].includes(opu.toUpperCase()) ? ENDPOINTS.GET_GNE_TAB_BIG_CARD_FINANCIAL_OPU+'old':ENDPOINTS.GET_GNE_TAB_BIG_CARD_FINANCIAL_OPU}`,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA, OPU.LMT]
      );
      break;
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_BIG_CARD_PLANT,
        ENDPOINTS.GET_GNE_TAB_BIG_CARD_PLANT_OPU,
        [OPU.PFLNG, OPU.MLNG, OPU.LNGA, OPU.GP]
      );
      break;
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_BIG_CARD_PRODUCTION,
        ENDPOINTS.GET_GNE_TAB_BIG_CARD_PRODUCTION_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA, OPU.LMT, OPU.GP]
      );
      break;
    default:
      url = ENDPOINTS.GET_GNE_TAB_BIG_CARD_HSSE;
      break;
  }

  return url ? axiosClient.get(url + payload?.params) : undefined;
}

export function getScoreCardGNESmallCard(payload: any) {
  let url = "";
  const opu = getOpuApi("Scorecards");
  url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GET_GNE_TAB_SMALL_CARD,
    ENDPOINTS.GET_GNE_TAB_SMALL_CARD_OPU,
    [OPU.PFLNG, OPU.MLNG, OPU.LNGA]
  );
  return url ? axiosClient.get(url + payload?.params) : undefined;
}

// GET BREAKDOWN CARDS
export function getScoreBreakdownCard(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = "";
  switch (payload.tabActive) {
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_FINANCIAL,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_FINANCIAL_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_PLANT,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_PLANT_OPU,
        [OPU.MLNG, OPU.LNGA],
        [OPU.PFLNG]
      );
      break;
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_PRODUCTION,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_PRODUCTION_OPU,
        [OPU.MLNG, OPU.LNGA],
        [OPU.PFLNG]
      );
      break;
  }
  return url
    ? axiosClient.get(url, {
      params: payload.params,
    })
    : undefined;
}

export function getScorePrimaryCardDetail(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = "";
  switch (payload.tabActive) {
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_FINANCIAL_DETAIL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_FINANCIAL_DETAIL_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_PLANT_DETAIL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_PLANT_DETAIL_OPU,
        [OPU.PFLNG, OPU.MLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_PRODUCTION_DETAIL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_PRODUCTION_DETAIL_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
  }

  return url ? axiosClient.get(url, { params: payload.params }) : undefined;
}

export function getScoreCardPrimaryCardDetailLevel3(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = "";
  switch (payload.tabActive) {
    case TAB_SCORE_CARD.HSSE_PERFORMANCE:
      url = ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_HSSE;
      break;
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_FINANCIAL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_FINANCIAL_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_PLANT,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_PLANT_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_PRODUCTION,
        ENDPOINTS.GET_GNE_TAB_PRIMARY_CARD_DETAIL_LEVEL3_PRODUCTION_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
  }
  return url ? axiosClient.get(url, { params: payload.params }) : undefined;
}

export function getScoreCardBreakdownCardsLv3(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = "";
  switch (payload.tabActive) {
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_LV3_PLANT,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_LV3_PLANT_OPU,
        [],
        [OPU.MLNG, OPU.LNGA, OPU.PFLNG]
      );
      break;
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_LV3_PRODUCTION,
        ENDPOINTS.GET_GNE_TAB_BREAKDOWN_CARD_LV3_PRODUCTION_OPU,
        [],
        [OPU.MLNG, OPU.LNGA]
      );
      break;
  }
  return url ? axiosClient.get(url, { params: payload.params }) : undefined;
}

export function getScoreCardRevenueChart(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = "";
  switch (payload.tabActive) {
    case TAB_SCORE_CARD.HSSE_PERFORMANCE:
      url = ENDPOINTS.GET_REVENUE_CHART_HSSE;
      break;
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_REVENUE_CHART_FINANCIAL,
        ENDPOINTS.GET_REVENUE_CHART_FINANCIAL_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_REVENUE_CHART_PLANT,
        ENDPOINTS.GET_REVENUE_CHART_PLANT_OPU,
        [OPU.PFLNG, OPU.MLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_REVENUE_CHART_PRODUCTION,
        ENDPOINTS.GET_REVENUE_CHART_PRODUCTION_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
  }
  return url
    ? axiosClient.get(url, {
      params: payload.params,
    })
    : undefined;
}

export function getScoreCardWaterfallChart(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = "";
  switch (payload.tabActive) {
    case TAB_SCORE_CARD.HSSE_PERFORMANCE:
      url = ENDPOINTS.GET_WATERFALL_CHART_HSSE;
      break;
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_WATERFALL_CHART_FINANCIAL,
        ENDPOINTS.GET_WATERFALL_CHART_FINANCIAL_OPU,
        [],
        [OPU.MLNG, OPU.PFLNG]
      );
      break;
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_WATERFALL_CHART_PLANT,
        ENDPOINTS.GET_WATERFALL_CHART_PLANT_OPU,
        [OPU.MLNG, OPU.LNGA],
        [OPU.PFLNG]
      );
      break;
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_WATERFALL_CHART_PRODUCTION,
        ENDPOINTS.GET_WATERFALL_CHART_PRODUCTION_OPU,
        [],
        [OPU.LNGA, OPU.MLNG, OPU.PFLNG]
      );
      break;
  }
  return url
    ? axiosClient.get(url, {
      params: payload.params,
    })
    : undefined;
}

export function getScoreCardRevenueChartLvl3(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = "";
  switch (payload.tabActive) {
    case TAB_SCORE_CARD.HSSE_PERFORMANCE:
      url = ENDPOINTS.GET_REVENUE_CHART_HSSE_LVL3;
      break;
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_REVENUE_CHART_FINANCIAL_LVL3,
        ENDPOINTS.GET_REVENUE_CHART_FINANCIAL_LVL3_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_REVENUE_CHART_PLANT_LVL3,
        ENDPOINTS.GET_REVENUE_CHART_PLANT_LVL3_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_REVENUE_CHART_PRODUCTION_LVL3,
        ENDPOINTS.GET_REVENUE_CHART_PRODUCTION_LVL3_OPU,
        [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
      );
      break;
  }
  return url
    ? axiosClient.get(url, {
      params: payload.params,
    })
    : undefined;
}

export function getScoreCardWaterfallChartLvl3(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = "";
  switch (payload.tabActive) {
    case TAB_SCORE_CARD.HSSE_PERFORMANCE:
      url = ENDPOINTS.GET_WATERFALL_CHART_HSSE_LVL3;
      break;
    case TAB_SCORE_CARD.FINANCIAL_EXCELLENCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_WATERFALL_CHART_FINANCIAL_LVL3,
        ENDPOINTS.GET_WATERFALL_CHART_FINANCIAL_LVL3_OPU,
        [],
        [OPU.MLNG, OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PLANT_PERFORMANCE:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_WATERFALL_CHART_PLANT_LVL3,
        ENDPOINTS.GET_WATERFALL_CHART_PLANT_LVL3_OPU,
        [OPU.MLNG],
        [OPU.LNGA]
      );
      break;
    case TAB_SCORE_CARD.PRODUCTION_GENERATION:
      url = buildApiEndPoint(
        opu as string,
        SCORECARES_API_BASE_URL,
        ENDPOINTS.GET_WATERFALL_CHART_PRODUCTION_LVL3,
        ENDPOINTS.GET_WATERFALL_CHART_PRODUCTION_LVL3_OPU,
        [OPU.MLNG],
        [OPU.LNGA]
      );
      break;
  }
  return url
    ? axiosClient.get(url, {
      params: payload.params,
    })
    : undefined;
}

// GET PERIODSLIST
export function getScoreCardPeriodsList() {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GET_GNE_PERIODS_LIST,
    ENDPOINTS.GET_GNE_PERIODS_LIST_OPU,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
  );
  return axiosClient.get(url);
}

// DELETE FILE UPLOAD
export function deleteUploadFile(payload) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GNE_DELETE_UPLOAD_FILE,
    ENDPOINTS.GNE_DELETE_UPLOAD_FILE_OPU,
    []
  );
  return axiosClient.post(url + payload.param, payload);
}

// dowload PERIODSLIST
export function downloadUploadFile(payload) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GNE_DOWNLOAD_UPLOAD_FILE,
    ENDPOINTS.GNE_DOWNLOAD_UPLOAD_FILE_OPU,
    []
  );
  return axiosClient.post(url, payload);
}

// PREVIEW FILE UPLOAD
export function previewUploadFile(payload) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GNE_PREVIEW_UPLOAD_FILE,
    ENDPOINTS.GNE_PREVIEW_UPLOAD_FILE_OPU,
    []
  );
  return axiosClient.post(url, payload);
}

// INIT MULTIPART-UPLOADING
export function initMultipartUpload(payload) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.INIT_MULTIPART_UPLOAD,
    ENDPOINTS.INIT_MULTIPART_UPLOAD_OPU,
    []
  );
  return axiosClient.post(url, payload);
}

// GET scorecard list
export function getScoreMenuList(payload: any) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GET_SCORE_CARD_LIST,
    ENDPOINTS.GET_SCORE_CARD_LIST_OPU,
    []
  );
  return axiosClient.get(url + payload.params);
}

// GET list history upload
export function getListHistoryUpload(payload: any) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GET_SCORE_CARD_LIST_LIST_HISTORY_UPLOAD,
    ENDPOINTS.GET_SCORE_CARD_LIST_LIST_HISTORY_UPLOAD_OPU,
    []
  );
  return axiosClient.get(url + payload.params);
}

// GET list history error
export function getListHistoryError(payload: any) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GET_SCORE_CARD_LIST_HISTORY_ERROR,
    '',
    []
  );
  
  return axiosClient.get(url + (payload.params || ''));
}

//get ScoreCardGNEStrategicInitiatives

export function getScoreCardGNEStrategicInit(payload: any) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    GNE_ENDPOINT,
    ENDPOINTS.GET_GNE_TAB_STRATEGIC_INITIATIVES_OPU,
    [OPU.MLNG, OPU.PFLNG, OPU.LNGA]
  );
  return url ? axiosClient.get(url, { params: payload.params }) : undefined;
}

export function getScorecardLmtOperationalSecondaryCardDetail(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_LMT_OPERATIONAL_PERFORMANCE_SECONDARY_CARD_DETAIL,
    [OPU.LMT]
  );
  return url ? axiosClient.get(url + payload) : undefined;
}

export function getScorecardLmtHsseSecondaryCardDetail(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_LMT_HSSE_PERFORMANCE_SECONDARY_CARD_DETAIL,
    [OPU.LMT]
  );
  return url ? axiosClient.get(url + payload) : undefined;
}

export function getScorecardLmtOperationalExecSummary(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_LMT_OPERATIONAL_PERFORMANCE_EXEC_SUMMARY,
    [OPU.LMT]
  );
  return url ? axiosClient.get(url + payload) : undefined;
}

export function getScorecardMlngHseExecSummary(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_MLNG_HSE_EXEC_SUMMARY,
    [OPU.MLNG]
  );
  return url ? axiosClient.get(url + payload) : undefined;
}

export function getScorecardMlngFinancialExecSummary(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_MLNG_FINANCIAL_EXCELLENCE_EXEC_SUMMARY,
    [OPU.MLNG]
  );
  return url ? axiosClient.get(url + payload) : undefined;
}

export function getScorecardMlngPlantExecSummary(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_MLNG_PLANT_PERFOMANCE_EXEC_SUMMARY,
    [OPU.MLNG]
  );
  return url ? axiosClient.get(url + payload) : undefined;
}

export function getScorecardLmtFinancialSecondaryCardDetail(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_LMT_FINANCIAL_EXCELLENCE_SECONDARY_CARD_DETAIL,
    [OPU.LMT]
  );
  return url ? axiosClient.get(url + payload) : undefined;
}

export function getScorecardLmtChartData(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_LMT_SALES_VOLUME_CHART_DATA,
    [OPU.LMT]
  );
  return url ? axiosClient.post(url, payload) : undefined;
}

export function getScorecardLmtSalesCountryData(payload?: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_LMT_SALES_COUNTRY_DATA,
    [OPU.LMT]
  );
  return url ? axiosClient.get(url+ (payload === undefined ? '' : payload)) : undefined;
}

export function getScorecardLmtContractDetailTable(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_LMT_CONTRACT_DETAIL_TABLE,
    [OPU.LMT]
  );
  return url ? axiosClient.post(url, payload) : undefined;
}

export function uploadScorecardsFile(payload) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.UPLOAD_SCORECARD_FILE,
    ENDPOINTS.UPLOAD_SCORECARD_FILE_OPU,
    []
  );
  return url ? axiosClient.post(url, payload) : undefined;
}

export function validateScorecardsFile(payload) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.VALIDATE_SCORECARD_FILE,
    ENDPOINTS.VALIDATE_SCORECARD_FILE,
    []
  );
  return url ? axiosClient.post(url, payload) : undefined;
}

export function getPresignedUrlMUltipart(payload, partNumber, uploadId) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    `${ENDPOINTS.GNE_PREVIEW_UPLOAD_FILE}?partNumber=${partNumber}&uploadId=${encodeURIComponent(uploadId)}`,
    `${ENDPOINTS.GNE_PREVIEW_UPLOAD_FILE_OPU}?partNumber=${partNumber}&uploadId=${encodeURIComponent(uploadId)}`,
    []
  );
  return axiosClient.post(url, payload);
}

export function multipartUploadFile(payload, url) {

  return axiosClientFileAnonymous.put(url, payload.byteFile);
}

export function completeMultipartUploadFile(payload) {
  const opu = getOpuApi("Scorecards");
  const url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.COMPLETE_MULTIPART_UPLOAD,
    ENDPOINTS.COMPLETE_MULTIPART_UPLOAD_OPU,
    []
  );
  return axiosClient.post(url, payload);
}

export function getScorecardLmtContractDetail(payload: any) {
  // https://apidev.go.petronas.com/scorecards/api/v1/lmt/operationaldetail/getlmtcontractdetailtable

  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_LMT_CONTRACT_DETAIL,
    [OPU.LMT]
  );
  return url ? axiosClient.post(url, payload) : undefined;
}

export function getScorecardLmtSaleVolume(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    '',
    ENDPOINTS.GET_LMT_SALE_VOLUME,
    [OPU.LMT]
  );
  return url ? axiosClient.post(url, payload) : undefined;
}

export function getSecondaryCards(payload: any) {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GET_GNE_TAB_PERFORMANCE_SECONDARY_CARD,
    '',
    [OPU.GNE]
  );
  return url ? axiosClient.get(url + payload) : undefined;
}

export function getCargoDelivery() {
  const opu = getOpuApi("Scorecards");
  let url = buildApiEndPoint(
    opu as string,
    SCORECARES_API_BASE_URL,
    ENDPOINTS.GET_CARGO_DELIVERY,
    ENDPOINTS.GET_CARGO_DELIVERY_OPU,
    [OPU.GNE, OPU.MLNG, OPU.PFLNG, OPU.LNGA, OPU.LMT]
  );
  return url ? axiosClient.get(url) : undefined;
}
import { get } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchScoreCardMlngHseExecSummary,
    openScorecardSecondaryCardTabModal
}
    from "../../../../../action/actionScoreCard";

import {
    LMT_PAGE_TYPE, 
    LMT_TAB_SCORE_CARD, MONTH_SHORT_TEXT, LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS
}
    from "../../../../../constants/score-card.constants";

import ScorecardExecutiveSummary from "../ExecutiveSummary/ScorecardExecutiveSummary";

import s from '../../SecondaryCard.module.css';

export default function BaseMLNGHssePerformanceSecondary() {
    const dispatch = useDispatch();

    const { isVisibleModal, currentDate, selectedScorecard, selectedTabIndex } = useSelector((state) => {
        const scorecard = get(state, 'scoreCard', null);
        return {
            isVisibleModal: scorecard.isOpenScorecardSecondaryCardModel,
            currentDate: scorecard.currentDate,
            selectedScorecard: scorecard.selectedScorecard,
            selectedTabIndex: scorecard.selectedTabIndex
        };
    });

    const hssePerformanceData = useSelector((state) =>
        get(state, 'scoreCard.hssePeromanceData', [])
    );
    const sectionName = hssePerformanceData.sectionName
    
    function requestExecSummary() {
        const params = `?section=${LMT_TAB_SCORE_CARD.HSSE_PERFORMANCE}&page=${LMT_PAGE_TYPE.SCORECARD}` +
            `&year=${currentDate.year}&month=${MONTH_SHORT_TEXT[currentDate.month - 1]}&key=${selectedScorecard?.name}`;
        dispatch(fetchScoreCardMlngHseExecSummary(params));
    }

    const scorecardTypeId = selectedScorecard ? selectedScorecard.scorecardTypeId : 0

    useEffect(() => {
        if (isVisibleModal && selectedScorecard) {
            switch (scorecardTypeId) {
                // case LMT_HSSE_SCORECARD_TYPE.GROUP_FATALITY:
                //     requestDetail(scorecardTypeId);
                //     dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.GROUP_FATALITY));
                //     break;
                // case LMT_HSSE_SCORECARD_TYPE.MHSEV:
                //     requestDetail(scorecardTypeId);
                //     dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.MHSEV));
                //     break;
                // case LMT_HSSE_SCORECARD_TYPE.SUSTAINABILITY:
                //     requestDetail(scorecardTypeId);
                //     dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.SUSTAINABILITY));
                //     break;
                default:
                    requestExecSummary();
                    dispatch(openScorecardSecondaryCardTabModal(LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.EXECUTIVE_SUMMARY));
                    break;
            }
        }
    }, [selectedScorecard]);


    function renderTab(i) {
        if (i === LMT_SCORECARD_SECONDARY_HSSE_TAB_ENUMS.EXECUTIVE_SUMMARY) {
            return <ScorecardExecutiveSummary isDetailBreakdown={true} sectionName={sectionName}/>
        }
    }

    return (
        <div className={s.modalBody}>
            {renderTab(0)}
        </div>
    )
}


import { CheckOutlined } from "@ant-design/icons";
import React from "react";

import s from "./strategicCard.module.css";

const StrategicCard = ({
  objecttive,
  keyCard,
  handleOpenHighlight,
  activeHighlight,
}) => {
  return (
    <div className={`${s.strategicCard} ${objecttive?.color ? s[objecttive?.color] : s.default }`}>
      <div className={s.cardHeader}>
        <div className={s.cardHeaderLeft}>
          <span className={s.lastUpdated}>LAST UPDATED</span>{" "}
          <span className={s.lastUpdateValue}>{objecttive.lastUpdated}</span>
        </div>
        <div className={s.cardHeaderRight}>
          <span className={s.cardStatus}>{objecttive?.status}</span>
          <span className={s.cardStatusIcon}>
            {objecttive?.status?.toLowerCase() === "completed" ? (
              <CheckOutlined className={s.checkIcon} />
            ) : (
              <>
                <img
                  className={s.cardStatusIconProcess}
                  src="/img/icon/timer_black.svg"
                />
              </>
            )}
          </span>
        </div>
      </div>
      <div className={s.cardContent}>
        {objecttive.objective.map((v, index) => (
          <div key={index}>{v}</div>
        ))}
      </div>
      <div className={s.cardFooter}>
        <div className={s.cardFooterText}>
          <span className={s.highlightLabel}>HIGHLIGHT</span>{" "}
          <img
            src="/img/icon/HighLight.svg"
            className={keyCard === activeHighlight ? s.iconArrowRotate : ""}
            onClick={() =>
              handleOpenHighlight(keyCard === activeHighlight ? null : keyCard)
            }
          />
        </div>
        <div
          className={`${s.cardPanel} ${
            keyCard === activeHighlight ? s.cardPanelExpand : ""
          }`}
        >
          {objecttive.highlights.map((highlight, index) => {
            return (
              <p className={s.highlightContent} key={index}>
                {highlight}
              </p>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StrategicCard;

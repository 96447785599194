import React from 'react';
import s from './primary-info.module.css';
import { Row, Col, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { formatDate, numberWithCommas } from '../../../utils/callout.utils';
import { formatUnit } from '../../../utils/unit.utils';
import { number } from '@amcharts/amcharts4/core';

function PrimaryInfo() {
  const { callOutData } = useSelector((state) => {
    const callout = get(state, 'callout', null);
    return {
      callOutData: callout.calloutData,
    };
  });

  function _formatTimestamp(timestamp){
    if(!timestamp) {
      return `N/A`
    }
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    const formattedTime = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });

    return `${formattedDate} ${formattedTime}`;
  }

  function _renderDetailInfo() {
    return (
      <div className={s.detailInfo}>
        {callOutData?.primaryTags?.map((p) => {
          return (
            <div 
              className={s.detailInfoItem} 
              id={callOutData.locationId === 103 
                ? s.pgu 
                : s.others} /*Use different id to differentiate PGU and others*/
            > 
              <div className={s.infoItem}>
                <Row>
                  <Col span={12} key={p?.tagName}>
                    <p className={s.detailInfoTitle}>{p?.tagName}</p>
                  </Col>
                  <Col span={12}>
                    <div className={s.timeSinceLastColor}>
                      <span
                        className={`${s.colorDot} ${s[p?.primaryValue?.color]}`}
                      />
                      {p?.primaryValue?.color &&
                        p?.dataSource[0]?.timeSinceLastColorChange && (
                          <span className={s.detailInfoText}>
                            {p.dataSource[0].timeSinceLastColorChange}
                          </span>
                        )}
                    </div>
                  </Col>
                </Row>
              </div>
              <Row gutter={8}>
                {p?.dataSource.map((d) => {
                  return (
                    <Col span={12} key={p?.tagName}>
                      <div>
                        <p className={s.detailInfoSubTitle}>
                          {d.dataSourceType}
                        </p>
                        <p className={s.detailInfoText}>
                          <img alt='info' src='/img/icon/ic-time.svg' />
                          &nbsp;&nbsp;
                          <span>{_formatTimestamp(d.timeStamp)}</span>
                        </p>
                        <p className={s.detailInfoText}>
                          <img alt='info' src='/img/icon/ic-db.svg' />
                          &nbsp;&nbsp;
                          <span>{d.dataSourceName}</span>
                        </p>
                        <p className={s.detailInfoText}>
                          <img alt='info' src='/img/icon/ic-tag.svg' />
                          &nbsp;&nbsp;
                          <span>{d.dataSourceTarget}</span>
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={s.mapRightPanelPrimaryInfo}>
      <Row gutter={16}>
        <Col span={6}>
          <div className={s.primaryInfoImage}>
            <img
              src={
                callOutData?.imageUrl === ''
                  ? '/callout-images/vessels/Default.png'
                  : callOutData?.imageUrl
              }
              alt='vessel'
            />
          </div>
        </Col>
        <Col span={18}>
          <div className={s.primaryInfoContent}>
            <Row gutter={8}>
              <Col span={22}>
                <div className={s.contentDetail}>
                  <Row gutter={[16, 16]}>
                    {callOutData?.primaryTags?.map((p, i) => {
                      const primaryTagsNumber =
                        callOutData?.primaryTags?.length;
                      const isCouple = primaryTagsNumber === 2;
                      if (isCouple && i === 0) {
                        return (
                          <>
                            <Col span={12}>
                              <div className={s.infoItem}>
                                <Row>
                                  <Col span={2}>
                                    <span
                                      className={`${s.colorDot} ${
                                        s[p?.primaryValue?.color]
                                      }`}
                                    />
                                  </Col>
                                  <Col span={22}>
                                    <span className={s.infoTitle}>{p?.tagName}</span>
                                    <p className={s.infoNumber}>
                                      <span className={s.primaryNumber}>
                                        {numberWithCommas(
                                          p?.primaryValue?.value
                                        )}
                                      </span>
                                      &nbsp;
                                      <span className={s.numberUnit}>
                                        {formatUnit(p?.primaryValue?.unit)}
                                      </span>
                                    </p>
                                    <p className={s.infoSub}>
                                      {p?.primaryValue?.thresholdText}{' '}
                                      {p?.primaryValue?.thresholdValue?.toLocaleString("en-US") !== null && (
                                        <>
                                          <b>
                                            {p?.primaryValue?.thresholdValue?.toLocaleString("en-US")}
                                          </b>
                                          &nbsp;
                                          <span className={s.subUnit}>
                                            {p?.primaryValue?.thresholdText === 'No target set' ? '' : formatUnit(p?.primaryValue?.unit)}
                                          </span>
                                        </>
                                      )}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                            <Col span={12}></Col>
                          </>
                        );
                      }
                      return (
                        <Col span={12}>
                          <div className={s.infoItem}>
                            <Row>
                              <Col span={2}>
                                <span
                                  className={`${s.colorDot} ${  
                                    s[p?.tagName === 'GPK Production' ? 'green' : p?.primaryValue?.color]
                                  }`}
                                />
                              </Col>
                              <Col span={22}>
                                <p 
                                  className={s.infoTitle} 
                                  style={
                                    {fontWeight: p?.tagName === "Total Demand" 
                                    ? 500 
                                    : undefined}
                                  }
                                >
                                  {p?.tagName}
                                </p>
                                <p className={s.infoNumber}>
                                  <span className={s.primaryNumber}>
                                    {numberWithCommas(p?.primaryValue?.value)}
                                  </span>
                                  &nbsp;
                                  <span className={s.numberUnit}>
                                    {formatUnit(p?.primaryValue?.unit)}
                                  </span>
                                </p>
                                <p className={s.infoSub}>
                                  {p?.primaryValue?.thresholdText}{' '}
                                    <>
                                      <b>{p?.tagName === 'GPK Production' ? 571 : p?.primaryValue?.thresholdValue?.toLocaleString("en-US")}</b>
                                      &nbsp;
                                      <span className={s.subUnit}>
                                        {p?.primaryValue?.thresholdText === 'No target set' ? '' : formatUnit(p?.primaryValue?.unit)}
                                      </span>
                                    </>
                                  {/* )} */}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              </Col>
              <Col span={2}>
                <Tooltip
                  overlayClassName=
                    {callOutData.locationId === 103 
                    ? s.callOutTooltipOverlayPGU 
                    : s.callOutTooltipOverlay} /*Use different class name to differentiate PGU and others*/
                  placement='bottomLeft'
                  title={() => _renderDetailInfo()}
                  trigger='click'
                >
                  <div className={s.contentMoreDetail}>
                    <img alt='info' src='/img/icon/ic-info.svg' />
                  </div>
                </Tooltip>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PrimaryInfo;

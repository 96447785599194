export const LOCATION = {
  UKUG: 'UK / UG',
  PLC: 'PETRONAS LNG Complex (PLC)',
  PGU: 'Peninsular Gas Utilization (PGU)',
};

export const CALLOUT_TYPE = {
  DEFAULT: 1,
  UKUG: 6,
  RGTSU: 5,
  BerthingSGUDang: 3,
  PLC: 4,
  PGU: 7,
  RGT: 8,
  LNG_DELIVERY: 9
};

export const CALLOUT_BERTH = [36, 37, 38];

export const FLOWRATE_ORDER = {
  NORTHERN: 1,
  CENTRAL: 2,
  SOUTHERN: 3,
  EASTERN: 4
};

export const CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS = {
  HOURLY: 'HOURLY',
  CUMULATIVE: 'CUMULATIVE MONTHLY'
}

export const CALLOUT_DAILY_CUMULATIVE_OPTIONS = [CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS.HOURLY, CALLOUT_DAILY_CUMULATIVE_OPTIONS_ENUMS.CUMULATIVE]